import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Brush,
} from "recharts";
import MuiSwitch from "../MuiSwitch";
import { commonXaxisProp } from "../../const/analytics";
const OfferDistribution = () => {
  const labelStyle = {
    fontFamily: "Proxima Nova A",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "17.05px",
    textAlign: "left",
  };
  return (
    <Stack direction={"row"} gap={"28px"} width={"100%"} alignItems={"center"}>
      <Typography sx={labelStyle}>OFFER DISTRIBUTION </Typography>
      <MuiSwitch />
    </Stack>
  );
};
const CustomAreaChart = ({
  color,
  width,
  legend,
  data,
  strokeColor,
  backgroundColor,
  labels,
  enbaleOfferDistribution,
}) => {
  const startDate = data?.length > 9 ? data.length - 10 : 0;
  const endDate = data?.length > 9 ? data.length - 1 : data?.length - 1 ?? 0;
  return (
    <Box
      sx={{
        width: "100%",
        height: "390px",
        borderRadius: "8px",
        padding: "25px 20px",
        boxShadow: "0px 4px 20px 0px #0000001A",
        backgroundColor: "#fff",
        boxSizing: "border-box",
        mt: "18px",
      }}
    >
      {enbaleOfferDistribution && (
        <Box
          sx={{
            paddingLeft: "25px",
            paddingBottom: "8px",
          }}
        >
          {<OfferDistribution />}
        </Box>
      )}
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        padding={"2px 0 16px 2px"}
        marginLeft={"18px"}
      >
        <Typography
          style={{
            fontFamily: "Proxima Nova A",
            fontSize: 14,
            fontWeight: 500,
            lineHeight: "17px",
            letterSpacing: "1px",
          }}
        >
          {legend?.label}
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "12px",
              height: "1px",
              background: "#21CBE2",
            }}
          ></Box>
          <Typography
            style={{
              fontFamily: "Proxima Nova A",
              textTransform: "capitalize",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "17px",
              letterSpacing: "2px",
              color: "#949494",
            }}
          >
            {legend?.sublabel}
          </Typography>
        </Box>
      </Stack>
      <ResponsiveContainer
        width="100%"
        height={enbaleOfferDistribution ? "85%" : "90%"}
      >
        <AreaChart data={data}>
          <CartesianGrid vertical={false} horizontal={false} />
          <defs>
            <linearGradient
              id={`color${backgroundColor}`}
              x1="0"
              y1="0"
              x2="0"
              y2="1"
            >
              <stop
                offset="0%"
                stopColor={backgroundColor}
                stopOpacity={0.4}
              ></stop>
              <stop
                offset="100%"
                stopColor={backgroundColor}
                stopOpacity={0.05}
              ></stop>
            </linearGradient>
          </defs>
          <XAxis dataKey="name" {...commonXaxisProp} />
          <YAxis
            tick={{ stroke: "#949494" }}
            fontFamily="ROBOTO"
            fontSize={"11px"}
            fontWeight={500}
            letterSpacing={"1px"}
          />
          {data.length > 10 && (
            <Brush
              dataKey="name"
              startIndex={startDate}
              endIndex={endDate}
              height={15}
              stroke="#8884D8"
            />
          )}
          <Tooltip />
          {!labels ? (
            <Area
              type="linear"
              dataKey="amt"
              stroke={strokeColor}
              strokeWidth={2}
              fill={`url(#color${backgroundColor})`}
            />
          ) : (
            labels &&
            labels?.map((res) => (
              <Area
                type="linear"
                dataKey={res}
                stroke={strokeColor}
                strokeWidth={2}
                fill={`url(#color${backgroundColor})`}
              />
            ))
          )}
        </AreaChart>
      </ResponsiveContainer>
    </Box>
  );
};
export default CustomAreaChart;