import { applyMiddleware, combineReducers, legacy_createStore } from "redux";
import { reducer as CreateRuleReducer } from "./CreateRuleReducer/reducer";
import { reducer as OfferReducer } from "./OfferReducer/reducer";
import { reducer as AnalyticsReducer } from "./AnalyticsReducer/reducer";
import { reducer as AuthReducer } from "./AuthReducer/reducer";
import { thunk } from "redux-thunk";

const rootReducer = combineReducers({CreateRuleReducer,OfferReducer,AnalyticsReducer,AuthReducer})
export const Store = legacy_createStore(rootReducer,applyMiddleware(thunk))



// import { applyMiddleware, combineReducers, legacy_createStore } from "redux";
// import { reducer as CreateRuleReducer } from "./CreateRuleReducer/reducer";
// import { reducer as OfferReducer } from "./OfferReducer/reducer";
// import { reducer as AnalyticsReducer } from "./AnalyticsReducer/reducer";
// import { reducer as AuthReducer } from "./AuthReducer/reducer";
// import {thunk} from "redux-thunk";

// // Combine all reducers
// const rootReducer = combineReducers({
//   CreateRuleReducer,
//   OfferReducer,
//   AnalyticsReducer,
//   AuthReducer
// });

// // Function to load state from localStorage
// const loadState = () => {
//   try {
//     const serializedState = localStorage.getItem('reduxState');
//     if (serializedState === null) {
//       return undefined; // No state in localStorage, return undefined to use initial state
//     }
//     return JSON.parse(serializedState);
//   } catch (err) {
//     console.error("Could not load state", err);
//     return undefined;
//   }
// };

// // Function to save state to localStorage
// const saveState = (state) => {
//   try {
//     const serializedState = JSON.stringify(state);
//     localStorage.setItem('reduxState', serializedState);
//   } catch (err) {
//     console.error("Could not save state", err);
//   }
// };

// // Load the persisted state
// const persistedState = loadState();

// // Create the store with thunk middleware and persisted state
// export const Store = legacy_createStore(
//   rootReducer,
//   persistedState, // Initialize store with persisted state
//   applyMiddleware(thunk)
// );

// // Subscribe to store changes and save the state to localStorage
// Store.subscribe(() => {
//   saveState(Store.getState());
// });
