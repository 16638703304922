import React from "react";
import AccessDenied from "../assets/AccessDenied.png";
import { Box, Typography } from "@mui/material";

export default function NotFound() {
  return (
    <Box
      sx={{
        height: "90vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box sx={{ width: "577px" }}>
        <img
          style={{ height: "88px", width: "88px" }}
          src={AccessDenied}
          alt=""
        />
        <Typography
          sx={{
            fontFamily: "Mulish",
            fontWeight: 700,
            fontSize: "24px",
            lineHeight: "30.12px",
            marginTop:"18px"
          }}
        >
          Access Denied
        </Typography>
        <Typography
          sx={{
            fontFamily: "Mulish",
            fontWeight: 400,
            fontSize: "17px",
            lineHeight: "22px",
            color: "#384151",
            marginTop:"12px",
            textAlign: "center",
          }}
        >
          You do not have permission to access this section.
        </Typography>
        <Typography
          sx={{
            fontFamily: "Mulish",
            fontWeight: 400,
            fontSize: "17px",
            lineHeight: "22px",
            color: "#384151",
            textAlign: "center",
          }}
        >
         Please contact your administrator if 
        </Typography>
        <Typography
          sx={{
            fontFamily: "Mulish",
            fontWeight: 400,
            fontSize: "17px",
            lineHeight: "22px",
            color: "#384151",
            // marginTop:"12px",
            textAlign: "center",
          }}
        >
        you believe this is an error.
        </Typography>
      </Box>
    </Box>
  );
}
