import styled from "@emotion/styled";
import { Tooltip, tooltipClasses } from "@mui/material";

export const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "#000",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#000",
      maxWidth: 500,
    },
  }));