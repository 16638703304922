import {
  getTypeOption,
  onMount,
  resetCreateRuleData,
  setCreateCohortChanges,
  setCreateRuleChanges,
  storeProductId,
} from "./actionType";

const initialState = {
  select1: [],
  typeOption: [],
  productId: "",
  createRuleData: {
    name: "",
    description: "",
    whereSection: [{data:[{}],type:"AND"}],
  },
  createCohortData: {
    name: "",
    description: "",
    cohortActionType: {},
    csvUrl:"",
    csvFile:"",
    whereSection: [{data:[{}],type:"AND"}],
  },
};

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case onMount: {
      return { ...state, select1: payload };
    }
    case getTypeOption:
      return { ...state, typeOption: payload };
    case storeProductId:
      return { ...state, productId: payload };
    case setCreateRuleChanges:
      return {
        ...state,
        createRuleData: {
          ...state.createRuleData,
          ...payload,
        },
      };
    case resetCreateRuleData:
      return {
        ...state,
        createRuleData: {
          name: "",
          description: "",
          whereSection: [{data:[{}],type:"AND"}],
        },
      };
    case setCreateCohortChanges:
      return {
        ...state,
        createCohortData: {
          ...state.createCohortData,
          ...payload,
        },
      };
    default:
      return state;
  }
};
