import React, { useEffect, useState } from "react";
import UserCohortsFileUpload from "../components/userCohorts/UserCohortsFileUpload";
import { Box, Button, Typography } from "@mui/material";
import Label from "../UIComponents/Label";
import MuiInput from "../UIComponents/MuiInput";
import MuiTextarea from "../UIComponents/MuiTextarea";
import { isValidUserCohortData } from "../utils/validations";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import MuiSelect from "../UIComponents/MuiSelect";
import { getRuleSetFunction } from "../Redux/OfferReducer/action";
import MuiSelectAndSearch from "../UIComponents/MuiSelectAndSearch";
import MuiButton from "../UIComponents/MuiButton";
import sampleCsv from '../assets/Voucher.csv';

// const initialData = [{
//   csvFile: "",
//   url: "",
//   name: "",
//   description: "",
// }];

const SingleOption = [
  { label: "Static", value: "STATIC" },
  { label: "Dynamic", value: "DYNAMIC" },
];

const CreateCollection = ({
  // initialData = getInitialData,
  activeTab,
  tabData,
  setTabData,
  handleCancel,
  handleCreateRule,
  cancelButtonHide = false,
}) => {
  // const [data, setData] = useState({ initialData });
  // const [error, setError] = useState(initialData);
  const dispatch = useDispatch();
  const ruleData = useSelector((store) => store.OfferReducer.ruleOption);
  const token = useSelector((store) => store?.AuthReducer?.token ?? "");
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const ruleOption = ruleData.map((element) => {
    return { name: element.name, id: element.id };
  });

  const uploadCsvFile = async (file, json_data, type) => {
    if (!token) return false;
    const url = `${process.env.REACT_APP_API_BASE_URL}/api/admin/create-cohorts`;
    const options = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };
    const formData = new FormData();
    if (type === "STATIC") {
      formData.append("CollectionItemsCSV", file);
    }
    formData.append("json_data", JSON.stringify(json_data));

    try {
      console.log(formData, "formData");
      const response = await axios.post(url, formData, options);
      console.log(response, "response");
      if (response.status === 200) {
        // Proceed with your logic here
        return true;
        // You can access response data if needed like: response.data
      }
    } catch (error) {
      console.log("Error While Uploading File", error);
      toast.error(error.response?.data?.message);
      return false;
    }
  };

  const handleChange = (key, value) => {
    if (key === "csvFile" && value) {
      // Check if the selected file is a CSV file
      if (value.type !== "text/csv") {
        toast.error("Please select a CSV file.");
        return;
      }
    }
    setTabData((prevTabData) => {
      return prevTabData.map((tab, index) => {
        if (index === activeTab) {
          if (key === "type") {
            if (value === "STATIC") {
              return {
                ...tab,
                [key]: value,
                linkToRule: "",
              };
            } else {
              return {
                ...tab,
                [key]: value,
                csvFile: "",
              };
            }
          } else {
            return {
              ...tab,
              [key]: value,
            };
          }
        }
        return tab;
      });
    });
  };
  console.log(tabData, "tab");

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = sampleCsv;
    link.download = 'Voucher.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleSubmit = async () => {
    const name = tabData[activeTab]?.name;
    const description = tabData[activeTab]?.description;
    const linkToRule = tabData[activeTab]?.linkToRule;
    const type = tabData[activeTab]?.type;
    const json_data = {
      name: name,
      description: description,
      type: tabData[activeTab]?.type,
    };

    if (linkToRule) {
      json_data.ruleSetId = linkToRule.id;
    }

    console.log(json_data, "json_data");

    if (!tabData[activeTab]?.csvFile && tabData[activeTab].type === "STATIC") {
      toast.error("CSV file is required");
      return;
    }

    if (
      !tabData[activeTab]?.linkToRule?.id &&
      tabData[activeTab].type === "DYNAMIC"
    ) {
      toast.error("Rule is required");
      return;
    }

    if (!name) {
      toast.error("Name is required");
      return;
    }

    if (!description) {
      toast.error("Description is required");
      return;
    }

    const FileUpload = await uploadCsvFile(
      tabData[activeTab]?.csvFile,
      json_data,
      type
    );

    if (!FileUpload) return;

    toast.success("Cohort Created Successfully");
    setTimeout(() => {
      if (pathname === "/userCohorts-create") {
        navigate("/userCohorts-view");
        setTabData([
          {
            csvFile: "",
            name: "",
            description: "",
          },
        ]);
      } else {
        handleCancel(tabData[activeTab], activeTab);
      }
    }, 2000);
  };

  useEffect(() => {
    dispatch(getRuleSetFunction());
  }, []);
  console.log(tabData[activeTab]);
  return (
    <Box
      sx={{
        mx: pathname == "/userCohorts-create" ? "20px" : "0px",
        my: pathname == "/userCohorts-create" ? "20px" : "0px",
        backgroundColor: "white",
      }}
    >
      <ToastContainer />

      <Box
        sx={{
          marginBottom: "20px",
          maxWidth: "470px",
          // marginLeft: pathname == "/userCohorts-create" ? "13px" : "5px",
          px: pathname == "/userCohorts-create" ? "10px" : "0px",
          // backgroundColor:"white"
        }}
      >
        <Label label="1.Cohort Name" />
        <MuiInput
          value={tabData[activeTab]?.name}
          onChange={(value) => handleChange("name", value)}
          placeholder="40 Characters"
          // errorMessage={error?.name}
        />
      </Box>
      <Box
        sx={{
          marginBottom: "20px",
          maxWidth: "470px",
          marginLeft: "5px",
          //   px: pathname == "/userCohorts-create" ? "10px" : "0px",
        }}
      >
        <Label label="2.Cohort Description" />
        <MuiTextarea
          placeholder="200 Characters"
          minRows={3}
          value={tabData[activeTab]?.description}
          onChange={(value) => handleChange("description", value)}
          // errorMessage={error?.description}
        />
      </Box>
      <Box
        sx={{
          marginBottom: "20px",
          maxWidth: "470px",
          marginLeft: "5px",
          //   px: pathname == "/userCohorts-create" ? "10px" : "0px",
        }}
      >
        <Label label="3. Cohort Type" />
        <MuiSelect
          value={tabData[activeTab]?.type}
          options={SingleOption}
          onChange={(value) => handleChange("type", value)}
        />
      </Box>
      <Box>
        {tabData[activeTab]?.type === "STATIC" ? (
          <Box
            sx={{
              maxWidth: "470px",
              marginBottom: "20px",
              marginLeft: "5px",
              //   px: pathname == "/userCohorts-create" ? "10px" : "0px",
            }}
          >
            <Box sx={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
              <Label label="4.Upload CSV" />

              <Typography
                onClick={handleDownload}
                style={{
                  fontFamily: "Mulish",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "17.57px",
                  color: "#0F1828",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                Download Sample
              </Typography>
            </Box>

            <UserCohortsFileUpload
              data={tabData[activeTab]}
              textVisibility={false}
              // errorMessage={error}
              onChange={(key, value) => handleChange(key, value)}
            />
          </Box>
        ) : tabData[activeTab]?.type === "DYNAMIC" ? (
          <Box
            sx={{
              display: "flex",
              gap: 5,
              alignItems: "end",
              flexWrap: "wrap",
              mb: "40px",
            }}
          >
            <Box sx={{ width: "470px", marginLeft: "5px" }}>
              <Label label="4. Link To Rule" />

              <MuiSelectAndSearch
                value={tabData[activeTab]?.linkToRule}
                onChange={(value) => handleChange("linkToRule", value)}
                options={ruleOption}
                placeholder="Link To Rule"
              />
            </Box>

            <Typography
              sx={{
                pb: "15px",
                color: "#000",
                fontFamily: "ABeeZee",
                fontSize: "15px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
              }}
            >
              OR
            </Typography>

            <Box sx={{ pb: "5px" }}>
              <MuiButton onClick={handleCreateRule}>Create Rule</MuiButton>
            </Box>
          </Box>
        ) : null}
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          pt: 2,
          pl: 1,
          paddingBottom: "20px",
          marginLeft: "10px",
          px: pathname == "/userCohorts-create" ? "10px" : "0px",
        }}
      >
        <Button
          color="inherit"
          //   onClick={handleBack}
          sx={{ mr: 1 }}
          style={{
            display: cancelButtonHide ? "none" : "visible",
            backgroundColor: "white",
            color: "black",
            padding: "8px 30px",
            marginRight: "20px",
          }}
          variant="outlined"
          size="small"
          onClick={() => handleCancel(tabData[activeTab], activeTab)}
        >
          Cancel
        </Button>
        <Button
          style={{
            backgroundColor: "black",
            color: "white",
            padding: "8px 30px",
          }}
          variant="contained"
          size="small"
          onClick={handleSubmit}
          sx={{ mr: 1 }}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default CreateCollection;
