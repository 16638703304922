import { Box, CircularProgress } from '@mui/material'
import React from 'react'

const CircularLoader = () => {
  return (
    <Box sx={{
        display : "flex",
        height : "30vh",
        justifyContent : "center",
        alignItems : "center"
       }}>
        <CircularProgress style={{color : "#26292D"}}/>
       </Box>
  )
}

export default CircularLoader