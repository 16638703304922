import { Box, Button, Typography } from "@mui/material";
import React from "react";
import BoldLabel from "../../UIComponents/BoldLevel";
import MuiInput from "../../UIComponents/MuiInput";
import MuiTextarea from "../../UIComponents/MuiTextarea";
import UserCohortsFileUpload from "../../components/userCohorts/UserCohortsFileUpload";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import arrow from "../../assets/arrow-left.svg";
import { setCreateCohortChanges } from "../../Redux/CreateRuleReducer/actionType";
import MuiSelectAndSearch from "../../UIComponents/MuiSelectAndSearch";
import UrlUpload from "../../UIComponents/UrlUpload";
import MuiSelect from "../../UIComponents/MuiSelect";
import deleteLogo from "../../assets/redDeleteBtn.png";
import addLogo from "../../assets/add.svg";
import { FaPlus } from "react-icons/fa6";

const options = [
  { label: "AND", value: "AND" },
  { label: "OR", value: "OR" },
];

const options2 = [
  { id: "AND", name: "AND" },
  { id: "OR", name: "OR" },
];

export default function CreateCohortPage() {
  const data = useSelector((store) => store.CreateRuleReducer.createCohortData);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleData = (key, value) => {
    dispatch({
      type: setCreateCohortChanges,
      payload: { [key]: value },
    });
  };

  const handleWhereSectionChange = (index, groupIndex, key, value) => {
    const updatedWhereSection = [...data.whereSection];
    updatedWhereSection[index].data[groupIndex][key] = value;
    handleData("whereSection", updatedWhereSection);
  };

  const handleAddCriteria = () => {
    const newCriteria = {
      data: [
        {
          role: "",
          operator: "",
          value: "",
          divider: "AND",
        },
      ],
      type: "AND",
    };
    handleData("whereSection", [...data.whereSection, newCriteria]);
  };

  const handleDeleteCriteria = (index) => {
    if (data.whereSection.length > 1) {
      const updatedWhereSection = [...data.whereSection];
      updatedWhereSection.splice(index, 1);
      handleData("whereSection", updatedWhereSection);
    }
  };

  const handleAddGroup = (index) => {
    const newGroup = {
      role: "",
      operator: "",
      value: "",
      divider: "AND",
    };
    const updatedWhereSection = [...data.whereSection];
    updatedWhereSection[index].data = [
      ...updatedWhereSection[index].data,
      newGroup,
    ];
    handleData("whereSection", updatedWhereSection);
  };

  const handleDeleteGroup = (index, groupIndex) => {
    const updatedWhereSection = [...data.whereSection];
    updatedWhereSection[index].data = updatedWhereSection[index].data.filter(
      (_, i) => i !== groupIndex
    );
    handleData("whereSection", updatedWhereSection);
  };

  console.log(data, "cohortData");
  return (
    <Box
      sx={{
        margin: "20px",
        padding: "20px",
        backgroundColor: "#FFFFFF",
        borderRadius: "8px",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <Button
          sx={{ minWidth: "0px" }}
          onClick={() =>
            navigate("/offers/create-offers/set-qualifying-criteria")
          }
        >
          <img style={{ height: "16px", width: "16px" }} src={arrow} alt="" />
        </Button>
        <Typography
          sx={{
            fontFamily: "Lato",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "20px",
            color: "#0B1B32",
          }}
        >
          Create New Rule
        </Typography>
      </Box>

      <Box>
        <Box sx={{ width: "500px" }}>
          <BoldLabel label="Rule Name" />
          <MuiInput
            value={data?.name}
            onChange={(e) => handleData("name", e)}
            placeholder="40 characters"
          />
        </Box>
        <Box sx={{ width: "500px" }}>
          <BoldLabel label="Rule Description" />
          <MuiTextarea
            value={data.description}
            onChange={(e) => handleData("description", e)}
            placeholder="200 characters"
          />
        </Box>
        <Box sx={{ width: "500px" }}>
          <BoldLabel label="Select Cohort Action" />
          <MuiSelectAndSearch
            options={[
              { name: "Cohort Rule", id: "cohort_rule" },
              { name: "Upload CSV", id: "upload_csv" },
            ]}
            value={data.cohortActionType}
            onChange={(value) => handleData("cohortActionType", value)}
          />
        </Box>

        <Box
          sx={{
            width: "500px",
            mt: "20px",
            display:
              data.cohortActionType?.id === "upload_csv" ? "block" : "none",
          }}
        >
          <UserCohortsFileUpload
            data={data}
            // errorMessage={error}
            onChange={(key, value) => handleData(key, value)}
          />
          <BoldLabel label="Or Upload from URL" mb="8px" />
          <UrlUpload
            placeholder="Add file URL"
            handleUpload={() => null}
            value={data?.CsvUrl}
            onChange={(value) => handleData("CsvUrl", value)}
          />
        </Box>

        <Box
          sx={{
            display:
              data?.cohortActionType?.id === "cohort_rule" ? "block" : "none",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Lato",
              fontWeight: 700,
              fontSize: "16px",
              lineHeight: "20px",
              color: "#0B1B32",
              marginTop: "25px",
              marginBottom: "20px",
              textAlign: "left",
            }}
          >
            Where
          </Typography>

          <Box>
            {data?.whereSection?.map((item, index) => (
              <Box key={index}>
                <Box
                  sx={{
                    display: index === 0 ? "none" : "flex",
                    alignItems: "center",
                    width: "100%",
                    gap: "26px",
                    borderRadius: "8px",
                    padding: "14px",
                    width: "calc(100%-48px)",
                    mt: "14px",
                    mb: "19px",
                    height: "32px",
                  }}
                >
                  <Box
                    sx={{ border: "1px solid #0B1B32", width: "100%" }}
                  ></Box>
                  <Box sx={{ width: "180px" }}>
                    <MuiSelect
                      backgroundColor="black"
                      selectedBackgroundColor="black"
                      textColor="white"
                      selectedTextColor="white"
                      hoverBackgroundColor="gray"
                      options={options}
                      value={item.type}
                      onChange={(e) =>
                        handleData("whereSection", [
                          ...data.whereSection.slice(0, index),
                          { ...item, type: e },
                          ...data.whereSection.slice(index + 1),
                        ])
                      }
                    />
                  </Box>
                  <Box
                    sx={{ border: "1px solid #0d1520", width: "100%" }}
                  ></Box>
                </Box>

                <Box
                  mb="20px"
                  sx={{
                    width: "calc(100%-28px)",
                    backgroundColor: "#F4F8FC",
                    borderRadius: "8px",
                    padding: "14px",
                  }}
                >
                  {item?.data?.length > 0 &&
                    item?.data?.map((group, groupIndex) => (
                      <Box key={groupIndex}>
                        <Box
                          sx={{
                            display: groupIndex === 0 ? "none" : "flex",
                            alignItems: "center",
                            width: "calc(100%-68px)",
                            gap: "26px",
                            mt: "14px",
                            mb: "19px",
                            height: "32px",
                          }}
                        >
                          <Box
                            sx={{ border: "1px solid #9CBFEB", width: "100%" }}
                          ></Box>
                          <Box sx={{ width: "180px" }}>
                            <MuiSelect
                              options={options}
                              value={group.divider}
                              textColor="#003D86"
                              backgroundColor="#E8F0F9"
                              onChange={(e) =>
                                handleWhereSectionChange(
                                  index,
                                  groupIndex,
                                  "divider",
                                  e
                                )
                              }
                            />
                          </Box>
                          <Box
                            sx={{ border: "1px solid #9CBFEB", width: "100%" }}
                          ></Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "20px",
                            alignItems: "end",
                            width: "calc(100%-56px)",
                            backgroundColor:
                              item.data.length > 1 ? "#D5E8F6" : "",
                            padding: item.data.length > 1 ? "14px" : "",
                            borderRadius: "8px",
                          }}
                        >
                          <Box sx={{ width: "100%" }}>
                            <BoldLabel label="Role" mt="0px" />
                            <MuiSelectAndSearch
                              options={options2}
                              value={group.role}
                              onChange={(e) =>
                                handleWhereSectionChange(
                                  index,
                                  groupIndex,
                                  "role",
                                  e
                                )
                              }
                            />
                          </Box>
                          <Box sx={{ width: "100%" }}>
                            <BoldLabel label="Operator" mt="0px" />
                            <MuiSelectAndSearch
                              options={options2}
                              value={group.operator}
                              onChange={(e) =>
                                handleWhereSectionChange(
                                  index,
                                  groupIndex,
                                  "operator",
                                  e
                                )
                              }
                            />
                          </Box>
                          <Box sx={{ width: "100%", mb: "4px" }}>
                            <BoldLabel label="Add Value" mt="0px" />
                            <MuiInput
                              value={group.value}
                              onChange={(e) =>
                                handleWhereSectionChange(
                                  index,
                                  groupIndex,
                                  "value",
                                  e
                                )
                              }
                              placeholder=""
                            />
                          </Box>
                          <Button
                            sx={{
                              minWidth: "40px",
                              height: "40px",
                              borderRadius: "6px",
                              backgroundColor: "#FC55551A",
                              mb: "4px",
                              display: item.data.length > 1 ? "block" : "none",
                            }}
                            onClick={() => handleDeleteGroup(index, groupIndex)}
                          >
                            <img
                              style={{ height: "25px", width: "25px" }}
                              src={deleteLogo}
                              alt=""
                            />
                          </Button>
                          <Box sx={{ ml: "5%", mb: "4px" }}>
                            <Button onClick={() => handleAddGroup(index)}>
                              <img src={addLogo} alt="" />
                              &nbsp;
                              <Typography
                                sx={{
                                  fontFamily: "Lato",
                                  fontWeight: 700,
                                  fontSize: "12px",
                                  lineHeight: "14.4px",
                                }}
                              >
                                Add
                              </Typography>
                              &nbsp;
                              <Typography
                                sx={{
                                  fontFamily: "Lato",
                                  fontWeight: 700,
                                  fontSize: "12px",
                                  lineHeight: "14.4px",
                                }}
                              >
                                Group
                              </Typography>
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    ))}
                </Box>
              </Box>
            ))}
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: "14px" }}>
            <Button
              sx={{
                width: "151px",
                height: "28px",
                backgroundColor: "#003D86",
                borderRadius: "6px",
                color: "#FFFFFF",
                "&:hover": {
                  backgroundColor: "#003D86",
                },
              }}
              onClick={handleAddCriteria}
            >
              <FaPlus />
              <Typography
                sx={{
                  fontFamily: "Lato",
                  fontSize: "12px",
                  lineHeight: "14.4px",
                  fontWeight: 500,
                  marginLeft: "6px",
                  textTransform: "capitalize",
                }}
              >
                Create New Criteria
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          gap: "16px",
          mt: "40px",
        }}
      >
        <Button
          sx={{
            color: "#003D86",
            fontFamily: "Lato",
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "16.8px",
            backgroundColor: "#E8F0F9",
            width: "136px",
            height: "30px",
            borderRadius: "6px",
            "&:hover": {
              backgroundColor: "#E8F0F9",
            },
          }}
        >
          Cancel
        </Button>
        <Button
          sx={{
            color: "#FFFFFF",
            fontFamily: "Lato",
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "16.8px",
            backgroundColor: "#003D86",
            width: "136px",
            height: "30px",
            borderRadius: "6px",
            "&:hover": {
              backgroundColor: "#003D86",
            },
          }}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
}
