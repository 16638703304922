export const CreateGame_PreData = () => {
  const ctaColor = "#FFA500";
  const ctaTextColor = "#EBD8FC";
  const color = "#EBD8FC";

  return {
    ctaColor,
    ctaTextColor,
    color,
  };
};

export function transformRewardDetails(rewardDetails) {
  const result = {};
  const cashbackType = rewardDetails.cashback_type;

  for (const [key, value] of Object.entries(rewardDetails)) {
    if (key === "cashback_type") {
      // result[key] = value;
    } else {
      result[`${cashbackType}_${key}`] = value;
    }
  }

  return result;
}

export function convertRewardDetails(rewardDetails) {
  const { cashback_type, ...otherDetails } = rewardDetails;

  return Object.entries(otherDetails).reduce((acc, [key, value]) => {
    acc[`${cashback_type}_${key}`] = value.toString();
    return acc;
  }, {});
}

export const fetchCsvFileFromUrl = async (url) => {
  try {
    const response = await fetch(new URL(url).pathname);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const blob = await response.blob();
    const fileName = url.split("/").pop();
    const file = new File([blob], fileName, { type: "text/csv" });

    return file;
    // onChange({
    //   ...data,
    //   csvFile: file,
    //   csvUrl: url,
    //   csvFileName: fileName,
    // });

    console.log("File fetched successfully:", file);
  } catch (error) {
    console.error("Error fetching file:", error.message);
    // onChange({ ...data, csvError: `Error fetching CSV file: ${error.message}` });
  }
};

export const convertLimitArrayToObject = (data) => {
  return data.reduce((result, item) => {
    if (item.type && item.type.id && item.amount !== undefined) {
      result[item.type.id] = item.amount;
    }
    return result;
  }, {});
};

export function convertLimitToRequiredFormat(resultData) {
  return Object.entries(resultData).map(([id, amount]) => ({
    time_required: true,
    amount_required: true,
    type: {
      id: id,
      name: id.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
    },
    amount: amount
  }));
}