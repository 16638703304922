import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { initialOfferEditValue } from "../Redux/OfferReducer/actionType";
import OfferPreview from "../components/offers/OfferPreview";
import { Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import { convertRewardDetails, fetchCsvFileFromUrl } from "../utils/CreateGame_PreData";

const offerDataValue = () => {
  return {
    basicOffers: {
      category: "",
      subCategory: "",
      product: "",
    },
    offerDetails: {
      merchant: "",
      title: "",
      description: "",
      startDate: null,
      endDate: null,
      offerInformationForPWABefore: "",
      offerInformationForPWAAfter: "",
      TAndC: "",
      coverPhoto: "",
      coverPhotoId: "",
      offerInitiative: "",
      gameType: "",
      rewardCurrency: "",
    },
    createGame: {
      merchantLogo: "",
      merchantLogoId: "",
      scratchCardColor: "",
      offerTitleColor: "",
      scratchCardImage: "",
      scratchCardImageId: "",
      scratchCardImageUrl: "",
      merchantLogoUrl: "",
    },
    setOfferRules: {
      linkToRule: "",
      linkToCohort: "",
    },
    offerLimit: {
      offerLevelLimits: {
        fairUsageOfRewardIssuance: {
          hourly: "",
          daily: "",
          weekly: "",
          monthly: "",
        },
        fairUsageOfRewardRedemption: {
          hourly: "",
          daily: "",
          weekly: "",
          monthly: "",
        },
      },
      userLevelLimits: {
        fairUsageOfRewardIssuance: {
          hourly: "",
          daily: "",
          weekly: "",
          monthly: "",
          totalAmount: "",
        },
        fairUsageOfRewardRedemption: {
          hourly: "",
          daily: "",
          weekly: "",
          monthly: "",
          totalAmount: "",
        },
      },
    },
  };
};

export default function PreviewOffer() {
  const [offerData, setOfferData] = useState(offerDataValue());
  const [coverPhoto, setCoverPhoto] = useState(null);
  const [merchantLogoImg, setMerchantLogoImg] = useState(null);
  const [scratchCardImage, setScratchCardImage] = useState(null);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  //   console.log(offerDataValue, "offerDataValue");
  const { id } = useParams();
  const token = useSelector((store) => store.AuthReducer.token);
  const BaseUrl = process.env.REACT_APP_API_BASE_URL;
  const { state } = useLocation();
  const rewards = useSelector(
    (store) => store?.OfferReducer?.allRewards
  )?.rewardTypes;
  const gameData = useSelector((store) => store?.OfferReducer?.allGames);
  const handleData = (key, value) => {
    setOfferData((pre) => {
      return {
        ...pre,
        [key]: value,
      };
    });
  };

  const getOfferDataByID = () => {
    console.log(token, "stateinner");
    let url = `${BaseUrl}/api/admin/offer/${id}`;

    if (pathname.startsWith("/approve-offers")) {
        url += "?offerApprovalStatus=pending";
    }

    axios
        .get(url, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((res) => {
            const offerLimit = res.data?.values?.find((e) => e.entity_type === "OFFER")?.limit_type;
            const userLimit = res.data?.values?.find((e) => e.entity_type === "USER")?.limit_type;
            dispatch({ type: initialOfferEditValue, payload: res.data });
            setOfferData((prev) => ({
                ...prev,
                basicOffers: {
                    ...prev.basicOffers,
                    offerId: res.data?.offer_history?.id??"",
                    category: pathname.startsWith("/approve-offers")
                        ? res.data?.updated_offer_details?.categoryId
                        : res.data?.categoryId ?? "",
                    previous_category: res.data?.previous_offer_details?.categoryId ?? [],
                    subCategory: pathname.startsWith("/approve-offers")
                        ? res.data?.updated_offer_details?.subcategoryId
                        : res.data?.subcategoryId ?? "",
                    previous_subCategory: res.data?.previous_offer_details?.subcategoryId ?? [],
                    product: pathname.startsWith("/approve-offers")
                        ? res.data?.updated_offer_details?.productId
                        : res.data?.productId ?? "",
                    previous_product: res.data?.previous_offer_details?.productId ?? [],
                },
                offerDetails: {
                    merchant: pathname.startsWith("/approve-offers")
                        ? res.data?.updated_offer_details?.merchantId
                        : res.data?.merchantId ?? "",
                    previous_merchant: res.data?.previous_offer_details?.merchantId ?? [],
                    title: pathname.startsWith("/approve-offers")
                        ? res.data?.updated_offer_details?.name
                        : res.data?.name ?? "",
                    previous_title: res.data?.previous_offer_details?.name ?? "",
                    description: pathname.startsWith("/approve-offers")
                        ? res.data?.updated_offer_details?.description
                        : res.data?.description ?? "",
                    previous_description: res.data?.previous_offer_details?.description ?? "",
                    startDate: pathname.startsWith("/approve-offers")
                        ? res.data?.updated_offer_details?.startsAt
                        : res.data?.startsAt ?? "",
                    previous_startDate: res.data?.previous_offer_details?.startsAt ?? "",
                    endDate: pathname.startsWith("/approve-offers")
                        ? res.data?.updated_offer_details?.endsAt
                        : res.data?.endsAt ?? "",
                    previous_endDate: res.data?.previous_offer_details?.endsAt ?? "",
                    programId: pathname.startsWith("/approve-offers")
                        ? res.data?.updated_offer_details?.programId
                        : res.data?.programId ?? "",
                    previous_programId: res.data?.previous_offer_details?.programId ?? "",
                    lockingPeriod: pathname.startsWith("/approve-offers")
                        ? res.data?.updated_offer_details?.lockingPeriod
                        : res.data?.lockingPeriod ?? "",
                    previous_lockingPeriod: res.data?.previous_offer_details?.lockingPeriod ?? "",
                    expiryPeriod: pathname.startsWith("/approve-offers")
                        ? res.data?.updated_offer_details?.expiryPeriod
                        : res.data?.expiryPeriod ?? "",
                    previous_expiryPeriod: res.data?.previous_offer_details?.expiryPeriod ?? "",
                    offerInformationForPWABefore: pathname.startsWith("/approve-offers")
                        ? res.data?.updated_offer_details?.informationBeforeRedemption
                        : res.data?.informationBeforeRedemption ?? "",
                    previous_offerInformationForPWABefore: res.data?.previous_offer_details?.informationBeforeRedemption ?? "",
                    offerInformationForPWAAfter: pathname.startsWith("/approve-offers")
                        ? res.data?.updated_offer_details?.informationAfterRedemption
                        : res.data?.informationAfterRedemption ?? "",
                    previous_offerInformationForPWAAfter: res.data?.previous_offer_details?.informationAfterRedemption ?? "",
                    TAndC: pathname.startsWith("/approve-offers")
                        ? res.data?.updated_offer_details?.termsAndConditions
                        : res.data?.termsAndConditions ?? "",
                    previous_TAndC: res.data?.previous_offer_details?.termsAndConditions ?? "",
                    coverPhoto: pathname.startsWith("/approve-offers")
                        ? res.data?.updated_offer_details?.coverImageUrl
                        : res.data.coverImageUrl ?? "",
                    previous_coverPhoto: res.data?.previous_offer_details?.coverImageUrl ?? "",
                    coverImageUrl: pathname.startsWith("/approve-offers")
                        ? res.data?.updated_offer_details?.coverImageUrl
                        : res.data.coverImageUrl ?? "",
                    previous_coverImageUrl: res.data?.previous_offer_details?.coverImageUrl ?? "",
                    coverPhotoId: pathname.startsWith("/approve-offers")
                        ? res.data?.updated_offer_details?.coverImageId
                        : res.data.coverImageId ?? "",
                    previous_coverPhotoId: res.data?.previous_offer_details?.coverImageId ?? "",
                    offerInitiative: pathname.startsWith("/approve-offers")
                        ? res.data?.updated_offer_details?.initiativeId
                        : res.data?.initiativeId ?? "",
                    previous_offerInitiative: res.data?.previous_offer_details?.initiativeId ?? "",
                    gameType: pathname.startsWith("/approve-offers")
                        ? res.data?.updated_offer_details?.game?.gameId
                        : res.data?.game?.gameId ?? "",
                    previous_gameType: res.data?.previous_offer_details?.game?.gameId ?? {},
                    rewardCurrency: pathname.startsWith("/approve-offers")
                        ? res.data?.updated_offer_details?.currencyCode
                        : res.data?.currencyCode ?? "",
                    previous_rewardCurrency: res.data?.previous_offer_details?.currencyCode ?? "",
                    rewardCurrencyId: pathname.startsWith("/approve-offers")
                        ? res.data?.updated_offer_details?.currencyId
                        : res.data?.currencyId ?? "",
                    previous_rewardCurrencyId: res.data?.previous_offer_details?.currencyId ?? [],
                    csvUrl: pathname.startsWith("/approve-offers")
                        ? res.data?.updated_offer_details?.csvUrl
                        : res.data?.csvUrl ?? "",
                    previous_csvUrl: res.data?.previous_offer_details?.csvUrl ?? "",
                },
                createGame: {
                    merchantLogo: pathname.startsWith("/approve-offers")
                        ? res.data?.updated_offer_details?.game?.merchantLogoUrl
                        : res.data?.game?.merchantLogoUrl ?? "",
                    previous_merchantLogo: res.data?.previous_offer_details?.game?.merchantLogoUrl ?? "",
                    merchantLogoUrl: pathname.startsWith("/approve-offers")
                        ? res.data?.updated_offer_details?.game?.merchantLogoUrl
                        : res.data?.game?.merchantLogoUrl ?? "",
                    previous_merchantLogoUrl: res.data?.previous_offer_details?.game?.merchantLogoUrl ?? "",
                    merchantLogoId: pathname.startsWith("/approve-offers")
                        ? res.data?.updated_offer_details?.game?.merchantLogoId
                        : res.data?.game?.merchantLogoId ?? "",
                    previous_merchantLogoId: res.data?.previous_offer_details?.game?.merchantLogoId ?? "",
                    scratchCardColor: pathname.startsWith("/approve-offers")
                        ? res.data?.updated_offer_details?.game?.scratchCardColour
                        : res.data?.game?.scratchCardColour ?? "",
                    previous_scratchCardColor: res.data?.previous_offer_details?.game?.scratchCardColour ?? "",
                    offerTitleColor: pathname.startsWith("/approve-offers")
                        ? res.data?.updated_offer_details?.game?.offerTitleColour
                        : res.data?.game?.offerTitleColour ?? "",
                    previous_offerTitleColor: res.data?.previous_offer_details?.game?.offerTitleColour ?? "",
                    scratchCardImage: pathname.startsWith("/approve-offers")
                        ? res.data?.updated_offer_details?.game?.scratchCardImageUrl
                        : res.data?.game?.scratchCardImageUrl ?? "",
                    previous_scratchCardImage: res.data?.previous_offer_details?.game?.scratchCardImageUrl ?? "",
                    scratchCardImageId: pathname.startsWith("/approve-offers")
                        ? res.data?.updated_offer_details?.game?.scratchCardImageId
                        : res.data?.game?.scratchCardImageId ?? "",
                    previous_scratchCardImageId: res.data?.previous_offer_details?.game?.scratchCardImageId ?? "",
                    scratchCardImageUrl: pathname.startsWith("/approve-offers")
                        ? res.data?.updated_offer_details?.game?.scratchCardImageUrl
                        : res.data?.game?.scratchCardImageUrl ?? "",
                    previous_scratchCardImageUrl: res.data?.previous_offer_details?.game?.scratchCardImageUrl ?? "",
                },
                setOfferRules: {
                    linkToRule: pathname.startsWith("/approve-offers")
                        ? res.data?.updated_offer_details?.earnRuleSetId
                        : res.data?.earnRuleSetId ?? {},
                    previous_linkToRule: res.data?.previous_offer_details?.earnRuleSetId ?? {},
                    linkToCohort: pathname.startsWith("/approve-offers")
                        ? res.data?.updated_offer_details?.collectionsIds ?? []
                        : res.data?.collectionsIds ?? [],
                    previous_linkToCohort: res.data?.previous_offer_details?.collectionsIds ?? [],
                },
                offerLimit: {
                    offerLevelLimits: {
                        fairUsageOfRewardIssuance: {
                            daily: pathname.startsWith("/approve-offers")
                                ? res.data?.updated_offer_details?.offerLimit?.daily
                                : offerLimit?.daily,
                            previous_daily: res.data?.previous_offer_details?.offerLimit?.daily,
                            weekly: pathname.startsWith("/approve-offers")
                                ? res.data?.updated_offer_details?.offerLimit?.weekly
                                : offerLimit?.weekly,
                            previous_weekly: res.data?.previous_offer_details?.offerLimit?.weekly,
                            monthly: pathname.startsWith("/approve-offers")
                                ? res.data?.updated_offer_details?.offerLimit?.monthly
                                : offerLimit?.monthly,
                            previous_monthly: res.data?.previous_offer_details?.offerLimit?.monthly,
                            total: pathname.startsWith("/approve-offers")
                                ? res.data?.updated_offer_details?.offerLimit?.overall
                                : offerLimit?.overall,
                            previous_total: res.data?.previous_offer_details?.offerLimit?.overall,
                        },
                    },
                    userLevelLimits: {
                        fairUsageOfRewardIssuance: {
                            daily: pathname.startsWith("/approve-offers")
                                ? res.data?.updated_offer_details?.userLimit?.daily
                                : userLimit?.daily,
                            previous_daily: res.data?.previous_offer_details?.userLimit?.daily,
                            weekly: pathname.startsWith("/approve-offers")
                                ? res.data?.updated_offer_details?.userLimit?.weekly
                                : userLimit?.weekly,
                            previous_weekly: res.data?.previous_offer_details?.userLimit?.weekly,
                            monthly: pathname.startsWith("/approve-offers")
                                ? res.data?.updated_offer_details?.userLimit?.monthly
                                : userLimit?.monthly,
                            previous_monthly: res.data?.previous_offer_details?.userLimit?.monthly,
                            totalAmount: pathname.startsWith("/approve-offers")
                                ? res.data?.updated_offer_details?.userLimit?.overall
                                : userLimit?.overall,
                            previous_totalAmount: res.data?.previous_offer_details?.userLimit?.overall,
                        },
                        fairUsageOfRewardRedemption: {
                            daily: pathname.startsWith("/approve-offers")
                                ? res.data?.updated_offer_details?.userLimit?.daily_issuance
                                : userLimit?.daily_issuance,
                            previous_daily: res.data?.previous_offer_details?.userLimit?.daily_issuance,
                            weekly: pathname.startsWith("/approve-offers")
                                ? res.data?.updated_offer_details?.userLimit?.weekly_issuance
                                : userLimit?.weekly_issuance,
                            previous_weekly: res.data?.previous_offer_details?.userLimit?.weekly_issuance,
                            monthly: pathname.startsWith("/approve-offers")
                                ? res.data?.updated_offer_details?.userLimit?.monthly_issuance
                                : userLimit?.monthly_issuance,
                            previous_monthly: res.data?.previous_offer_details?.userLimit?.monthly_issuance,
                            totalAmount: pathname.startsWith("/approve-offers")
                                ? res.data?.updated_offer_details?.userLimit?.overall_issuance
                                : userLimit?.overall_issuance,
                            previous_totalAmount: res.data?.previous_offer_details?.userLimit?.overall_issuance,
                        },
                    },
                },
            }));
        })
        .catch((error) => {
            console.log(error);
        });
};


  const getOfferDataByIDv2 = () => {
    axios
      .get(`${BaseUrl}/api/admin/offer-v2/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const offerLimit = res.data?.values?.find(
          (e) => e.entity_type == "OFFER"
        )?.limit_type;
        const userLimit = res.data?.values?.find(
          (e) => e.entity_type == "USER"
        )?.limit_type;
        // const userRedeem = res.data?.values?.find((e)=>e.entityType=="MAXIMUM")?.limitType;
        const gameDetails = gameData?.find(
          (item) => item?.id === res?.data?.game?.game_type_id
        );

        // console.log(res.data,"response");
        dispatch({ type: initialOfferEditValue, payload: res.data });
        setOfferData((prev) => ({
          ...prev,
          basicOffers: {
            ...prev.basicOffers,
            category: res.data?.categoryId ?? "",
            subCategory: res.data?.subcategoryId ?? "",
            product: res.data?.productId ?? "",
          },
          offerDetails: {
            merchant: res.data?.merchantId ?? "",
            title: res.data?.name ?? "",
            description: res.data?.description ?? "",
            startDate: res.data?.startsAt ?? "",
            endDate: res.data?.endsAt ?? "",
            programId: res.data?.programId ?? "",
            lockingPeriod: res.data?.lockingPeriod ?? "",
            expiryPeriod: res.data?.expiryPeriod ?? "",
            offerInformationForPWABefore:
              res.data?.informationBeforeRedemption ?? "",
            offerInformationForPWAAfter:
              res.data?.informationAfterRedemption ?? "",
            TAndC: res.data?.termsAndConditions ?? "",
            coverPhoto: res.data?.coverImageUrl ?? "",
            coverImageUrl: res.data?.coverImageUrl ?? "",
            coverPhotoId: res.data?.coverImageId ?? "",
            offerInitiative: res.data?.initiativeId ?? "",
            gameType: res.data?.game?.gameId ?? "",
            rewardCurrency: res.data?.currencyCode ?? "",
            rewardCurrencyId: res.data?.currencyId ?? "",
            csvUrl: res.data?.csvUrl ?? "",
            // csvUrl : "https://gratifystorage01.blob.core.windows.net/gratify-dev/offerAnalytics_Mon Jun 03 2024 17_31_51 GMT+0530 (India Standard Time) (2).csv_e7ff9b"
          },
          createGame: {
            // merchantLogo: "",
            // merchantLogoId: "",
            // scratchCardColor: "",
            // offerTitleColor: "",
            // scratchCardImage: "",
            // scratchCardImageId: "",
            // scratchCardImageUrl: "",
            // merchantLogoUrl: "",
            gameName: gameDetails?.name,
            game_type_id: res?.data?.game?.game_type_id,
            game: {
              scratchCardData:
                gameDetails?.name === "Scratch Card"
                  ? res?.data?.game?.rewards?.map((item) => {
                      const rewardName = rewards?.find(
                        (e) => e?.id === item?.reward_type_id
                      )?.name;

                      const rewardType = rewards
                        ?.find((e) => e?.id === item?.reward_type_id)
                        ?.reward_details?.find(
                          (ele) => ele?.backend_label === "reward_type"
                        )
                        ?.options.find((ele) => ele?.name === rewardName);
                      const limitOptions = rewards.find(
                        (reward) => reward?.name === rewardType?.name
                      )?.limitOptions;
                      // console.log(limitOptions, "limitOptions");
                      return {
                        title_required: true,
                        probability_required: true,
                        limit: false,
                        limit_required: true,
                        rewardLevel_required: true,
                        rewardType_required: true,
                        title: item?.title,
                        probability: item?.win_probability,
                        reward_type: rewardType,
                        cashback_type: {
                          ...rewards
                            ?.find(
                              (reward) => reward.id === item?.reward_type_id
                            )
                            ?.reward_details?.find(
                              (element) =>
                                element?.backend_label === "cashback_type"
                            )
                            ?.options?.map((option) => {
                              return {
                                id: option?.value,
                                name: option?.label,
                                fields: option?.fields,
                              };
                            })
                            ?.find(
                              (elem) =>
                                elem?.id === item?.reward_details?.cashback_type
                            ),
                        },
                        ...convertRewardDetails(item?.reward_details),
                        ...limitOptions,
                        rewardLimit: [
                          {
                            time_required: true,
                            amount_required: true,
                          },
                        ],
                      };
                    })
                  : [],
              spinTheWheelData:
                gameDetails?.name === "Spin The Wheel"
                  ? res?.data?.game?.rewards?.map((item) => {
                      return {
                        title_required: true,
                        probability_required: true,
                        limit: true,
                        limit_required: true,
                        rewardLevel_required: true,
                        title: item?.title,
                        probability: item?.win_probability,
                        reward_type: rewards?.find(
                          (e) => e?.id === item?.reward_type_id
                        ),
                        cashback_type: {
                          ...rewards
                            ?.find(
                              (reward) => reward.id === item?.reward_type_id
                            )
                            ?.reward_details?.find(
                              (element) =>
                                element?.backend_label === "cashback_type"
                            )
                            ?.options?.map((option) => {
                              return {
                                id: option?.value,
                                name: option?.label,
                                fields: option?.fields,
                              };
                            })
                            ?.find(
                              (elem) =>
                                elem?.id === item?.reward_details?.cashback_type
                            ),
                        },
                        ...convertRewardDetails(item?.reward_details),
                      };
                    })
                  : [],
              slotMachineData:
                gameDetails?.name === "Slot Machine"
                  ? res?.data?.game?.rewards?.map((item) => {
                      return {
                        title_required: true,
                        probability_required: true,
                        limit: true,
                        limit_required: true,
                        rewardLevel_required: true,
                        title: item?.title,
                        probability: item?.win_probability,
                        reward_type: rewards?.find(
                          (e) => e?.id === item?.reward_type_id
                        ),
                        cashback_type: {
                          ...rewards
                            ?.find(
                              (reward) => reward.id === item?.reward_type_id
                            )
                            ?.reward_details?.find(
                              (element) =>
                                element?.backend_label === "cashback_type"
                            )
                            ?.options?.map((option) => {
                              return {
                                id: option?.value,
                                name: option?.label,
                                fields: option?.fields,
                              };
                            })
                            ?.find(
                              (elem) =>
                                elem?.id === item?.reward_details?.cashback_type
                            ),
                        },
                        ...convertRewardDetails(item?.reward_details),
                      };
                    })
                  : [],
              triviaData:
                gameDetails?.name === "Trivia"
                  ? res?.data?.game?.rewards?.map((item) => {
                      return {
                        title_required: true,
                        probability_required: true,
                        limit: true,
                        limit_required: true,
                        rewardLevel_required: true,
                        title: item?.title,
                        probability: item?.win_probability,
                        reward_type: rewards?.find(
                          (e) => e?.id === item?.reward_type_id
                        ),
                        cashback_type: {
                          ...rewards
                            ?.find(
                              (reward) => reward.id === item?.reward_type_id
                            )
                            ?.reward_details?.find(
                              (element) =>
                                element?.backend_label === "cashback_type"
                            )
                            ?.options?.map((option) => {
                              return {
                                id: option?.value,
                                name: option?.label,
                                fields: option?.fields,
                              };
                            })
                            ?.find(
                              (elem) =>
                                elem?.id === item?.reward_details?.cashback_type
                            ),
                        },
                        ...convertRewardDetails(item?.reward_details),
                      };
                    })
                  : [],
              triviaData_step1: {
                triviaType: "",
                questions: [
                  {
                    title: "",
                    addMedia: false,
                    images: [],
                    options: [{ optionTitle: "", isSelected: true }],
                  },
                ],
              },
            },
            beforePlay: {
              scratchCardData:
                gameDetails?.name === "Scratch Card"
                  ? res?.data?.game?.design?.before_play || {}
                  : {},
              spinTheWheelData:
                gameDetails?.name === "Spin The Wheel"
                  ? res?.data?.game?.design?.before_play || {}
                  : {},
              slotMachineData:
                gameDetails?.name === "Slot Machine"
                  ? res?.data?.game?.design?.before_play || {}
                  : {},
              triviaData:
                gameDetails?.name === "Trivia"
                  ? res?.data?.game?.design?.before_play || {}
                  : {},
            },
            afterPlay: {
              scratchCardData:
                gameDetails?.name === "Scratch Card"
                  ? res?.data?.game?.design?.after_play || {}
                  : {},
              spinTheWheelData:
                gameDetails?.name === "Spin The Wheel"
                  ? res?.data?.game?.design?.after_play || {}
                  : {},
              slotMachineData:
                gameDetails?.name === "Slot Machine"
                  ? res?.data?.game?.design?.after_play || {}
                  : {},
              triviaData:
                gameDetails?.name === "Trivia"
                  ? res?.data?.game?.design?.after_play || {}
                  : {},
            },
            whenPlay: {
              triviaData:
                gameDetails?.name === "Trivia"
                  ? res?.data?.game?.design?.when_play || {}
                  : {},
            },
            addNewRewards: {
              scratchCardData: {
                rewardType: "",
                previouslyCreatedReward: "",
                templates: "",
                startFromScratch: {
                  rewardType: {},
                  rewardTitle: "",
                },
              },
              spinTheWheelData: {
                rewardType: "",
                previouslyCreatedReward: "",
                templates: "",
                startFromScratch: {
                  rewardType: {},
                  rewardTitle: "",
                },
              },
              slotMachineData: {
                rewardType: "",
                previouslyCreatedReward: "",
                templates: "",
                startFromScratch: {
                  rewardType: {},
                  rewardTitle: "",
                },
              },
              triviaData: {
                rewardType: "",
                previouslyCreatedReward: "",
                templates: "",
                startFromScratch: {
                  rewardType: {},
                  rewardTitle: "",
                },
              },
            },
          },
          setOfferRules: {
            linkToRule: res.data?.earnRuleSetId ?? {},
            linkToCohort: res.data?.collectionsIds ?? [],
          },
          offerLimit: {
            offerLevelLimits: {
              fairUsageOfRewardIssuance: {
                // hourly: offerLimit?.hourly,
                daily: offerLimit?.daily,
                weekly: offerLimit?.weekly,
                monthly: offerLimit?.monthly,
                total: offerLimit?.overall,
              },
              // fairUsageOfRewardRedemption: {
              //   hourly: "",
              //   daily: "",
              //   weekly: "",
              //   monthly: "",
              // },
            },
            userLevelLimits: {
              fairUsageOfRewardIssuance: {
                // hourly: userLimit?.hourly,
                daily: userLimit?.daily,
                weekly: userLimit?.weekly,
                monthly: userLimit?.monthly,
                totalAmount: userLimit?.overall,
              },
              fairUsageOfRewardRedemption: {
                daily: userLimit?.daily_issuance,
                weekly: userLimit?.weekly_issuance,
                monthly: userLimit?.monthly_issuance,
                totalAmount: userLimit?.overall_issuance,
              },
            },
          },
        }));
        // setData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getOfferDataByIDv3 = async () => { 
    try {
      const res = await axios.get(`${BaseUrl}/api/admin/offer-v2/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      const offerLimit = res.data?.values?.find((e) => e.entity_type === "OFFER")?.limit_type;
      const userLimit = res.data?.values?.find((e) => e.entity_type === "USER")?.limit_type;
      const gameDetails = gameData?.find((item) => item?.id === res?.data?.game?.game_type_id);
  
      const updatedGameData = await Promise.all(
        res?.data?.game?.rewards?.map(async (item) => {
          const rewardName = rewards?.find((e) => e?.id === item?.reward_type_id)?.name;
          const rewardType = rewards
            ?.find((e) => e?.id === item?.reward_type_id)
            ?.reward_details?.find((ele) => ele?.backend_label === "reward_type")
            ?.options.find((ele) => ele?.name === rewardName);
          const limitOptions = rewards.find((reward) => reward?.name === rewardType?.name)?.limitOptions;
      
          let  csvFile =item?.reward_details?.csvUrl ? await fetchCsvFileFromUrl(item?.reward_details?.csvUrl):"";
          // if (gameDetails?.name === "Scratch Card") {
          //   csvFile = await fetchCsvFileFromUrl("https://gratifystorage01.blob.core.windows.net/gratify-dev/330d31_SampleVouchersSheet1.csv");
          // }
      
          return {
            title_required: true,
            probability_required: true,
            // limit: gameDetails?.name !== "Scratch Card",
            limit:false,
            limit_required: true,
            rewardLevel_required: true,
            rewardType_required: true,
            title: item?.title,
            probability: item?.win_probability,
            reward_type: rewardType,
            cashback_type: {
              ...rewards
                ?.find((reward) => reward.id === item?.reward_type_id)
                ?.reward_details?.find((element) => element?.backend_label === "cashback_type")
                ?.options?.map((option) => ({
                  id: option?.value,
                  name: option?.label,
                  fields: option?.fields,
                }))
                ?.find((elem) => elem?.id === item?.reward_details?.cashback_type),
            },
            ...convertRewardDetails(item?.reward_details),
            ...limitOptions,
            voucherCollectionId: item?.reward_details?.voucherCollectionId ?? "",
            csvUrl: item?.reward_details?.csvUrl ?? "",
            csvFile,
            rewardLevel: limitOptions?.limitLevelOptions?.find((e) => e.id === item?.rewardLimits?.[0]?.limit_level),
            rewardType: limitOptions?.limitTypeOptions?.find((e) => e.id === item?.rewardLimits?.[0]?.entity_type),
            rewardLimit: limitOptions?.limitOptions
              ?.filter((limit) => item?.rewardLimits?.[0]?.hasOwnProperty(limit?.id))
              .map((limit) => ({
                time_required: true,
                amount_required: true,
                type: {
                  id: limit.id,
                  name: limit.name,
                },
                amount: item?.rewardLimits[0][limit.id],
              })),
          };
        })
      );
      
      // Now, use updatedGameData to set the appropriate game data based on the game type
      const scratchCardData = gameDetails?.name === "Scratch Card" ? updatedGameData : [];
      const spinTheWheelData = gameDetails?.name === "Spin The Wheel" ? updatedGameData : [];
      const slotMachineData = gameDetails?.name === "Slot Machine" ? updatedGameData : [];
      const triviaData = gameDetails?.name === "Trivia" ? updatedGameData : [];
  
      // Now, integrate the resolved data into the state update
      dispatch({ type: initialOfferEditValue, payload: res.data });
      setOfferData((prev) => ({
        ...prev,
        basicOffers: {
          ...prev.basicOffers,
          category: res.data?.categoryId ?? "",
          subCategory: res.data?.subcategoryId ?? "",
          product: res.data?.productId ?? "",
        },
        offerDetails: {
          merchant: res.data?.merchantId ?? "",
          title: res.data?.name ?? "",
          description: res.data?.description ?? "",
          startDate: res.data?.startsAt ?? "",
          endDate: res.data?.endsAt ?? "",
          programId: res.data?.programId ?? "",
          lockingPeriod: res.data?.lockingPeriod ?? "",
          expiryPeriod: res.data?.expiryPeriod ?? "",
          offerInformationForPWABefore: res.data?.informationBeforeRedemption ?? "",
          offerInformationForPWAAfter: res.data?.informationAfterRedemption ?? "",
          TAndC: res.data?.termsAndConditions ?? "",
          coverPhoto: res.data?.coverImageUrl ?? "",
          coverImageUrl: res.data?.coverImageUrl ?? "",
          coverPhotoId: res.data?.coverImageId ?? "",
          offerInitiative: res.data?.initiativeId ?? "",
          gameType: res.data?.game?.gameId ?? "",
          rewardCurrency: res.data?.currencyCode ?? "",
          rewardCurrencyId: res.data?.currencyId ?? "",
          csvUrl: res.data?.csvUrl ?? "",
        },
        createGame: {
          gameName: gameDetails?.name,
          game_type_id: res?.data?.game?.game_type_id,
          game: { 
            scratchCardData: scratchCardData,
            spinTheWheelData:spinTheWheelData,
            slotMachineData:slotMachineData,
            triviaData:triviaData,
            triviaData_step1: {
              triviaType: "",
              questions: [
                {
                  title: "",
                  addMedia: false,
                  images: [],
                  options: [{ optionTitle: "", isSelected: true }],
                },
              ],
            },
          },
          beforePlay: {
            scratchCardData: gameDetails?.name === "Scratch Card" ? res?.data?.game?.design?.before_play || {} : {},
            spinTheWheelData: gameDetails?.name === "Spin The Wheel" ? res?.data?.game?.design?.before_play || {} : {},
            slotMachineData: gameDetails?.name === "Slot Machine" ? res?.data?.game?.design?.before_play || {} : {},
            triviaData: gameDetails?.name === "Trivia" ? res?.data?.game?.design?.before_play || {} : {},
          },
          afterPlay: {
            scratchCardData: gameDetails?.name === "Scratch Card" ? res?.data?.game?.design?.after_play || {} : {},
            spinTheWheelData: gameDetails?.name === "Spin The Wheel" ? res?.data?.game?.design?.after_play || {} : {},
            slotMachineData: gameDetails?.name === "Slot Machine" ? res?.data?.game?.design?.after_play || {} : {},
            triviaData: gameDetails?.name === "Trivia" ? res?.data?.game?.design?.after_play || {} : {},
          },
          whenPlay: {
            triviaData: gameDetails?.name === "Trivia" ? res?.data?.game?.design?.when_play || {} : {},
          },
          addNewRewards: {
            scratchCardData: {
              rewardType: "",
              previouslyCreatedReward: "",
              templates: "",
              startFromScratch: {
                rewardType: {},
                rewardTitle: "",
              },
            },
            spinTheWheelData: {
              rewardType: "",
              previouslyCreatedReward: "",
              templates: "",
              startFromScratch: {
                rewardType: {},
                rewardTitle: "",
              },
            },
            slotMachineData: {
              rewardType: "",
              previouslyCreatedReward: "",
              templates: "",
              startFromScratch: {
                rewardType: {},
                rewardTitle: "",
              },
            },
            triviaData: {
              rewardType: "",
              previouslyCreatedReward: "",
              templates: "",
              startFromScratch: {
                rewardType: {},
                rewardTitle: "",
              },
            },
          },
        },
        setOfferRules: {
          linkToRule: res.data?.earnRuleSetId ?? {},
          linkToCohort: res.data?.collectionsIds ?? [],
        },
        offerLimit: {
          offerLevelLimits: {
            fairUsageOfRewardIssuance: {
              daily: offerLimit?.daily,
              weekly: offerLimit?.weekly,
              monthly: offerLimit?.monthly,
              total: offerLimit?.overall,
            },
          },
          userLevelLimits: {
            fairUsageOfRewardIssuance: {
              daily: userLimit?.daily,
              weekly: userLimit?.weekly,
              monthly: userLimit?.monthly,
              totalAmount: userLimit?.overall,
            },
            fairUsageOfRewardRedemption: {
              daily: userLimit?.daily_issuance,
              weekly: userLimit?.weekly_issuance,
              monthly: userLimit?.monthly_issuance,
              totalAmount: userLimit?.overall_issuance,
            },
          },
        },
      }));
    } catch (error) {
      console.error(error);
    }
  };
  const getOfferDataByIDv4 = async () => { 
    try {
      const res = await axios.get(`${BaseUrl}/api/admin/offer-v2/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      const offerLimit = res.data?.values?.find((e) => e.entity_type === "OFFER")?.limit_type;
      const userLimit = res.data?.values?.find((e) => e.entity_type === "USER")?.limit_type;
      const gameDetails = gameData?.find((item) => item?.id === res?.data?.game?.game_type_id);
  
      const updatedGameData = await Promise.all(
        res?.data?.game?.rewards?.map(async (item) => {
          const rewardName = rewards?.find((e) => e?.id === item?.reward_type_id)?.name;
          const rewardType = rewards
            ?.find((e) => e?.id === item?.reward_type_id)
            ?.reward_details?.find((ele) => ele?.backend_label === "reward_type")
            ?.options.find((ele) => ele?.name === rewardName);
          const limitOptions = rewards.find((reward) => reward?.name === rewardType?.name)?.limitOptions;
      
          let  csvFile =item?.reward_details?.csvUrl ? await fetchCsvFileFromUrl(item?.reward_details?.csvUrl):"";
          // if (gameDetails?.name === "Scratch Card") {
          //   csvFile = await fetchCsvFileFromUrl("https://gratifystorage01.blob.core.windows.net/gratify-dev/330d31_SampleVouchersSheet1.csv");
          // }
      
          return {
            title_required: true,
            probability_required: true,
            // limit: gameDetails?.name !== "Scratch Card",
            limit:false,
            limit_required: true,
            rewardLevel_required: true,
            rewardType_required: true,
            title: item?.title,
            probability: item?.win_probability,
            reward_type: rewardType,
            rewardIcon:item?.reward_details?.reward_icon,
            cashback_type: {
              ...rewards
                ?.find((reward) => reward.id === item?.reward_type_id)
                ?.reward_details?.find((element) => element?.backend_label === "cashback_type")
                ?.options?.map((option) => ({
                  id: option?.value,
                  name: option?.label,
                  fields: option?.fields,
                }))
                ?.find((elem) => elem?.id === item?.reward_details?.cashback_type),
            },
            ...convertRewardDetails(item?.reward_details),
            ...limitOptions,
            voucherCollectionId: item?.reward_details?.voucherCollectionId ?? "",
            csvUrl: item?.reward_details?.csvUrl ?? "",
            csvFile,
            rewardLevel: limitOptions?.limitLevelOptions?.find((e) => e.id === item?.rewardLimits?.[0]?.limit_level),
            rewardType: limitOptions?.limitTypeOptions?.find((e) => e.id === item?.rewardLimits?.[0]?.entity_type),
            rewardLimit: limitOptions?.limitOptions
              ?.filter((limit) => item?.rewardLimits?.[0]?.hasOwnProperty(limit?.id))
              .map((limit) => ({
                time_required: true,
                amount_required: true,
                type: {
                  id: limit.id,
                  name: limit.name,
                },
                amount: item?.rewardLimits[0][limit.id],
              })),
          };
        })
      );
      
      // Now, use updatedGameData to set the appropriate game data based on the game type
      const scratchCardData = gameDetails?.name === "Scratch Card" ? updatedGameData : [];
      const spinTheWheelData = gameDetails?.name === "Spin The Wheel" ? updatedGameData : [];
      const slotMachineData = gameDetails?.name === "Slot Machine" ? updatedGameData : [];
      const triviaData = gameDetails?.name === "Trivia" ? updatedGameData : [];
  
      let obj ={
        // ...prev,
        basicOffers: {
          // ...prev.basicOffers,
          category: res.data?.categoryId ?? "",
          subCategory: res.data?.subcategoryId ?? "",
          product: res.data?.productId ?? "",
        },
        offerDetails: {
          merchant: res.data?.merchantId ?? "",
          title: res.data?.name ?? "",
          description: res.data?.description ?? "",
          startDate: res.data?.startsAt ?? "",
          endDate: res.data?.endsAt ?? "",
          programId: res.data?.programId ?? "",
          lockingPeriod: res.data?.lockingPeriod ?? "",
          expiryPeriod: res.data?.expiryPeriod ?? "",
          offerInformationForPWABefore: res.data?.informationBeforeRedemption ?? "",
          offerInformationForPWAAfter: res.data?.informationAfterRedemption ?? "",
          TAndC: res.data?.termsAndConditions ?? "",
          coverPhoto: res.data?.coverImageUrl ?? "",
          coverImageUrl: res.data?.coverImageUrl ?? "",
          coverPhotoId: res.data?.coverImageId ?? "",
          offerInitiative: res.data?.initiativeId ?? "",
          gameType: res.data?.game?.gameId ?? "",
          rewardCurrency: res.data?.currencyCode ?? "",
          rewardCurrencyId: res.data?.currencyId ?? "",
          csvUrl: res.data?.csvUrl ?? "",
        },
        createGame: {
          gameName: gameDetails?.name,
          game_type_id: res?.data?.game?.game_type_id,
          game: { 
            scratchCardData: scratchCardData,
            spinTheWheelData:spinTheWheelData,
            slotMachineData:slotMachineData,
            triviaData:triviaData,
            triviaData_step1: {
              triviaType: "",
              questions: [
                {
                  title: "",
                  addMedia: false,
                  images: [],
                  options: [{ optionTitle: "", isSelected: true }],
                },
              ],
            },
          },
          beforePlay: {
            scratchCardData: gameDetails?.name === "Scratch Card" ? res?.data?.game?.design?.before_play || {} : {},
            spinTheWheelData: gameDetails?.name === "Spin The Wheel" ? res?.data?.game?.design?.before_play || {} : {},
            slotMachineData: gameDetails?.name === "Slot Machine" ? res?.data?.game?.design?.before_play || {} : {},
            triviaData: gameDetails?.name === "Trivia" ? res?.data?.game?.design?.before_play || {} : {},
          },
          afterPlay: {
            scratchCardData: gameDetails?.name === "Scratch Card" ? res?.data?.game?.design?.after_play || {} : {},
            spinTheWheelData: gameDetails?.name === "Spin The Wheel" ? res?.data?.game?.design?.after_play || {} : {},
            slotMachineData: gameDetails?.name === "Slot Machine" ? res?.data?.game?.design?.after_play || {} : {},
            triviaData: gameDetails?.name === "Trivia" ? res?.data?.game?.design?.after_play || {} : {},
          },
          whenPlay: {
            triviaData: gameDetails?.name === "Trivia" ? res?.data?.game?.design?.when_play || {} : {},
          },
          addNewRewards: {
            scratchCardData: {
              rewardType: "",
              previouslyCreatedReward: "",
              templates: "",
              startFromScratch: {
                rewardType: {},
                rewardTitle: "",
              },
            },
            spinTheWheelData: {
              rewardType: "",
              previouslyCreatedReward: "",
              templates: "",
              startFromScratch: {
                rewardType: {},
                rewardTitle: "",
              },
            },
            slotMachineData: {
              rewardType: "",
              previouslyCreatedReward: "",
              templates: "",
              startFromScratch: {
                rewardType: {},
                rewardTitle: "",
              },
            },
            triviaData: {
              rewardType: "",
              previouslyCreatedReward: "",
              templates: "",
              startFromScratch: {
                rewardType: {},
                rewardTitle: "",
              },
            },
          },
        },
        setOfferRules: {
          linkToRule: res.data?.earnRuleSetId ?? {},
          linkToCohort: res.data?.collectionsIds ?? [],
        },
        offerLimit: {
          offerLevelLimits: {
            fairUsageOfRewardIssuance: {
              daily: offerLimit?.daily,
              weekly: offerLimit?.weekly,
              monthly: offerLimit?.monthly,
              total: offerLimit?.overall,
            },
          },
          userLevelLimits: {
            fairUsageOfRewardIssuance: {
              daily: userLimit?.daily,
              weekly: userLimit?.weekly,
              monthly: userLimit?.monthly,
              totalAmount: userLimit?.overall,
            },
            fairUsageOfRewardRedemption: {
              daily: userLimit?.daily_issuance,
              weekly: userLimit?.weekly_issuance,
              monthly: userLimit?.monthly_issuance,
              totalAmount: userLimit?.overall_issuance,
            },
          },
        },
      }
      dispatch({ type: initialOfferEditValue, payload: obj });
      // Now, integrate the resolved data into the state update
      setOfferData((prev) => (obj));
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if(pathname.startsWith("/approve-offers")){
      getOfferDataByID(id);
    }else if (state?.id) {
      getOfferDataByIDv4(state?.id);
    }
  }, []);

  // console.log(state.UPDATE, "permission");

  return (
    <Box padding={"20px"} sx={{ backgroundColor: "white" }}>
      {/* <OfferStepper offerData={offerData} /> */}
      <OfferPreview
        data={offerData}
        onChange={(key, data) => handleData(key, data)}
        coverPhoto={coverPhoto}
        handleCoverPhoto={setCoverPhoto}
        merchantLogoImg={merchantLogoImg}
        handleMerchantLogoImg={setMerchantLogoImg}
        scratchCardImage={scratchCardImage}
        handleScratchCardImage={setScratchCardImage}
        updatePermission= {state?.UPDATE}
      />
    </Box>
  );
}
