import React from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import FormHelperText from "@mui/material/FormHelperText";

const MuiRadioGroup = ({ onChange, value, options, errorMessage = "",editBoolean }) => {
  return (
    <Box sx={{ textAlign: "left", mt: "10px" }}>
      <FormControl error={!!errorMessage}>
        <RadioGroup
          value={value ?? ""}
          onChange={(e) => onChange(e.target.value)}
          sx={{
            display: "flex",
            boxSizing: "border-box",
            maxWidth: "510px",
            alignItems: "flex-start",
            alignContent: "flex-start",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: "10px",
            margin: "auto",
          }}
        >
          {options?.map((option) => (
            <FormControlLabel
              key={option?.value}
              value={option?.value}
              label={option?.label}
              control={
                <Radio
                sx={{
                  color: "gray", // Unchecked color
                  "&.Mui-checked": {
                    color: editBoolean ? "orange" : "black", // Checked color
                  },
                    fontFamily: "Mulish",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                    p: 1,
                  }}
                />
              }
            />
          ))}
        </RadioGroup>
        <FormHelperText>{errorMessage}</FormHelperText>
      </FormControl>
    </Box>
  );
};

export default MuiRadioGroup;
