import {
  allDataSubmitted,
  cancelErrorSubmit,
  clearCreateOfferData,
  clearRewardPreviousValue,
  closeModel,
  editConfirmation,
  editOfferFail,
  editOfferSuccess,
  editReward,
  errorSubmitOffer,
  getCategory,
  getCohortsData,
  getCurrency,
  getGames,
  getGameType,
  getInitiative,
  getMerchant,
  getPreviouslyCreateRewards,
  getProduct,
  getRewardTypes,
  getRuleSet,
  getSubCategory,
  initialOfferEditValue,
  projectionLoaderStatus,
  resetEdit,
  saveRewardPreviousValue,
  selectGameType,
  setAfterPlayChange,
  setAfterPlayReward,
  setBeforePlayChange,
  setDrawerImageOption,
  setOfferDetailsDataChanges,
  setQualifyingCriteriaDataChanges,
  setRewardArray,
  toggleReward,
} from "./actionType";

const initialState = {
  offerData: {
    offerDetails: {},
    setQualifyingCriteria: {
      step:1,
    },
    createGame: {
      game: {
        scratchCardData: [],
        spinTheWheelData: [],
        slotMachineData: [],
        triviaData: [],
        triviaData_step1: {
          triviaType: "",
          questions: [
            {
              title: "",
              addMedia: false,
              images: [],
              options: [{ optionTitle: "", isSelected: true }],
            },
          ],
        },
      },
      beforePlay: {
        scratchCardData: {},
        spinTheWheelData: {},
        slotMachineData: {},
        triviaData: {},
      },
      afterPlay: {
        scratchCardData: [],
        spinTheWheelData: [],
        slotMachineData: [],
        triviaData:[],
      },
      whenPlay: {
        triviaData: {},
      },
      addNewRewards: {
        scratchCardData: {
          rewardType: "",
          previouslyCreatedReward: "",
          templates: "",
          startFromScratch: {
            rewardType: {},
            rewardTitle: "",
          },
        },
        spinTheWheelData: {
          rewardType: "",
          previouslyCreatedReward: "",
          templates: "",
          startFromScratch: {
            rewardType: {},
            rewardTitle: "",
          },
        },
        slotMachineData: {
          rewardType: "",
          previouslyCreatedReward: "",
          templates: "",
          startFromScratch: {
            rewardType: {},
            rewardTitle: "",
          },
        },
        triviaData: {
          rewardType: "",
          previouslyCreatedReward: "",
          templates: "",
          startFromScratch: {
            rewardType: {},
            rewardTitle: "",
          },
        },
      },
      drawerData: {
        backend_label: "",
        image_option: [],
        gameDataName:"",
        game_type:"",
        action_type:"",
        rewardIndex:"",
      },
    },
  },
  categoryOption: [],
  subCategoryOption: [],
  productOption: [],
  merchantOption: [],
  ruleOption: [],
  cohortsOption: [],
  initiativeOption: [],
  gamesOption: [],
  rewardCurrencyOption: [],
  initialOfferEditValue: [],
  flag: false,
  editSuccess: false,
  editError: false,
  editErrorMessage: "",
  submitOfferError: false,
  offerId: "3",
  errorMessage: "",
  allGames: [],
  allRewardTypes: [],
  rewardPreviousValue: {},
  allRewards: {},
  projectionLoader: false,
};

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case setOfferDetailsDataChanges:
      return {
        ...state,
        offerData: {
          ...state.offerData,
          offerDetails: {
            ...state.offerData.offerDetails,
            ...payload,
          },
        },
      };
    case setQualifyingCriteriaDataChanges:
      return {
        ...state,
        offerData: {
          ...state.offerData,
          setQualifyingCriteria: {
            ...state.offerData.setQualifyingCriteria,
            ...payload,
          },
        },
      };
    case selectGameType:
      return {
        ...state,
        offerData: {
          ...state.offerData,
          createGame: {
            ...state.offerData.createGame,
            ...payload,
          },
        },
      };
    case toggleReward:
      return {
        ...state,
        offerData: {
          ...state.offerData,
          createGame: {
            ...state.offerData.createGame,
            game: {
              ...state.offerData.createGame.game,
              [payload.gameDataName]: payload.updatedRewardArray,
            },
          },
        },
      };
    case setRewardArray:
      return {
        ...state,
        offerData: {
          ...state.offerData,
          createGame: {
            ...state.offerData.createGame,
            game: {
              ...state.offerData.createGame.game,
              [payload.gameDataName]: payload.rewardArray,
            },
          },
        },
      };
    case editReward:
      return {
        ...state,
        offerData: {
          ...state.offerData,
          createGame: {
            ...state.offerData.createGame,
            game: {
              ...state.offerData.createGame.game,
              [payload.gameDataName]: payload.updatedRewardArray,
            },
          },
        },
      };
    case setBeforePlayChange:
      const { gameDataName, field, value, additionalFields } = payload;
      return {
        ...state,
        offerData: {
          ...state.offerData,
          createGame: {
            ...state.offerData.createGame,
            beforePlay: {
              ...state.offerData.createGame.beforePlay,
              [gameDataName]: {
                ...state.offerData.createGame.beforePlay[gameDataName],
                [field]: value,
                ...additionalFields,
              },
            },
          },
        },
      };
      case setAfterPlayChange:
        return {
          ...state,
          offerData: {
            ...state.offerData,
            createGame: {
              ...state.offerData.createGame,
              afterPlay: {
                ...state.offerData.createGame.afterPlay,
                [payload.gameDataName]: state.offerData.createGame.afterPlay[payload.gameDataName].map((reward, index) => {
                  if (index === payload.rewardIndex) {
                    return {
                      ...reward,
                      [payload.field]: payload.value,
                      ...payload.additionalFields,
                    };
                  }
                  return reward;
                }),
              },
            },
          },
        };
      case setAfterPlayReward:
        return{
          ...state,
          offerData: {
            ...state.offerData,
            createGame: {
              ...state.offerData.createGame,
              afterPlay: {
                ...state.offerData.createGame.afterPlay,
                [payload.gameDataName]: payload.rewardArray,
              },
            },
          },
        
        }
      case setDrawerImageOption:
        return {
          ...state,
          offerData: {
            ...state.offerData,
            createGame: {
              ...state.offerData.createGame,
              drawerData: {
                ...state.offerData.createGame.drawerData,
                image_option: payload?.image_option,
                backend_label: payload?.backend_label,
                gameDataName: payload?.gameDataName,
                game_type: payload?.game_type,
                action_type: payload?.action_type,
                rewardIndex: payload?.rewardIndex,
              },
            },
          },
        };
      case clearCreateOfferData:
        return{
          ...state,
          offerData: {
            offerDetails: {},
            setQualifyingCriteria: {
              step:1,
            },
            createGame: {
              game: {
                scratchCardData: [],
                spinTheWheelData: [],
                slotMachineData: [],
                triviaData: [],
                triviaData_step1: {
                  triviaType: "",
                  questions: [
                    {
                      title: "",
                      addMedia: false,
                      images: [],
                      options: [{ optionTitle: "", isSelected: true }],
                    },
                  ],
                },
              },
              beforePlay: {
                scratchCardData: {},
                spinTheWheelData: {},
                slotMachineData: {},
                triviaData: {},
              },
              afterPlay: {
                scratchCardData: [],
                spinTheWheelData: [],
                slotMachineData: [],
                triviaData:[],
              },
              whenPlay: {
                triviaData: {},
              },
              addNewRewards: {
                scratchCardData: {
                  rewardType: "",
                  previouslyCreatedReward: "",
                  templates: "",
                  startFromScratch: {
                    rewardType: {},
                    rewardTitle: "",
                  },
                },
                spinTheWheelData: {
                  rewardType: "",
                  previouslyCreatedReward: "",
                  templates: "",
                  startFromScratch: {
                    rewardType: {},
                    rewardTitle: "",
                  },
                },
                slotMachineData: {
                  rewardType: "",
                  previouslyCreatedReward: "",
                  templates: "",
                  startFromScratch: {
                    rewardType: {},
                    rewardTitle: "",
                  },
                },
                triviaData: {
                  rewardType: "",
                  previouslyCreatedReward: "",
                  templates: "",
                  startFromScratch: {
                    rewardType: {},
                    rewardTitle: "",
                  },
                },
              },
              drawerData: {
                backend_label: "",
                image_option: [],
                gameDataName:"",
                game_type:"",
                action_type:"",
                rewardIndex:"",
              },
            },
          },
        }
    case getCategory:
      return { ...state, categoryOption: payload };
    case getSubCategory:
      return { ...state, subCategoryOption: payload };
    case getProduct:
      return { ...state, productOption: payload };
    case getMerchant:
      return { ...state, merchantOption: payload };
    case getRuleSet:
      return { ...state, ruleOption: payload };
    case getInitiative:
      return { ...state, initiativeOption: payload };
    case getGames:
      return { ...state, gamesOption: payload };
    case getCurrency:
      return { ...state, rewardCurrencyOption: payload };
    case allDataSubmitted:
      return { ...state, flag: true, offerId: payload.offerId };
    case editOfferSuccess:
      return { ...state, editSuccess: true };
    case editOfferFail:
      return { ...state, editError: true, editErrorMessage: payload };
    case editConfirmation:
      return { ...state, flag: true };
    case resetEdit:
      return { ...state, editSuccess: false, editError: false, flag: false };
    case closeModel:
      return { ...state, flag: false };
    case getCohortsData:
      return { ...state, cohortsOption: payload };
    case initialOfferEditValue:
      return { ...state, initialOfferEditValue: payload };
    case errorSubmitOffer:
      return { ...state, submitOfferError: true, errorMessage: payload };
    case cancelErrorSubmit:
      return { ...state, submitOfferError: false };
    case getGameType:
      return { ...state, allGames: payload };
    case getRewardTypes:
      return { ...state, allRewardTypes: payload };
    case saveRewardPreviousValue:
      return { ...state, rewardPreviousValue: payload };
    case clearRewardPreviousValue:
      return { ...state, rewardPreviousValue: {} };
    case getPreviouslyCreateRewards:
      return { ...state, allRewards: payload };
    case projectionLoaderStatus:
      return { ...state, projectionLoader: payload };
    default:
      return state;
  }
};
