import { Box, Button, Switch, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import MuiInput from "../MuiInput";
import Label from "../Label";
// import SmallImageUpload from "../../SmallImageUpload";
import tickImage from "../../assets/whiteTick.png";
// import MuiRadioGroup from "../../MuiRadioGroup";
import ColorInput from "../ColorInput";
import { CreateGame_PreData } from "../../utils/CreateGame_PreData";
import MuiRadioGroup from "../MuiRadioGroup";
import SmallImageUpload from "../SmallImageUpload";

export default function AfterPlay({
  data,
  onChange,
  handleAfterPlayChange,
  fields,
  game,
}) {
  const { ctaColor, ctaTextColor, color } = CreateGame_PreData();

  useEffect(() => {
    const defaultColorFields = fields
      .filter(
        (field) =>
          field?.input_type === "color_picker" ||
          (field?.input_type === "image_and_color_picker" &&
            field?.options?.some((option) =>
              option?.backend_label.includes("color")
            ))
      )
      .reduce((acc, field) => {
        if (field?.input_type === "color_picker") {
          if (!data?.afterPlay?.[game]?.[field?.backend_label]) {
            acc[field?.backend_label] = ctaColor; // default color
          }
        } else if (field?.input_type === "image_and_color_picker") {
          const colorOption = field?.options?.find((option) =>
            option?.backend_label.includes("color")
          );
          if (
            colorOption &&
            !data?.afterPlay?.[game]?.[colorOption?.backend_label]
          ) {
            acc[colorOption?.backend_label] = color; // default color
          }
        }
        return acc;
      }, {});

    if (Object.keys(defaultColorFields).length > 0) {
      handleAfterPlayChange("prefilledData", "", defaultColorFields);
    }
  }, []);

  let num = 1;

  return (
    <Box>
      {fields?.length > 0 &&
        fields.map((field, index) => {
          if (!field?.mandatory) return null;
          const labelNum = num++;

          return (
            <Box
              key={field?.backend_label}
              sx={{
                mt: index > 0 ? "20px" : "0px",
              }}
            >
              <Label label={`${labelNum}. ${field?.label}`} />
              {field?.input_type === "input_box" ? (
                <Box sx={{ width: "400px" }}>
                  <MuiInput
                    value={data?.afterPlay?.[game]?.[field?.backend_label]}
                    onChange={(event) =>
                      handleAfterPlayChange(field?.backend_label, event)
                    }
                  />
                </Box>
              ) : field?.input_type === "color_picker" ? (
                <Box sx={{ width: "400px" }}>
                  <ColorInput
                    value={
                      data?.afterPlay?.[game]?.[field?.backend_label] ||
                      "#EBD8FC"
                    }
                    onChange={(color) =>
                      handleAfterPlayChange(field?.backend_label, color)
                    }
                  />
                </Box>
              ) : field?.input_type === "image_and_color_picker" ? (
                <Box mt={"20px"}>
                  <Box mt={"-10px"}>
                    <MuiRadioGroup
                      options={field?.options}
                      value={
                        data?.afterPlay?.[game]?.[field?.backend_label] ||
                        "color"
                      }
                      onChange={(value) =>
                        handleAfterPlayChange(field?.backend_label, value)
                      }
                    />
                  </Box>
                  <Box></Box>
                  {data?.afterPlay?.[game]?.[field?.backend_label] ===
                  "image" ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "start",
                        gap: "15px",
                      }}
                    >
                      {field?.options
                        ?.find((option) =>
                          option?.backend_label.includes("image")
                        )
                        ?.options?.map((image, index) => (
                          <Box
                            key={index}
                            sx={{
                              height: "100px",
                              width: "100px",
                              borderRadius: "8px",
                              border:
                                data?.afterPlay?.[game]?.[
                                  field?.options?.find((o) =>
                                    o.backend_label.includes("image")
                                  )?.backend_label
                                ] === image
                                  ? "3px solid black"
                                  : "3px solid transparent",
                              position: "relative",
                              overflow: "hidden",
                            }}
                            onClick={() =>
                              handleAfterPlayChange(
                                field?.options?.find((o) =>
                                  o.backend_label.includes("image")
                                )?.backend_label,
                                image
                              )
                            }
                          >
                            <img
                              style={{
                                height: "100%",
                                width: "100%",
                                borderRadius: "6px",
                                objectFit: "cover",
                              }}
                              src={image}
                              alt=""
                            />
                            {data?.afterPlay?.[game]?.[
                              field?.options?.find((o) =>
                                o.backend_label.includes("image")
                              )?.backend_label
                            ] === image && (
                              <img
                                style={{
                                  position: "absolute",
                                  top: "5px",
                                  left: "5px",
                                  zIndex: 100,
                                  width: "20px",
                                  height: "20px",
                                }}
                                src={tickImage}
                                alt="Selected"
                              />
                            )}
                          </Box>
                        ))}
                      <Box
                        sx={{
                          height: "100px",
                          display: "flex",
                          alignItems: "center",
                          gap: "25px",
                        }}
                      >
                        <Typography>OR</Typography>
                        <Box>
                          <SmallImageUpload
                            value={
                              data?.afterPlay?.[game]?.[
                                `${
                                  field?.options?.find((o) =>
                                    o.backend_label.includes("image")
                                  )?.backend_label
                                }_upload_url`
                              ]
                            }
                            onClick={() => {
                              if (
                                data?.afterPlay?.[game]?.[
                                  `${
                                    field?.options?.find((o) =>
                                      o.backend_label.includes("image")
                                    )?.backend_label
                                  }_upload_url`
                                ]
                              ) {
                                handleAfterPlayChange(
                                  field?.options?.find((o) =>
                                    o.backend_label.includes("image")
                                  )?.backend_label,
                                  data?.afterPlay?.[game]?.[
                                    `${
                                      field?.options?.find((o) =>
                                        o.backend_label.includes("image")
                                      )?.backend_label
                                    }_upload_url`
                                  ]
                                );
                              }
                            }}
                            borderValue={
                              data?.afterPlay?.[game]?.[
                                field?.options?.find((o) =>
                                  o.backend_label.includes("image")
                                )?.backend_label
                              ]
                            }
                            onChange={(file) =>
                              handleAfterPlayChange(
                                `${
                                  field?.options?.find((o) =>
                                    o.backend_label.includes("image")
                                  )?.backend_label
                                }_upload`,
                                file
                              )
                            }
                            handleImage={(value) =>
                              onChange({
                                ...data,
                                afterPlay: {
                                  ...data.afterPlay,
                                  [game]: {
                                    ...data.afterPlay[game],
                                    [`${
                                      field?.options?.find((o) =>
                                        o.backend_label.includes("image")
                                      )?.backend_label
                                    }`]: value,
                                    [`${
                                      field?.options?.find((o) =>
                                        o.backend_label.includes("image")
                                      )?.backend_label
                                    }_upload`]: value,
                                    [`${
                                      field?.options?.find((o) =>
                                        o.backend_label.includes("image")
                                      )?.backend_label
                                    }_upload_url`]: value,
                                    [`${
                                      field?.options?.find((o) =>
                                        o.backend_label.includes("image")
                                      )?.backend_label
                                    }_upload_id`]: value,
                                    [field?.options?.find((o) =>
                                      o.backend_label.includes("image")
                                    )?.backend_label]: value,
                                  },
                                },
                              })
                            }
                            image={
                              data?.afterPlay?.[game]?.[
                                `${
                                  field?.options?.find((o) =>
                                    o.backend_label.includes("image")
                                  )?.backend_label
                                }_upload_base64`
                              ]
                            }
                          />
                        </Box>
                      </Box>
                    </Box>
                  ) : (
                    <Box mt={"-10px"} sx={{ width: "400px" }}>
                      <ColorInput
                        value={
                          data?.afterPlay?.[game]?.[
                            field?.options?.find((o) =>
                              o.backend_label.includes("color")
                            )?.backend_label
                          ]
                        }
                        onChange={(color) =>
                          handleAfterPlayChange(
                            field?.options?.find((o) =>
                              o.backend_label.includes("color")
                            )?.backend_label,
                            color
                          )
                        }
                      />
                    </Box>
                  )}
                </Box>
              ) : field?.input_type === "image" ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    gap: "15px",
                  }}
                >
                  {field?.options
                    ?.find((option) => option?.value === "image")
                    ?.options?.map((image, index) => (
                      <Box
                        key={index}
                        sx={{
                          height: "100px",
                          width: "100px",
                          borderRadius: "8px",
                          border:
                            data?.afterPlay?.[game]?.[
                              field?.options?.find((o) => o.value === "image")
                                ?.backend_label
                            ] === image
                              ? "3px solid black"
                              : "3px solid transparent",
                          position: "relative",
                          overflow: "hidden",
                        }}
                        onClick={() =>
                          handleAfterPlayChange(
                            field?.options?.find((o) => o.value === "image")
                              ?.backend_label,
                            image
                          )
                        }
                      >
                        <img
                          style={{
                            height: "100%",
                            width: "100%",
                            borderRadius: "6px",
                            objectFit: "cover",
                          }}
                          src={image}
                          alt=""
                        />
                        {data?.afterPlay?.[game]?.[
                          field?.options?.find((o) => o.value === "image")
                            ?.backend_label
                        ] === image && (
                          <img
                            style={{
                              position: "absolute",
                              top: "5px",
                              left: "5px",
                              zIndex: 100,
                              width: "20px",
                              height: "20px",
                            }}
                            src={tickImage}
                            alt="Selected"
                          />
                        )}
                      </Box>
                    ))}
                  <Box
                    sx={{
                      height: "100px",
                      display: "flex",
                      alignItems: "center",
                      gap: "25px",
                    }}
                  >
                    <Typography>OR</Typography>
                    <Box>
                      <SmallImageUpload
                        value={
                          data?.afterPlay?.[game]?.[
                            `${
                              field?.options?.find((o) => o.value === "image")
                                ?.backend_label
                            }_upload_url`
                          ]
                        }
                        onClick={() => {
                          if (
                            data?.afterPlay?.[game]?.[
                              `${
                                field?.options?.find(
                                  (o) => o.value === "image"
                                )?.backend_label
                              }_upload_url`
                            ]
                          ) {
                            handleAfterPlayChange(
                              field?.options?.find((o) => o.value === "image")
                                ?.backend_label,
                              data?.afterPlay?.[game]?.[
                                `${
                                  field?.options?.find(
                                    (o) => o.value === "image"
                                  )?.backend_label
                                }_upload_url`
                              ]
                            );
                          }
                        }}
                        borderValue={
                          data?.afterPlay?.[game]?.[
                            field?.options?.find((o) => o.value === "image")
                              ?.backend_label
                          ]
                        }
                        onChange={(file) =>
                          handleAfterPlayChange(
                            `${
                              field?.options?.find((o) => o.value === "image")
                                ?.backend_label
                            }_upload`,
                            file
                          )
                        }
                        handleImage={(value) =>
                          onChange({
                            ...data,
                            afterPlay: {
                              ...data.afterPlay,
                              [game]: {
                                ...data.afterPlay[game],
                                [`${
                                  field?.options?.find(
                                    (o) => o.value === "image"
                                  )?.backend_label
                                }`]: value,
                                [`${
                                  field?.options?.find(
                                    (o) => o.value === "image"
                                  )?.backend_label
                                }_upload`]: value,
                                [`${
                                  field?.options?.find(
                                    (o) => o.value === "image"
                                  )?.backend_label
                                }_upload_url`]: value,
                                [`${
                                  field?.options?.find(
                                    (o) => o.value === "image"
                                  )?.backend_label
                                }_upload_id`]: value,
                                [field?.options?.find(
                                  (o) => o.value === "image"
                                )?.backend_label]: value,
                              },
                            },
                          })
                        }
                        image={
                          data?.afterPlay?.[game]?.[
                            `${
                              field?.options?.find((o) => o.value === "image")
                                ?.backend_label
                            }_upload_base64`
                          ]
                        }
                      />
                    </Box>
                  </Box>
                </Box>
              ) : (
                ""
              )}
            </Box>
          );
        })}
    </Box>
  );
}