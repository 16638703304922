import { Button } from "@mui/material";
import { useState } from "react";
import Select, { components } from "react-select";

const Control = ({ children, ...props }) => {
  const { emoji, onEmojiClick, isFocused } = props.selectProps;
  // console.log(isFocused);

  return (
    <components.Control {...props}>
      {children}
      {isFocused && (
        <Button
          onMouseDown={(e) => {
            e.stopPropagation();
            onEmojiClick(e);
          }}
          sx={{
            background: "#000",
            color: "#fff",
            "&:hover": {
              background: "#000",
            },
          }}
        >
          {emoji}
        </Button>
      )}
    </components.Control>
  );
};

const CustomSelectProps = ({ options, handleClick, onChange, value }) => {
  const [isFocused, setIsFocused] = useState(false);
  const styles = {
    control: (css) => ({
      ...css,
      textAlign: "left", // Align options to the left
    }),
    menu: (provided) => ({
      ...provided,
      textAlign: "left", // Align options to the left
    }),
    option: (provided, state) => ({
      ...provided,
      background: state.isSelected ? '#cee2f7' : 'inherit',
      color: 'black',
      '&:hover': {
        background: 'rgba(182, 181, 181, 0.3)',
      },
      fontSize: '18px',
    }),
  };

  const emoji = "ADD";

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <Select
      value={options?.find((res) => res.value === value) ?? ""}
      isFocused={isFocused}
      emoji={emoji}
      menuPlacement="auto"
      placeholder="--Select--"
      onEmojiClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        handleClick(e);
      }}
      onChange={(e) => {
        onChange(e.value);
      }}
      components={{ Control }}
      isSearchable
      name="emoji"
      options={options}
      styles={styles}
      onFocus={handleFocus}
      onBlur={handleBlur}
    />
  );
};

export default CustomSelectProps;
