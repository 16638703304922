// ReusableRadioGroup.js
import React from "react";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { Box, Checkbox, FormGroup, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CustomDatePicker from "../DateTimePickers/CustomDatePicker";
import ReusableTimePicker from "../DateTimePickers/ReusableTimePicker";
import PropTypes from "prop-types";

const ReusableRadioGroup = ({
  value,
  onChange = () => {},
  options,
  date,
  name,
  time,
}) => {
  const emptyCallBack = () => {};
  return (
    <React.Fragment>
<FormControl>
  <FormGroup
    name={name}
    value={value}
    sx={{
      display: "flex",
      boxSizing: "border-box",
      width: "365px",
      alignItems: "flex-start",
      alignContent: "flex-start",
      flexDirection: "column",
      gap: "0px",
      margin: "auto",
    }}
  >
    {options.map((option) => (
      <FormControlLabel
        key={option.value}
        value={option.value}
        label={option.label}
        control={
          <Checkbox
            sx={{
              color: "#0F1828",
              "&.Mui-checked": {
                color: "#0F1828", // Checked color
              },
            }}
          />
        }
        checked={value?.includes(typeof option.value === "string" ? option.value : String(option.value))}
       onChange={(e) => {
        e.stopPropagation()
        console.log(value?.includes(option.value) , value , option?.value)
        onChange(name,e.target.value , e.target.checked);
      }}
        sx={{
          color: "#272728",
          fontWeight: 400,
          fontSize: "14px",
          fontFamily: "Proxima Nova A",
          lineHeight: "17.05px",
        }}
      />
    ))}
  </FormGroup>
</FormControl>
      {date && (
        <React.Fragment>
          <Typography m={"10px 0"}>{date?.customDateHeader ?? ""}</Typography>
          <Box
            sx={{
              display: "grid",
              columnGap: 1,
              gridTemplateColumns: "repeat(2, 1fr)",
              marginTop: "12px",
            }}
          >
            {date?.customDateLabel &&
              date.customDateLabel.map((label, index) => (
                <LocalizationProvider dateAdapter={AdapterDayjs} key={index}>
                  <CustomDatePicker
                    value={
                      date?.customDateState[date.customDateName[index]] ?? ""
                    }
                    label={label}
                    name={
                      date?.customDateName ? date.customDateName[index] : ""
                    }
                    setState={date?.setState ?? emptyCallBack}
                    onChange={date?.customeDateHandler ?? emptyCallBack}
                    isoString={date?.isoString}
                    isDispatch={date.isDispatch}
                    state={date.customDateState}
                  />
                </LocalizationProvider>
              ))}
          </Box>
        </React.Fragment>
      )}

      {time && (
        <React.Fragment>
          <Typography m={"10px 0"}>{time?.timeHeader ?? ""}</Typography>
          <Box
            sx={{
              display: "fl",
              columnGap: 1,
              gridTemplateColumns: "repeat(2, 1fr)",
              marginTop: "12px",
            }}
          >
            {time &&
              time?.customTimeName &&
              time.customTimeName.map((name, index) => (
                <LocalizationProvider dateAdapter={AdapterDayjs} key={index}>
                  <ReusableTimePicker
                    ampm={false}
                    label={time?.timeLabel[index] ?? ""}
                    name={name || ""}
                    value={time?.timeState[name] ?? ""}
                    setState={time?.setState ?? emptyCallBack}
                    onChange={time?.customeTimeHandler ?? emptyCallBack}
                  />
                </LocalizationProvider>
              ))}
          </Box>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

ReusableRadioGroup.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  date: PropTypes.shape({
    customDateHeader: PropTypes.string,
    customDateLabel: PropTypes.arrayOf(PropTypes.string),
    customDateName: PropTypes.arrayOf(PropTypes.string),
    customDateState: PropTypes.object,
    setState: PropTypes.func,
    customeDateHandler: PropTypes.func,
  }),
  time: PropTypes.shape({
    timeHeader: PropTypes.string,
    customTimeName: PropTypes.arrayOf(PropTypes.string),
    timeLabel: PropTypes.arrayOf(PropTypes.string),
    timeState: PropTypes.object,
    setState: PropTypes.func,
    customeTimeHandler: PropTypes.func,
  }),
  isDispatch: PropTypes.bool, // or adjust according to your needs
};

export default ReusableRadioGroup;
