import { Box, Stack } from "@mui/material";
import React from "react";
import AnalyticsSection from "../AnalyticsSection";
import CustomBarChart from "../charts/CustomBarChart";
import ProjectionSection from "./ProjectionSection";

// const sections = [
//   {
//     key: "data_based_on_custom_period",
//     title: "Data Based on ${CUSTOM_PERIOD}",
//     titleColor: "#585858",
//     sidebackgroundColor: "#D4D71E",
//     columnsInRow: 3,
//     options: {
//       "#_total_transactions": 3000,
//       "#_total_eligible_transactions": 3000,
//     },
//   },
//   {
//     key: "rewards_analytics",
//     title: "Rewards Analytics",
//     titleColor: "#585858",
//     sidebackgroundColor: "#D4D71E",
//     columnsInRow: 4,
//     options: {
//       total_users: 500,
//       distinct_users: 100,
//       total_digigold_value: 3000,
//       total_neucoins: 5000,
//       total_reward_value: 5000,
//       average_reward: 10,
//     },
//   },
// ];
// const data = [
//     [
//         {
//           name: "Page A",
//           uv: 4000,
//           pv: 2400,
//           amt: 2400,
//         },
//         {
//           name: "Page B",
//           uv: 3000,
//           pv: 1398,
//           amt: 2210,
//         },
//         {
//           name: "Page C",
//           uv: 2000,
//           pv: 2800,
//           amt: 2290,
//         },
//         {
//           name: "Page D",
//           uv: 2780,
//           pv: 3908,
//           amt: 2000,
//         },
//         {
//           name: "Page E",
//           uv: 1890,
//           pv: 4800,
//           amt: 2181,
//         }
//       ],

//       [
//         {
//           name: "Page A",
//           uv: 4000,
//           pv: 2400,
//           amt: 2400,
//         },
//         {
//           name: "Page B",
//           uv: 3000,
//           pv: 1398,
//           amt: 2210,
//         },
//         {
//           name: "Page C",
//           uv: 2000,
//           pv: 9800,
//           amt: 2290,
//         },
//         {
//           name: "Page D",
//           uv: 2780,
//           pv: 3908,
//           amt: 2000,
//         },
//         {
//           name: "Page E",
//           uv: 1890,
//           pv: 4800,
//           amt: 2181,
//         },

//       ]
// ];

export default function ViewProjection({ tabData, activeTab }) {
  const data = tabData[activeTab]?.projectionData?.charts;
  const sections = tabData[activeTab]?.projectionData?.sections;
  console.log(tabData[activeTab], "tabData");
  return (
    <Box pb={2} pr={2}>
      <Stack spacing={5} boxSizing={"border-box"} width={"100%"} mt={"15px"}>
        {sections?.map((res) => (
          <ProjectionSection
            title={res.title}
            options={Object.entries(res?.options) ?? []}
            titleColor={res?.titleColor}
            sidebackgroundColor={res?.sidebackgroundColor}
            columnsInRow={res?.columnsInRow}
          />
        ))}
      </Stack>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gridGap: "30px",
          marginY: "24px",
        }}
      >
        {data?.map((res, index) => (
          <CustomBarChart
            data={res?.data}
            barColor={["#D693D7", "#D4D71E"][index]}
            chartTitle={res?.chartTitle}
            yAxisTitle={res?.yAxisTitle}
            xAxisTitle={res?.xAxisTitle}
          />
        ))}
      </Box>
      {/* <CustomBarChart data={data} barColor={"#D693D7"}  />  */}
    </Box>
  );
}
