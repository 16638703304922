import React, { useEffect, useState } from "react";
import CreateCollection from "./CreateCollection";
import TableTotalCount from "../components/TableTotalCount";
import { Box, Button } from "@mui/material";
import CreateRule from "../components/offers/CreateRule";
import CollectionCreateRule from "./CollectionCreateRule";
const initialData = [
  {
    csvFile: "",
    // url: "",
    name: "",
    description: "",
    type: "",
    linkToRule: {},
  },
];

const schema = {
  tabName: "Create Rule",
  tabType: "rule",
  rule: "",
  description: "",
  id: 1,
  type: "",
  whereSection: [{}],
};

export default function Collection() {
  // to handle Tabs
  const [totalTab, setTotalTab] = useState(1);
  // to track active tab
  const [activeTab, setActiveTab] = useState(0);
  // to map all the tabs
  const [tabArray, setTabArray] = useState([]);
  // to keep id unique
  const [lastId, setLastId] = useState(1);
  // to store the data of all tab
  const [tabData, setTabData] = useState(initialData);

  const handleAddTab = () => {
    if (tabData.length <= 6) {
      setTotalTab((prev) => prev + 1);
      setTabData((prev) => {
        return [...prev, { ...{ ...schema, whereSection: [{}] } }];
      });
    }
    // if we want to show any alert/toast for "Maximum number of tabs reached in that case we can add here in else condition"
  };

  const handleChangeTab = (i) => {
    setActiveTab(i);
  };

  const handleDeleteTab = (e, index) => {
    e.stopPropagation();
    let filteredTab = tabArray.filter((e, i) => index !== i);
    setTabArray(filteredTab);
    setTotalTab(filteredTab.length);
    let filtered = tabData.filter((e, i) => i !== index);
    setTabData(filtered);
  };

  const handleCancel = (e, index) => {
    let filteredTab = tabArray.filter((e, i) => index !== i);
    setTabArray(filteredTab);
    setTotalTab(filteredTab.length);
    let filtered = tabData.filter((e, i) => i !== index);
    setTabData(filtered);
  };

//   useEffect(() => {
//     setActiveTab(tabData.length - 1);
//   }, [tabData.length]);

  useEffect(() => {
    const newTabs = new Array(totalTab).fill(0);
    setTabArray(newTabs);
    setActiveTab(newTabs.length - 1);
    setLastId((prev) => prev + 1);
  }, [totalTab]);

  return (
    <Box m="40px 30px">
      <Box display="flex" gap="5px">
        {tabData.length > 0 &&
          tabData.map((e, i) => (
            <TableTotalCount
              key={i}
              handleChangeTab={() => handleChangeTab(i)}
              text={i === 0 ? "Cohorts" : e?.tabName}
              index={i}
              count={tabData.length}
              deleteTab={(e) => handleDeleteTab(e, i)}
              activeTab={activeTab}
              //   totalCount={e?.TabType === "" ? limits?.totalCount : null}
            />
          ))}
      </Box>

      <Box p="20px 0px" sx={{ backgroundColor: "#fff" }}>
        {activeTab === 0 ? (
          <CreateCollection
            tabData={tabData}
            setTabData={setTabData}
            activeTab={activeTab}
            handleCreateRule={handleAddTab}
            cancelButtonHide={true}
          />
        ) : (
          <CollectionCreateRule
            tabData={tabData}
            activeTab={activeTab}
            setTabData={setTabData}
            handleCancel={handleCancel}
          />
          // <CreateRule
          //   tabData={tabData}
          //   activeTab={activeTab}
          //   setTabData={setTabData}
          //   handleCancel={handleCancel}
          // />
        )}
      </Box>
    </Box>
  );
}
