import React from "react";
import Overview from "./Overview";
// import RewardLevel from "./RewardLevel";
import OfferLevel from "./OfferLevel";
import UserLevel from "./UserLevel";
import ProductLevel from "./ProductLevel";

const SwitchablePanel = ({
  activeButton,
  filter,
  handleReset,
  doneButton,
  dateFilter,
  handleShowDateFilter,
  showDateFilter,
  handleRemove,
  handleDateFilter,
}) => {
  switch (activeButton) {
    case "Overview":
      return (
        <Overview
          activeButton={activeButton}
          dateFilter={dateFilter}
          filter={filter}
          handleReset={handleReset}
          doneButton={doneButton}
          handleRemove={handleRemove(activeButton)}
          handleDateFilter={handleDateFilter}
        />
      );
    case "Product Level":
      return (
        <ProductLevel
          activeButton={activeButton}
          filter={filter}
          dateFilter={dateFilter}
          handleReset={handleReset}
          doneButton={doneButton}
          handleShowDateFilter={handleShowDateFilter}
          showDateFilter={showDateFilter[activeButton]}
          handleRemove={handleRemove(activeButton)}
          handleDateFilter={handleDateFilter}
        />
      );
    case "Offer Level":
      return (
        <OfferLevel
          activeButton={activeButton}
          filter={filter}
          dateFilter={dateFilter}
          handleReset={handleReset}
          doneButton={doneButton}
          handleShowDateFilter={handleShowDateFilter}
          showDateFilter={showDateFilter[activeButton]}
          handleRemove={handleRemove(activeButton)}
          handleDateFilter={handleDateFilter}
        />
      );
    case "User Level":
      return (
        <UserLevel
          activeButton={activeButton}
          dateFilter={dateFilter}
          filter={filter}
          handleReset={handleReset}
          doneButton={doneButton}
          handleDateFilter={handleDateFilter}
        />
      );
    default:
      return false;
  }
};

export default SwitchablePanel;
