import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import BasicOffers from "./BasicOffers";
import OfferDetails from "./OfferDetails";
import CreateGame from "./CreateGame";
import SetOfferRules from "./SetOfferRules";
import { useState } from "react";
import {
  isValidBasicOffersData,
  isValidCreateGameData,
  isValidOfferDetailsData,
  isValidOfferLimitData,
  isValidSetOfferRulesData,
} from "../../utils/validations";
import sanitizeHtml from "sanitize-html";
import OfferLimit from "./OfferLimit";
import TableTotalCount from "../TableTotalCount";
import OfferPreview from "./OfferPreview";
import { useEffect } from "react";
import CreateRule from "./CreateRule";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import UserCohortsCreate from "../../pages/UserCohortsCreate";
import CreateCollection from "../../pages/CreateCollection";
import CollectionCreateRule from "../../pages/CollectionCreateRule";
import { scrollFlagTrigger } from "../../Redux/AuthReducer/actionType";
import ViewProjection from "./ViewProjection";
import ProjectionList from "./ProjectionList";
import { StepConnector, styled } from "@mui/material";
import { convertRewardDetails, fetchCsvFileFromUrl } from "../../utils/CreateGame_PreData";
import { projectionLoaderStatus } from "../../Redux/OfferReducer/actionType";

const CustomStepConnector = styled(StepConnector)(({ theme }) => ({
  "&.MuiStepConnector-root": {
    top: 18,
    left: "calc(-50% + 14px)",
    right: "calc(50% + 14px)",
  },
  "& .MuiStepConnector-line": {
    borderColor: "grey",
    borderWidth: 2,
  },
  "&.Mui-completed .MuiStepConnector-line": {
    borderColor: "black",
    borderWidth: 2,
  },
  "&.Mui-active .MuiStepConnector-line": {
    borderColor: "black",
    borderWidth: 2,
  },
}));

const steps = [
  "Offer Basis",
  "Offer Details",
  "Create Game",
  "Set Offer Rules",
  // "Offer Limit",
];

const initialDataValue = () => {
  return {
    basicOffers: {
      category: [],
      subCategory: [],
      product: [],
    },
    offerDetails: {
      title: "",
      description: "",
      startDate: null,
      endDate: null,
      programId: "",
      lockingPeriod: "",
      expiryPeriod: "",
      offerInformationForPWABefore: "",
      offerInformationForPWAAfter: "",
      TAndC: "",
      coverPhoto: "",
      coverPhotoId: "",
      coverImageUrl: "",
      offerInitiative: "",
      gameType: "",
      rewardCurrency: [],
      rewardCurrencyId: [],
      merchant: [],
      csvFile: "",
      csvUrl: "",
    },
    createGame: {
      // merchantLogo: "",
      // merchantLogoId: "",
      // scratchCardColor: "",
      // offerTitleColor: "",
      // scratchCardImage: "",
      // scratchCardImageId: "",
      // scratchCardImageUrl: "",
      // merchantLogoUrl: "",
      game: {
        scratchCardData: [],
        spinTheWheelData: [],
        slotMachineData: [],
        triviaData:[],
        triviaData_step1: {
          triviaType: "",
          questions: [
            {
              title: "",
              addMedia: false,
              images: [],
              options: [{ optionTitle: "", isSelected: true }],
            },
          ],
        },
      },
      beforePlay: {
        scratchCardData: {},
        spinTheWheelData: {},
        slotMachineData: {},
        triviaData: {},
      },
      afterPlay: {
        scratchCardData: {},
        spinTheWheelData: {},
        slotMachineData: {},
        triviaData: {},
      },
      whenPlay:{
        triviaData: {},
      },
      addNewRewards: {
        scratchCardData: {
          rewardType: "",
          previouslyCreatedReward: "",
          templates: "",
          startFromScratch: {
            rewardType: {},
            rewardTitle: "",
          },
        },
        spinTheWheelData: {
          rewardType: "",
          previouslyCreatedReward: "",
          templates: "",
          startFromScratch: {
            rewardType: {},
            rewardTitle: "",
          },
        },
        slotMachineData: {
          rewardType: "",
          previouslyCreatedReward: "",
          templates: "",
          startFromScratch: {
            rewardType: {},
            rewardTitle: "",
          },
        },
        triviaData: {
          rewardType: "",
          previouslyCreatedReward: "",
          templates: "",
          startFromScratch: {
            rewardType: {},
            rewardTitle: "",
          },
        },
      },
    },
    setOfferRules: {
      linkToRule: "",
      linkToCohort: "",
    },
    offerLimit: {
      offerLevelLimits: {
        fairUsageOfRewardIssuance: {
          total: "",
          daily: "",
          weekly: "",
          monthly: "",
        },
        fairUsageOfRewardRedemption: {
          total: "",
          daily: "",
          weekly: "",
          monthly: "",
        },
      },
      userLevelLimits: {
        fairUsageOfRewardIssuance: {
          total: "",
          daily: "",
          weekly: "",
          monthly: "",
          totalAmount: "",
        },
        fairUsageOfRewardRedemption: {
          total: "",
          daily: "",
          weekly: "",
          monthly: "",
          totalAmount: "",
        },
      },
    },
  };
};

const initialBasicOffersError = () => {
  return {
    category: "",
    subCategory: "",
    merchant: "",
    product: "",
  };
};

const initialCreateGameError = () => {
  return {
    merchantLogo: "",
    scratchCardColor: "",
    offerTitleColor: "",
  };
};

const initialOfferDetailsError = () => {
  return {
    title: "",
    description: "",
    startDate: "",
    endDate: "",
    offerInformationForPWABefore: "",
    offerInformationForPWAAfter: "",
    TAndC: "",
    coverPhoto: "",
    offerInitiative: "",
    gameType: "",
    rewardCurrency: "",
  };
};

const initialSetOfferRulesError = () => {
  return {
    linkToRule: "",
    linkToCohort: "",
  };
};

const initialOfferLimitErrorError = () => {
  return {
    offerLevelLimits: {
      fairUsageOfRewardIssuance: {
        total: "",
        daily: "",
        weekly: "",
        monthly: "",
      },
      fairUsageOfRewardRedemption: {
        total: "",
        daily: "",
        weekly: "",
        monthly: "",
      },
    },
    userLevelLimits: {
      fairUsageOfRewardIssuance: {
        total: "",
        daily: "",
        weekly: "",
        monthly: "",
        totalAmount: "",
      },
      fairUsageOfRewardRedemption: {
        total: "",
        daily: "",
        weekly: "",
        monthly: "",
        totalAmount: "",
      },
    },
  };
};

const schema = {
  tabName: "Create Rule",
  tabType: "rule",
  rule: "",
  description: "",
  id: 1,
  type: "",
  whereSection: [{}],
};

const collectionRuleSchema = {
  tabName: "Cohort Rule",
  tabType: "cohortRule",
  rule: "",
  description: "",
  id: 1,
  whereSection: [{}],
};

const cohortSchema = {
  tabName: "Create Cohort",
  tabType: "cohort",
  csvFile: "",
  // url: "",
  name: "",
  description: "",
  type: "",
  linkToRule: {},
};

const projectionListSchema = {
  tabName: "Projection List",
  tabType: "projectionList",
  createModal: false,
  cancelModel: false,
  modalStatus: false,
  day: "",
  startDate: "",
  endDate: "",
};
const viewProjectionSchema = {
  tabName: "View Projection",
  tabType: "viewProjection",
  projectionData: {},
};

export default function OfferStepper({ initialData = initialDataValue }) {
  const [merchantLogoImg, setMerchantLogoImg] = useState(null);
  const [scratchCardImage, setScratchCardImage] = useState(null);
  const [preFilledData, setPreFilledData] = useState(initialData);
  const [isPreviewShow, setIsPreviewShow] = useState(false);

  const [coverPhoto, setCoverPhoto] = useState(null);
  const [furthestStepVisited, setFurthestStepVisited] = useState(0);
  // to handle stepper
  const [activeStep, setActiveStep] = useState(0);
  const [scrollFlag, setScrollFlag] = useState(true);
  // to handle Tabs
  const [totalTab, setTotalTab] = useState(1);
  // to track active tab
  const [activeTab, setActiveTab] = useState(0);
  // to map all the tabs
  const [tabArray, setTabArray] = useState([]);
  // to keep id unique
  const [lastId, setLastId] = useState(1);
  // to store the data of all tab
  const [tabData, setTabData] = useState([schema]);
  const { id } = useParams();
  const { state } = useLocation();
  const token = useSelector((store) => store.AuthReducer.token);
  const rewards = useSelector(
    (store) => store?.OfferReducer?.allRewards
  )?.rewardTypes;
  const gameData = useSelector((store) => store?.OfferReducer?.allGames);
  // console.log(state?.id,"state")
  const BaseUrl = process.env.REACT_APP_API_BASE_URL;
  const dispatch = useDispatch();
  const {pathname} = useLocation();
  // function to get offer details/data by ID ==>COPY_MODE
  const getOfferDetailsById = (id) => {
    console.log(token, "stateinner");
    let url = `${BaseUrl}/api/admin/offer/${id}`;
  

    if (pathname.startsWith("/approve-offers")) {
      // Append the offerApprovalStatus parameter
      url += "?offerApprovalStatus=pending";
    }
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const offerLimit = res.data?.values?.find(
          (e) => e.entity_type == "OFFER"
        )?.limit_type;
        const userLimit = res.data?.values?.find(
          (e) => e.entity_type == "USER"
        )?.limit_type;
        // const userRedeem = res.data?.values?.find(
        //   (e) => e.entityType == "MAXIMUM"
        // )?.limitType;
        console.log(offerLimit, "state");
        setPreFilledData((prev) => ({
          ...prev,
          basicOffers: {
            ...prev.basicOffers,
            category: pathname.startsWith("/approve-offers")
              ? res.data?.updated_offer_details?.categoryId
              : res.data?.categoryId ?? "",
            previous_category: res.data?.previous_offer_details?.categoryId ?? [],
            subCategory: pathname.startsWith("/approve-offers")
              ? res.data?.updated_offer_details?.subcategoryId
              : res.data?.subcategoryId ?? "",
            previous_subCategory: res.data?.previous_offer_details?.subcategoryId ?? [],
            product: pathname.startsWith("/approve-offers")
              ? res.data?.updated_offer_details?.productId
              : res.data?.productId ?? "",
            previous_product: res.data?.previous_offer_details?.productId ?? [],
          },
          offerDetails: {
            merchant: pathname.startsWith("/approve-offers")
              ? res.data?.updated_offer_details?.merchantId
              : res.data?.merchantId ?? "",
            previous_merchant: res.data?.previous_offer_details?.merchantId ?? [],
            title: pathname.startsWith("/approve-offers")
              ? res.data?.updated_offer_details?.name
              : res.data?.name ?? "",
            previous_title: res.data?.previous_offer_details?.name ?? "",
            description: pathname.startsWith("/approve-offers")
              ? res.data?.updated_offer_details?.description
              : res.data?.description ?? "",
            previous_description: res.data?.previous_offer_details?.description ?? "",
            startDate: pathname.startsWith("/approve-offers")
              ? res.data?.updated_offer_details?.startsAt
              : res.data?.startsAt ?? "",
            previous_startDate: res.data?.previous_offer_details?.startsAt ?? "",
            endDate: pathname.startsWith("/approve-offers")
              ? res.data?.updated_offer_details?.endsAt
              : res.data?.endsAt ?? "",
            previous_endDate: res.data?.previous_offer_details?.endsAt ?? "",
            programId: pathname.startsWith("/approve-offers")
              ? res.data?.updated_offer_details?.programId
              : res.data?.programId ?? "",
            previous_programId: res.data?.previous_offer_details?.programId ?? "",
            lockingPeriod: pathname.startsWith("/approve-offers")
              ? res.data?.updated_offer_details?.lockingPeriod
              : res.data?.lockingPeriod ?? "",
            previous_lockingPeriod: res.data?.previous_offer_details?.lockingPeriod ?? "",
            expiryPeriod: pathname.startsWith("/approve-offers")
              ? res.data?.updated_offer_details?.expiryPeriod
              : res.data?.expiryPeriod ?? "",
            previous_expiryPeriod: res.data?.previous_offer_details?.expiryPeriod ?? "",
            offerInformationForPWABefore: pathname.startsWith("/approve-offers")
              ? res.data?.updated_offer_details?.informationBeforeRedemption
              : res.data?.informationBeforeRedemption ?? "",
            previous_offerInformationForPWABefore: res.data?.previous_offer_details?.informationBeforeRedemption ?? "",
            offerInformationForPWAAfter: pathname.startsWith("/approve-offers")
              ? res.data?.updated_offer_details?.informationAfterRedemption
              : res.data?.informationAfterRedemption ?? "",
            previous_offerInformationForPWAAfter: res.data?.previous_offer_details?.informationAfterRedemption ?? "",
            TAndC: pathname.startsWith("/approve-offers")
              ? res.data?.updated_offer_details?.termsAndConditions
              : res.data?.termsAndConditions ?? "",
            previous_TAndC: res.data?.previous_offer_details?.termsAndConditions ?? "",
            coverPhoto: pathname.startsWith("/approve-offers")
              ? res.data?.updated_offer_details?.coverImageUrl
              : res.data.coverImageUrl ?? "",
            previous_coverPhoto: res.data?.previous_offer_details?.coverImageUrl ?? "",
            coverImageUrl: pathname.startsWith("/approve-offers")
              ? res.data?.updated_offer_details?.coverImageUrl
              : res.data.coverImageUrl ?? "",
            previous_coverImageUrl: res.data?.previous_offer_details?.coverImageUrl ?? "",
            coverPhotoId: pathname.startsWith("/approve-offers")
              ? res.data?.updated_offer_details?.coverImageId
              : res.data.coverImageId ?? "",
            previous_coverPhotoId: res.data?.previous_offer_details?.coverImageId ?? "",
            offerInitiative: pathname.startsWith("/approve-offers")
              ? res.data?.updated_offer_details?.initiativeId
              : res.data?.initiativeId ?? "",
            previous_offerInitiative: res.data?.previous_offer_details?.initiativeId ?? "",
            gameType: pathname.startsWith("/approve-offers")
              ? res.data?.updated_offer_details?.game?.gameId
              : res.data?.game?.gameId ?? "",
            previous_gameType: res.data?.previous_offer_details?.game?.gameId ?? "",
            rewardCurrency: pathname.startsWith("/approve-offers")
              ? res.data?.updated_offer_details?.currencyCode
              : res.data?.currencyCode ?? "",
            previous_rewardCurrency: res.data?.previous_offer_details?.currencyCode ?? "",
            rewardCurrencyId: pathname.startsWith("/approve-offers")
              ? res.data?.updated_offer_details?.currencyId
              : res.data?.currencyId ?? "",
            previous_rewardCurrencyId: res.data?.previous_offer_details?.currencyId ?? "",
            csvUrl: pathname.startsWith("/approve-offers")
              ? res.data?.updated_offer_details?.csvUrl
              : res.data?.csvUrl ?? "",
            previous_csvUrl: res.data?.previous_offer_details?.csvUrl ?? "",
          },
          createGame: {
            merchantLogo: pathname.startsWith("/approve-offers")
              ? res.data?.updated_offer_details?.game?.merchantLogoUrl
              : res.data?.game?.merchantLogoUrl ?? "",
            previous_merchantLogo: res.data?.previous_offer_details?.game?.merchantLogoUrl ?? "",
            merchantLogoUrl: pathname.startsWith("/approve-offers")
              ? res.data?.updated_offer_details?.game?.merchantLogoUrl
              : res.data?.game?.merchantLogoUrl ?? "",
            previous_merchantLogoUrl: res.data?.previous_offer_details?.game?.merchantLogoUrl ?? "",
            merchantLogoId: pathname.startsWith("/approve-offers")
              ? res.data?.updated_offer_details?.game?.merchantLogoId
              : res.data?.game?.merchantLogoId ?? "",
            previous_merchantLogoId: res.data?.previous_offer_details?.game?.merchantLogoId ?? "",
            scratchCardColor: pathname.startsWith("/approve-offers")
              ? res.data?.updated_offer_details?.game?.scratchCardColour
              : res.data?.game?.scratchCardColour ?? "",
            previous_scratchCardColor: res.data?.previous_offer_details?.game?.scratchCardColour ?? "",
            offerTitleColor: pathname.startsWith("/approve-offers")
              ? res.data?.updated_offer_details?.game?.offerTitleColour
              : res.data?.game?.offerTitleColour ?? "",
            previous_offerTitleColor: res.data?.previous_offer_details?.game?.offerTitleColour ?? "",
            scratchCardImage: pathname.startsWith("/approve-offers")
              ? res.data?.updated_offer_details?.game?.scratchCardImageUrl
              : res.data?.game?.scratchCardImageUrl ?? "",
            previous_scratchCardImage: res.data?.previous_offer_details?.game?.scratchCardImageUrl ?? "",
            scratchCardImageId: pathname.startsWith("/approve-offers")
              ? res.data?.updated_offer_details?.game?.scratchCardImageId
              : res.data?.game?.scratchCardImageId ?? "",
            previous_scratchCardImageId: res.data?.previous_offer_details?.game?.scratchCardImageId ?? "",
            scratchCardImageUrl: pathname.startsWith("/approve-offers")
              ? res.data?.updated_offer_details?.game?.scratchCardImageUrl
              : res.data?.game?.scratchCardImageUrl ?? "",
            previous_scratchCardImageUrl: res.data?.previous_offer_details?.game?.scratchCardImageUrl ?? "",
          },
          setOfferRules: {
            linkToRule: pathname.startsWith("/approve-offers")
              ? res.data?.updated_offer_details?.earnRuleSetId
              : res.data?.earnRuleSetId ?? {},
            previous_linkToRule: res.data?.previous_offer_details?.earnRuleSetId ?? {},
            linkToCohort: pathname.startsWith("/approve-offers")
              ? res.data?.updated_offer_details?.collectionsIds
              : res.data?.collectionsIds ?? [],
            previous_linkToCohort: res.data?.previous_offer_details?.collectionsIds ?? [],
          },
          offerLimit: {
            offerLevelLimits: {
              fairUsageOfRewardIssuance: {
                daily: pathname.startsWith("/approve-offers")
                  ? res.data?.updated_offer_details?.offerLimit?.daily
                  : offerLimit?.daily,
                previous_daily: res.data?.previous_offer_details?.offerLimit?.daily,
                weekly: pathname.startsWith("/approve-offers")
                  ? res.data?.updated_offer_details?.offerLimit?.weekly
                  : offerLimit?.weekly,
                previous_weekly: res.data?.previous_offer_details?.offerLimit?.weekly,
                monthly: pathname.startsWith("/approve-offers")
                  ? res.data?.updated_offer_details?.offerLimit?.monthly
                  : offerLimit?.monthly,
                previous_monthly: res.data?.previous_offer_details?.offerLimit?.monthly,
                total: pathname.startsWith("/approve-offers")
                  ? res.data?.updated_offer_details?.offerLimit?.overall
                  : offerLimit?.overall,
                previous_total: res.data?.previous_offer_details?.offerLimit?.overall,
              },
            },
            userLevelLimits: {
              fairUsageOfRewardIssuance: {
                daily: pathname.startsWith("/approve-offers")
                  ? res.data?.updated_offer_details?.userLimit?.daily
                  : userLimit?.daily,
                previous_daily: res.data?.previous_offer_details?.userLimit?.daily,
                weekly: pathname.startsWith("/approve-offers")
                  ? res.data?.updated_offer_details?.userLimit?.weekly
                  : userLimit?.weekly,
                previous_weekly: res.data?.previous_offer_details?.userLimit?.weekly,
                monthly: pathname.startsWith("/approve-offers")
                  ? res.data?.updated_offer_details?.userLimit?.monthly
                  : userLimit?.monthly,
                previous_monthly: res.data?.previous_offer_details?.userLimit?.monthly,
                totalAmount: pathname.startsWith("/approve-offers")
                  ? res.data?.updated_offer_details?.userLimit?.overall
                  : userLimit?.overall,
                previous_totalAmount: res.data?.previous_offer_details?.userLimit?.overall,
              },
              fairUsageOfRewardRedemption: {
                daily: pathname.startsWith("/approve-offers")
                  ? res.data?.updated_offer_details?.userLimit?.daily_issuance
                  : userLimit?.daily_issuance,
                previous_daily: res.data?.previous_offer_details?.userLimit?.daily_issuance,
                weekly: pathname.startsWith("/approve-offers")
                  ? res.data?.updated_offer_details?.userLimit?.weekly_issuance
                  : userLimit?.weekly_issuance,
                previous_weekly: res.data?.previous_offer_details?.userLimit?.weekly_issuance,
                monthly: pathname.startsWith("/approve-offers")
                  ? res.data?.updated_offer_details?.userLimit?.monthly_issuance
                  : userLimit?.monthly_issuance,
                previous_monthly: res.data?.previous_offer_details?.userLimit?.monthly_issuance,
                totalAmount: pathname.startsWith("/approve-offers")
                  ? res.data?.updated_offer_details?.userLimit?.overall_issuance
                  : userLimit?.overall_issuance,
                previous_totalAmount: res.data?.previous_offer_details?.userLimit?.overall_issuance,
              },
            },
          },
        }));
        
      })
      .catch((error) => {
        console.log(error?.response?.data);
      });
  };

  const getOfferDataByIDv2 = (id) => {
    console.log(token, "stateinner");
    axios
          .get(`${BaseUrl}/api/admin/offer-v2/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const offerLimit = res.data?.values?.find(
          (e) => e.entity_type == "OFFER"
        )?.limit_type;
        const userLimit = res.data?.values?.find(
          (e) => e.entity_type == "USER"
        )?.limit_type;
        const gameDetails = gameData?.find(
          (item) => item?.id === res?.data?.game?.game_type_id
        );
        // console.log(offerLimit, "state");
        setPreFilledData((prev) => ({
          ...prev,
          basicOffers: {
            ...prev.basicOffers,
            category: res.data?.categoryId ?? "",
            subCategory: res.data?.subcategoryId ?? "",
            product: res.data?.productId ?? "",
          },
          offerDetails: {
            merchant: res.data?.merchantId ?? "",
            title: res.data?.name ?? "",
            description: res.data?.description ?? "",
            startDate: res.data?.startsAt ?? "",
            endDate: res.data?.endsAt ?? "",
            programId: res.data?.programId ?? "",
            lockingPeriod: res.data?.lockingPeriod ?? "",
            expiryPeriod: res.data?.expiryPeriod ?? "",
            offerInformationForPWABefore:
              res.data?.informationBeforeRedemption ?? "",
            offerInformationForPWAAfter:
              res.data?.informationAfterRedemption ?? "",
            TAndC: res.data?.termsAndConditions ?? "",
            coverPhoto: res.data?.coverImageUrl ?? "",
            coverImageUrl: res.data?.coverImageUrl ?? "",
            coverPhotoId: res.data?.coverImageId ?? "",
            offerInitiative: res.data?.initiativeId ?? "",
            gameType: res.data?.game?.gameId ?? "",
            rewardCurrency: res.data?.currencyCode ?? "",
            rewardCurrencyId: res.data?.currencyId ?? "",
            csvUrl: res.data?.csvUrl ?? "",
            // csvUrl : "https://gratifystorage01.blob.core.windows.net/gratify-dev/offerAnalytics_Mon Jun 03 2024 17_31_51 GMT+0530 (India Standard Time) (2).csv_e7ff9b"
          },
          createGame: {
            // merchantLogo: "",
            // merchantLogoId: "",
            // scratchCardColor: "",
            // offerTitleColor: "",
            // scratchCardImage: "",
            // scratchCardImageId: "",
            // scratchCardImageUrl: "",
            // merchantLogoUrl: "",
            gameName: gameDetails?.name,
            game_type_id: res?.data?.game?.game_type_id,
            game: {
              scratchCardData:
                gameDetails?.name === "Scratch Card"
                  ? res?.data?.game?.rewards?.map((item) => {
                      const rewardName = rewards?.find(
                        (e) => e?.id === item?.reward_type_id
                      )?.name;

                      const rewardType = rewards
                        ?.find((e) => e?.id === item?.reward_type_id)
                        ?.reward_details?.find(
                          (ele) => ele?.backend_label === "reward_type"
                        )
                        ?.options.find((ele) => ele?.name === rewardName);
                      const limitOptions = rewards.find(
                        (reward) => reward?.name === rewardType?.name
                      )?.limitOptions;
                      // console.log(limitOptions, "limitOptions");
                      return {
                        title_required: true,
                        probability_required: true,
                        limit: false,
                        limit_required: true,
                        rewardLevel_required: true,
                        rewardType_required: true,
                        title: item?.title,
                        probability: item?.win_probability,
                        reward_type: rewardType,
                        cashback_type: {
                          ...rewards
                            ?.find(
                              (reward) => reward.id === item?.reward_type_id
                            )
                            ?.reward_details?.find(
                              (element) =>
                                element?.backend_label === "cashback_type"
                            )
                            ?.options?.map((option) => {
                              return {
                                id: option?.value,
                                name: option?.label,
                                fields: option?.fields,
                              };
                            })
                            ?.find(
                              (elem) =>
                                elem?.id === item?.reward_details?.cashback_type
                            ),
                        },
                        ...convertRewardDetails(item?.reward_details),
                        ...limitOptions,
                        rewardLimit: [
                          {
                            time_required: true,
                            amount_required: true,
                          },
                        ],
                      };
                    })
                  : [],
              spinTheWheelData:
                gameDetails?.name === "Spin The Wheel"
                  ? res?.data?.game?.rewards?.map((item) => {
                      return {
                        title_required: true,
                        probability_required: true,
                        limit: true,
                        limit_required: true,
                        rewardLevel_required: true,
                        title: item?.title,
                        probability: item?.win_probability,
                        reward_type: rewards?.find(
                          (e) => e?.id === item?.reward_type_id
                        ),
                        cashback_type: {
                          ...rewards
                            ?.find(
                              (reward) => reward.id === item?.reward_type_id
                            )
                            ?.reward_details?.find(
                              (element) =>
                                element?.backend_label === "cashback_type"
                            )
                            ?.options?.map((option) => {
                              return {
                                id: option?.value,
                                name: option?.label,
                                fields: option?.fields,
                              };
                            })
                            ?.find(
                              (elem) =>
                                elem?.id === item?.reward_details?.cashback_type
                            ),
                        },
                        ...convertRewardDetails(item?.reward_details),
                      };
                    })
                  : [],
              slotMachineData:
                gameDetails?.name === "Slot Machine"
                  ? res?.data?.game?.rewards?.map((item) => {
                      return {
                        title_required: true,
                        probability_required: true,
                        limit: true,
                        limit_required: true,
                        rewardLevel_required: true,
                        title: item?.title,
                        probability: item?.win_probability,
                        reward_type: rewards?.find(
                          (e) => e?.id === item?.reward_type_id
                        ),
                        cashback_type: {
                          ...rewards
                            ?.find(
                              (reward) => reward.id === item?.reward_type_id
                            )
                            ?.reward_details?.find(
                              (element) =>
                                element?.backend_label === "cashback_type"
                            )
                            ?.options?.map((option) => {
                              return {
                                id: option?.value,
                                name: option?.label,
                                fields: option?.fields,
                              };
                            })
                            ?.find(
                              (elem) =>
                                elem?.id === item?.reward_details?.cashback_type
                            ),
                        },
                        ...convertRewardDetails(item?.reward_details),
                      };
                    })
                  : [],
              triviaData:
                gameDetails?.name === "Trivia"
                  ? res?.data?.game?.rewards?.map((item) => {
                      return {
                        title_required: true,
                        probability_required: true,
                        limit: true,
                        limit_required: true,
                        rewardLevel_required: true,
                        title: item?.title,
                        probability: item?.win_probability,
                        reward_type: rewards?.find(
                          (e) => e?.id === item?.reward_type_id
                        ),
                        cashback_type: {
                          ...rewards
                            ?.find(
                              (reward) => reward.id === item?.reward_type_id
                            )
                            ?.reward_details?.find(
                              (element) =>
                                element?.backend_label === "cashback_type"
                            )
                            ?.options?.map((option) => {
                              return {
                                id: option?.value,
                                name: option?.label,
                                fields: option?.fields,
                              };
                            })
                            ?.find(
                              (elem) =>
                                elem?.id === item?.reward_details?.cashback_type
                            ),
                        },
                        ...convertRewardDetails(item?.reward_details),
                      };
                    })
                  : [],
              triviaData_step1: {
                triviaType: "",
                questions: [
                  {
                    title: "",
                    addMedia: false,
                    images: [],
                    options: [{ optionTitle: "", isSelected: true }],
                  },
                ],
              },
            },
            beforePlay: {
              scratchCardData:
                gameDetails?.name === "Scratch Card"
                  ? res?.data?.game?.design?.before_play || {}
                  : {},
              spinTheWheelData:
                gameDetails?.name === "Spin The Wheel"
                  ? res?.data?.game?.design?.before_play || {}
                  : {},
              slotMachineData:
                gameDetails?.name === "Slot Machine"
                  ? res?.data?.game?.design?.before_play || {}
                  : {},
              triviaData:
                gameDetails?.name === "Trivia"
                  ? res?.data?.game?.design?.before_play || {}
                  : {},
            },
            afterPlay: {
              scratchCardData:
                gameDetails?.name === "Scratch Card"
                  ? res?.data?.game?.design?.after_play || {}
                  : {},
              spinTheWheelData:
                gameDetails?.name === "Spin The Wheel"
                  ? res?.data?.game?.design?.after_play || {}
                  : {},
              slotMachineData:
                gameDetails?.name === "Slot Machine"
                  ? res?.data?.game?.design?.after_play || {}
                  : {},
              triviaData:
                gameDetails?.name === "Trivia"
                  ? res?.data?.game?.design?.after_play || {}
                  : {},
            },
            whenPlay: {
              triviaData:
                gameDetails?.name === "Trivia"
                  ? res?.data?.game?.design?.when_play || {}
                  : {},
            },
            addNewRewards: {
              scratchCardData: {
                rewardType: "",
                previouslyCreatedReward: "",
                templates: "",
                startFromScratch: {
                  rewardType: {},
                  rewardTitle: "",
                },
              },
              spinTheWheelData: {
                rewardType: "",
                previouslyCreatedReward: "",
                templates: "",
                startFromScratch: {
                  rewardType: {},
                  rewardTitle: "",
                },
              },
              slotMachineData: {
                rewardType: "",
                previouslyCreatedReward: "",
                templates: "",
                startFromScratch: {
                  rewardType: {},
                  rewardTitle: "",
                },
              },
              triviaData: {
                rewardType: "",
                previouslyCreatedReward: "",
                templates: "",
                startFromScratch: {
                  rewardType: {},
                  rewardTitle: "",
                },
              },
            },
          },
          setOfferRules: {
            linkToRule: res.data?.earnRuleSetId ?? {},
            linkToCohort: res.data?.collectionsIds ?? [],
          },
          offerLimit: {
            offerLevelLimits: {
              fairUsageOfRewardIssuance: {
                // hourly: offerLimit?.hourly,
                daily: offerLimit?.daily,
                weekly: offerLimit?.weekly,
                monthly: offerLimit?.monthly,
                total: offerLimit?.overall,
              },
              // fairUsageOfRewardRedemption: {
              //   hourly: "",
              //   daily: "",
              //   weekly: "",
              //   monthly: "",
              // },
            },
            userLevelLimits: {
              fairUsageOfRewardIssuance: {
                // hourly: userLimit?.hourly,
                daily: userLimit?.daily,
                weekly: userLimit?.weekly,
                monthly: userLimit?.monthly,
                totalAmount: userLimit?.overall,
              },
              fairUsageOfRewardRedemption: {
                daily: userLimit?.daily_issuance,
                weekly: userLimit?.weekly_issuance,
                monthly: userLimit?.monthly_issuance,
                totalAmount: userLimit?.overall_issuance,
              },
            },
          },
        }));


      })
      .catch((error) => {
        console.log(error?.response?.data);
      });
  };
  const getOfferDataByIDv4 = async (id) => { 
    try {
      const res = await axios.get(`${BaseUrl}/api/admin/offer-v2/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      const offerLimit = res.data?.values?.find((e) => e.entity_type === "OFFER")?.limit_type;
      const userLimit = res.data?.values?.find((e) => e.entity_type === "USER")?.limit_type;
      const gameDetails = gameData?.find((item) => item?.id === res?.data?.game?.game_type_id);
  
      const updatedGameData = await Promise.all(
        res?.data?.game?.rewards?.map(async (item) => {
          const rewardName = rewards?.find((e) => e?.id === item?.reward_type_id)?.name;
          const rewardType = rewards
            ?.find((e) => e?.id === item?.reward_type_id)
            ?.reward_details?.find((ele) => ele?.backend_label === "reward_type")
            ?.options.find((ele) => ele?.name === rewardName);
          const limitOptions = rewards.find((reward) => reward?.name === rewardType?.name)?.limitOptions;
      
          let  csvFile =item?.reward_details?.csvUrl ? await fetchCsvFileFromUrl(item?.reward_details?.csvUrl):"";
          // if (gameDetails?.name === "Scratch Card") {
          //   csvFile = await fetchCsvFileFromUrl("https://gratifystorage01.blob.core.windows.net/gratify-dev/330d31_SampleVouchersSheet1.csv");
          // }
      
          return {
            title_required: true,
            probability_required: true,
            // limit: gameDetails?.name !== "Scratch Card",
            limit:false,
            limit_required: true,
            rewardLevel_required: true,
            rewardType_required: true,
            title: item?.title,
            probability: item?.win_probability,
            reward_type: rewardType,
            rewardIcon:item?.reward_details?.reward_icon,
            cashback_type: {
              ...rewards
                ?.find((reward) => reward.id === item?.reward_type_id)
                ?.reward_details?.find((element) => element?.backend_label === "cashback_type")
                ?.options?.map((option) => ({
                  id: option?.value,
                  name: option?.label,
                  fields: option?.fields,
                }))
                ?.find((elem) => elem?.id === item?.reward_details?.cashback_type),
            },
            ...convertRewardDetails(item?.reward_details),
            ...limitOptions,
            voucherCollectionId: item?.reward_details?.voucherCollectionId ?? "",
            csvUrl: item?.reward_details?.csvUrl ?? "",
            csvFile,
            rewardLevel: limitOptions?.limitLevelOptions?.find((e) => e.id === item?.rewardLimits?.[0]?.limit_level),
            rewardType: limitOptions?.limitTypeOptions?.find((e) => e.id === item?.rewardLimits?.[0]?.entity_type),
            rewardLimit: limitOptions?.limitOptions
              ?.filter((limit) => item?.rewardLimits?.[0]?.hasOwnProperty(limit?.id))
              .map((limit) => ({
                time_required: true,
                amount_required: true,
                type: {
                  id: limit.id,
                  name: limit.name,
                },
                amount: item?.rewardLimits[0][limit.id],
              })),
          };
        })
      );
      
      // Now, use updatedGameData to set the appropriate game data based on the game type
      const scratchCardData = gameDetails?.name === "Scratch Card" ? updatedGameData : [];
      const spinTheWheelData = gameDetails?.name === "Spin The Wheel" ? updatedGameData : [];
      const slotMachineData = gameDetails?.name === "Slot Machine" ? updatedGameData : [];
      const triviaData = gameDetails?.name === "Trivia" ? updatedGameData : [];
  
      let obj ={
        // ...prev,
        basicOffers: {
          // ...prev.basicOffers,
          category: res.data?.categoryId ?? "",
          subCategory: res.data?.subcategoryId ?? "",
          product: res.data?.productId ?? "",
        },
        offerDetails: {
          merchant: res.data?.merchantId ?? "",
          title: res.data?.name ?? "",
          description: res.data?.description ?? "",
          startDate: res.data?.startsAt ?? "",
          endDate: res.data?.endsAt ?? "",
          programId: res.data?.programId ?? "",
          lockingPeriod: res.data?.lockingPeriod ?? "",
          expiryPeriod: res.data?.expiryPeriod ?? "",
          offerInformationForPWABefore: res.data?.informationBeforeRedemption ?? "",
          offerInformationForPWAAfter: res.data?.informationAfterRedemption ?? "",
          TAndC: res.data?.termsAndConditions ?? "",
          coverPhoto: res.data?.coverImageUrl ?? "",
          coverImageUrl: res.data?.coverImageUrl ?? "",
          coverPhotoId: res.data?.coverImageId ?? "",
          offerInitiative: res.data?.initiativeId ?? "",
          gameType: res.data?.game?.gameId ?? "",
          rewardCurrency: res.data?.currencyCode ?? "",
          rewardCurrencyId: res.data?.currencyId ?? "",
          csvUrl: res.data?.csvUrl ?? "",
        },
        createGame: {
          gameName: gameDetails?.name,
          game_type_id: res?.data?.game?.game_type_id,
          game: { 
            scratchCardData: scratchCardData,
            spinTheWheelData:spinTheWheelData,
            slotMachineData:slotMachineData,
            triviaData:triviaData,
            triviaData_step1: {
              triviaType: "",
              questions: [
                {
                  title: "",
                  addMedia: false,
                  images: [],
                  options: [{ optionTitle: "", isSelected: true }],
                },
              ],
            },
          },
          beforePlay: {
            scratchCardData: gameDetails?.name === "Scratch Card" ? res?.data?.game?.design?.before_play || {} : {},
            spinTheWheelData: gameDetails?.name === "Spin The Wheel" ? res?.data?.game?.design?.before_play || {} : {},
            slotMachineData: gameDetails?.name === "Slot Machine" ? res?.data?.game?.design?.before_play || {} : {},
            triviaData: gameDetails?.name === "Trivia" ? res?.data?.game?.design?.before_play || {} : {},
          },
          afterPlay: {
            scratchCardData: gameDetails?.name === "Scratch Card" ? res?.data?.game?.design?.after_play || {} : {},
            spinTheWheelData: gameDetails?.name === "Spin The Wheel" ? res?.data?.game?.design?.after_play || {} : {},
            slotMachineData: gameDetails?.name === "Slot Machine" ? res?.data?.game?.design?.after_play || {} : {},
            triviaData: gameDetails?.name === "Trivia" ? res?.data?.game?.design?.after_play || {} : {},
          },
          whenPlay: {
            triviaData: gameDetails?.name === "Trivia" ? res?.data?.game?.design?.when_play || {} : {},
          },
          addNewRewards: {
            scratchCardData: {
              rewardType: "",
              previouslyCreatedReward: "",
              templates: "",
              startFromScratch: {
                rewardType: {},
                rewardTitle: "",
              },
            },
            spinTheWheelData: {
              rewardType: "",
              previouslyCreatedReward: "",
              templates: "",
              startFromScratch: {
                rewardType: {},
                rewardTitle: "",
              },
            },
            slotMachineData: {
              rewardType: "",
              previouslyCreatedReward: "",
              templates: "",
              startFromScratch: {
                rewardType: {},
                rewardTitle: "",
              },
            },
            triviaData: {
              rewardType: "",
              previouslyCreatedReward: "",
              templates: "",
              startFromScratch: {
                rewardType: {},
                rewardTitle: "",
              },
            },
          },
        },
        setOfferRules: {
          linkToRule: res.data?.earnRuleSetId ?? {},
          linkToCohort: res.data?.collectionsIds ?? [],
        },
        // offerLimit: {
        //   offerLevelLimits: {
        //     fairUsageOfRewardIssuance: {
        //       daily: offerLimit?.daily,
        //       weekly: offerLimit?.weekly,
        //       monthly: offerLimit?.monthly,
        //       total: offerLimit?.overall,
        //     },
        //   },
        //   userLevelLimits: {
        //     fairUsageOfRewardIssuance: {
        //       daily: userLimit?.daily,
        //       weekly: userLimit?.weekly,
        //       monthly: userLimit?.monthly,
        //       totalAmount: userLimit?.overall,
        //     },
        //     fairUsageOfRewardRedemption: {
        //       daily: userLimit?.daily_issuance,
        //       weekly: userLimit?.weekly_issuance,
        //       monthly: userLimit?.monthly_issuance,
        //       totalAmount: userLimit?.overall_issuance,
        //     },
        //   },
        // },
      }
      // dispatch({ type: initialOfferEditValue, payload: obj });
      // Now, integrate the resolved data into the state update
      setPreFilledData((prev) => (obj));
    } catch (error) {
      console.error(error);
    }
  };
  // to add new Rule tab

  const handleAddTab = () => {
    if (tabData.length <= 5) {
      setTotalTab((prev) => prev + 1);
      setTabData((prev) => {
        return [...prev, { ...{ ...schema, whereSection: [{}] }, id: lastId }];
      });
    }
    // if we want to show any alert/toast for "Maximum number of tabs reached in that case we can add here in else condition"
  };

  // console.log(tabData, "tabData");
  const handleAddCohortsTab = () => {
    if (tabData.length <= 5) {
      setTotalTab((prev) => prev + 1);
      setTabData((prev) => {
        return [...prev, { ...cohortSchema, id: lastId }];
      });
    }
  };

  const handleAddProjectionsTab = () => {
    if (tabData.length <= 6) {
      setTotalTab((prev) => prev + 1);
      setTabData((prev) => {
        return [...prev, { ...projectionListSchema, id: lastId }];
      });
    }
  };

  const handleShowViewProjection = () => {
    if (tabData.length <= 6) {
      setTotalTab((prev) => prev + 1);
      setTabData((prev) => {
        return [...prev, { ...viewProjectionSchema, id: lastId }];
      });
    }
  };

  const handleAddCohortRuleTab = () => {
    if (tabData.length <= 5) {
      setTotalTab((prev) => prev + 1);
      setTabData((prev) => {
        return [
          ...prev,
          { ...{ ...collectionRuleSchema, whereSection: [{}] }, id: lastId },
        ];
      });
    }
    // if we want to show any alert/toast for "Maximum number of tabs reached in that case we can add here in else condition"
  };

  // to delete tab

  const handleDeleteTab = (e, index) => {
    e.stopPropagation();
    let filteredTab = tabArray.filter((e, i) => index !== i);
    setTabArray(filteredTab);
    setTotalTab(filteredTab.length);
    let filtered = tabData.filter((e, i) => i !== index);
    setTabData(filtered);
  };

  const handleCancel = (e, index) => {
    let filteredTab = tabArray.filter((e, i) => index !== i);
    setTabArray(filteredTab);
    setTotalTab(filteredTab.length);
    let filtered = tabData.filter((e, i) => i !== index);
    setTabData(filtered);
  };

  const handleCreateProjection = () => {
    dispatch({type:projectionLoaderStatus,payload:true});
    const postData = {
      categoryId: offerData?.basicOffers?.category,
      subcategoryId: offerData?.basicOffers?.subCategory,
      productId: offerData?.basicOffers?.product,
      merchantId: offerData?.offerDetails?.merchant,
      name: offerData?.offerDetails?.title,
      description: offerData?.offerDetails?.description,
      startsAt: offerData?.offerDetails?.startDate,
      endsAt: offerData?.offerDetails?.endDate,
      programId: offerData?.offerDetails?.programId,
      lockingPeriod: offerData?.offerDetails?.lockingPeriod,
      expiryPeriod: offerData?.offerDetails?.expiryPeriod,
      informationBeforeRedemption:
        offerData?.offerDetails?.offerInformationForPWABefore,
      informationAfterRedemption:
        offerData?.offerDetails?.offerInformationForPWAAfter,
      termsAndConditions: offerData?.offerDetails?.TAndC,
      initiativeId: offerData?.offerDetails?.offerInitiative,
      currencyCode: offerData?.offerDetails?.rewardCurrency,
      currencyId: offerData?.offerDetails?.rewardCurrencyId,
      coverImageId: offerData?.offerDetails?.coverPhotoId,
      earnRuleSetId: offerData?.setOfferRules?.linkToRule,
      collectionsIds: offerData?.setOfferRules?.linkToCohort,
    };

    console.log(postData, "offerData");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .post(`${BaseUrl}/api/analytics/projection`, postData, config)
      .then((res) => {
        console.log(res.data);
        handleShowViewProjection();

        setTabData((prevTabData) => {
          return prevTabData.map((tab, index) => {
            if (index === tabData.length) {
              // console.log(tabData.length,index,"value")
              return {
                ...tab,
                projectionData: res.data,
              };
            }
            return tab;
          });
        });
      })
      .catch((err) => {
        console.log(err);
      }).finally(()=>{
        dispatch({type:projectionLoaderStatus,payload:false});
      });
  };

  // to change tab
  const handleChangeTab = (i) => {
    setActiveTab(i);
  };
  // checking that if is present or if Id is present that means copy mode ==>COPY_MODE
  useEffect(() => {
    if(pathname.startsWith("/approve-offers")){
      getOfferDetailsById(id);
    }else if (state?.id) {
      getOfferDataByIDv4(state?.id);
    }
  }, []);
  // to handle add delete of tabs
  useEffect(() => {
    const newTabs = new Array(totalTab).fill(0);
    setTabArray(newTabs);
    setActiveTab(newTabs.length - 1);
    setLastId((prev) => prev + 1);
  }, [totalTab]);

  // offer form data
  const [offerData, setOfferData] = useState(initialData);
  // setting the value for prefilled data in copy mode ==>COPY_MODE
  useEffect(() => {
    setOfferData(preFilledData);
  }, [preFilledData]);
  // error for offer details
  const [offerDetailsError, setOfferDetailsError] = useState(
    initialOfferDetailsError()
  );

  // error  for basic offers
  const [basicOfferError, setBasicOfferError] = useState(
    initialBasicOffersError()
  );

  // error for create game
  const [createGameError, setCreateGameError] = useState(
    initialCreateGameError()
  );

  // error for set offer rules
  const [setOfferRulesError, setSetOfferRulesError] = useState(
    initialSetOfferRulesError()
  );

  // error for set offer rules
  const [offerLimitError, setOfferLimitError] = useState(
    initialOfferLimitErrorError()
  );

  // will set error message if field is required
  const handleErrorMessage = (key, value, handleState) => {
    handleState((pre) => {
      return {
        ...pre,
        [key]: value,
      };
    });
  };

  // to set form data
  const handleData = (key, value) => {
    setOfferData((pre) => {
      return {
        ...pre,
        [key]: value,
      };
    });
  };

  // will remove empty html tag of rich text editor
  const sanitizeOptions = {
    exclusiveFilter: (frame) => {
      const blockLevelTags = [
        "p",
        "div",
        "h1",
        "h2",
        "h3",
        "h4",
        "h5",
        "h6",
        "blockquote",
      ];
      // Remove empty tags
      // Remove empty block-level tags
      return (
        blockLevelTags.includes(frame.tag) &&
        (!frame.text.trim() || (frame.tag === "br" && !frame.isClosing))
      );
    },
  };

  const handleNext = () => {
    if (activeStep === 0) {
      // will set error to empty
      setBasicOfferError(initialBasicOffersError());
      if (
        isValidBasicOffersData(
          offerData?.basicOffers,
          handleErrorMessage,
          setBasicOfferError
        )
      ) {
        setActiveStep(activeStep + 1);
        setScrollFlag((prev) => !prev);
        dispatch({ type: scrollFlagTrigger, payload: scrollFlag });
      }
    } else if (activeStep === 1) {
      // will set error to empty
      setOfferDetailsError(initialOfferDetailsError());
      if (
        isValidOfferDetailsData(
          offerData?.offerDetails,
          handleErrorMessage,
          setOfferDetailsError
        )
      ) {
        // will remove empty html tags
        const offerInformationForPWABefore = sanitizeHtml(
          offerData?.offerDetails.offerInformationForPWABefore,
          sanitizeOptions
        );
        // will remove empty html tags
        const offerInformationForPWAAfter = sanitizeHtml(
          offerData?.offerDetails.offerInformationForPWAAfter,
          sanitizeOptions
        );
        setOfferData((pre) => {
          return {
            ...pre,
            offerDetails: {
              ...pre.offerDetails,
              offerInformationForPWABefore,
              offerInformationForPWAAfter,
            },
          };
        });
        setActiveStep(activeStep + 1);
        setScrollFlag((prev) => !prev);
        dispatch({ type: scrollFlagTrigger, payload: scrollFlag });
      }
    } else if (activeStep === 2) {
      // will set error to empty
      setCreateGameError(initialCreateGameError());
      if (
        isValidCreateGameData(
          offerData?.createGame,
          handleErrorMessage,
          setCreateGameError
        )
      ) {
        setActiveStep(activeStep + 1);
        setScrollFlag((prev) => !prev);
        dispatch({ type: scrollFlagTrigger, payload: scrollFlag });
      }
    } else if (activeStep === 3) {
      // will set error to empty
      setSetOfferRulesError(initialSetOfferRulesError());
      if (
        isValidSetOfferRulesData(
          offerData?.setOfferRules,
          handleErrorMessage,
          setSetOfferRulesError
        )
      ) {
        // setActiveStep(activeStep + 1);
        setScrollFlag((prev) => !prev);
        if (!isPreviewShow) {
          setIsPreviewShow(true);
        }
        dispatch({ type: scrollFlagTrigger, payload: scrollFlag });
      }
    } else {
      // setOfferLimitError(initialOfferLimitErrorError());
      // if (isValidOfferLimitData(offerData?.offerLimit, setOfferLimitError)) {
      //   if (!isPreviewShow) {
      //     setIsPreviewShow(true);
      //   }
      // }
      // setScrollFlag((prev) => !prev);
      // dispatch({ type: scrollFlagTrigger, payload: scrollFlag });
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setScrollFlag((prev) => !prev);
    dispatch({ type: scrollFlagTrigger, payload: scrollFlag });
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
    setScrollFlag((prev) => !prev);
    dispatch({ type: scrollFlagTrigger, payload: scrollFlag });
  };

  const handleSubmit = () => {};

  useEffect(() => {
    setOfferData(initialData);
  }, [initialData]);

  useEffect(() => {
    if (activeStep > furthestStepVisited) {
      setFurthestStepVisited(activeStep);
    }
  }, [activeStep]);

  return (
    <Box>
      <Box
        sx={{
          mx: "30px",
          my: "45px",
        }}
      >
        {/* <TableTotalCount text="create offer" /> */}

        <Box display="flex" gap="5px">
          {tabData.length > 0 &&
            tabData.map((e, i) => (
              <TableTotalCount
                key={i}
                handleChangeTab={() => handleChangeTab(i)}
                text={
                  i === 0 ? (pathname.startsWith("/approve-offers")?"Approve Offer" :id ? "edit offer" : "create offer") : e.tabName
                }
                index={i}
                count={tabData.length}
                deleteTab={(e) => handleDeleteTab(e, i)}
                activeTab={activeTab}
                width="165px"
              />
            ))}
        </Box>

        <Box
          sx={{
            backgroundColor: "white",
          }}
        >
          {isPreviewShow ? (
            <OfferPreview
              cancelPreviewShow={() => {
                setIsPreviewShow(false);
                setScrollFlag((prev) => !prev);
                dispatch({ type: scrollFlagTrigger, payload: scrollFlag });
              }}
              data={offerData}
              handleSubmit={handleSubmit}
              onChange={(key, data) => handleData(key, data)}
              basicOfferError={basicOfferError}
              handleBasicOffersError={setBasicOfferError}
              offerDetailsErrorMessage={offerDetailsError}
              handleOfferDetailsError={setOfferDetailsError}
              coverPhoto={coverPhoto}
              handleCoverPhoto={setCoverPhoto}
              createGameErrorMessage={createGameError}
              handleCreateGameError={setCreateGameError}
              merchantLogoImg={merchantLogoImg}
              handleMerchantLogoImg={setMerchantLogoImg}
              scratchCardImage={scratchCardImage}
              handleScratchCardImage={setScratchCardImage}
              setOfferRulesErrorMessage={setOfferRulesError}
              isPreviewShow={isPreviewShow}
              handleSetOfferRulesError={setSetOfferRulesError}
              handleOfferLimitError={() =>
                setOfferLimitError(initialOfferLimitErrorError())
              }
              offerLimitErrorMessage={offerLimitError}
            />
          ) : (
            <>
              <Stepper
                sx={{ pt: "28px", display: activeTab > 0 ? "none" : "visible" }}
                activeStep={activeStep}
                alternativeLabel
                connector={<CustomStepConnector />}
              >
                {steps.map((label, index) => {
                  // if want same stepper completed in copy offer as well then simply add {"state?.id" }along with id in the condition (as below).

                  // const isCompleted =
                  // state?.id || id ? index <= 4 : index < furthestStepVisited;
                  const isCompleted = id
                    ? index <= 3
                    : index <= furthestStepVisited;
                  return (
                    <Step
                      key={label}
                      completed={id ? index <= 3 : index < furthestStepVisited} // Add the completed prop
                      sx={{
                        "& .MuiSvgIcon-root": {
                          height: "28px",
                          width: "28px",
                          m: "1px",
                          border: "2px solid transparent",
                          borderRadius: "50%",
                          p: "2px",
                          cursor: isCompleted ? "pointer" : "default",
                        },
                        "& .Mui-active .MuiSvgIcon-root": {
                          height: "28px",
                          width: "28px",
                          m: "1px",
                          border: "2px solid black",
                          borderRadius: "50%",
                          p: "2px",
                          cursor: isCompleted ? "pointer" : "default",
                        },
                        "& .MuiStepLabel-label": {
                          fontFamily: "Mulish",
                          fontWeight: "600",
                          fontSize: "15px",
                          lineHeight: "17.57px",
                          color: "#000000",
                        },
                        "& .MuiStepLabel-root .Mui-completed": {
                          color: "black", // circle color (COMPLETED)
                        },
                        "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                          {
                            color: "black", // Just text label (COMPLETED)
                          },
                        "& .MuiStepLabel-root .Mui-active": {
                          color: "black", // circle color (ACTIVE)
                        },
                        "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                          {
                            color: "black", // Just text label (ACTIVE)
                          },
                        "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                          fill: "white", // circle's number (ACTIVE)
                        },
                      }}
                    >
                      <StepButton
                        onClick={handleStep(index)}
                        disabled={!isCompleted}
                      >
                        {label}
                      </StepButton>
                    </Step>
                  );
                })}
              </Stepper>
              <div>
                <React.Fragment>
                  <Box sx={{ pt: "32px", ml: "15px" }}>
                    {activeStep === 0 && activeTab === 0 ? (
                      <BasicOffers
                        data={offerData?.basicOffers}
                        onChange={(data) => handleData("basicOffers", data)}
                        errorMessage={basicOfferError}
                        handleBasicOffersError={setBasicOfferError}
                      />
                    ) : activeStep === 0 &&
                      tabData[activeTab]?.tabType == "rule" ? (
                      <CreateRule
                        activeTab={activeTab}
                        tabData={tabData}
                        setTabData={setTabData}
                        handleCancel={handleCancel}
                      />
                    ) : activeStep === 0 &&
                      tabData[activeTab]?.tabType == "cohort" ? (
                      <CreateCollection
                        activeTab={activeTab}
                        tabData={tabData}
                        setTabData={setTabData}
                        handleCancel={handleCancel}
                        handleCreateRule={handleAddCohortRuleTab}
                      />
                    ) : activeStep === 0 &&
                      tabData[activeTab]?.tabType == "projectionList" ? (
                      <ProjectionList
                        // handleShowViewProjection={handleShowViewProjection}
                        tabData={tabData}
                        activeTab={activeTab}
                        setTabData={setTabData}
                        handleCreateProjection={handleCreateProjection}
                      />
                    ) : activeStep === 0 &&
                      tabData[activeTab]?.tabType == "viewProjection" ? (
                      <ViewProjection tabData={tabData} activeTab={activeTab} />
                    ) : (
                      activeStep === 0 && (
                        <CollectionCreateRule
                          tabData={tabData}
                          activeTab={activeTab}
                          setTabData={setTabData}
                          handleCancel={handleCancel}
                        />
                      )
                    )}
                    {activeStep === 1 && activeTab === 0 ? (
                      <OfferDetails
                        data={offerData?.offerDetails}
                        onChange={(data) => handleData("offerDetails", data)}
                        errorMessage={offerDetailsError}
                        handleOfferDetailsError={setOfferDetailsError}
                        coverPhoto={coverPhoto}
                        handleCoverPhoto={setCoverPhoto}
                        activeStep={activeStep}
                      />
                    ) : activeStep === 1 &&
                      tabData[activeTab]?.tabType == "rule" ? (
                      <CreateRule
                        activeTab={activeTab}
                        tabData={tabData}
                        setTabData={setTabData}
                        handleCancel={handleCancel}
                      />
                    ) : activeStep === 1 &&
                      tabData[activeTab]?.tabType == "cohort" ? (
                      <CreateCollection
                        activeTab={activeTab}
                        tabData={tabData}
                        setTabData={setTabData}
                        handleCancel={handleCancel}
                        handleCreateRule={handleAddCohortRuleTab}
                      />
                    ) : activeStep === 1 &&
                      tabData[activeTab]?.tabType == "projectionList" ? (
                      <ProjectionList
                        // handleShowViewProjection={handleShowViewProjection}
                        tabData={tabData}
                        activeTab={activeTab}
                        setTabData={setTabData}
                        handleCreateProjection={handleCreateProjection}
                      />
                    ) : activeStep === 1 &&
                      tabData[activeTab]?.tabType == "viewProjection" ? (
                      <ViewProjection tabData={tabData} activeTab={activeTab} />
                    ) : activeStep === 1 &&
                      tabData[activeTab]?.tabType == "projectionList" ? (
                      <ProjectionList
                        // handleShowViewProjection={handleShowViewProjection}
                        tabData={tabData}
                        activeTab={activeTab}
                        setTabData={setTabData}
                        handleCreateProjection={handleCreateProjection}
                      />
                    ) : activeStep === 1 &&
                      tabData[activeTab]?.tabType == "viewProjection" ? (
                      <ViewProjection tabData={tabData} activeTab={activeTab} />
                    ) : (
                      activeStep === 1 && (
                        <CollectionCreateRule
                          tabData={tabData}
                          activeTab={activeTab}
                          setTabData={setTabData}
                          handleCancel={handleCancel}
                        />
                      )
                    )}
                    {activeStep === 2 && activeTab === 0 ? (
                      <CreateGame
                        data={offerData?.createGame}
                        onChange={(data) => handleData("createGame", data)}
                        errorMessage={createGameError}
                        handleCreateGameError={setCreateGameError}
                        merchantLogoImg={merchantLogoImg}
                        handleMerchantLogoImg={setMerchantLogoImg}
                        scratchCardImage={scratchCardImage}
                        handleScratchCardImage={setScratchCardImage}
                        activeStep={activeStep}
                        handleNext={handleNext}
                        handleBack={handleBack}
                      />
                    ) : activeStep === 2 &&
                      tabData[activeTab]?.tabType == "rule" ? (
                      <CreateRule
                        activeTab={activeTab}
                        tabData={tabData}
                        setTabData={setTabData}
                        handleCancel={handleCancel}
                      />
                    ) : activeStep === 2 &&
                      tabData[activeTab]?.tabType == "cohort" ? (
                      <CreateCollection
                        activeTab={activeTab}
                        tabData={tabData}
                        setTabData={setTabData}
                        handleCancel={handleCancel}
                        handleCreateRule={handleAddCohortRuleTab}
                      />
                    ) : activeStep === 2 &&
                      tabData[activeTab]?.tabType == "projectionList" ? (
                      <ProjectionList
                        // handleShowViewProjection={handleShowViewProjection}
                        tabData={tabData}
                        activeTab={activeTab}
                        setTabData={setTabData}
                        handleCreateProjection={handleCreateProjection}
                      />
                    ) : activeStep === 2 &&
                      tabData[activeTab]?.tabType == "viewProjection" ? (
                      <ViewProjection tabData={tabData} activeTab={activeTab} />
                    ) : (
                      activeStep === 2 && (
                        <CollectionCreateRule
                          tabData={tabData}
                          activeTab={activeTab}
                          setTabData={setTabData}
                          handleCancel={handleCancel}
                        />
                      )
                    )}
                    {activeStep === 3 && activeTab === 0 ? (
                      <SetOfferRules
                        data={offerData?.setOfferRules}
                        onChange={(data) => handleData("setOfferRules", data)}
                        errorMessage={setOfferRulesError}
                        handleSetOfferRulesError={setSetOfferRulesError}
                        handleTabs={handleAddTab}
                        handleAddCohortsTab={handleAddCohortsTab}
                        handleAddProjectionsTab={handleAddProjectionsTab}
                        isPreviewShow={isPreviewShow}
                      />
                    ) : // for opening new tab
                    activeStep === 3 &&
                      tabData[activeTab]?.tabType == "rule" ? (
                      <CreateRule
                        activeTab={activeTab}
                        tabData={tabData}
                        setTabData={setTabData}
                        handleCancel={handleCancel}
                      />
                    ) : activeStep === 3 &&
                      tabData[activeTab]?.tabType == "cohort" ? (
                      <CreateCollection
                        activeTab={activeTab}
                        tabData={tabData}
                        setTabData={setTabData}
                        handleCancel={handleCancel}
                        handleCreateRule={handleAddCohortRuleTab}
                      />
                    ) : activeStep === 3 &&
                      tabData[activeTab]?.tabType == "projectionList" ? (
                      <ProjectionList
                        // handleShowViewProjection={handleShowViewProjection}
                        tabData={tabData}
                        activeTab={activeTab}
                        setTabData={setTabData}
                        handleCreateProjection={handleCreateProjection}
                      />
                    ) : activeStep === 3 &&
                      tabData[activeTab]?.tabType == "viewProjection" ? (
                      <ViewProjection tabData={tabData} activeTab={activeTab} />
                    ) : (
                      activeStep === 3 && (
                        <CollectionCreateRule
                          tabData={tabData}
                          activeTab={activeTab}
                          setTabData={setTabData}
                          handleCancel={handleCancel}
                        />
                      )
                    )}
                    {activeStep === 4 && activeTab === 0 ? (
                      <OfferLimit
                        data={offerData?.offerLimit}
                        onChange={(data) => {
                          setOfferLimitError(initialOfferLimitErrorError());
                          handleData("offerLimit", data);
                        }}
                        errorMessage={offerLimitError}
                      />
                    ) : activeStep === 4 &&
                      tabData[activeTab]?.tabType == "rule" ? (
                      <CreateRule
                        activeTab={activeTab}
                        tabData={tabData}
                        setTabData={setTabData}
                        handleCancel={handleCancel}
                      />
                    ) : activeStep === 4 &&
                      tabData[activeTab]?.tabType == "cohort" ? (
                      <CreateCollection
                        activeTab={activeTab}
                        tabData={tabData}
                        setTabData={setTabData}
                        handleCancel={handleCancel}
                        handleCreateRule={handleAddCohortRuleTab}
                      />
                    ) : activeStep === 4 &&
                      tabData[activeTab]?.tabType == "projectionList" ? (
                      <ProjectionList
                        // handleShowViewProjection={handleShowViewProjection}
                        tabData={tabData}
                        activeTab={activeTab}
                        setTabData={setTabData}
                        handleCreateProjection={handleCreateProjection}
                      />
                    ) : activeStep === 4 &&
                      tabData[activeTab]?.tabType == "viewProjection" ? (
                      <ViewProjection tabData={tabData} activeTab={activeTab} />
                    ) : (
                      activeStep === 4 && (
                        <CollectionCreateRule
                          tabData={tabData}
                          activeTab={activeTab}
                          setTabData={setTabData}
                          handleCancel={handleCancel}
                        />
                      )
                    )}
                  </Box>

                  {activeTab === 0 ? (
                    <Box
                      sx={{
                        display: activeStep === 2 ? "none" : "flex",
                        flexDirection: "row",
                        pt: 2,
                        px: "15px",
                        paddingBottom: "37px",
                      }}
                    >
                      <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                        style={{
                          backgroundColor: "white",
                          color: activeStep === 0 ? "gray" : "black",
                          padding: "8px 30px",
                          marginRight: "20px",
                          display: activeStep === 0 ? "none" : "",
                          fontFamily: "Mulish",
                          fontWeight: "700",
                          fontSize: "16px",
                          lineHeight: "20.08px",
                          borderRadius: "7px",
                          height: "40px",
                        }}
                        variant="outlined"
                        size="small"
                      >
                        Previous
                      </Button>
                      <Button
                        style={{
                          backgroundColor: "black",
                          color: "white",
                          padding:
                            activeStep === steps.length - 1
                              ? "8px 20px"
                              : "8px 40px",
                          fontFamily: "Mulish",
                          fontWeight: "700",
                          fontSize: "16px",
                          lineHeight: "20.08px",
                          borderRadius: "7px",
                          height: "40px",
                        }}
                        variant="contained"
                        size="small"
                        onClick={handleNext}
                        sx={{ mr: 1 }}
                      >
                        {activeStep === steps.length - 1
                          ? "Preview & Save"
                          : "Next"}
                      </Button>
                    </Box>
                  ) : (
                    // Create rule tab Buttons
                    ""
                  )}
                </React.Fragment>
              </div>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
}
