import React, { useState, useEffect, useRef } from "react";
import "./MultiSelect.css";

const MultiSelect = ({
  options,
  defaultValues,
  state = [],
  onChange,
  name,
  handleChange,
}) => {
  const [filteredOptions, setFilteredOptions] = useState(options || []);
  const [selectedOptions, setSelectedOptions] = useState(
    defaultValues ? defaultValues : []
  );

  const [dropdownActive, setDropdownActive] = useState(true);
  const [cursor, setCursor] = useState(0);

  const multiSelectRef = useRef(null);
  const textInputRef = useRef(null);

  const handleDropdownClick = () => {
    setCursor(0);
    setDropdownActive(!dropdownActive);
    resetDropdown();
  };

  const resetDropdown = () => {
    if (dropdownActive) {
      textInputRef.current.value = "";
      textInputRef.current.focus();
      setFilteredOptions([...options]);
    }
  };

  const handleSearchChange = (e) => {
    const filter = new RegExp(e.target.value, "i");
    const filteredOptions = options.filter((opt) => filter.test(opt.label));
    setCursor(0);
    setFilteredOptions(filteredOptions);
  };

  const handleSearchKeyDown = (e) => {
    const key = e.which || e.keyCode || 0;
    if (key === 38 && cursor > 0) {
      setCursor(cursor - 1);
    }
    if (key === 40 && cursor < filteredOptions.length) {
      setCursor(cursor + 1);
    }
    if (key === 13) {
      const highlightedItem = filteredOptions[cursor];
      if (!highlightedItem) return;
      const newSelectedOptions = selectedOptions.find(
        (opt) => opt.label === highlightedItem.label
      )
        ? selectedOptions.filter((opt) => opt.label !== highlightedItem.label)
        : [...selectedOptions, highlightedItem];
      // setSelectedOptions(newSelectedOptions);
      // onChange(newSelectedOptions);
    }
  };

  const handleOptionChange = (e) => {
    const value = e.target.value;
    const isChecked = e.target.checked;
    handleChange(name, value, isChecked);
    let newSelectedOptions;
    if (isChecked) {
      const selectedItem = options.find((opt) => opt.value == value);
      newSelectedOptions = [...selectedOptions, selectedItem?.value]?.map(
        (res) => res
      );
    } else {
      newSelectedOptions = selectedOptions.filter((opt) => opt != value);
    }
    setSelectedOptions(newSelectedOptions);
    onChange(newSelectedOptions);
  };

  const handleOptionRemoveClick = (value, e) => {
    e.stopPropagation();
    const newSelectedOptions = selectedOptions.filter(
      (item) => item.value !== value
    );
    setSelectedOptions(newSelectedOptions);
    onChange(newSelectedOptions);
  };

  const handleMousedown = (e) => {
    e.stopPropagation();
    if (multiSelectRef.current && !multiSelectRef.current.contains(e.target)) {
      if (dropdownActive) setDropdownActive(false);
    }
  };

  const handleEscKeyUp = (e) => {
    const key = e.which || e.keyCode || 0;
    if (key === 27) setDropdownActive(false);
  };

  // useEffect(() => {
  //   onChange(selectedOptions);
  //   document.addEventListener("mousedown", handleMousedown);
  //   return () => {
  //     document.removeEventListener("mousedown", handleMousedown);
  //   };
  // }, [selectedOptions]);

  useEffect(() => {
    // console.log(state,"filterState");
    // if (state?.length === 0 && selectedOptions.length > 0) {
    // }
    setSelectedOptions(state);
  }, [state]);

  return (
    <div
      className={`multiselect-wrapper ${dropdownActive ? "is-active" : ""}`}
      ref={multiSelectRef}
    >
      <div
        className={`multiselect__result-area ${
          dropdownActive ? "is-active" : ""
        }`}
      >
        <div className="multiselect__search-area">
          <input
            className="multiselect__search-input"
            type="text"
            ref={textInputRef}
            placeholder="Search..."
            onChange={handleSearchChange}
            // onKeyDown={handleSearchKeyDown}
            onKeyUp={handleEscKeyUp}
          />
          <span className="fa fa-search multiselect__search-icon" />
        </div>
        <ul
          className="multiselect-results"
          style={{
            height: filteredOptions?.length < 6 ? "fit-content" : 6 * 45,
            overflow: "scroll",
          }}
        >
          {filteredOptions.map((option, index) => (
            <li
              key={option.value}
              className={`multiselect-results__item ${
                index === cursor ? "is-highlighted" : ""
              }`}
              style={{
                backgroundColor: selectedOptions?.includes(option?.value)
                  ? "#93c1f1"
                  : "",
                // color: selectedOptions?.includes(option?.value) ? "#fff" : "",
              }}
            >
              <input
                type="checkbox"
                onChange={handleOptionChange}
                className="custom-checkbox"
                id={`opt-${option.value}`}
                value={option.value}
                checked={selectedOptions?.includes(option?.value)}
                disabled=""
                tabIndex="8"
              />
              <label
                htmlFor={`opt-${option.value}`}
                className="custom-checkbox-label"
              >
                {option.label}
              </label>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default MultiSelect;
