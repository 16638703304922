import React, { useEffect, useRef, useState } from "react";
import { getToken } from "../../../utils/token";
import axios from "axios";
import { Box, Button, Typography } from "@mui/material";
import { BootstrapTooltip } from "../../../utils/tooltip";
import { MdPreview } from "react-icons/md";
import usePermission from "../../../utils/permissions";
import CustomSwitch from "../../../UIComponents/CustomSwitch";
import { FaPauseCircle, FaPlayCircle, FaPlus } from "react-icons/fa";
import copyIcon from "../../../assets/copy.png";
import UiTable from "../../UiTable";
import preview from "../../../assets/eye.svg";
import copy from "../../../assets/copy.svg";
import play from "../../../assets/play.svg";
import pause from "../../../assets/pause.svg";
import { toast, ToastContainer } from "react-toastify";
import CustomConfirmationDialog from "../../../UIComponents/CustomConfirmationDialog";
import SearchBar from "../../../UIComponents/SearchBar";
import exportImg from "../../../assets/export.svg";
import plus from "../../../assets/Plus.svg";
import { Navigate, useNavigate } from "react-router-dom";

const TData = () => {
    return [
      { key: "id", displayName: "Offer ID", flag: true },
      { key: "name", displayName: "Offer Name", flag: true },
      { key: "game_type", displayName: "Game Type", flag: true },
      { key: "reward_currency", displayName: "Reward Currency", flag: true },
      { key: "offer_status", displayName: "Offer Status", flag: true },
      { key: "duration", displayName: "Duration", flag: true },
      { key: "visibility", displayName: "Visibility", flag: true },
      // { key: "category_id", displayName: "Category", flag: false },
      // { key: "subcategory_id", displayName: "Subcategory", flag: false },
      // { key: "merchant_id", displayName: "Merchant", flag: false },
    ];
  };

export default function ViewOfferPage() {
  const [offers, setOffers] = useState({
    data: [],
    paginationModel: {
      pageSize: 10,
      page: 0,
    },
    totalCount: 0,
    allOfferCount: 0,
    pendingOfferCount: 0,
    denyOfferCount: 0,
  });
  const [loading, setLoading] = useState(false);
  const [offerStatus, setOfferStatus] = useState(false);
  const [flag, setFlag] = useState(true);
  const [editData, setEditData] = useState(false);
  const [message, setMessage] = useState("");
  const [search, setSearch] = useState("");
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const token = getToken();
  const permission = usePermission("/view-offers");
  const IdRef = useRef("");
  const navigate = useNavigate();

  console.log(permission);

  const getAllOffers = async () => {
    setLoading(true);
    const body = {
      page: offers?.paginationModel.page + 1,
      itemsPerPage: offers?.paginationModel.pageSize,
      ...(search && { search }),
    };
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.post(`${baseUrl}/api/offers`, body, {
        headers,
      });
      console.log(response.data);
      let formateData = response?.data?.data?.map((e) => {
        return {
          id: e.id,
          name: e.offer_name,
          game_type: e.game_type,
          reward_currency: e.reward_currency,
          offer_status: e.status,
          duration: e.duration + " Days",
          visibility: e?.visibility,
          offer_visibility: e?.visibility,
          offer_status: e.status,
          action: {
            copyBtn: copyIcon,
            priority: e.offer_name.length < 10 ? "high" : "low",
            editBtn:
              e.status == "ACTIVE" ? (
                <img
                  style={{ width: "17px", height: "17px" }}
                  src={pause}
                  alt=""
                />
              ) : (
                <img
                  style={{ width: "17px", height: "17px" }}
                  src={play}
                  alt=""
                />
              ),
          },
        };
      });
      setOffers((prev) => {
        return {
          ...prev,
          data: formateData ?? [],
          totalCount: response?.data?.offerCount ?? 0,
          allOfferCount: response?.data?.allOfferCount ?? 0,
          pendingOfferCount: response?.data?.pendingOfferCount ?? 0,
          denyOfferCount: response?.data?.denyOfferCount ?? 0,
        };
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // Debounce utility function
  const debounce = (func, delay) => {
    let timeout;
    return function (...args) {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(context, args), delay);
    };
  };

  // Debounced handleVisibility function
  const handleVisibility = debounce((e, params) => {
    e.stopPropagation();

    const id = params.id;
    if (!id) return;

    const filterData = offers.data.find((e) => e.id === id);
    const url = `${baseUrl}/api/admin/offer/${id}`;
    console.log(params?.row?.offer_visibility, "params");
    axios
      .patch(
        url,
        {
          visibility: params?.row?.offer_visibility === 0 ? 1 : 0,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setOfferStatus((prev) => !prev);
        toast.success("offer visibility changed successfully");
      })
      .catch((err) => {
        console.log(
          "error while status updating",
          err?.response?.data?.message
        );
        toast.error(err?.response?.data?.message);
      });
  }, 500);

  function handleStatusChangeByID(id) {
    if (!id) return;

    const filterData = offers.data.find((e) => e.id === id);
    console.log(filterData, "filterData");
    // toast.success("Status Changed Successfully");
    const url = `${baseUrl}/api/admin/offer/${id}`;
    axios
      .patch(
        url,
        {
          status: filterData?.offer_status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setOfferStatus((prev) => !prev);
        toast.success("offer status changed successfully");
      })
      .catch((err) => {
        console.log(
          "error while status updating",
          err?.response?.data?.message
        );
        toast.error(err?.response?.data?.message);
      });
  }

  function handleEdit(e, params) {
    e.stopPropagation();
    IdRef.current = params?.id;
    const filterData = offers?.data?.find((e) => e?.id === params?.id);
    // console.log(filterData.offer_status)
    if (permission?.UPDATE) {
      if (filterData?.offer_status === "ACTIVE") {
        setMessage("Do you really want to Pause this offer?");
      } else {
        setMessage("Do you really want to start this offer?");
      }
      setEditData(true);
    }
    // console.log(`delete ${params.id}`);
  }
  const exportToCSV = () => {
    if (offers.data.length === 0) {
      toast.error("No data to export");
      return;
    }

    // Generate headers dynamically
    const headers = Object.keys(offers.data[0]).filter(key => key !== 'action');

    // Convert header keys to display-friendly format
    const displayHeaders = headers.map(header => 
      header.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
    );

    const csvData = offers.data.map(offer => 
      headers.map(header => {
        if (header === 'visibility') {
          return offer[header] ? "Visible" : "Hidden";
        }
        return offer[header];
      })
    );

    const csvContent = [
      displayHeaders.join(","),
      ...csvData.map(row => row.join(","))
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "offer_data.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  const handlePaginationModel = (value) => {
    if (!value) return;
    setOffers((prev) => ({ ...prev, paginationModel: value }));
  };
  const handlePreview = () => {};
  const handleCopy = () => {};

  const columns = [
    {
      field: "id",
      headerName: "Offer ID",
      minWidth: 100,
      // flex: 0.5,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Typography
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
              textAlign="center"
            >
              NA
            </Typography>
          );
        }
        return (
          <Box
            paddingRight="auto"
            // marginLeft="10px"
            fontFamily="Lato"
            fontWeight={400}
            fontSize="12px"
            lineHeight="14.4px"
            letterSpacing="2%"
          >
            {params?.value}
          </Box>
        );
      },
    },
    {
      field: "name",
      headerName: "Offer Name",
      // flex: 1.4,
      minWidth: 250,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Typography
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
            >
              NA
            </Typography>
          );
        }

        return (
          <BootstrapTooltip title={params.value}>
            <Typography
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
              color="#043A87"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              {params?.value}
            </Typography>
          </BootstrapTooltip>
        );
      },
    },
    {
      field: "game_type",
      headerName: "Game Type",
      minWidth: 180,
      // flex: 0.9,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Typography
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
            >
              NA
            </Typography>
          );
        }

        return (
          <BootstrapTooltip title={params.value}>
            <Box
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              {params?.value}
            </Box>
          </BootstrapTooltip>
        );
      },
    },
    {
      field: "reward_currency",
      headerName: "Reward Currency",
      minWidth: 120,
      // flex: 0.8,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Typography
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
            >
              NA
            </Typography>
          );
        }

        return (
          <Box
            fontFamily="Lato"
            fontWeight={400}
            fontSize="12px"
            lineHeight="14.4px"
            letterSpacing="2%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace={"nowrap"}
          >
            {params?.value}
          </Box>
        );
      },
    },
    {
      field: "offer_status",
      headerName: "Offer Status",
      minWidth: 120,
      // flex: 0.7,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Typography
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
            >
              NA
            </Typography>
          );
        }

        return (
          <Box
            fontFamily="Lato"
            fontWeight={400}
            fontSize="12px"
            lineHeight="14.4px"
            letterSpacing="2%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace={"nowrap"}
          >
            {params?.value}
          </Box>
        );
      },
    },
    {
      field: "duration",
      headerName: "Duration",
      minWidth: 100,
      // flex: 0.6,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Typography
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
            >
              NA
            </Typography>
          );
        }

        return (
          <Box
            fontFamily="Lato"
            fontWeight={400}
            fontSize="12px"
            lineHeight="14.4px"
            letterSpacing="2%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace={"nowrap"}
          >
            {params?.value}
          </Box>
        );
      },
    },
    {
      field: "visibility",
      headerName: "Visibility",
      minWidth: 50,
      // flex: 0.5,
      renderCell: (params) => {
        // if (!params?.value) {
        //   return "NA";
        // }

        return (
          <Box
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <CustomSwitch
              checked={params?.value === 1}
              onChange={(e) => handleVisibility(e, params)}
            />
          </Box>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 150,
      // flex: 1,
      marginLeft: "100px",
      renderCell: (params) => {
        const statusValue = offers?.data?.filter((e) => e.id === params.id)[0]
          ?.offer_status;
        return (
          <Box sx={{ display: "flex", gap: "10px" }}>
            <BootstrapTooltip
              title={
                !permission?.CREATE ? "You don't have permission" : "Copy Offer"
              }
            >
              <Box
                // sx={{
                //   width: "100%",
                //   height: "100%",
                //   display: "grid",
                //   placeItems: "center",
                // }}
                onClick={(e) => handleCopy(e, params)}
              >
                <img
                  style={{ height: "18px", width: "18px" }}
                  src={copy}
                  alt="copy"
                />
              </Box>
            </BootstrapTooltip>

            <BootstrapTooltip title={"Preview"}>
              <Box
                // sx={{
                //   width: "100%",
                //   height: "100%",
                //   display: "grid",
                //   placeItems: "center",
                // }}
                onClick={(e) => handlePreview(e, params)}
              >
                <img
                  src={preview}
                  style={{ height: "18px", height: "18px" }}
                  alt=""
                />
                {/* <MdPreview
                  style={{
                    height: "24px",
                    width: "24px",
                    // color: permission?.UPDATE ? "black" : "gray",
                    color: "black",
                  }}
                /> */}
              </Box>
            </BootstrapTooltip>

            <BootstrapTooltip
              title={
                !permission?.UPDATE
                  ? "You don't have permission"
                  : statusValue === "ACTIVE"
                  ? "Pause"
                  : "Play"
              }
            >
              <Box
                // sx={{
                //   width: "100%",
                //   height: "100%",
                //   display: "grid",
                //   placeItems: "center",
                // }}
                onClick={(e) => handleEdit(e, params)}
              >
                {params?.value?.editBtn}
                {/* <img
              onClick={(e) => handleEdit(e, params)}
              style={{ height: "24px", width: "24px" }}
              src={params?.value}
              alt="delete"
            /> */}
              </Box>
            </BootstrapTooltip>
          </Box>
        );
      },
    },
  ];

  console.log(offers, "offers");

  useEffect(() => {
    let debounceTimer;
    if(flag){
      setFlag(false);
    }else{
      debounceTimer = setTimeout(() => {
          getAllOffers();
        }, 800);
    }

      return () => {
        clearTimeout(debounceTimer);
      };
  }, [search]);

  useEffect(() => {
    getAllOffers();
  }, [
    offerStatus,
    offers?.paginationModel?.page,
    offers?.paginationModel?.pageSize,
  ]);

  return (
    <Box sx={{ padding: "20px" }}>
      <ToastContainer />
      <Box
        sx={{ display: "flex", justifyContent: "space-between", mb: "16px" }}
      >
        <SearchBar
          value={search}
          setState={setSearch}
          placeholder={"Search here..."}
        />
        <Box>
          <Button
            sx={{
              height: "30px",
              width: "106px",
              backgroundColor: "#E8F0F9",
            }}
          >
            <img
              style={{ height: "16px", width: "16px" }}
              src={exportImg}
              alt=""
            />
            <Typography
              sx={{
                fontFamily: "Lato",
                fontWeight: 500,
                fontSize: "12px",
                lineHeight: "14.4px",
                marginLeft: "6px",
                textTransform: "capitalize",
              }}
              onClick={exportToCSV}
            >
              Export Data
            </Typography>
          </Button>

          <Button
            sx={{
              width: "136px",
              height: "30px",
              backgroundColor: "#003D86",
              borderRadius: "6px",
              marginLeft: "12px",
              color: "#FFFFFF",
              "&:hover": {
                backgroundColor: "#003D86",
              },
            }}
            onClick={()=>navigate("/create-offers")}
          >
            <img style={{ height: "16px", width: "16px" }} src={plus} alt="" />
            <Typography
              sx={{
                fontFamily: "Lato",
                fontSize: "12px",
                lineHeight: "14.4px",
                fontWeight: 500,
                marginLeft: "6px",
                textTransform: "capitalize",
              }}
            >
              Create New Offer
            </Typography>
          </Button>
        </Box>
      </Box>
      {/* Table */}
      <Box>
        <UiTable
          row={offers?.data || []}
          columns={columns || []}
          totalCount={offers?.totalCount}
          paginationModel={offers?.paginationModel}
          setPaginationModel={handlePaginationModel}
          //   handleRowClick={handleRowClick}
          loading={loading}
        />
        <CustomConfirmationDialog
          open={editData}
          setOpen={setEditData}
          deleteById={handleStatusChangeByID}
          prop={IdRef.current}
          status="delete"
          message={message}
        />
      </Box>
    </Box>
  );
}
