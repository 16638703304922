import React from "react";
import OfferLevelLimit from "./OfferLevelLimit";
import UserLevelLimit from "./UserLevelLimit";

const OfferLimit = ({ data, onChange,errorMessage }) => {
  const handleData = (key, value) => {
    onChange({ ...data, [key]: value });
  };
console.log(data)
  return (
    <div>
      <OfferLevelLimit
        data={data?.offerLevelLimits}
        onChange={(value) => handleData("offerLevelLimits", value)}
        errorMessage={errorMessage?.offerLevelLimits}
      />
      <UserLevelLimit
        data={data?.userLevelLimits}
        onChange={(value) => handleData("userLevelLimits", value)}
        errorMessage={errorMessage?.userLevelLimits}
      />
    </div>
  );
};

export default OfferLimit;
