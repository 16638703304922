import "./App.css";
import AllRoutes from "./components/AllRoutes";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

function App() {
  const BaseUrl = process.env.REACT_APP_API_BASE_URL;
  const CreateOfferUrl = process.env.REACT_APP_OFFER_SUCCESS_URL;

  console.log(BaseUrl,CreateOfferUrl,"env");
  return (
    <div className="App" style={{ background: "#F7F9FC", height: "100vh" }}>
      {/* <ToastContainer
              position='top-right'
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              // theme='colored'
            /> */}
      <AllRoutes />
    </div>
  );
}

export default App;
