export const getCategory = "getCategory"
export const getSubCategory = "getSubCategory"
export const getProduct = "getProduct"
export const getMerchant = "getMerchant"
export const getRuleSet = "getRuleSet"
export const getInitiative = "getInitiative"
export const getGames = "getGames"
export const getCurrency = "getCurrency"
export const allDataSubmitted = "allDataSubmitted"
export const closeModel = "closeModel"
export const  getCohortsData = "getCohortsData";
export const initialOfferEditValue = "initialOfferEditValue"
export const editOfferSuccess = "editOfferSuccess";
export const editOfferFail= "editOfferFail";
export const editConfirmation = "editConfirmation";
export const resetEdit = "resetEdit";
export const errorSubmitOffer = "errorSubmitOffer";
export const cancelErrorSubmit = "cancelErrorSubmit";
export const getGameType = "getGameType";
export const getRewardTypes = "getRewardTypes";
export const saveRewardPreviousValue = "saveRewardPreviousValue";
export const clearRewardPreviousValue = "clearRewardPreviousValue";
export const getPreviouslyCreateRewards = "getPreviouslyCreateRewards";
export const projectionLoaderStatus = "projectionLoader";
export const setOfferDetailsDataChanges = "setOfferDetailsDataChanges";
export const setQualifyingCriteriaDataChanges = "setQualifyingCriteriaDataChanges";
export const setCreateGameDataChanges = "setCreateGameDataChanges";
export const selectGameType = "selectGameType";
export const toggleReward = "toggleReward";
export const setRewardArray = "setRewardArray";
export const editReward = "editReward";
export const setBeforePlayChange = "setBeforePlayChange";
export const setAfterPlayChange = "setAfterPlayChange";
export const setDrawerImageOption = "setDrawerImageOption";
export const setAfterPlayReward = "setAfterPlayReward";
export const clearCreateOfferData = "clearCreateOfferData";
