import React, { useEffect, useState } from "react";
import bellIcon from "../assets/BellIcon.svg";
import {
  Box,
  Popover,
  Typography,
  Badge,
  IconButton,
  Skeleton,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { getToken } from "../utils/token";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import FiberNewIcon from "@mui/icons-material/FiberNew";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

const Arrow = styled("span")(({ theme }) => ({
  position: "absolute",
  fontSize: 7,
  width: "3em",
  height: "3em",
  "&::before": {
    content: '""',
    margin: "auto",
    display: "block",
    width: 0,
    height: 0,
    borderStyle: "solid",
    borderWidth: "1em",
    borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
  },
}));

export default function Notification() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [flag, setFlag] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [unreadNumber, setUnreadNumber] = useState(0);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const BaseUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setNotifications([]);
    setPage(1);
    setHasMore(true);
    getAllNotification(1);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const markAsRead = (notificationId) => {
    const token = getToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .put(
        `${BaseUrl}/api/notifications/${notificationId}`,
        { is_read: true },
        config
      )
      .then((res) => {
        console.log(res.data);
        setFlag((prev) => !prev);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAllNotification = (currentPage = 1, itemsPerPage = 4) => {
    if (!hasMore && currentPage !== 1) return;

    setLoading(true);
    const token = getToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get(
        `${BaseUrl}/api/notifications?page=${currentPage}&itemsPerPage=${itemsPerPage}`,
        config
      )
      .then((res) => {
        console.log(res.data, "notification");
        if (currentPage === 1) {
          setNotifications(res.data?.data);
        } else {
          setNotifications((prev) => [...prev, ...res.data?.data]);
        }
        setUnreadNumber(+res.data?.unreadNotificationCount);
        setHasMore(res.data?.data.length === itemsPerPage);
        setPage(currentPage);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  console.log(page, "page");
  const handleScroll = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    if (scrollHeight - scrollTop <= clientHeight * 1.5 && !loading && hasMore) {
      getAllNotification(page + 1);
    }
  };

  useEffect(() => {
    // if (open) {
    getAllNotification(1);
    // }
  }, [flag]);

  return (
    <Box>
      <IconButton onClick={handleClick}>
        <StyledBadge badgeContent={unreadNumber} color="error">
          <Box component="img" src={bellIcon} alt="Notification Bell" />
        </StyledBadge>
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          sx: {
            width: 380,
            height: 410,
            mt: 1,
            pb: 2,
            overflow: "hidden",
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              left: "50%",
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
      >
        <Arrow
          sx={{
            position: "absolute",
            top: -7,
            left: "50%",
            transform: "translateX(-50%)",
          }}
        />
        <Box
          sx={{
            p: 2,
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              fontWeight: "bold",
              borderBottom: "2px solid #f0f0f0",
              pb: 1,
              mb: 2,
            }}
          >
            Notifications
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              overflowY: "auto",
              paddingRight: "5px", // This creates space for the scrollbar
              "&::-webkit-scrollbar": {
                width: "8px",
                marginLeft: "5px", // This doesn't actually work for scrollbars
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "#f1f1f1",
                marginLeft: "5px", // This doesn't actually work for scrollbars
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#888",
                borderRadius: "4px",
                marginLeft: "5px", // This doesn't actually work for scrollbars
              },
              "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: "#555",
              },
              // This creates the actual margin effect
              "&": {
                scrollbarWidth: "thin", // For Firefox
                scrollbarColor: "#888 #f1f1f1", // For Firefox
                "&::-webkit-scrollbar": {
                  width: "8px",
                  marginLeft: "5px",
                },
                "&::-webkit-scrollbar-track": {
                  marginLeft: "5px",
                },
                "&::-webkit-scrollbar-thumb": {
                  marginLeft: "5px",
                },
              },
            }}
            onScroll={handleScroll}
          >
            {notifications.length > 0 ? (
              notifications.map((notification, index) => (
                <Box
                  key={notification?.id}
                  sx={{
                    mb: 2,
                    p: 1,
                    mx: "2px",
                    borderRadius: 2,
                    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                    transition: "box-shadow 0.3s ease",
                    "&:hover": {
                      boxShadow: "0 4px 8px rgba(0,0,0,0.15)",
                    },
                    backgroundColor:
                      notification?.is_read === 0 ? "#eaeae8" : "white",
                  }}
                >
                  <Typography sx={{ fontSize: 14, color: "text.primary" }}>
                    {notification?.notification_title}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 12,
                      color: "text.primary",
                      display: "-webkit-box",
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {notification?.notification_message}
                  </Typography>
                  <Box sx={{ position: "relative", display: "inline-block" }}>
                    {/* {notification?.is_read === 0 && (
                      <FiberNewIcon 
                        sx={{
                          position: 'absolute',
                          top: -10,
                          right: -15,
                          fontSize: 16,
                          color: '#FF0000',
                        }}
                      />
                    )} */}
                    <Box
                      component="span"
                      sx={{
                        color: "primary.main",
                        fontWeight: 600,
                        fontSize: 12,
                        cursor: "pointer",
                        display: "inline-block",
                        "&:hover": {
                          textDecoration: "underline",
                        },
                      }}
                      onClick={() => {
                        if (notification?.is_read === 0) {
                          markAsRead(notification?.id);
                        }
                        navigate(`${notification?.notification_url}`);
                      }}
                    >
                      View Details
                    </Box>
                  </Box>
                </Box>
              ))
            ) : (
              <Typography sx={{ color: "text.secondary" }}>
                No notifications
              </Typography>
            )}
            {loading && (
              <Box
                sx={{ mt: 2, display: "flex", justifyContent: "center", mb: 2 }}
              >
                {/* <Skeleton variant="rectangular" width="100%" height={60} sx={{ mb: 1 }} /> */}
                <CircularProgress />
              </Box>
            )}
          </Box>
        </Box>
      </Popover>
    </Box>
  );
}
