import React, { useEffect, useRef, useState } from "react";
import { ArrowDropDownOutlined } from "@mui/icons-material";
import FlexBetween from "./FlexBetween";
import {
  AppBar,
  Button,
  Box,
  Typography,
  IconButton,
  Toolbar,
  Menu,
  MenuItem,
} from "@mui/material";
import profile from "../assets/profile.jpg";
import Header from "./Header";
import { useLocation, useNavigate } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import { setLogout } from "../state";
import BellIcon from "../assets/BellIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { getLimitOptions, userLogout } from "../Redux/AuthReducer/actionType";
import { getTypeOptionsFunction, getWhereSectionSelectOne } from "../Redux/CreateRuleReducer/action";
import { getAllGameTypes, getCategoryFunction, getMerchantFunction, getAllRewardFunction } from "../Redux/OfferReducer/action";
import userProfile from "../assets/Guest-user.png"
import { handleGetSelectOption } from "../Redux/AuthReducer/action";
import Notification from "../UIComponents/Notification";

const TopNavbar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  //   const dispatch = useDispatch();
  //   const navigate = useNavigate();

  // console.log(pathname.substring(1));

  const data = localStorage.getItem("Auth")
  const AuthDetails = data?JSON.parse(data):{};

  const dispatch = useDispatch()
  const topRef = useRef(null);
  const handleLogOut = ()=>{
    localStorage.setItem("Auth", "");
    dispatch({type:userLogout})
    navigate("/login")
  }
  const userName = useSelector((store) => store.AuthReducer.userName);
  
  const token = useSelector((store) => store.AuthReducer.token);
  const scrollFlag = useSelector((store) => store.AuthReducer.scrollFlag);

  useEffect(() => {
    if (token) {
      // dispatch(getWhereSectionSelectOne());
      dispatch(getTypeOptionsFunction(token));
      dispatch(getCategoryFunction(token))
      dispatch(getMerchantFunction(token));
      // dispatch(handleGetSelectOption());
      dispatch(getAllRewardFunction())
      dispatch(getAllGameTypes(1,5))
    }
  }, [token]);

  // Scroll to top on component 
    useEffect(() => {
    topRef.current.scrollIntoView({ behavior: "smooth" });
  }, [scrollFlag]);

  return (
    <AppBar
      sx={{
        position: "static",
        background: "none",
        boxShadow: "none",
        backgroundColor: "white",
      }}
      ref={topRef}
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        {/* LEFT SIDE */}
        <FlexBetween>
          <Header title={pathname.substring(1)} />
        </FlexBetween>

        {/* RIGHT SIDE */}
        <FlexBetween gap="1.5rem">
          {/* <IconButton>
            <img src={BellIcon} alt="notification" />
          </IconButton> */}
        <Notification totalNumber={3}/>

          <FlexBetween>
            <div
              className="border"
              style={{
                border: "1px solid var(--text-30, rgba(0, 0, 0, 0.30)",
                borderRadius: "100px",
              }}
            >
              <Button
                onClick={handleClick}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  textTransform: "none",
                  gap: "1rem",
                }}
              >
                <Box
                  component="img"
                  alt="profile"
                  src={userProfile}
                  height="32px"
                  width="32px"
                  borderRadius="50%"
                  sx={{ objectFit: "cover" }}
                />
                <Box textAlign="left">
                  <Typography
                    fontWeight="bold"
                    fontSize="0.85rem"
                    sx={{ color: "black" }}
                  >
                   {userName}
                  </Typography>
                </Box>
                <ArrowDropDownOutlined
                  sx={{ color: "black", fontSize: "25px" }}
                />
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={isOpen}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              >
                <MenuItem onClick={handleLogOut}>Log Out</MenuItem>
              </Menu>
            </div>
          </FlexBetween>
        </FlexBetween>
      </Toolbar>
    </AppBar>
  );
};

export default TopNavbar;
