import React from "react";
import {
  FormControl,
  InputAdornment,
  OutlinedInput,
  Button,
  Box,
} from "@mui/material";

const UrlUpload = ({
  placeholder,
  onChange,
  handleUpload,
  value,
  errorMessage,
}) => {
  return (
    <FormControl sx={{ width: "100%", mb: "10px" }} variant="outlined">
      <OutlinedInput
        size="small"
        sx={{paddingRight:"0px"}}
        onChange={(e) => onChange(e?.target?.value)}
        value={value}
        error={errorMessage}
        endAdornment={
          <InputAdornment position="end">
            <Button
              color="inherit"
              onClick={handleUpload}
              style={{
                backgroundColor: "#F9F9FC",
                color: "#003D86",
                padding: "8px 30px",
                fontFamily:"Lato",
                fontWeight: 600,
                fontSize: "12px",
                lineHeight: "16px",
                height:"40px",
              }}
              // variant="outlined"
              size="small"
            >
              Upload
            </Button>
          </InputAdornment>
        }
        placeholder={placeholder}
      />
      {errorMessage && (
        <Box
          sx={{
            mt: 1,
            fontSize: "12px",
            color: "#d74848",
            textAlign: "left",
            ml: "20px",
          }}
        >
          {errorMessage}
        </Box>
      )}
    </FormControl>
  );
};

export default UrlUpload;
