import React, { useMemo } from "react";
import { Typography, Box } from "@mui/material";
import HeadingIcon from "../assets/breadcrum-arrow.svg";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

function capitalizeEachWord(inputString) {
  return inputString
    .split(" ")
    .map(word => capitalizeFirstLetter(word))
    .join(" ");
}

const Header = ({ title }) => {
  const CleanString = useMemo(() => {
    const parts = title.split("/").filter(part => part !== "");
    return parts.map((part, index) => (
      <React.Fragment key={index}>
        <Box
          component="span"
          sx={{
            display: "inline-flex",
            alignItems: "center",
            color: index === parts.length - 1 ? "#043A87" : "inherit",
          }}
        >
          {part.split("-").map((word, i) => (
            <React.Fragment key={i}>
              {i > 0 && " "}
              {capitalizeFirstLetter(word)}
            </React.Fragment>
          ))}
          {index < parts.length - 1 && (
            <Box
              component="img"
              sx={{
                height: 15,
                width: 15,
                mx: 1,
                display: "inline-flex",
                alignItems: "center",
              }}
              alt="Icon"
              src={HeadingIcon}
            />
          )}
        </Box>
      </React.Fragment>
    ));
  }, [title]);

  return (
    <Typography
      variant="h5"
      fontWeight="bold"
      textTransform="capitalize"
      sx={{
        color: "#667085",
        fontFamily: "Lato",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "16.8px",
        display: "flex",
        alignItems: "center",
      }}
    >
      {CleanString ?? ""}
    </Typography>
  );
};

export default Header;