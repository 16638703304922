import { Box, Stack, Typography } from "@mui/material";
import React from "react";

const AnalyticsCard = ({
  value,
  title,
  height,
  titleColor = "#363636",
  sidebackgroundColor = "#9383D1",
}) => {
  // css
  const containerStyle = {
    width: "100%",
    height: height ? height : "100px",
    gap: "10%",
    borderRadius: "12px 12px 12px 12px",
    background: "#fff",
    position: "relative",
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center", // Vertically center the content
  };

  const analyticsCashTextStyle = {
    fontFamily: "Proxima Nova A",
    fontSize: "26px",
    fontWeight: 700,
    lineHeight: "32px",
    letterSpacing: "0em",
    textAlign: "left",
  };

  const analyticsTitleTextStyle = {
    fontFamily: "Proxima Nova A",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "21.9px",
    color: titleColor,
    textTransform  : "capitalize",
    textAlign: "left",
  };

  return (
    <Box sx={containerStyle}>
      <Box
        sx={{
          width: "6px",
          height: "96%",
          background: sidebackgroundColor,
          borderRadius: "12px 0 0  12px",
        }}
      ></Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignContent: "center",
        }}
      >
        <Stack spacing={2}>
          <Typography sx={analyticsCashTextStyle}>{value}</Typography>
          <Typography sx={analyticsTitleTextStyle}>{title}</Typography>
        </Stack>
      </Box>
    </Box>
  );
};

export default AnalyticsCard;
