import React, { useEffect, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function SampleNextArrow(props) {
  const { className, style, onClick, goToNextSlide = () => {} } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block",backgroundColor:"#B5BBC5",padding:"5px 3px 1px 3px", borderRadius:"50%",right:"22px" }}
      onClick={(e) => {
        // goToNextSlide();
        onClick(e);
      }}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick, goToPrevSlide } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" ,backgroundColor:"#B5BBC5",padding:"5px 3px 1px 3px", borderRadius:"50%",left:"50px"}}
      onClick={(e) => {
        // goToPrevSlide();
        onClick(e);
      }}
    />
  );
}

const ReactSlickCarousel = ({ settings, children, setCurrentIndex, max }) => {
  const goToPrevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? max : prevIndex - 1));
  };

  const goToNextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === max ? 0 : prevIndex + 1));
  };

  useEffect(() => {
    setCurrentIndex(0);
  }, []);

  // Merge custom settings with default settings
  const sliderRef = useRef(null);
  const mergedSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    swipe: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    afterChange: (index) => setCurrentIndex(index), // Update currentIndex after slide change
  };

  return (
    <div style={{ width: 400, height: 500, position: "relative",paddingRight:"30px" }}>
      <Slider de ref={sliderRef} {...mergedSettings}>
        {children}
      </Slider>
    </div>
  );
};

export default ReactSlickCarousel;
