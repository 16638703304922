
import { Autocomplete, FormControl, FormHelperText, TextField } from "@mui/material";
import React from "react";
const MuiSelectAndSearch = ({ options, value , onChange,label,errorMessage,isDisable=false,placeholder,  editBoolean }) => {
  return (
    <FormControl
    sx={{ mt: "10px", width: "100%" }}
    variant="outlined"
    size="small"
    error={!!errorMessage}
  >
    <Autocomplete
      freeSoloAu
      disablePortal
      disabled={isDisable}
      id="combo-box-demo"
      getOptionLabel={(value) => value?.name || ""}
      fullWidth
      sx={{
        '& .MuiAutocomplete-inputRoot': {
          padding: "1px 8px",
          borderRadius:"6px",
          height:"32px",
          fontFamily:"Lato",
          fontSize:"12px",
          fontWeight: "400",
          lineHeight: "16px",
          '& .MuiAutocomplete-input': {
            padding: "0px",
          }
        },
        "& .MuiInputBase-input.Mui-disabled": {
          WebkitTextFillColor: "#000000ba",
        },
        ...(editBoolean && {
          '& fieldset': {
            borderColor: 'orange !important',
            borderWidth: '1px',
          },
          '&:hover fieldset': {
            borderColor: 'orange !important',
          },
          '&.Mui-focused fieldset': {
            borderColor: 'orange !important',
          },
        }),
        ...(errorMessage && {
          '& fieldset': {
            borderColor: '#d40e10 !important',
            borderWidth: '1px',
          },
          '&:hover fieldset': {
            borderColor: '#d40e10 !important',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#d40e10 !important',
          },
        }),
      }}
      options={options || []}
      value={value || ""}
      onChange={(event, newValue) => {
        onChange(newValue)
      }}
    //   renderInput={(params) => (
    //     <TextField
    //       {...params}
    //       type="text"
    //     //   variant="outlined"
    //       color="secondary"
    //       style={{ padding: "0", height: "30px" }}
    //       label={label}
    //       fullWidth
    //     />
    //   )}
    renderInput={(params) => (
        <TextField
          {...params}
          // label="filterSelectedOptions"
          placeholder= {placeholder? placeholder :"--Select--"}
        />
      )}
    />
    <FormHelperText>{errorMessage}</FormHelperText>
       </FormControl>
  );
};
export default MuiSelectAndSearch;