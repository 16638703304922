import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Box, Typography, IconButton } from "@mui/material";
import UploadIcon from "../assets/UploadIcon.svg";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";

function FileUpload({ value, onChange, errorMessage }) {
  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      if (acceptedFiles?.length !== 0) {
        onChange(acceptedFiles[0]);
      }
      if (rejectedFiles?.length !== 0) {
        toast.error("please select csv file");
      }
    },
    [onChange]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: [
      ".csv",
    ],
  });

  return (
    <>
      {value && value.name ? (
        <Box
          sx={{
            borderRadius: "12px",
            // border: `1px dashed ${errorMessage ? "#d74848" : "#2B64F6"}`,
            backgroundColor: "#F3F7FE",
            py: "38px",
            px: "90px",
          }}
        >
          <Typography
            sx={{
              color: "#272728",
              textAlign: "center",
              fontFamily: "Outfit",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "23px",
              mb: "8px",
            }}
          >
            {" "}
            CSV file selected
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "5px",
            }}
          >
            <Typography
              sx={{
                color: "#65758C",
                textAlign: "center",
                fontFamily: "Outfit",
                fontSize: "15px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "19px",
                maxWidth: "250px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {value?.name}{" "}
            </Typography>
            <IconButton onClick={() => onChange("")}>
              <CloseIcon sx={{ color: "Stroke", fontSize: "20px" }} />
            </IconButton>
          </Box>
        </Box>
      ) : (
        <Box
          {...getRootProps()}
          sx={{
            borderRadius: "12px",
            // border: `1px dashed ${errorMessage ? "#d74848" : "#2B64F6"}`,
            backgroundColor: "#F3F7FE",
            py: "38px",
            px: "90px",
          }}
        >
          <input
            {...getInputProps()}
            type="file"
            accept=".csv"
          />
          {isDragActive ? (
            // <Box
            //   sx={{
            //     borderRadius: "12px",
            //     border: `1px dashed ${errorMessage ? "#d74848" : "#2B64F6"}`,
            //     backgroundColor: "#F3F7FE",
            //     py: "38px",
            //     px: "90px",
            //   }}
            // >
            <Typography
              sx={{
                color: "#65758C",
                textAlign: "center",
                fontFamily: "Outfit",
                fontSize: "15px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "19px",
                m: "30px",
              }}
            >
              Drop the files here ...
            </Typography>
          ) : (
            // </Box>
            // <Box
            //   sx={{
            //     borderRadius: "12px",
            //     border: `1px dashed ${errorMessage ? "#d74848" : "#2B64F6"}`,
            //     backgroundColor: "#F3F7FE",
            //     py: "38px",
            //     px: "90px",
            //   }}
            // >
            <Box sx={{ cursor: "pointer" }}>
              <Box
                sx={{
                  borderRadius: "50%",
                  backgroundColor: "#D5D5D5",
                  height: "20px",
                  width: "20px",
                  p: "10px",
                  margin: "auto",
                  mb: "5px",
                }}
              >
                <img src={UploadIcon} alt="icon" height={20} width={20} />
              </Box>
              <Typography
                sx={{
                  color: "#272728",
                  textAlign: "center",
                  fontFamily: "Outfit",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "23px",
                  mb: "8px",
                }}
              >
                Select a CSV file to upload
              </Typography>
              <Typography
                sx={{
                  color: "#65758C",
                  textAlign: "center",
                  fontFamily: "Outfit",
                  fontSize: "15px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "19px",
                }}
              >
                or drag and drop it here.
              </Typography>
            </Box>
            // </Box>
          )}
        </Box>
      )}
      {errorMessage && (
        <Box
          sx={{
            mt: 1,
            fontSize: "14px",
            color: "#d74848",
            textAlign: "left",
            ml: "20px",
          }}
        >
          {errorMessage}
        </Box>
      )}
    </>
  );
}

export default FileUpload;
