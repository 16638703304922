export const commonXaxisProp = {
    tick : {  angle: -45 , stroke: "#949494" },
    textAnchor : "end",
    fontFamily : "Proxima Nova",
    fontSize: "13px",
    interval : 0,
    fontWeight : 300,
    height:53,
    letterSpacing : "1px",
  }

  
  export const commonYAxiosProp = {
    letterSpacing : "1px",
    textAnchor : "end",
    fontFamily : "Proxima Nova",
    fontSize: "11px",
    tick : {stroke : "#000"}
  }