import React, { useState, useEffect, useRef } from "react";
import { Box, TextField } from "@mui/material";
import { ChromePicker } from "react-color";

const ColorInput = ({
  value,
  onChange,
  type = "text",
  placeholder,
  label = "",
  errorMessage = "",
  disable = false,
  mt = "10px",
}) => {
  const [color, setColor] = useState(value);
  const [showPicker, setShowPicker] = useState(false);
  const pickerRef = useRef(null);
  const colorBoxRef = useRef(null);

  useEffect(() => {
    setColor(value);
  }, [value]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target) &&
          colorBoxRef.current && !colorBoxRef.current.contains(event.target)) {
        setShowPicker(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleColorChange = (color) => {
    setColor(color.hex);
    onChange(color.hex);
  };

  const handleInputChange = (inputValue) => {
    setColor(inputValue);
    if (type === "number") {
      const numericValue = parseFloat(inputValue);
      if (!isNaN(numericValue) && numericValue >= 0) {
        onChange(numericValue);
      } else {
        onChange(""); 
      }
    } else {
      onChange(inputValue);
    }
  };

  const handleColorBoxClick = (e) => {
    e.stopPropagation();
    setShowPicker((prev) => !prev);
  };

  return (
    <Box sx={{ position: "relative", width: "100%", mt: mt }}>
      <Box sx={{ position: "relative", width: "100%" }}>
        <Box
          ref={colorBoxRef}
          sx={{
            backgroundColor: color,
            height: "20px", // Match the input height
            width: "20px",
            position: "absolute",
            left: "8px",
            top: "50%",
            transform: "translateY(-50%)",
            borderRadius: "3px",
            cursor: "pointer",
            zIndex: 2,
          }}
          onClick={handleColorBoxClick}
        />
        <TextField
          sx={{
            width: "100%",
            borderRadius: "6px",
            "& .MuiInputBase-root": {
              height: "32px", // Match height
              fontFamily: "Lato", // Set font family for input text
              fontWeight: 400, // Set font weight for input text
              fontSize: "12px", // Set font size for input text
              lineHeight: "16px", // Set line height for input text
              padding: "8px 22px", // Adjust padding to fit the 32px height
            },
          }}
          type={type}
          value={color}
          disabled={disable}
          onChange={(e) => handleInputChange(e.target.value)}
          label={label}
          placeholder={placeholder}
          size="small"
          helperText={errorMessage}
          error={!!errorMessage}
          onWheel={() => document.activeElement.blur()}
          InputProps={{
            sx: { pl: "40px" }, // Padding for the color box
          }}
        />
      </Box>
      {showPicker && !disable && (
        <Box
          ref={pickerRef}
          sx={{
            position: "absolute",
            zIndex: 9999,
            top: "100%",
            left: "0",
            marginTop: "5px",
            paddingBottom: "20px",
          }}
        >
          <ChromePicker color={color} onChange={handleColorChange} />
        </Box>
      )}
    </Box>
  );
};

export default ColorInput;
