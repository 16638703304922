import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { Avatar, Box, Button, Typography } from "@mui/material";
import SwitchableButtonComponent from "../components/SwitchableButtonComponent";
import DateFilterPanel from "../components/DateFilterPanel";
import spinnerIcon from "../assets/spinner.svg";
import SwipeableDrawerComponent from "../components/SwipeableDrawerComponent ";
import CustomFilter from "../components/Filter/CustomFilter";
import SwitchablePanel from "../components/SwitchablePanel";
import {
  initialStateOfferLevel,
  initialStateOverviewFilter,
  initialStateRewardLevelFilter,
  initialStateUserLevelFilter,
} from "../utils/initialStates";
import axios from "axios";
import { useSelector } from "react-redux";
import MuiButton from "../UIComponents/MuiButton";
import { formatDateNew } from "../utils/formateDate";
import { download } from "./Transactions";
import usePermission from "../utils/permissions";

// const switchableButtonOptions = [
//   "Overview",
//   "Product Level",
//   "Offer Level",
//   "User Level",
// ];

const filterSelectOptions = [
  { label: "Custom", value: "CUSTOM" },
  { label: "Today", value: "TODAY" },
  { label: "Yesterday", value: "YESTERDAY" },
  { label: "This Week", value: "THIS_WEEK" },
  { label: "This Month", value: "THIS_MONTH" },
  { label: "All Time", value: "ALL_TIME" },
];

function initializeValueByName(name) {
  switch (name) {
    case "Overview":
      return initialStateOverviewFilter;
    case "Product Level":
      return initialStateRewardLevelFilter;
    case "Offer Level":
      return initialStateRewardLevelFilter;
    case "User Level":
      return initialStateUserLevelFilter;
    default:
      return;
  }
}
const Analytics = () => {
  const token = useSelector((store) => store.AuthReducer.token);
  const permission = usePermission();
  const switchableButtonOptions = permission?.SUB_SECTIONS ?? []
  const navigate = useNavigate();
  const location = useLocation();
  const isAuth = useSelector((store) => store.AuthReducer.token);
  const searchParams = new URLSearchParams(location.search);
  const tabs = searchParams.get("tabs");
  const [activeButton, setActiveButton] = useState(switchableButtonOptions[0] || "");
  const anchor = "right";
  const dateFilterLayout = {
    select: "",
    from_time: "",
    to_time: "",
  };

  // console.log(switchableButtonOptions,"permission")

// initial value of filterOptions
  // {
  //   Overview: [],
  //   "Product Level": [],
  //   "Offer Level": [],
  //   "User Level": [],
  // }

  const [filterOptions, setFilterOptions] = useState(() => {
    return switchableButtonOptions.reduce((acc, res) => {
      acc[res] = [];
      return acc;
    }, {});
  });

  const downloadOverview = async () => {
    const BaseUrl = process.env.REACT_APP_API_BASE_URL;
    const url = `${BaseUrl}/api/export`;
    const token = isAuth;

    const obj = {};

    if (
      dateFilter?.select &&
      dateFilter?.select === "Custom" &&
      dateFilter?.to_time &&
      dateFilter?.from_time
    ) {
      let startDate = formatDateNew(dateFilter?.from_time ?? "");
      let endDate = formatDateNew(dateFilter?.to_time ?? "");
      obj["dateStart"] = startDate;
      obj["dateEnd"] = endDate;
    }

    if (dateFilter?.select) {
      obj["day"] = dateFilter?.select;
    }

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Adjust content type as needed
    };

    try {
      const response = await axios.post(url, obj, {
        headers,
      });

      download(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleExport = (callback, activeButton) => {
    switch (activeButton) {
      case "Overview":
        callback();
        break;
      default:
        break;
    }
  };

  const [showDateFilter, setShowDateFilter] = useState({
    "Product Level": true,
    "Offer Level": true,
  });

  const [dateFilter, setDateFilter] = useState(() => {
    return switchableButtonOptions.reduce((acc, option) => {
      acc[option] = {
        ...dateFilterLayout,
        select: option === "Product Level" ? "ALL_TIME" : "THIS_MONTH",
      };
      return acc;
    }, {});
  });

  const [doneButton, setDoneButton] = useState(() => {
    return switchableButtonOptions.reduce((acc, res) => {
      acc[res] = false;
      return acc;
    }, {});
  });

  const [showFilter, setShowFilter] = useState(() => {
    return switchableButtonOptions.reduce((acc, option) => {
      acc[option] = {
        right: false,
      };
      return acc;
    }, {});
  });

  const handleShowDateFilter = (name, value) => {
    setShowDateFilter((prev) => ({ ...prev, [name]: value }));
  };

  const handleDateFilter = (parentname, name, value, obj) => {
    if (obj) {
      setDateFilter((prev) => ({
        ...prev,
        [parentname]: { ...prev[parentname], ...obj },
      }));
    } else {
      if (name === "select" && value === "CUSTOM") {
        setDateFilter((prev) => ({
          ...prev,
          [parentname]: {
            ...prev[parentname],
            [name]: value,
            from_time: "",
            to_time: "",
          },
        }));
      } else {
        setDateFilter((prev) => ({
          ...prev,
          [parentname]: { ...prev[parentname], [name]: value },
        }));
      }
    }
  };

  const toggleDrawer = (anchor, open, activeButton) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setShowFilter((prev) => {
      return {
        ...prev,
        [activeButton]: {
          ...prev[activeButton],
          [anchor]: open,
        },
      };
    });
  };

  const handleReset = (activeButton, resetvalue = {}) => {
    const dateFilterLayout = {
      select: activeButton === "Product Level" ? "ALL_TIME" : "THIS_MONTH",
      from_time: "",
      to_time: "",
    };

    setFilterOptions((prevOptions) => {
      const updatedOptions = [...prevOptions[activeButton]];

      const updatedData = updatedOptions?.map((filter) => ({
        ...filter,
        state: [],
      }));

      const newFilterOptions = { ...prevOptions, [activeButton]: updatedData };
      return newFilterOptions;
    });

    setDateFilter((prev) => ({
      ...prev,
      [activeButton]: { ...dateFilterLayout },
    }));
  };

  const handleDoneButton = (activeButton) => {
    setDoneButton((prev) => ({ ...prev, [activeButton]: !prev[activeButton] }));
  };

  const handleFilterState =
    (activeButton) => (name, value, checked, status) => {
      if (status === "clear") {
        setFilterOptions((prevOptions) => {
          const updatedOptions = [...prevOptions[activeButton]];

          const updatedData = updatedOptions?.map((filter) =>
            filter?.name === name ? { ...filter, state: [] } : filter
          );

          const newFilterOptions = {
            ...prevOptions,
            [activeButton]: updatedData,
          };

          return newFilterOptions;
        });
        return;
      }

      if (checked) {
        setFilterOptions((prevOptions) => {
          const updatedOptions = [...prevOptions[activeButton]];

          const updatedData = updatedOptions?.map((filter) =>
            filter?.name === name
              ? { ...filter, state: [...filter.state, value] }
              : filter
          );

          const newFilterOptions = {
            ...prevOptions,
            [activeButton]: updatedData,
          };

          return newFilterOptions;
        });
      } else {
        setFilterOptions((prevOptions) => {
          const updatedOptions = [...prevOptions[activeButton]];

          const updatedData = updatedOptions?.map((filter) =>
            filter?.name === name
              ? {
                  ...filter,
                  state: filter.state.filter((res) => res !== value),
                }
              : filter
          );

          const newFilterOptions = {
            ...prevOptions,
            [activeButton]: updatedData,
          };

          return newFilterOptions;
        });
      }
    };

  const handleClearFilter = (activeButton, initializeValueByName) => () => {
    setFilterOptions((prevOptions) => {
      const updatedOptions = [...prevOptions[activeButton]];

      const updatedData = updatedOptions?.map((filter) => ({
        ...filter,
        state: [],
      }));

      const newFilterOptions = { ...prevOptions, [activeButton]: updatedData };

      return newFilterOptions;
    });
  };

  const fetchFilters = async (status) => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Adjust content type as needed
    };
    try {
      const response = await axios.get(
        `${baseUrl}/api/filters?page=${status}`,
        {
          headers,
        }
      );
      if (response) {
        const config = response.data;
        const data = config.map((res) => {
          return { ...res, state: [] };
        });
        setFilterOptions((prev) => ({
          ...prev,
          [activeButton]: data,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRemove = (activeButton) => (name, value) => {
    setFilterOptions((prevOptions) => {
      const updatedOptions = [...prevOptions[activeButton]];

      console.log(updatedOptions, "testing");

      const updatedData = updatedOptions?.map((filter) =>
        filter?.name === name
          ? {
              ...filter,
              state: filter.state.filter((res) => res !== value),
            }
          : filter
      );

      const newFilterOptions = {
        ...prevOptions,
        [activeButton]: updatedData,
      };
      return newFilterOptions;
    });
  };

  // useEffect(() => {
  //   // Create or update the query parameter
  //   const queryParams = new URLSearchParams(location.search);
  //   queryParams.set('tabs', activeButton);

  //   navigate({ search: queryParams.toString(), replace: true });
  // }, [activeButton]);

  // css
  const containerStyle = {
    margin: "0  1.5rem",
    background: "#f7f9fc",
    mt: "25px",
  };

  const moreFilterButtonStyle = {
    height: "45px",
    padding: "14px 20px 14px 20px",
    borderRadius: "5px",
    border: "1px solid #D9D9D9",
    background: "#fff",
  };

  const moreFilterTextStyle = {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "17.5px",
    fontFamily: "Proxima Nova A",
    color: "#26292D",
  };

  useEffect(() => {
    if (activeButton === "Overview") {
      fetchFilters("rewardAnalytics");
    } else if (activeButton === "Product Level") {
      fetchFilters("productAnalytics");
    } else if (activeButton === "Offer Level") {
      fetchFilters("offerAnalytics");
    }
  }, [activeButton]);

  return (
    <Box sx={containerStyle}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "18px",
        }}
      >
        {(typeof showDateFilter[activeButton] === "undefined" ||
          showDateFilter[activeButton]) && (
          <DateFilterPanel
            key={activeButton}
            dateFilter={dateFilter}
            filterSelectOptions={filterSelectOptions}
            activeButton={activeButton}
            handleDateFilter={handleDateFilter}
          />
        )}
        {activeButton !== "User Level" && showDateFilter[activeButton] && (
          <Button
            sx={moreFilterButtonStyle}
            startIcon={
              <Avatar
                src={spinnerIcon}
                sx={{
                  width: "16px",
                  height: "16px",
                }}
              />
            }
            onClick={() => {
              toggleDrawer(anchor, true, activeButton)();
            }}
            variant={"text"}
          >
            <Typography sx={moreFilterTextStyle}>{"More Filter"}</Typography>
          </Button>
        )}
      </Box>

      <SwitchableButtonComponent
        activeButton={activeButton}
        setActiveButton={setActiveButton}
        switchableButtonOptions={switchableButtonOptions}
      />

      <SwitchablePanel
        key={activeButton}
        activeButton={activeButton}
        filter={filterOptions[activeButton]}
        handleReset={handleReset}
        doneButton={doneButton[activeButton]}
        dateFilter={dateFilter[activeButton]}
        handleShowDateFilter={handleShowDateFilter}
        showDateFilter={showDateFilter}
        handleRemove={handleRemove}
        handleDateFilter={handleDateFilter}
        handleExport={handleExport}
      />

      <SwipeableDrawerComponent
        onOpen={toggleDrawer(anchor, true, activeButton)}
        isOpen={showFilter[activeButton]?.right ?? ""}
        onClose={toggleDrawer(anchor, false, activeButton)}
        anchor={anchor}
      >
        <CustomFilter
          key={activeButton}
          onCLose={toggleDrawer(anchor, false, activeButton)}
          filterOptions={filterOptions[activeButton]}
          handleDoneButton={() => {
            handleDoneButton(activeButton);
          }}
          handleClear={handleClearFilter(activeButton, initializeValueByName)}
          handleFilterState={handleFilterState(activeButton, dateFilter)}
        />
      </SwipeableDrawerComponent>
    </Box>
  );
};

export default Analytics;
