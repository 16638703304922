import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
export const PrivateRoute = ({ children }) => {
    const isAuth = useSelector(store=>store.AuthReducer.token);
    // console.log(isAuth,"auth")
  if (!isAuth) {
    return <Navigate to="/login" />;
  }
  return children;
};