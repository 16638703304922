import React, { useState } from "react";
import UserCohortsFileUpload from "../components/userCohorts/UserCohortsFileUpload";
import { Box, Button } from "@mui/material";
import Label from "../UIComponents/Label";
import MuiInput from "../UIComponents/MuiInput";
import MuiTextarea from "../UIComponents/MuiTextarea";
import { isValidUserCohortData } from "../utils/validations";
import axios from "axios";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";

// const initialData = [{
//   csvFile: "",
//   url: "",
//   name: "",
//   description: "",
// }];

const UserCohortsCreate = ({
  // initialData = getInitialData,
  activeTab,
  tabData,
  setTabData,
  handleCancel,
  cancelButtonHide = false,
}) => {
  // const [data, setData] = useState({ initialData });
  // const [error, setError] = useState(initialData);
  const token = useSelector((store) => store?.AuthReducer?.token ?? "");
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const uploadCsvFile = async (file, json_data) => {
    if (!file || !token) return false;
    const url = `${process.env.REACT_APP_API_BASE_URL}/api/admin/create-cohorts`;
    const options = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };
    const formData = new FormData();
    formData.append("CollectionItemsCSV", file);
    formData.append("json_data", JSON.stringify(json_data));

    try {
      console.log(formData, "formData");
      const response = await axios.post(url, formData, options);
      console.log(response, "response");
      if (response.status === 200) {
        // Proceed with your logic here
        return true;
        // You can access response data if needed like: response.data
      }
    } catch (error) {
      console.log("Error While Uploading File", error);
      return false;
    }
  };

  const handleChange = (key, value) => {
    // if (error?.[key]) {
    //   setError((prev) => {
    //     return {
    //       ...prev,
    //       [key]: "",
    //     };
    //   });
    // }
    // setData((pre) => {
    //   return {
    //     ...pre,
    //     [key]: value,
    //   };
    // });
    setTabData((prevTabData) => {
      return prevTabData.map((tab, index) => {
        if (index === activeTab) {
          return {
            ...tab,
            [key]: value,
          };
        }
        return tab;
      });
    });
  };

  const handleError = (key, value) => {
    // setError((pre) => {
    //   return {
    //     ...pre,
    //     [key]: value,
    //   };
    // });
  };

  const handleSubmit = async () => {
    // setError(initialData);
    // if (!isValidUserCohortData(data, handleError)) {
    //   return;
    // }
    

    const name = tabData[activeTab]?.name;
    const description = tabData[activeTab]?.description;
    const json_data = {
      name: name,
      description: description,
    };

    if(!tabData[activeTab]?.csvFile){
      toast.error("CSV file is required")
      return ;
    }

    if(!name){
      toast.error("Name is required")
      return ;
    }

    if(!description){
      toast.error("Description is required")
      return ;
    }

    const FileUpload = await uploadCsvFile(
      tabData[activeTab]?.csvFile,
      json_data
    );

    if (!FileUpload) return;

    toast.success("Cohort Created Successfully");
    setTimeout(() => {
    if(pathname==="/create-offers"){
      handleCancel(tabData[activeTab], activeTab);
    }else{

        navigate("/userCohorts-view");
        setTabData([{
          csvFile: "",
          name: "",
          description: "",
        }])
      }
    }, 2000);
  };
  console.log(tabData[activeTab]);
  return (
    <Box
      sx={{
        mx: pathname == "/userCohorts-create" ? "30px" : "0px",
        my: pathname == "/userCohorts-create" ? "20px" : "0px",
        backgroundColor: "white",
      }}
    >
      <ToastContainer/>
      <UserCohortsFileUpload
        data={tabData[activeTab]}
        // errorMessage={error}
        onChange={(key, value) => handleChange(key, value)}
      />
      <Box
        sx={{
          marginBottom: "20px",
          maxWidth: "470px",
          marginLeft: pathname == "/userCohorts-create" ? "13px" : "5px",
          px: pathname == "/userCohorts-create" ? "10px" : "0px",
          // backgroundColor:"white"
        }}
      >
        <Label label="2.Cohort Name" />
        <MuiInput
          value={tabData[activeTab]?.name}
          onChange={(value) => handleChange("name", value)}
          placeholder="40 Characters"
          // errorMessage={error?.name}
        />
      </Box>
      <Box
        sx={{
          marginBottom: "30px",
          maxWidth: "470px",
          marginLeft: pathname == "/userCohorts-create" ? "13px" : "5px",
          px: pathname == "/userCohorts-create" ? "10px" : "0px",
        }}
      >
        <Label label="3.Cohort Description" />
        <MuiTextarea
          placeholder="200 Characters"
          minRows={3}
          value={tabData[activeTab]?.description}
          onChange={(value) => handleChange("description", value)}
          // errorMessage={error?.description}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          pt: 2,
          pl: 1,
          paddingBottom: "20px",
          marginLeft: "10px",
          px: pathname == "/userCohorts-create" ? "10px" : "0px",
        }}
      >
        <Button
          color="inherit"
          //   onClick={handleBack}
          sx={{ mr: 1 }}
          style={{
            display: cancelButtonHide ? "none" : "visible",
            backgroundColor: "white",
            color: "black",
            padding: "8px 30px",
            marginRight: "20px",
          }}
          variant="outlined"
          size="small"
        >
          Cancel
        </Button>
        <Button
          style={{
            backgroundColor: "black",
            color: "white",
            padding: "8px 30px",
          }}
          variant="contained"
          size="small"
          onClick={handleSubmit}
          sx={{ mr: 1 }}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default UserCohortsCreate;
