import React from "react";
import FilterWrapper from "./FilterWrapper";
import FilterHeader from "./FilterHeader";
import CustomAccordion from "./CustomAccordion";
import ReusableRadioGroup from "./ReusableRadioGroup";
import { Box, Button } from "@mui/material";
import FilterClearDoneButton from "./FilterClearDoneButton";
import FormContainer from "../../UIComponents/MultiSelectChechboxSearch/FormContainer";
import FilteredResults from "../FilteredResults";
// import { Padding } from "@mui/icons-material";

const CustomFilter = ({
  onCLose = () => {},
  filterOptions,
  handleClear = () => {},
  isDispatchRadio = false,
  handleFilterState,
  handleDoneButton,
}) => {
  if (!filterOptions) return "--";
const handleRemove = (name, value, status)=>{
const checked = false;
  if(!name&&!value) return
  handleFilterState(name,value,checked);
}
  return (
    <FilterWrapper>
      <FilterHeader onClose={onCLose} />
      <Box p={1} >
      <FilteredResults options={filterOptions} handleRemove={handleRemove} status="clear" />
      </Box>
      <FilterClearDoneButton
        onDone={() => {
          handleDoneButton();
          onCLose();
        }}
        onClear={handleClear}
      />

      {filterOptions.length > 0 &&
        filterOptions.map(
          ({ state, name, options, summaryText, date, time }, index) => (
            <CustomAccordion summary={summaryText} key={index}>
              {options?.length >= 6 && (
                <FormContainer
                  options={options}
                  state={state}
                  handleChange={handleFilterState}
                  name={name}
                />
              )}

              {options?.length < 6 && (
                <ReusableRadioGroup
                  value={state}
                  name={name}
                  onChange={handleFilterState}
                  options={options}
                  date={date}
                  time={time}
                  state={state}
                  isDispatch={isDispatchRadio}
                />
              )}
              <Button
                variant="text"
                sx={{
                  color: "#000",
                  marginTop: "10px",
                  cursor: "pointer",
                  fontFamily: "Proxima Nova A",
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "12px",
                  letterSpacing: "0em",
                  textAlign: "left",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  if (date && date?.customDateState) {
                    if (
                      date.customDateState.startDate ||
                      date.customDateState.endDate
                    ) {
                      date.setState({ startDate: "", endDate: "" });
                    }
                  }

                  if (time && time.timeState) {
                    if (time.timeState.startTime || time.timeState.endTime) {
                      time.setState({ startTime: "", endTime: "" });
                    }
                  }
                  if (state) {
                    handleFilterState(name, [], false, "clear");
                  }
                }}
              >
                Clear
              </Button>
            </CustomAccordion>
          )
        )}
    </FilterWrapper>
  );
};

export default CustomFilter;
