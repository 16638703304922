import React from "react";
import { Box } from "@mui/material";

const BoldLabel = ({ label,color="#1D1F2C",mt="20px",mb="0px" }) => {
  return (
    <Box
      sx={{
        color: color,
        fontFamily: "Lato",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "16px",
        textTransform: "capitalize",
        textAlign:'left',
        marginTop:mt,
        marginBottom:mb,
      }}
    >
      {label}
    </Box>
  );
};

export default BoldLabel;
