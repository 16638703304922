import React, { useEffect, useRef, useState } from "react";
import { getToken } from "../../utils/token";
import axios from "axios";
import { Avatar, Box, Button, Stack, Typography } from "@mui/material";
import { BootstrapTooltip } from "../../utils/tooltip";
import usePermission from "../../utils/permissions";
import copyIcon from "../../assets/copy.svg";
import UiTable from "../UiTable";
import { toast, ToastContainer } from "react-toastify";
import CustomConfirmationDialog from "../../UIComponents/CustomConfirmationDialog";
import SearchBar from "../../UIComponents/SearchBar";
import exportImg from "../../assets/export.svg";
import plus from "../../assets/Plus.svg";
import documentsvg from "../../assets/Frame.svg";
import { useNavigate } from "react-router-dom";
import deleteIcon from "../../assets/delete.png";
import QueryInput from "../QueryInput";
const TData = () => {
  return [
    { key: "id", displayName: "Offer ID", flag: true },
    { key: "name", displayName: "Offer Name", flag: true },
    { key: "game_type", displayName: "Game Type", flag: true },
    { key: "reward_currency", displayName: "Reward Currency", flag: true },
    { key: "offer_status", displayName: "Offer Status", flag: true },
    { key: "duration", displayName: "Duration", flag: true },
    { key: "visibility", displayName: "Visibility", flag: true },
    // { key: "category_id", displayName: "Category", flag: false },
    // { key: "subcategory_id", displayName: "Subcategory", flag: false },
    // { key: "merchant_id", displayName: "Merchant", flag: false },
  ];
};

const titleStyle = {
    fontFamily: "Lato",
    fontSize: 20,
    fontWeight: 600,
    lineHeight: "24px",
    letterSpacing: "0em",
    color: "#0F1828",
  };
  
  const subTitle = {
    fontFamily: "Lato",
    width: "325px",
    fontSize: 16,
    fontWeight: 400,
    lineHeight: "21px",
    textAlign: "center",
    letterSpacing: "0em",
    color: "#0F182899",
  };

export default function TransactionsPage() {
  const [transactions, setTransactions] = useState({
    data: [],
    paginationModel: {
      pageSize: 10,
      page: 0,
    },
    totalCount: 0,
    allOfferCount: 0,
    pendingOfferCount: 0,
    denyOfferCount: 0,
  });
  const [loading, setLoading] = useState(false);
  const [offerStatus, setOfferStatus] = useState(false);
  const [noOptions, setNoOptions] = useState(false);
  const [editData, setEditData] = useState(false);
  const [message, setMessage] = useState("");
  const [search, setSearch] = useState("");
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const token = getToken();
  const permission = usePermission("/view-transactions");
  const IdRef = useRef("");
  const navigate = useNavigate();

  console.log(permission);

  const getAllTransactions = async () => {
    setLoading(true);
    const body = {
      page: transactions?.paginationModel.page + 1,
      itemsPerPage: transactions?.paginationModel.pageSize,
      ...(search && {externalCustomerId: search }),
    };
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.post(`${baseUrl}/api/transactions`, body, {
        headers,
      });
      console.log(response.data);
      const formateData = response?.data?.data.map((e) => {
        return {
          transaction_id: e?.transaction_id,
          externalCustomerId: e?.externalCustomerId,
          product_code: e?.product_code,
          merchant_code: e?.merchant_code,
          offer_name: e?.offer_name,
          points_issued: e?.points_issued,
          reward_currency: e?.reward_currency,
          status: e?.status,
          offer_description: e?.offer_description,
          offer_type: e?.offer_type,
          offer_id: e?.offer_id,
          initiative_code: e?.initiative_code,
          category_code: e?.category_code,
          subcategory_code: e?.subcategory_code,
        };
      });

      setTransactions((prev) => ({
        ...prev,
        data: formateData ?? [],
        totalCount: response?.data?.ledgerCount ?? 0,
      }));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  function handleStatusChangeByID(id) {
    if (!id) return;

    const filterData = transactions.data.find((e) => e.id === id);
    console.log(filterData, "filterData");
    // toast.success("Status Changed Successfully");
    const url = `${baseUrl}/api/admin/offer/${id}`;
    axios
      .patch(
        url,
        {
          status: filterData?.offer_status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setOfferStatus((prev) => !prev);
        toast.success("offer status changed successfully");
      })
      .catch((err) => {
        console.log(
          "error while status updating",
          err?.response?.data?.message
        );
        toast.error(err?.response?.data?.message);
      });
  }

  const exportToCSV = () => {
    if (transactions.data.length === 0) {
      toast.error("No data to export");
      return;
    }

    // Generate headers dynamically
    const headers = Object.keys(transactions.data[0]).filter(
      (key) => key !== "action"
    );

    // Convert header keys to display-friendly format
    const displayHeaders = headers.map((header) =>
      header
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ")
    );

    const csvData = transactions.data.map((offer) =>
      headers.map((header) => {
        if (header === "visibility") {
          return offer[header] ? "Visible" : "Hidden";
        }
        return offer[header];
      })
    );

    const csvContent = [
      displayHeaders.join(","),
      ...csvData.map((row) => row.join(",")),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "rule_data.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  const handlePaginationModel = (value) => {
    if (!value) return;
    setTransactions((prev) => ({ ...prev, paginationModel: value }));
  };
  const handleCopy = () => {};
  const handleSearch = () => {
    if (search.length > 0) {
      setNoOptions(true);
      getAllTransactions();
      // setSearchFlag(prev=>!prev)
    } else {
      setNoOptions(false);
      setTransactions((prev) => ({ ...prev, data: [] }));
    }
  };


  const columns = [
    {
      field: "transaction_id",
      headerName: "Transaction ID",
      minWidth: 100,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Box
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              NA
            </Box>
          );
        }
        return (
          <Box
            fontFamily="Lato"
            fontWeight={400}
            fontSize="12px"
            lineHeight="14.4px"
            letterSpacing="2%"
            color="#043A87"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace={"nowrap"}
          >
            {params?.value}
          </Box>
        );
      },
    },
    {
      field: "externalCustomerId",
      headerName: "Customer#",

      minWidth: 120,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Box
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              NA
            </Box>
          );
        }

        return (
          <BootstrapTooltip title={params.value}>
            <Box
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
              color="#043A87"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              {params?.value}
            </Box>
          </BootstrapTooltip>
        );
      },
    },
    {
      field: "product_code",
      headerName: "Product Code",
      minWidth: 100,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Box
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              NA
            </Box>
          );
        }

        return (
          <Box
            fontFamily="Lato"
            fontWeight={400}
            fontSize="12px"
            lineHeight="14.4px"
            letterSpacing="2%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace={"nowrap"}
          >
            {params?.value}
          </Box>
        );
      },
    },
    {
      field: "merchant_code",
      headerName: "Merchant Code",
      minWidth: 120,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Box
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              NA
            </Box>
          );
        }

        return (
          <Box
            fontFamily="Lato"
            fontWeight={400}
            fontSize="12px"
            lineHeight="14.4px"
            letterSpacing="2%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace={"nowrap"}
          >
            {params?.value}
          </Box>
        );
      },
    },

    {
      field: "offer_name",
      headerName: "Offer Name",
      minWidth: 150,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Box
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              NA
            </Box>
          );
        }
        return (
          <BootstrapTooltip title={params.value}>
            <Box
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              {params?.value}
            </Box>
          </BootstrapTooltip>
        );
      },
    },
    {
      field: "points_issued",
      headerName: "Points Issued",
      minWidth: 100,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Box
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              NA
            </Box>
          );
        }

        return (
          <Box
            fontFamily="Lato"
            fontWeight={400}
            fontSize="12px"
            lineHeight="14.4px"
            letterSpacing="2%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace={"nowrap"}
          >
            {params?.value}
          </Box>
        );
      },
    },

    {
      field: "reward_currency",
      headerName: "Reward Currency",
      minWidth: 120,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Box
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              NA
            </Box>
          );
        }
        let phoneNumber = params.value;
        let number = phoneNumber.slice(0, 3) + " " + phoneNumber.slice(3);
        return (
          <Typography
            fontFamily="Lato"
            fontWeight={400}
            fontSize="12px"
            lineHeight="14.4px"
            letterSpacing="2%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace={"nowrap"}
          >
            {params.value}
          </Typography>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 120,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Box
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              NA
            </Box>
          );
        }

        return (
          <Box
            fontFamily="Lato"
            fontWeight={400}
            fontSize="12px"
            lineHeight="14.4px"
            letterSpacing="2%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace={"nowrap"}
          >
            {params?.value}
          </Box>
        );
      },
    },
    {
      field: "offer_description",
      headerName: "Offer Description",
      minWidth: 120,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Box
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              NA
            </Box>
          );
        }

        return (
          <Box
            fontFamily="Lato"
            fontWeight={400}
            fontSize="12px"
            lineHeight="14.4px"
            letterSpacing="2%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace={"nowrap"}
          >
            {params?.value}
          </Box>
        );
      },
    },
    {
      field: "offer_type",
      headerName: "Offer Type",
      minWidth: 120,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Box
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              NA
            </Box>
          );
        }

        return (
          <Box
            fontFamily="Lato"
            fontWeight={400}
            fontSize="12px"
            lineHeight="14.4px"
            letterSpacing="2%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace={"nowrap"}
          >
            {params?.value}
          </Box>
        );
      },
    },
    {
      field: "offer_id",
      headerName: "Offer Id",
      minWidth: 120,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Box
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              NA
            </Box>
          );
        }

        return (
          <Box
            fontFamily="Lato"
            fontWeight={400}
            fontSize="12px"
            lineHeight="14.4px"
            letterSpacing="2%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace={"nowrap"}
          >
            {params?.value}
          </Box>
        );
      },
    },
    {
      field: "initiative_code",
      headerName: "Initiative Code",
      minWidth: 120,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Box
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              NA
            </Box>
          );
        }

        return (
          <Box
            fontFamily="Lato"
            fontWeight={400}
            fontSize="12px"
            lineHeight="14.4px"
            letterSpacing="2%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace={"nowrap"}
          >
            {params?.value}
          </Box>
        );
      },
    },
    {
      field: "category_code",
      headerName: "Category Code",
      minWidth: 150,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Box
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              NA
            </Box>
          );
        }

        return (
          <Box
            fontFamily="Lato"
            fontWeight={400}
            fontSize="12px"
            lineHeight="14.4px"
            letterSpacing="2%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace={"nowrap"}
          >
            {params?.value}
          </Box>
        );
      },
    },
    {
      field: "subcategory_code",
      headerName: "Subcategory Code",
      minWidth: 120,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Box
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              NA
            </Box>
          );
        }

        return (
          <Box
            fontFamily="Lato"
            fontWeight={400}
            fontSize="12px"
            lineHeight="14.4px"
            letterSpacing="2%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace={"nowrap"}
          >
            {params?.value}
          </Box>
        );
      },
    },
  ];

  console.log(transactions, "transactions");

//   useEffect(() => {
//     let debounceTimer;
//     debounceTimer = setTimeout(() => {
//       getAllTransactions();
//     }, 800);

//     return () => {
//       clearTimeout(debounceTimer);
//     };
//   }, [search]);

  useEffect(() => {
    getAllTransactions();
  }, [
    offerStatus,
    transactions?.paginationModel?.page,
    transactions?.paginationModel?.pageSize,
  ]);

  return (
    <Box sx={{ padding: "20px" }}>
      <ToastContainer />
      <Box
        sx={{ display: "flex", justifyContent: "space-between", mb: "16px" }}
      >
        {/* <SearchBar
          value={search}
          setState={setSearch}
          placeholder={"Search here..."}
        /> */}
               <QueryInput
              placeholder={"Search user by User#"}
              value={search}
              setState={setSearch}
              handleSearch={handleSearch}
              hitEnter={true}
            />
        <Box sx={{display:noOptions && transactions.data?.length>0 ?"block":"none"}}>
          <Button
            sx={{
              height: "30px",
              width: "106px",
              backgroundColor: "#E8F0F9",
            }}
          >
            <img
              style={{ height: "16px", width: "16px" }}
              src={exportImg}
              alt=""
            />
            <Typography
              sx={{
                fontFamily: "Lato",
                fontWeight: 500,
                fontSize: "12px",
                lineHeight: "14.4px",
                marginLeft: "6px",
                textTransform: "capitalize",
              }}
              onClick={exportToCSV}
            >
              Export Data
            </Typography>
          </Button>
        </Box>
      </Box>
      {/* Table */}
      <Box>
      <Box>
        {!noOptions ? (
          <Stack
            spacing={3}
            justifyContent={"center"}
            alignItems={"center"}
            marginTop={"30vh"}
          >
            {/* {!search && ( */}
            <Avatar
              sx={{
                borderRadius: "0",
              }}
              src={documentsvg}
            />
            {/* )} */}
            <Stack spacing={2} justifyContent={"center"} alignItems={"center"}>
              {/* {!search ? ( */}
              <>
                <Typography sx={titleStyle}>Search User#</Typography>
                <Typography sx={subTitle}>
                  Simply type the user# into the search box and instantly
                  retrieve the data.
                </Typography>
              </>
            </Stack>
          </Stack>
        ) : noOptions && transactions?.data.length > 0 ? (
            <Box>
            <UiTable
              row={transactions?.data || []}
              alternaviteIdName={"transaction_id"}
              columns={columns || []}
              totalCount={transactions?.totalCount}
              paginationModel={transactions?.paginationModel}
              setPaginationModel={handlePaginationModel}
              //   handleRowClick={handleRowClick}
              loading={loading}
            />
            <CustomConfirmationDialog
              open={editData}
              setOpen={setEditData}
              deleteById={handleStatusChangeByID}
              prop={IdRef.current}
              status="delete"
              message={message}
            />
          </Box>
        ) : (
          <Stack
            spacing={2}
            justifyContent={"center"}
            alignItems={"center"}
            marginTop={"30vh"}
          >
            <Typography sx={titleStyle}>No Data Found</Typography>
          </Stack>
        )}
      </Box>


   
      </Box>
    </Box>
  );
}
