import React, { useEffect, useRef, useState } from "react";
import { getToken } from "../../../utils/token";
import axios from "axios";
import { Box, Button, Typography } from "@mui/material";
import { BootstrapTooltip } from "../../../utils/tooltip";
import { MdPreview } from "react-icons/md";
import usePermission from "../../../utils/permissions";
import CustomSwitch from "../../../UIComponents/CustomSwitch";
import { FaPauseCircle, FaPlayCircle, FaPlus } from "react-icons/fa";
import copyIcon from "../../../assets/copy.svg";
import UiTable from "../../UiTable";
import preview from "../../../assets/eye.svg";
import copy from "../../../assets/copy.svg";
import play from "../../../assets/play.svg";
import pause from "../../../assets/pause.svg";
import { toast, ToastContainer } from "react-toastify";
import CustomConfirmationDialog from "../../../UIComponents/CustomConfirmationDialog";
import SearchBar from "../../../UIComponents/SearchBar";
import exportImg from "../../../assets/export.svg";
import plus from "../../../assets/Plus.svg";
import { Navigate, useNavigate } from "react-router-dom";
import deleteIcon from "../../../assets/delete.png";
const TData = () => {
  return [
    { key: "id", displayName: "Offer ID", flag: true },
    { key: "name", displayName: "Offer Name", flag: true },
    { key: "game_type", displayName: "Game Type", flag: true },
    { key: "reward_currency", displayName: "Reward Currency", flag: true },
    { key: "offer_status", displayName: "Offer Status", flag: true },
    { key: "duration", displayName: "Duration", flag: true },
    { key: "visibility", displayName: "Visibility", flag: true },
    // { key: "category_id", displayName: "Category", flag: false },
    // { key: "subcategory_id", displayName: "Subcategory", flag: false },
    // { key: "merchant_id", displayName: "Merchant", flag: false },
  ];
};

export default function ViewCohortPage() {
  const [cohorts, setCohorts] = useState({
    data: [],
    paginationModel: {
      pageSize: 10,
      page: 0,
    },
    totalCount: 0,
    allOfferCount: 0,
    pendingOfferCount: 0,
    denyOfferCount: 0,
  });
  const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState(true);
  const [offerStatus, setOfferStatus] = useState(false);
  const [editData, setEditData] = useState(false);
  const [message, setMessage] = useState("");
  const [search, setSearch] = useState("");
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const token = getToken();
  const permission = usePermission("/view-cohorts");
  const IdRef = useRef("");
  const navigate = useNavigate();

  console.log(permission);

  const getAllRules = async () => {
    setLoading(true);
    const body = {
      page: cohorts?.paginationModel.page + 1,
      pageSize: cohorts?.paginationModel.pageSize,
      ...(search && { search }),
    };
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.post(`${baseUrl}/api/collections`, body, {
        headers,
      });
      console.log(response.data);
      let formateData = response?.data?.data?.map((e) => {
        return {
          id: e.id,
          name: e.name,
          description: e.description,
          copyBtn: copyIcon,
          deleteBtn: deleteIcon,
        };
      });
      setCohorts((prev) => ({
        ...prev,
        data: formateData ?? [],
        totalCount: response?.data?.paginationInfo.totalItems ?? 0,
      }));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  function handleStatusChangeByID(id) {
    if (!id) return;

    const filterData = cohorts.data.find((e) => e.id === id);
    console.log(filterData, "filterData");
    // toast.success("Status Changed Successfully");
    const url = `${baseUrl}/api/admin/offer/${id}`;
    axios
      .patch(
        url,
        {
          status: filterData?.offer_status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setOfferStatus((prev) => !prev);
        toast.success("offer status changed successfully");
      })
      .catch((err) => {
        console.log(
          "error while status updating",
          err?.response?.data?.message
        );
        toast.error(err?.response?.data?.message);
      });
  }

  const exportToCSV = () => {
    if (cohorts.data.length === 0) {
      toast.error("No data to export");
      return;
    }

    // Generate headers dynamically
    const headers = Object.keys(cohorts.data[0]).filter(
      (key) => key !== "action"
    );

    // Convert header keys to display-friendly format
    const displayHeaders = headers.map((header) =>
      header
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ")
    );

    const csvData = cohorts.data.map((offer) =>
      headers.map((header) => {
        if (header === "visibility") {
          return offer[header] ? "Visible" : "Hidden";
        }
        return offer[header];
      })
    );

    const csvContent = [
      displayHeaders.join(","),
      ...csvData.map((row) => row.join(",")),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "cohort_data.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  const handlePaginationModel = (value) => {
    if (!value) return;
    setCohorts((prev) => ({ ...prev, paginationModel: value }));
  };
  function handleCopy(e, params) {
    e.stopPropagation();
    const data = cohorts.data.find((e) => e.id === params.id);
    console.log(params?.row?.name,"data")
    if (params?.row?.name) {
      const  name  = params?.row?.name;
      navigator.clipboard
        .writeText(name)
        .then(() => {
          console.log(`Copied: ${name}`);
          toast.success("Cohorts name copied successfully");
        })
        .catch((error) => {
          console.error("Copy failed: ", error);
          toast.error("Failed to copy offer name");
        });
    }
  }

  const columns = [
    //  {
    //   field: "id",
    //   headerName: "Cohorts ID",
    //   // flex:1.5,
    //   minWidth: 350,
    //   renderCell: (params) => {
    //     if (!params?.value) {
    //       return "NA";
    //     }
    //     return (
    //       <BootstrapTooltip title={params.value}>
    //         <Box
    //           paddingRight="auto"
    //           fontFamily="ABeeZee"
    //           fontWeight={400}
    //           fontSize="13px"
    //           lineHeight="15.37px"

    //           letterSpacing="2%"
    //                 overflow="hidden"
    //           textOverflow="ellipsis"
    //           whiteSpace={"nowrap"}
    //         >
    //           {params?.value}
    //         </Box>
    //       </BootstrapTooltip>
    //     );
    //   },
    // },
    {
      field: "name",
      headerName: "Cohorts Name",
      // flex:1.5,
      minWidth: 500,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Typography
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
            >
              NA
            </Typography>
          );
        }

        return (
          <BootstrapTooltip title={params.value}>
            <Box
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              {params?.value}
            </Box>
          </BootstrapTooltip>
        );
      },
    },

    {
      field: "description",
      headerName: "Cohorts Description",
      minWidth: 450,
      // flex:2.5,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Typography
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
            >
              NA
            </Typography>
          );
        }

        return (
          <BootstrapTooltip title={params.value}>
            <Box
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              {params?.value}
            </Box>
          </BootstrapTooltip>
        );
      },
    },
    // {
    //   field: "csv_file",
    //   headerName: "CSV File",
    //   minWidth: 250,
    //   renderCell: (params) => {
    //     if (!params?.value) {
    //       return "NA";
    //     }

    //     return <Box>{params?.value}</Box>;
    //   },
    // },

    {
      field: "copyBtn",
      headerName: "Action",
      width: 150,
      // flex: 1,
      renderCell: (params) => {
        //   if (!params?.value) {
        //     return "NA";
        //   }

        return (
          <Box
            // sx={{
            //   width: "100%",
            //   height: "100%",
            //   display: "grid",
            //   placeItems: "center",
            // }}
            marginLeft="5px"
            onClick={(e) => handleCopy(e, params)}
          >
            <BootstrapTooltip title="Copy Name">
              <img
                style={{ height: "18px", width: "18px" }}
                src={params?.value}
                alt="copy"
              />
            </BootstrapTooltip>
          </Box>
        );
      },
    },
    // {
    //   field: "deleteBtn",
    //   headerName: "",
    //   width: 150,
    //   flex: 1,
    //   renderCell: (params) => {
    //     //   if (!params?.value) {
    //     //     return "NA";
    //     //   }

    //     return (
    //       <Box
    //         sx={{
    //           width: "100%",
    //           height: "100%",
    //           display: "grid",
    //           placeItems: "center",
    //         }}
    //         onClick={(e) => handleDelete(e, params)}
    //       >
    //         {/* {params?.value} */}
    //         <img
    //           style={{ height: "24px", width: "24px" }}
    //           src={params?.value}
    //           alt="delete"
    //         />
    //       </Box>
    //     );
    //   },
    // },
  ];

  console.log(cohorts, "cohorts");

  useEffect(() => {
    let debounceTimer;

    if(flag){
      setFlag(false)
    }else{
      debounceTimer = setTimeout(() => {
        getAllRules();
      }, 800);
    }

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [search]);

  useEffect(() => {
    getAllRules();
  }, [
    offerStatus,
    cohorts?.paginationModel?.page,
    cohorts?.paginationModel?.pageSize,
  ]);

  return (
    <Box sx={{ padding: "20px" }}>
      <ToastContainer />
      <Box
        sx={{ display: "flex", justifyContent: "space-between", mb: "16px" }}
      >
        <SearchBar
          value={search}
          setState={setSearch}
          placeholder={"Search here..."}
        />
        <Box>
          <Button
            sx={{
              height: "30px",
              width: "106px",
              backgroundColor: "#E8F0F9",
            }}
          >
            <img
              style={{ height: "16px", width: "16px" }}
              src={exportImg}
              alt=""
            />
            <Typography
              sx={{
                fontFamily: "Lato",
                fontWeight: 500,
                fontSize: "12px",
                lineHeight: "14.4px",
                marginLeft: "6px",
                textTransform: "capitalize",
              }}
              onClick={exportToCSV}
            >
              Export Data
            </Typography>
          </Button>

          <Button
            sx={{
              width: "143px",
              height: "30px",
              backgroundColor: "#003D86",
              borderRadius: "6px",
              marginLeft: "12px",
              color: "#FFFFFF",
              "&:hover": {
                backgroundColor: "#003D86",
              },
            }}
            onClick={() => navigate("/userCohorts-create")}
          >
            <img style={{ height: "16px", width: "16px" }} src={plus} alt="" />
            <Typography
              sx={{
                fontFamily: "Lato",
                fontSize: "12px",
                lineHeight: "14.4px",
                fontWeight: 500,
                marginLeft: "6px",
                textTransform: "capitalize",
              }}
            >
              Create New Cohort
            </Typography>
          </Button>
        </Box>
      </Box>
      {/* Table */}
      <Box>
        <UiTable
          row={cohorts?.data || []}
          columns={columns || []}
          totalCount={cohorts?.totalCount}
          paginationModel={cohorts?.paginationModel}
          setPaginationModel={handlePaginationModel}
          //   handleRowClick={handleRowClick}
          loading={loading}
        />
        <CustomConfirmationDialog
          open={editData}
          setOpen={setEditData}
          deleteById={handleStatusChangeByID}
          prop={IdRef.current}
          status="delete"
          message={message}
        />
      </Box>
    </Box>
  );
}
