import React from "react";
import { Typography, Box } from "@mui/material";
import MuiInput from "../../UIComponents/MuiInput";
const OfferLevelLimit = ({ data, onChange,errorMessage }) => {
  
  const handleData = (key, value) => {
    onChange({ ...data, [key]: value });
  };

  return (
    <>
      <Typography
        sx={{
          fontFamily: "Inter",
          fontSize: "20px",
          fontWeight: 500,
          lineHeight: "24px",
          letterSpacing: "0em",
          textAlign: "left",
          mb: "24px",
        }}
      >
        Offer Budget
      </Typography>
      <Typography
        sx={{
          fontFamily: "Inter",
          fontSize: "17px",
          fontWeight: 500,
          lineHeight: "21px",
          letterSpacing: "0em",
          textAlign: "left",
          mb: "15px",
        }}
      >
        1. Maximum Points
      </Typography>
      <Typography
        sx={{
          fontFamily: "Inter",
          fontSize: "15px",
          fontWeight: 400,
          lineHeight: "18.15px",
          textAlign: "left",
          color: "#565656",
          mb: "20px",
        }}
      >
        Maximum points that can be issued against this offer
      </Typography>
      <Box sx={{ display: "flex", gap: "29px", mb: "24px", flexWrap: "wrap" }}>

        <Box sx={{ maxWidth: "136px" }}>
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontSize: "14px",
              fontWeight: 600,
              lineHeight: "17px",
              textAlign: "left",
              color: "#6F747E",
              letterSpacing: "0em",
              mb: "10px",
            }}
          >
            Daily
          </Typography>
          <MuiInput
           type="number"
            placeholder="value"
            value={data?.fairUsageOfRewardIssuance?.daily}
            onChange={(value) =>
              handleData("fairUsageOfRewardIssuance", {
                ...data?.fairUsageOfRewardIssuance,
                daily: value,
              })
            }
            errorMessage={errorMessage?.fairUsageOfRewardIssuance?.daily}
            editBoolean={data?.fairUsageOfRewardIssuance?.previous_daily??false}
          />
        </Box>
        <Box sx={{ maxWidth: "136px" }}>
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontSize: "14px",
              fontWeight: 600,
              lineHeight: "17px",
              textAlign: "left",
              color: "#6F747E",
              letterSpacing: "0em",
              mb: "10px",
            }}
          >
            Weekly
          </Typography>
          <MuiInput
           type="number"
            placeholder="value"
            value={data?.fairUsageOfRewardIssuance?.weekly}
            onChange={(value) =>
              handleData("fairUsageOfRewardIssuance", {
                ...data?.fairUsageOfRewardIssuance,
                weekly: value,
              })
            }
            errorMessage={errorMessage?.fairUsageOfRewardIssuance?.weekly}
            editBoolean={data?.fairUsageOfRewardIssuance?.previous_weekly??false}
          />
        </Box>
        <Box sx={{ maxWidth: "136px" }}>
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontSize: "14px",
              fontWeight: 600,
              lineHeight: "17px",
              textAlign: "left",
              color: "#6F747E",
              letterSpacing: "0em",
              mb: "10px",
            }}
          >
            Monthly
          </Typography>
          <MuiInput
           type="number"
            placeholder="value"
            value={data?.fairUsageOfRewardIssuance?.monthly}
            onChange={(value) =>
              handleData("fairUsageOfRewardIssuance", {
                ...data?.fairUsageOfRewardIssuance,
                monthly: value,
              })
            }
            errorMessage={errorMessage?.fairUsageOfRewardIssuance?.monthly}
            editBoolean={data?.fairUsageOfRewardIssuance?.previous_monthly??false}
          />
        </Box>
        <Box sx={{ maxWidth: "136px" }}>
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontSize: "14px",
              fontWeight: 600,
              lineHeight: "17px",
              textAlign: "left",
              color: "#6F747E",
              letterSpacing: "0em",
              mb: "10px",
            }}
          >
            Total
          </Typography>
          <MuiInput
          type="number"
            placeholder="value"
            value={data?.fairUsageOfRewardIssuance?.total}
            onChange={(value) =>
              handleData("fairUsageOfRewardIssuance", {
                ...data?.fairUsageOfRewardIssuance,
                total: value,
              })
            }
            errorMessage={errorMessage?.fairUsageOfRewardIssuance?.total}
            editBoolean={data?.fairUsageOfRewardIssuance?.previous_total??false}
          />
        </Box>
      </Box>
      {/* <Typography
        sx={{
          fontFamily: "Inter",
          fontSize: "17px",
          fontWeight: 500,
          lineHeight: "21px",
          letterSpacing: "0em",
          textAlign: "left",
          mb: "15px",
        }}
      >
        2. Fair usage of reward redemption
      </Typography>
      <Typography
        sx={{
          fontFamily: "Inter",
          fontSize: "15px",
          fontWeight: 400,
          lineHeight: "18.15px",
          textAlign: "left",
          color: "#565656",
          mb: "20px",
        }}
      >
        Max number of scratch cards that can be redeemed by a customer
      </Typography>
      <Box sx={{ display: "flex", gap: "29px", mb: "42px", flexWrap: "wrap" }}>
        <Box sx={{ maxWidth: "136px" }}>
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontSize: "14px",
              fontWeight: 600,
              lineHeight: "17px",
              textAlign: "left",
              color: "#6F747E",
              letterSpacing: "0em",
              mb: "10px",
            }}
          >
            Total
          </Typography>
          <MuiInput
           type="number"
            placeholder="value"
            value={data?.fairUsageOfRewardRedemption?.total}
            onChange={(value) =>
              handleData("fairUsageOfRewardRedemption", {
                ...data?.fairUsageOfRewardRedemption,
                total: value,
              })
            }
            errorMessage={errorMessage?.fairUsageOfRewardRedemption?.total}

          />
        </Box>
        <Box sx={{ maxWidth: "136px" }}>
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontSize: "14px",
              fontWeight: 600,
              lineHeight: "17px",
              textAlign: "left",
              color: "#6F747E",
              letterSpacing: "0em",
              mb: "10px",
            }}
          >
            Daily
          </Typography>
          <MuiInput
           type="number"
            placeholder="value"
            value={data?.fairUsageOfRewardRedemption?.daily}
            onChange={(value) =>
              handleData("fairUsageOfRewardRedemption", {
                ...data?.fairUsageOfRewardRedemption,
                daily: value,
              })
            }
            errorMessage={errorMessage?.fairUsageOfRewardRedemption?.daily}

          />
        </Box>
        <Box sx={{ maxWidth: "136px" }}>
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontSize: "14px",
              fontWeight: 600,
              lineHeight: "17px",
              textAlign: "left",
              color: "#6F747E",
              letterSpacing: "0em",
              mb: "10px",
            }}
          >
            Weekly
          </Typography>
          <MuiInput
           type="number"
            placeholder="value"
            value={data?.fairUsageOfRewardRedemption?.weekly}
            onChange={(value) =>
              handleData("fairUsageOfRewardRedemption", {
                ...data?.fairUsageOfRewardRedemption,
                weekly: value,
              })
            }
            errorMessage={errorMessage?.fairUsageOfRewardRedemption?.weekly}

          />
        </Box>
        <Box sx={{ maxWidth: "136px" }}>
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontSize: "14px",
              fontWeight: 600,
              lineHeight: "17px",
              textAlign: "left",
              color: "#6F747E",
              letterSpacing: "0em",
              mb: "10px",
            }}
          >
            Monthly
          </Typography>
          <MuiInput
           type="number"
            placeholder="value"
            value={data?.fairUsageOfRewardRedemption?.monthly}
            onChange={(value) =>
              handleData("fairUsageOfRewardRedemption", {
                ...data?.fairUsageOfRewardRedemption,
                monthly: value,
              })
            }
            errorMessage={errorMessage?.fairUsageOfRewardRedemption?.monthly}
          />
        </Box>
      </Box> */}
    </>
  );
};

export default OfferLevelLimit;
