import React, { useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
// import { getAllRewardFunction } from "../../Redux/OfferReducer/action";

export default function Games({ setGameType, data, onChange, handleNext, handleBack,activeStep }) {
  const gameData = useSelector((store) => store?.OfferReducer?.allGames);
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const handleSelectGame = (game, id) => {
    setGameType(game);
    onChange({ ...data, gameName: game, game_type_id: id });
  };

  return (
    <Box>
      <Typography
        sx={{
          fontFamily: "Mulish",
          fontWeight: 600,
          fontSize: "18px",
          lineHeight: "23.4px",
          color: "#0F1828",
          mb: "20px",
          textAlign: "start",
        }}
      >
        Choose Game Type
      </Typography>
      <Box sx={{ display: "flex", gap: "20px", height: activeStep===2?"40vh":"30vh" }}>
        {gameData?.map((game, index) => {
          const isSelected = data?.gameName === game?.name;
          const isEditable = pathname.startsWith("/edit-offers") || pathname.startsWith("/preview-offer");
          const cursorStyle = (isEditable && isSelected) || !isEditable ? "pointer" : "default";

          return (
            <Box
              key={index}
              sx={{
                height: "160px",
                width: "23%",
                border: isSelected ? "3px dashed black" : "2px dashed #0000004D",
                borderRadius: "12px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                cursor: cursorStyle,
                gap: "12px",
              }}
              onClick={() => {
                if (pathname.startsWith("/edit-offers") || pathname.startsWith("/preview-offer")) {
                  if (data?.gameName === game?.name) {
                    handleSelectGame(game?.name, game?.id);
                  }
                } else {
                  handleSelectGame(game?.name, game?.id);
                }
              }}
            >
              <img
                src={game?.icon}
                style={{ height: "48px", width: "48px" }}
                alt={`${game?.name} Image`}
              />
              <Typography
                sx={{
                  fontFamily: "Mulish",
                  fontWeight: 600,
                  fontSize: "16px",
                  lineHeight: "20.08px",
                  color: "#272728",
                }}
              >
                {game?.name}
              </Typography>
            </Box>
          );
        })}
      </Box>
      <Box
        sx={{
          display:activeStep===2? "flex":"none",
          flexDirection: "row",
          pt: 2,
          paddingBottom: "40px",
        }}
      >
        <Button
          color="inherit"
          onClick={handleBack}
          sx={{ mr: 1 }}
          style={{
            backgroundColor: "white",
            color: "black",
            padding: "8px 30px",
            marginRight: "20px",
            fontFamily: "Mulish",
            fontWeight: "700",
            fontSize: "16px",
            lineHeight: "20.08px",
            borderRadius: "7px",
            height: "40px",
          }}
          variant="outlined"
          size="small"
        >
          Previous
        </Button>
        <Button
          style={{
            backgroundColor: "black",
            color: "white",
            padding: "8px 40px",
            fontFamily: "Mulish",
            fontWeight: "700",
            fontSize: "16px",
            lineHeight: "20.08px",
            borderRadius: "7px",
            height: "40px",
          }}
          variant="contained"
          size="small"
          onClick={handleNext}
          sx={{ mr: 1 }}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
}
