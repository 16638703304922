import React, { useEffect } from "react";
import Label from "../Label";
import MuiSelectAndSearch from "../MuiSelectAndSearch";
import { Box, Button, Typography } from "@mui/material";
import UserCohortsFileUpload from "../../components/userCohorts/UserCohortsFileUpload";
import MuiInput from "../MuiInput";
import SmallImageUpload from "../SmallImageUpload";
import sampleCsv from "../../assets/Voucher.csv";

const options = [
  { name: "Better Luck Next Time", id: "better_luck_next_time" },
  { name: "Voucher", id: "voucher" },
  { name: "Neu Coins", id: "neu_coins" },
  { name: "Digital Gold", id: "digital_gold" },
  { name: "Cashback", id: "cashback" },
];

export default function EditReward({
  data,
  handleStartFromScratch,
  onChange,
  rewards,
  field,
  setRewardTypeName = () => {},
  handleEditReward,
  rewardIndex,
  handleUpdateReward = ()=>{},
  handleCancelEditReward
}) {
  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = sampleCsv;
    link.download = "Voucher.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  console.log(rewards, "rewardsDetails");

  return (
    <Box>
      {rewards?.length > 0 &&
        rewards?.map((item, index) => (
          <Box
            mt={"15px"}
            sx={{ display: item?.mandatory ? "block" : "none" }}
            key={`reward-${item.backend_label}-${index}`}
          >
            {item?.input_type === "dropdown" ? (
              <Box sx={{ width: "400px" }}>
                <Label label={`${index + 1}. ${item.label}`} />
                <MuiSelectAndSearch
                  options={item?.options?.map((option) => ({
                    name: option?.label || option?.name,
                    id: option?.value || option?.id,
                    fields: option?.fields,
                  }))}
                  value={
                    data?.game?.[field]?.[rewardIndex]?.[item?.backend_label]
                  }
                  onChange={(event) => {
                    handleEditReward(item?.backend_label, event, rewardIndex);
                    if (index === 0) {
                      setRewardTypeName(event?.name);
                    }
                  }}
                  placeholder="--Select--"
                />
              </Box>
            ) : item?.input_type === "file_upload" ? (
              <Box sx={{ width: "400px" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    // px: "10px",
                  }}
                >
                  <Label label={`${index + 1}. ${item.label}`} />

                  <Typography
                    onClick={handleDownload}
                    style={{
                      fontFamily: "Mulish",
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "17.57px",
                      color: "#0F1828",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    Download Sample
                  </Typography>
                </Box>
                <UserCohortsFileUpload
                  data={
                    data?.game?.[field]?.[rewardIndex]
                  }
                  textVisibility={false}
                  onChange={(field, event) =>
                    handleEditReward(field, event, rewardIndex)
                  }
                  errorMessage={data?.game?.[field]?.[rewardIndex]?.csvError}
                />
              </Box>
            ) : item?.input_type === "input_box" ? (
              <Box sx={{ width: "400px" }}>
                <Label label={`${index + 1}. ${item.label}`} />
                <MuiInput
                  value={
                    data?.game?.[field]?.[rewardIndex]?.[item?.backend_label] ||
                    ""
                  }
                  onChange={(event) =>
                    handleEditReward(item?.backend_label, event, rewardIndex)
                  }
                  placeholder="40 Characters"
                />
              </Box>
            ) : item?.input_type === "image_upload" ? (
              <Box>
                <Label label={`${index + 1}. ${item.label}`} />
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: "20px",
                      py: "15px",
                    }}
                  >
                    {item?.options?.map((option, optionIndex) => (
                      <Box
                        key={optionIndex}
                        sx={{
                          width: "120px",
                          height: "100px",
                          backgroundColor: "#F4F4F4",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "10px",
                          border:
                            data?.game?.[field]?.[rewardIndex]?.rewardIcon ===
                            option
                              ? "2px solid #000000"
                              : "2px solid transparent",
                        }}
                        onClick={() =>
                          handleEditReward("rewardIcon", option, rewardIndex)
                        }
                      >
                        <img
                          style={{ height: "64px", width: "64px" }}
                          src={option}
                          alt=""
                        />
                      </Box>
                    ))}
                  </Box>

                  <Box
                    sx={{
                      height: "100px",
                      display: "flex",
                      alignItems: "center",
                      gap: "25px",
                    }}
                  >
                    <Typography>OR</Typography>
                    <Box>
                      <SmallImageUpload
                        value={
                          item?.options?.includes(data?.game?.[field]?.[rewardIndex]?.rewardIcon)?"":data?.game?.[field]?.[rewardIndex]?.rewardIcon  
                        }
                        onClick={() => {
                          if (
                            data?.game?.[field]?.[rewardIndex]
                              ?.rewardIconUploadUrl
                          ) {
                            handleEditReward(
                              "rewardIcon",
                              data?.game?.[field]?.[rewardIndex]
                                ?.rewardIconUploadUrl,
                              rewardIndex
                            );
                          }
                        }}
                        borderValue={
                          data?.game?.[field]?.[rewardIndex]?.rewardIcon
                        }
                        onChange={(event) =>
                          handleEditReward(
                            "rewardIconUpload",
                            event,
                            rewardIndex
                          )
                        }
                        handleImage={(value) =>
                          onChange({
                            ...data,
                            game: {
                              ...data.game,
                              [field]: data.game[field].map((item, index) => 
                                index === rewardIndex 
                                  ? {
                                      ...item,
                                      rewardIconUpload: value,
                                      rewardIconUploadUrl: value,
                                      rewardIconUploadId: value,
                                      rewardIcon: value,
                                    }
                                  : item
                              ),
                            },
                          })
                        }
                        image={
                          data?.game?.[field]?.[rewardIndex]
                            ?.rewardIconUploadUrl
                        }
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            ) : item?.input_type === "array" ? (
              <Box>
                <Label label={`${index + 1}. ${item.label}`} />

                <Box sx={{ display: "flex", gap: "10px" }}>
                  {(() => {
                    // const fields =
                    //   data?.addNewRewards?.[field]?.startFromScratch?.[
                    //     rewards?.[index - 1]?.backend_label
                    //   ]?.fields;
                    // const cashbackTypeName =
                    //   data?.addNewRewards?.[field]?.startFromScratch?.[
                    //     rewards?.[index - 1]?.backend_label
                    //   ]?.id || "anything";

                    const fields =
                    data?.game?.[field]?.[rewardIndex]?.[rewards?.[index - 1]?.backend_label]?.fields;
                  
                  const cashbackTypeName =
                  data?.game?.[field]?.[rewardIndex]?.[rewards?.[index - 1]?.backend_label]?.id || "anything";

                    console.log(cashbackTypeName, "cashbackTypeName");
                    if (!fields || fields.length === 0) {
                      return (
                        <Box sx={{ width: "400px" }}>
                          <MuiInput
                            key={`input-${cashbackTypeName}`}
                            value={
                              data?.game?.[field]?.[rewardIndex]?.[
                                cashbackTypeName
                              ] || ""
                            }
                            onChange={(event) =>
                              handleEditReward(
                                cashbackTypeName,
                                event,
                                rewardIndex
                              )
                            }
                            placeholder="Enter value"
                          />
                        </Box>
                      );
                    }

                    return fields.map((fieldItem, fieldIndex) => {
                      const key = `${cashbackTypeName}_${fieldItem?.backend_label}`;
                      return (
                        <Box
                          key={`field-${key}-${fieldIndex}`}
                          sx={{
                            width:
                              fields.length > 2
                                ? "150px"
                                : fields.length === 2
                                ? "195px"
                                : "400px",
                          }}
                        >
                          <MuiInput
                            key={`input-${key}`}
                            value={
                              data?.game?.[field]?.[rewardIndex]?.[key] || ""
                            }
                            onChange={(event) =>
                              handleEditReward(key, event, rewardIndex)
                            }
                            placeholder={fieldItem?.label}
                          />
                        </Box>
                      );
                    });
                  })()}
                </Box>
              </Box>
            ) : (
              <Label label={`${index + 1}. ${item.label}`} />
            )}
          </Box>
        ))}

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          pt: 2,
          // px: step === 1 ? "15px" : "0px",
          paddingBottom: "37px",
        }}
      >
        <Button
          color="inherit"
          onClick={handleCancelEditReward}
          sx={{ mr: 1 }}
          style={{
            backgroundColor: "white",
            color: "black",
            padding: "8px 30px",
            marginRight: "20px",
            fontFamily: "Mulish",
            fontWeight: "700",
            fontSize: "16px",
            lineHeight: "20.08px",
            borderRadius: "7px",
            height: "40px",
          }}
          variant="outlined"
          size="small"
        >
          Close
        </Button>
        <Button
          style={{
            backgroundColor: "black",
            color: "white",
            padding: "8px 40px",
            fontFamily: "Mulish",
            fontWeight: "700",
            fontSize: "16px",
            lineHeight: "20.08px",
            borderRadius: "7px",
            height: "40px",
          }}
          variant="contained"
          size="small"
          onClick={handleUpdateReward}
          sx={{ mr: 1 }}
        >
          Update
        </Button>
      </Box>
    </Box>
  );
}
