import React from "react"; // Import your FlexBetween component
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import FlexBetween from "../FlexBetween";

const FilterHeader = ({ onClose }) => {
  return (
    <FlexBetween
      sx={{
        p: "0.7rem 0.7rem",
        borderBottom: "1px solid #D9D9D9",
        marginTop: "62px",
      }}
    >
      <Typography
        variant="h6"
        sx={{
          fontFamily: "Proxima Nova A",
          fontSize: "20px",
          fontWeight: 600,
          lineHeight: "24px",
          letterSpacing: "0em",
          textAlign: "left",
          color: "#000000",
        }}
      >
        Filter
      </Typography>

      <IconButton onClick={onClose}>
        <CloseIcon sx={{ fill: "#949494", fontWeight: 600 }} />
      </IconButton>
    </FlexBetween>
  );
};

export default FilterHeader;
