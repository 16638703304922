import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Label from "../../../UIComponents/Label";
import MultiSelect from "../../../UIComponents/MultiSelect";
import { useDispatch, useSelector } from "react-redux";
import BoldLabel from "../../../UIComponents/BoldLevel";
import MuiInput from "../../../UIComponents/MuiInput";
import MuiTextarea from "../../../UIComponents/MuiTextarea";
import { MUIDateTimePicker } from "../../../UIComponents/MUIDateTimePicker";
import MuiSelectAndSearch from "../../../UIComponents/MuiSelectAndSearch";
import axios from "axios";
import NewImageUpload from "../../../UIComponents/NewImageUpload";
import ReactSlickCarousel from "../../ReactSlickCarousel";
import ViewAllDeals from "../../scratchcard/offer/ViewAllDeals";
import Deals from "../deals/Deals";
import { useNavigate } from "react-router-dom";
import {
  getInitiativeFunction,
  getMerchantFunction,
  getProductFunction,
} from "../../../Redux/OfferReducer/action";
import editLogo from "../../../assets/Pen.png";
import DaysInput from "../../../UIComponents/DaysInput";
const option = [
  {
    id: 1,
    name: "Option 1",
  },
  {
    id: 2,
    name: "Option 2",
  },
  {
    id: 3,
    name: "Option 3",
  },
];

export default function OfferDetailsPage({ showEdit,isEdit }) {
  const [loading,setLoading] = useState(false)
  const carouselSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
  };
  const [poster, setPoster] = useState("");
  const [index, setIndex] = useState(0);
  const token = useSelector((store) => store.AuthReducer.token);
  const BaseUrl = process.env.REACT_APP_API_BASE_URL;
  const data = useSelector(
    (store) => store.OfferReducer?.offerData?.offerDetails
  );

 
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const productOption = useSelector(
    (store) => store.OfferReducer?.productOption
  );
  const merchantOption = useSelector(
    (store) => store.OfferReducer?.merchantOption
  );
  const initiativeOption = useSelector(
    (store) => store.OfferReducer?.initiativeOption
  );
  const handleData = (key, value) => {
    if (key === "coverPhoto") {
      setLoading(true)
      const formData = new FormData();
      formData.append("file", value);
      axios
        .post(`${BaseUrl}/api/upload`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log("Upload successful", response.data.id);
          dispatch({
            type: "setOfferDetailsDataChanges",
            payload: {
              [key]: value,
              coverPhotoId: response.data.id,
              coverImageUrl: response.data?.public_url,
            },
          });
          setLoading(false)
        })
        .catch((error) => {
          console.error("Error uploading file:", error);
          setLoading(false)
          // to show error in case any error occurs in uploading image
          // toast.error("error uploading file")
        });
    } else {
      dispatch({
        type: "setOfferDetailsDataChanges",
        payload: { [key]: value },
      });
    }
    console.log(key, value);
  };
  console.log(data, "reduxData");
  useEffect(() => {
    dispatch(getProductFunction());
    dispatch(getMerchantFunction());
    dispatch(getInitiativeFunction());
  }, []);
  return (
    <Box
      sx={{
        backgroundColor: "#FFFFFF",
        padding: "0px 0px 20px 20px",
        margin: "20px",
        marginTop:showEdit? "15px" : "35px",
        borderRadius: "8px",
      }}
    >
      <Box
        sx={{
          display: showEdit ? "flex" : "none",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "1px solid #E4E4F0",
          my: "16px",
          py: "8px",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Lato",
            fontSize: "16px",
            fontWeight: "700",
            lineHeight: "20px",
            color: "#0B1B32",
          }}
        >
          Offer Details
        </Typography>
        <Button
          sx={{
            display: "flex",
            gap: "6px",
            mr: "10px",
            px: "10px",
          }}
          onClick={() => {
            navigate("/offers/create-offers/offer-details");
          }}
        >
          <img
            style={{ height: "16px", height: "16px" }}
            src={editLogo}
            alt=""
          />
          <Typography
            sx={{
              fontFamily: "Lato",
              fontSize: "14px",
              fontWeight: "600",
              lineHeight: "16.8px",
              color: "#003D86",
            }}
          >
            Edit
          </Typography>
        </Button>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8} pr={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box sx={{ maxWidth: "510px" }}>
                <BoldLabel mt="0px" label="Select Product" />
                <MuiSelectAndSearch
                  options={productOption}
                  value={data?.product ?? []}
                  onChange={(value) => handleData("product", value)}
                  isDisable={showEdit}
                  placeholder="Select / Select"
                  //   errorMessage={errorMessage?.product}
                  //   editBoolean={data?.previous_product?.length > 0}
                />
                {data?.product?.name && (
                  <Box sx={{ display: "flex", gap: "5px", mt: "6px" }}>
                    <Box
                      sx={{
                        borderRadius: "20px",
                        backgroundColor: "#F9F9FC",
                        fontFamily: "Lato",
                        fontWeight: 400,
                        fontSize: "10px",
                        lineHeight: "16px",
                        color: "#003D86",
                        padding: "2px 8px",
                      }}
                    >
                      Category : {data?.product?.category_name}
                    </Box>
                    <Box
                      sx={{
                        borderRadius: "20px",
                        backgroundColor: "#F9F9FC",
                        fontFamily: "Lato",
                        fontWeight: 400,
                        fontSize: "10px",
                        lineHeight: "16px",
                        color: "#003D86",
                        padding: "2px 8px",
                      }}
                    >
                      Sub Category : {data?.product?.subcategory_name}
                    </Box>
                  </Box>
                )}
              </Box>

              <Box sx={{ maxWidth: "510px" }}>
                <BoldLabel mt="16px" label="Offer Description" />
                <MuiTextarea
                  placeholder="eg: Don't miss our End of Season Sale! Up to 50% off on select items. Refresh your wardrobe with the latest styles at unbeatable prices. Hurry, while stocks last!"
                  minRows={4}
                  value={data?.description}
                  onChange={(value) => handleData("description", value)}
                  isDisable={showEdit}
                  // errorMessage={errorMessage?.description}
                  // editBoolean={data?.previous_description??false}
                />
              </Box>
              <Box sx={{ maxWidth: "510px" }}>
                <BoldLabel label="From Date And Time" />
                <MUIDateTimePicker
                disabled={showEdit}
                  value={data?.startDate}
                  onChange={(value) => handleData("startDate", value)}
                  // errorMessage={errorMessage?.startDate}
                  placeholder=""
                  editBoolean={data?.previous_startDate ?? false}
                  // disablePast={true}
                  // maxDate={data?.startDate ? new Date(data?.endDate) : null}
                />
              </Box>
              <Box sx={{ maxWidth: "510px" }}>
                <BoldLabel label="Lock In Period" />
                <DaysInput
                  value={data?.lockingPeriod}
                  onChange={(value) => handleData("lockingPeriod", value)}
                  placeholder="Enter no. of days"
                  disable={showEdit}
                  // errorMessage={errorMessage?.lockingPeriod}
                  // editBoolean={data?.previous_lockingPeriod??false}
                />
              </Box>
              <Box sx={{ maxWidth: "510px" }}>
                <BoldLabel label="Select Merchant" />
                <MultiSelect
                  value={data?.merchant ?? []}
                  onChange={(value) => handleData("merchant", value)}
                  options={merchantOption}
                  placeholder="Select / Select"
                  disable={showEdit}
                  //   errorMessage={errorMessage?.merchant}
                  //   editBoolean={data?.previous_merchant?.length>0??false}
                />
              </Box>
              <Box sx={{ maxWidth: "510px" }}>
                <BoldLabel label="Offer Cover Photo" />
                <NewImageUpload
                  //   loader={loader}
                  loader={loading}
                  disable={showEdit}
                  value={data?.coverImageUrl}
                  onChange={(value) => handleData("coverPhoto", value)}
                  maxSize="2MB"
                  ratio="4:3"
                  //   errorMessage={errorMessage?.coverPhoto}
                  //   image={coverPhoto}
                  handleImage={(value) => {
                    dispatch({
                      type: "setOfferDetailsDataChanges",
                      payload: {
                        coverPhoto: value,
                        coverPhotoId: value,
                        coverImageUrl: value,
                      },
                    });
                    // onChange({
                    //   ...data,
                    //   coverPhoto: value,
                    //   coverPhotoId: value,
                    //   coverImageUrl: value,
                    // });

                    // handleCoverPhoto(value);
                  }}
                  editBoolean={data?.previous_coverPhoto ?? false}
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box sx={{ maxWidth: "510px" }}>
                <BoldLabel label="Offer Title" mt="0px" />
                <MuiInput
                  value={data?.title}
                  onChange={(value) => handleData("title", value)}
                  placeholder="Offer Title"
                  disable={showEdit}
                  // errorMessage={errorMessage?.title}
                  // editBoolean={data?.previous_title??false}
                />
                {data?.product?.name && (
                  <Box
                    sx={{
                      display: "flex",
                      gap: "5px",
                      mt: "6px",
                      visibility: "hidden",
                    }}
                  >
                    <Box
                      sx={{
                        borderRadius: "20px",
                        backgroundColor: "#F9F9FC",
                        fontFamily: "Lato",
                        fontWeight: 400,
                        fontSize: "10px",
                        lineHeight: "16px",
                        color: "#003D86",
                        padding: "2px 8px",
                      }}
                    >
                      Category: {data?.product?.category_name}
                    </Box>
                    <Box
                      sx={{
                        borderRadius: "20px",
                        backgroundColor: "#F9F9FC",
                        fontFamily: "Lato",
                        fontWeight: 400,
                        fontSize: "10px",
                        lineHeight: "16px",
                        color: "#003D86",
                        padding: "2px 8px",
                      }}
                    >
                      Sub Category: {data?.product?.subcategory_name}
                    </Box>
                  </Box>
                )}
              </Box>
              <Box sx={{ maxWidth: "510px" }}>
                <BoldLabel label="Offer T&C" />
                <MuiTextarea
                  placeholder="eg: Don't miss our End of Season Sale! Up to 50% off on select items. Refresh your wardrobe with the latest styles at unbeatable prices. Hurry, while stocks last!"
                  minRows={4}
                  value={data?.termsAndConditions}
                  onChange={(value) => handleData("termsAndConditions", value)}
                  isDisable={showEdit}
                  // errorMessage={errorMessage?.description}
                  // editBoolean={data?.previous_description??false}
                />
              </Box>
              <Box sx={{ maxWidth: "510px" }}>
                <BoldLabel label="To Date And Time" />
                <MUIDateTimePicker
                disabled={showEdit}
                  value={data?.endDate}
                  onChange={(value) => handleData("endDate", value)}
                  placeholder=""
                  // errorMessage={errorMessage?.endDate}
                  editBoolean={data?.previous_endDate ?? false}
                  // disablePast={true}
                  // minDate={data?.startDate ? new Date(data?.startDate) : null}
                />
              </Box>
              <Box sx={{ maxWidth: "510px" }}>
                <BoldLabel label="Expiry Period" />
                <DaysInput
                  value={data?.expiryPeriod}
                  onChange={(value) => handleData("expiryPeriod", value)}
                  placeholder="Enter no. of days"
                  disable={showEdit}
                  // errorMessage={errorMessage?.expiryPeriod}
                  // editBoolean={data?.previous_expiryPeriod??false}
                />
              </Box>
              <Box sx={{ maxWidth: "510px" }}>
                <BoldLabel label="Offer Initiative" />
                <MuiSelectAndSearch
                  value={data?.offerInitiative}
                  onChange={(value) => handleData("offerInitiative", value)}
                  options={initiativeOption}
                  placeholder="Select / Select"
                  isDisable={showEdit}
                  //   errorMessage={errorMessage?.merchant}
                  //   editBoolean={data?.previous_merchant?.length>0??false}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          md={4}
          sx={{
            backgroundColor: "#E8F0F9",
            borderRadius: "0px 8px 8px 8px",
            border: "1px solid #B5BBC5",
            placeItems: "center",
            position: "relative",
            overflow: "hidden",
          }}
        >
          {/* Phone view preview */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              position: "absolute",
              top: "-120px",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            <ReactSlickCarousel
              max={1}
              setCurrentIndex={setIndex}
              settings={carouselSettings}
            >
              <ViewAllDeals
                data={{
                  label: "offer cover photo",
                  img: data?.coverImageUrl,
                }}
              />
              <Deals dealData={data} poster={poster} />
            </ReactSlickCarousel>
          </Box>
        </Grid>
      </Grid>

      <Box
        sx={{
          display:showEdit?"none": "flex",
          justifyContent: "flex-start",
          gap: "16px",
          marginTop: "41px",
        }}
      >
        <Button
          sx={{
            width: "136px",
            height: "30px",
            backgroundColor: "#E8F0F9",
            padding: "7px 12px",
            borderRadius: "6px",
            "&:hover": {
              backgroundColor: "#E8F0F9",
            },
          }}
        >
          <Typography
            sx={{
              fontFamily: "Lato",
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "16.8px",
              color: "#003D86",
            }}
          >
            Cancel
          </Typography>
        </Button>
        <Button
          sx={{
            width: "136px",
            height: "30px",
            backgroundColor: "#003D86",
            padding: "7px 12px",
            borderRadius: "6px",
            "&:hover": {
              backgroundColor: "#003D86",
            },
          }}
          onClick={() =>
            navigate("/offers/create-offers/set-qualifying-criteria")
          }
        >
          <Typography
            sx={{
              fontFamily: "Lato",
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "16.8px",
              color: "#FFFFFF",
            }}
          >
            Save & Next
          </Typography>
        </Button>
      </Box>
    </Box>
  );
}
