import React, { useEffect, useState } from "react";
import { Avatar, Box, Button, Stack, Typography } from "@mui/material";
import spinnerIcon from "../assets/spinner.svg";
import SearchActionComponent from "../components/SearchActionComponent";
import { FS_OPTIONS } from "../options";
import UiTable from "../components/UiTable";
import { truncateWithEllipsis } from "../utils/removeSlugeFromString";
import formatDate, { formatDateNew } from "../utils/formateDate";
// import convertTimestampTo12HourFormat from "../../utils/convertTimeStampTo12HourFormat";
import TableTotalCount from "../components/TableTotalCount";
// import AddColumnIcon from "../assets/AddColumnIcon.svg";
import AddColumn from "../components/transactions/AddColumn";
import axios from "axios";
import { useSelector } from "react-redux";
import useDebounce from "../hooks/useDebounce";
import DateFilterPanel from "../components/DateFilterPanel";
import SwipeableDrawerComponent from "../components/SwipeableDrawerComponent ";
import CustomFilter from "../components/Filter/CustomFilter";
import CommonDateFilterPanel from "../components/Filter/CommonDateFilterPanel";
import MuiButton from "../UIComponents/MuiButton";
import { BootstrapTooltip } from "../utils/tooltip";
import documentsvg from "../assets/Frame.svg";
import dayjs from "dayjs";
import QueryInput from "../components/QueryInput";
import Loader from "../components/Loader";
import FilteredResults from "../components/FilteredResults";
import { handleDownload } from "../utils/download";
import usePermission from "../utils/permissions";
import ToggleColumn from "../UIComponents/ToggleColumn";
// import { getAllTransactions } from "../services/TransactionApisService";


const titleStyle = {
  fontFamily: "Proxima Nova A",
  fontSize: 20,
  fontWeight: 600,
  lineHeight: "24px",
  letterSpacing: "0em",
  color: "#0F1828",
};

const subTitle = {
  fontFamily: "Proxima Nova A",
  width: "325px",
  fontSize: 16,
  fontWeight: 400,
  lineHeight: "21px",
  textAlign: "center",
  letterSpacing: "0em",
  color: "#0F182899",
};
const data = {
  "Offer Status": [
    {
      name: "ACTIVE",
      backend_label: "status",
      backend_value: "ACTIVE",
    },
    {
      name: "INACTIVE",
      backend_label: "status",
      backend_value: "INACTIVE",
    },
  ],
  Status: [
    {
      name: "LOCKED",
      backend_label: "integration_status",
      backend_value: "LOCKED",
    },
    {
      name: "UNLOCKED",
      backend_label: "integration_status",
      backend_value: "UNLOCKED",
    },
    {
      name: "ALLOTTED",
      backend_label: "integration_status",
      backend_value: "ALLOTTED",
    },
    {
      name: "EXPIRED",
      backend_label: "integration_status",
      backend_value: "EXPIRED",
    },
    {
      name: "REDEEMED",
      backend_label: "integration_status",
      backend_value: "REDEEMED",
    },
    {
      name: "CANCELLED",
      backend_label: "integration_status",
      backend_value: "CANCELLED",
    },
    {
      name: "PROCESSED",
      backend_label: "integration_status",
      backend_value: "PROCESSED",
    },
    {
      name: "FAILED",
      backend_label: "integration_status",
      backend_value: "FAILED",
    },
  ],
  Currency: [
    {
      backend_label: "id",
      name: "Digital Gold",
      backend_value: 1,
    },
    {
      backend_label: "id",
      name: "Neu Coin",
      backend_value: 2,
    },
  ],
  Category: [
    {
      backend_label: "id",
      name: "Financial Services",
      backend_value: "87abbcdb-85b4-4d29-90f3-26fc95e9e4c4",
    },
    {
      backend_label: "id",
      name: "Payments",
      backend_value: "c41b5887-ba90-11ee-ab95-0ab5f679b0f6",
    },
  ],
  Subcategory: [
    {
      backend_label: "id",
      name: "Lending",
      backend_value: "87abbcdb-85b4-4d29-90f3-26fc95e9e4c4",
    },
    {
      backend_label: "id",
      name: "Upi",
      backend_value: "c41b5887-ba90-11ee-ab95-0ab5f679b0f6",
    },
  ],
  Product: [
    {
      backend_label: "id",
      name: "BNPL",
      backend_value: "87abbcdb-85b4-4d29-90f3-26fc95e9e4c4",
    },
    {
      backend_label: "id",
      name: "RCC",
      backend_value: "c41b5887-ba90-11ee-ab95-0ab5f679b0f6",
    },
  ],
  Merchant: [
    {
      backend_label: "id",
      name: "Big Basket",
      backend_value: "87abbcdb-85b4-4d29-90f3-26fc95e9e4c4",
    },
    {
      backend_label: "id",
      name: "Croma",
      backend_value: "c41b5887-ba90-11ee-ab95-0ab5f679b0f6",
    },
  ],
  Offer: [
    {
      backend_label: "id",
      name: "BNPL BigBasket offer",
      backend_value: "87abbcdb-85b4-4d29-90f3-26fc95e9e4c4",
    },
    {
      backend_label: "id",
      name: "BNPL EaseBuzz Offer update",
      backend_value: "c41b5887-ba90-11ee-ab95-0ab5f679b0f6",
    },
  ],
};

export const download = function (data, name = "transactions") {
  const blob = new Blob([data], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.setAttribute("href", url);
  a.setAttribute("download", `${name}_${new Date()}.csv`);
  a.click();
};

export const GenericText = ({
  variant = "body1",
  text,
  css = "",
  isEllipsis = false,
  maxlength,
}) => {
  const typoStyles = {
    color: "var(--text-dark, #000)",
    textAlign: "center",
    fontFamily: "Proxima Nova",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    letterSpacing: "0.26px",
    textTransform: "capitalize",
  };
  return isEllipsis ? (
    <Typography variant={variant} sx={css ? { ...css } : { ...typoStyles }}>
      {truncateWithEllipsis(text, maxlength)}
    </Typography>
  ) : (
    <Typography variant={variant} sx={css ? { ...css } : { ...typoStyles }}>
      {text}
    </Typography>
  );
};

// mui dataGrid columns


const filterSelectOptions = [
  { label: "Custom", value: "CUSTOM" },
  { label: "Today", value: "TODAY" },
  { label: "Yesterday", value: "YESTERDAY" },
  { label: "This Week", value: "THIS_WEEK" },
  { label: "This Month", value: "THIS_MONTH" },
  { label: "All Time", value: "ALL_TIME" },
];

const initialFilterOptions = {
  offer_status: [],
  status: [],
  reward_currency: [],
};

const TData = ()=>{
  return [
      { "key": "transaction_id", "displayName": "Transaction ID", "flag": true },
      { "key": "externalCustomerId", "displayName": "Customer#", "flag": true },
      { "key": "product_code", "displayName": "Product Code", "flag": true },
      { "key": "merchant_code", "displayName": "Merchant Code", "flag": true },
      { "key": "offer_name", "displayName": "Offer Name", "flag": true },
      { "key": "points_issued", "displayName": "Points Issued", "flag": true },
      { "key": "reward_currency", "displayName": "Reward Currency", "flag": true },
      { "key": "status", "displayName": "Status", "flag": true },
      { "key": "offer_description", "displayName": "Offer Description","flag":false },
      { "key": "offer_type", "displayName": "Offer Type","flag":false },
      { "key": "offer_id", "displayName": "Offer Id","flag":false },
      { "key": "initiative_code", "displayName": "Initiative Code","flag":false },
      { "key": "category_code", "displayName": "Category Code","flag":false },
      { "key": "subcategory_code", "displayName": "Subcategory Code","flag":false }
    ]
}

const Transactions = () => {
  console.log(TData,"TData")
  // State for managing the search query
  const [search, setSearch] = useState("");
  const [flag, setFlag] = useState(0);
  const anchor = "right";
  const [loading, setLoading] = useState(false);
  const [doneButton, setDoneButton] = useState(false);
  const [noOptions, setNoOptions] = useState(false);
  const [searchFlag, setSearchFlag] = useState(false);
  const [showFilter, setShowFilter] = useState([]);
  const searchDebounce = useDebounce(search, 500);
  const BaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [filterConfig, setFilterConfig] = useState([]);
  const [transactions, setTransactions] = useState({
    data: [],
    paginationModel: {
      pageSize: 10,
      page: 0,
    },
    totalCount: 0,
  });

  const isAuth = useSelector((store) => store.AuthReducer.token);

  const [fieldsName, setFieldName] = useState(TData);
  const [columnFields, setColumnFields] = useState([]);

  console.log(fieldsName,TData ,"columnFields");
  const dateFilterLayout = {
    select: "THIS_MONTH",
    from_time: "",
    to_time: "",
  };

  const [dateFilter, setDateFilter] = useState(dateFilterLayout);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  // filter options
  const [filterOptions, setFilterOptions] = useState({});

  const [filter, setFilter] = useState({
    right: false,
  });

  const permission = usePermission();
  console.log(permission, "permission");

  const handleFilter = (key, value) => {
    setFilterOptions((pre) => {
      return {
        ...pre,
        [key]: value,
      };
    });
  };

  const handleToggleColumn = (i)=>{
    // console.log(i, "index");
    // const data = fieldsName.map((e,index)=>{
    //   if(index===i){
    //     e.flag =!e.flag
    //   }
    //   return e
    // })

    setFieldName(prev=> prev.map((e,index)=>{
      if(index===i){
        e.flag =!e.flag
      }
      return e
    }));
    
  }

  const fetchFilters = async () => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const token = isAuth;

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Adjust content type as needed
    };
    try {
      const response = await axios.get(
        `${baseUrl}/api/filters?page=transaction`,
        {
          headers,
        }
      );
      if (response) {
        const config = response.data;
        let obj = {};
        const data = config.map((res) => {
          if (!obj.hasOwnProperty(res?.name)) {
            obj[res?.name] = [];
          }
          return { ...res, state: [] };
        });

        // setFilterOptions(obj);
        setFilterConfig(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //will set initial 7 column in table
  const getColumns = (AllColumns) => {
    const data = AllColumns?.map((column, index) => {
      if (
        (column?.key?.toLowerCase()?.includes("date") || index <= 6) &&
        index <= 6
      ) {
        return {
          field: column.key,
          headerName: column.displayName,
          minWidth: 200,
          renderCell: (params) => {
            if (!params?.value) {
              return (
                <Box
                  fontFamily="ABeeZee"
                  fontWeight="400"
                  fontSize="13px"
                  lineHeight="15.37px"
                  letterSpacing="2%"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace={"nowrap"}
                >
                  NA
                </Box>
              );
            }

            return <>{params?.value}</>;
          },
        };
      } else if (index <= 6) {
        return {
          field: column.key,
          headerName: column.displayName,
          minWidth: 200,
          renderCell: (params) => {
            if (!params?.value) {
              return (
                <Box
                  fontFamily="ABeeZee"
                  fontWeight="400"
                  fontSize="13px"
                  lineHeight="15.37px"
                  letterSpacing="2%"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace={"nowrap"}
                >
                  NA
                </Box>
              );
            }

            return <>{params?.value}</>;
          },
        };
      }
      // If the conditions are not met, return undefined
    }).filter(Boolean); // Filter out any undefined values
    setColumnFields(data);
  };

  const handlePaginationModel = (value) => {
    if (!value) return;
    setTransactions((prev) => ({ ...prev, paginationModel: value }));
  };

  // to add column fields in table
  const handleColumnAdd = (checked, name, label) => {
    setColumnFields((prevFields) => {
      if (checked) {
        // If checked, add the field to the array

        if (name.includes("date")) {
          return [
            ...prevFields,
            {
              field: name,
              headerName: label,
              minWidth: 150,
              renderCell: (params) => {
                if (!params?.value) {
                  return (
                    <Box
                      fontFamily="ABeeZee"
                      fontWeight="400"
                      fontSize="13px"
                      lineHeight="15.37px"
                      letterSpacing="2%"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      whiteSpace={"nowrap"}
                    >
                      NA
                    </Box>
                  );
                }

                const date = formatDate(params?.value);
                return (
                  <BootstrapTooltip title={date}>

                  <Box
                    fontFamily="ABeeZee"
                    fontWeight="400"
                    fontSize="13px"
                    lineHeight="15.37px"
                    letterSpacing="2%"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    whiteSpace={"nowrap"}
                  >
                    {date}
                  </Box>
                  </BootstrapTooltip>
                );
              },
            },
          ];
        } else {
          return [
            ...prevFields,
            {
              field: name,
              headerName: label,
              width: 150,
              renderCell: (params) => {
                if (!params?.value) {
                  return (
                    <Box
                      fontFamily="ABeeZee"
                      fontWeight="400"
                      fontSize="13px"
                      lineHeight="15.37px"
                      letterSpacing="2%"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      whiteSpace={"nowrap"}
                    >
                      NA
                    </Box>
                  );
                }

                return (
                  <BootstrapTooltip title={params?.value}>
                  <Box
                    fontFamily="ABeeZee"
                    fontWeight="400"
                    fontSize="13px"
                    lineHeight="15.37px"
                    letterSpacing="2%"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    whiteSpace={"nowrap"}
                  >
                    {params.value}
                  </Box>
                  </BootstrapTooltip>
                );
              },
            },
          ];
        }
      } else {
        // If unchecked, filter out the field from the array
        return prevFields.filter((field) => field.field !== name);
      }
    });
  };
  // funtion to build query
  const builderQuery = () => {
    const querys = [];
    if (
      dateFilter?.select &&
      dateFilter?.select === "Custom" &&
      dateFilter?.to_time &&
      dateFilter?.from_time
    ) {
      let startDate = formatDateNew(dateFilter?.from_time ?? "");
      let endDate = formatDateNew(dateFilter?.to_time ?? "");
      querys.push(`dateStart=${startDate}&dateEnd=${endDate}`);
    }

    if (dateFilter?.select && dateFilter?.select === "Today") {
      querys.push(`day=TODAY`);
    }

    if (dateFilter?.select && dateFilter?.select === "Yesterday") {
      querys.push(`day=YESTERDAY`);
    }
    if (dateFilter?.select && dateFilter?.select === "This Week") {
      querys.push(`day=THIS_WEEK`);
    }
    if (dateFilter?.select && dateFilter?.select === "This Month") {
      querys.push(`day=THIS_MONTH`);
    }
    if (dateFilter?.select && dateFilter?.select === "All Time") {
      querys.push(`day=ALL_TIME`);
    }

    if (filterOptions?.offer_status?.length > 0) {
      querys.push(`offer_status=${filterOptions.offer_status}`);
    }
    if (filterOptions?.status?.length > 0) {
      querys.push(`status=${filterOptions.status}`);
    }
    if (filterOptions?.reward_currency?.length > 0) {
      querys.push(`reward_currency=${filterOptions.reward_currency}`);
    }

    if (searchDebounce && search) {
      querys.push(`search=${searchDebounce}`);
    }

    let splitquery = querys.length > 1 ? querys.join("&") : querys.join("");

    return splitquery;
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setFilter((prev) => ({ ...prev, [anchor]: open }));
  };

  //css

  const moreFilterButtonStyle = {
    height: "45px",
    padding: "14px 20px 14px 20px",
    borderRadius: "5px",
    border: "1px solid #D9D9D9",
    background: "#fff",
  };

  const moreFilterTextStyle = {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "17.5px",
    fontFamily: "Proxima Nova A",
    color: "#26292D",
  };

  //filter config

  // const [filterConfig,setFilterConfig] = useState([])
  const handleDoneButton = () => {
    // setFlag(prev=>!prev)
    setDoneButton((prev) => !prev);
  };

  const handleExport = async (data = {}) => {
    const url = `${BaseUrl}/api/export`;
    const token = isAuth;

    const obj = {
      ...(search && searchDebounce && { externalCustomerId: searchDebounce }),
      pageName: "transaction",
      // page: transactions?.paginationModel.page + 1,
      // itemsPerPage: transactions?.paginationModel.pageSize,
    };

    if (
      dateFilter?.select &&
      dateFilter?.select === "CUSTOM" &&
      dateFilter?.to_time &&
      dateFilter?.from_time
    ) {
      let startDate = formatDateNew(dateFilter?.from_time ?? "");
      let endDate = formatDateNew(dateFilter?.to_time ?? "");
      obj["dateStart"] = startDate;
      obj["dateEnd"] = endDate;
    }

    if (dateFilter?.select) {
      obj["day"] = dateFilter?.select;
    }

    obj["total_count"] = transactions?.totalCount;

    if (filterOptions) {
      Object.entries(filterOptions)?.forEach(([key, value]) => {
        if (value?.length > 0) {
          obj[key] = value;
        }
      });
    }

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Adjust content type as needed
    };

    try {
      const response = await axios.post(url, obj, {
        headers,
      });

      download(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFilterState = (name, value, checked, status) => {
    if (status === "clear") {
      setFilterConfig((prev) => {
        const updatedData = prev?.map((filter) =>
          filter?.name === name ? { ...filter, state: [] } : filter
        );
        return updatedData;
      });
      return;
    }
    if (checked) {
      setFilterConfig((prev) => {
        const updatedData = prev?.map((filter) =>
          filter?.name === name
            ? { ...filter, state: [...filter?.state, value] }
            : filter
        );
        return updatedData;
      });
    } else {
      setFilterConfig((prev) => {
        const updatedData = prev?.map((filter) =>
          filter?.name === name
            ? {
                ...filter,
                state: filter.state.filter((res) => res !== value),
              }
            : filter
        );
        return updatedData;
      });
    }
  };

  const handleClearFilter = (initializeValueByName) => () => {
    setFilterConfig((prev) => {
      const updatedData = prev?.map((filter) => ({ ...filter, state: [] }));
      return updatedData;
    });
  };

  const handleDateFilter = (name, value) => {
    // if (
    //   dateFilter?.select !== "CUSTOM" &&
    //   (dateFilter?.to_time || dateFilter?.from_time)
    // ) {
    //   setDateFilter((prev) => ({
    //     ...prev,
    //     from_time: "",
    //     to_time: "",
    //   }));
    // } else {

    setDateFilter((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (value === "ALL_TIME") {
      setDateFilter((prev) => ({
        ...prev,
        from_time: { unset: true },
        to_time: { unset: true },
      }));
    }
    if (value === "CUSTOM") {
      setDateFilter((prev) => ({
        ...prev,
        from_time: { unset: true },
        to_time: { unset: true },
      }));
    }
    // }
  };

  const postFilterData = async (status, allData , isPagination=false) => {
    if (status !== "download") {
      setLoading(true);
    }
    // setLoading(true);
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const token = isAuth;

    const data = builderQuery();

    if(!isPagination){
      const paginationValue = {
        page: 0,
        pageSize:  transactions?.paginationModel.pageSize,
      };
        handlePaginationModel(paginationValue);
      }


    const obj = {
      ...(search && { externalCustomerId: search }),
      page: isPagination ? transactions?.paginationModel.page + 1 : 1,
      itemsPerPage: transactions?.paginationModel.pageSize,
    };

    if (status === "done") {
      setShowFilter(filterConfig);
    }
    console.log(showFilter, "filterConfig");

    if (!status || status === "download") {
      showFilter?.forEach((res) => {
        if (res?.state?.length > 0) {
          obj[res?.name] = res?.state;
        }
      });
    } else if (status === "clear") {
      console.log(allData, "data");
      allData?.forEach((res) => {
        if (res?.state?.length > 0) {
          obj[res?.name] = res?.state;
        }
      });
    } else if (status === "done") {
      console.log(allData, "done");
      filterConfig?.forEach((res) => {
        if (res?.state?.length > 0) {
          obj[res?.name] = res?.state;
        }
      });
    }
    // console.log(obj,"Objdata")
    if (
      dateFilter?.select &&
      dateFilter?.select === "CUSTOM" &&
      dateFilter?.to_time &&
      dateFilter?.from_time
    ) {
      let startDate = formatDateNew(dateFilter?.from_time ?? "");
      let endDate = formatDateNew(dateFilter?.to_time ?? "");
      obj["dateStart"] = startDate;
      obj["dateEnd"] = endDate;
    }

    if (dateFilter?.select) {
      obj["day"] = dateFilter?.select;
      console.log(dateFilter, "dateFilter");
    }
    console.log(obj, "Objdata");
    // if (filterConfig) {
    //   Object.entries(filterConfig)?.forEach(([key, value]) => {
    //     if (value?.state?.length > 0) {
    //       obj[value?.name] = value?.state;
    //     }
    //   });
    // }

    if (status === "download") {
      obj["total_count"] = transactions?.totalCount;
      handleDownload(baseUrl, obj, token, "transaction");
      return;
    }

    if (Object.entries(obj).length === 0) return;

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Adjust content type as needed
    };

    try {
      const response = await axios.post(`${baseUrl}/api/transactions`, obj, {
        headers,
      });

      console.log(response?.data?.data, "filter response");
      setStartDate(response.data.day_start_date);
      setEndDate(response.data.day_end_date);
      
      // to set page to 1 if no data is found
      // if(response?.data?.data?.length === 0 && transactions?.paginationModel.page>0){
      //   setTransactions(prev=>{
      //     return{
      //       ...prev,
      //       paginationModel:{
      //        ...prev.paginationModel,
      //         page:0
      //       }
      //     }
      //   })
      // }

      const formateData = response?.data?.data.map((e)=>{
      return{
        transaction_id:e?.transaction_id,
        externalCustomerId:e?.externalCustomerId,
        product_code:e?.product_code,
        merchant_code:e?.merchant_code,
        offer_name:e?.offer_name,
        points_issued:e?.points_issued,
        reward_currency:e?.reward_currency,
        status:e?.status,
        offer_description:e?.offer_description,
        offer_type:e?.offer_type,
        offer_id:e?.offer_id,
        initiative_code:e?.initiative_code,
        category_code:e?.category_code,
        subcategory_code:e?.subcategory_code
      }
      })

      setTransactions((prev) => ({
        ...prev,
        data: formateData ?? [],
        totalCount: response?.data?.ledgerCount ?? 0,
      }));

      setLoading(false);
    } catch (error) {
      console.log(error);
      // setTransactions({
      //   data: [],
      //   paginationModel: {
      //     pageSize: 10,
      //     page: 0,
      //   },
      //   totalCount: 0,
      // })
    }
    setLoading(false);
  };

  const handleRemove = (name, value) => {
    setFilterConfig((prevOptions) => {
      const updatedOptions = [...prevOptions];
      console.log(updatedOptions, "testing");
      const updatedData = updatedOptions?.map((filter) =>
        filter?.name === name
          ? {
              ...filter,
              state: filter.state.filter((res) => res !== value),
            }
          : filter
      );
      return updatedData;
    });
  };

  const removeFilters = (name, value) => {
    handleRemove(name, value);
    const latestData = showFilter.map((filter) =>
      filter?.name === name
        ? {
            ...filter,
            state: filter.state.filter((res) => res !== value),
          }
        : filter
    );
    setShowFilter((prevOptions) => {
      const updatedData = prevOptions?.map((filter) =>
        filter?.name === name
          ? {
              ...filter,
              state: filter.state.filter((res) => res !== value),
            }
          : filter
      );

      return updatedData;
    });
    postFilterData("clear", latestData);
  };
  console.log(showFilter, "showFilter");

  const handleSearch = () => {
    if (search.length > 0) {
      setNoOptions(true);
      postFilterData();
      // setSearchFlag(prev=>!prev)
    } else {
      setNoOptions(false);
      setTransactions((prev) => ({ ...prev, data: [] }));
    }
  };

  const columns = [
    {
      field: "transaction_id",
      headerName: "Transaction ID",
      minWidth: 100,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Box
              fontFamily="ABeeZee"
              fontWeight="400"
              fontSize="13px"
              lineHeight="15.37px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              NA
            </Box>
          );
        }
        return (
          <Box
            fontFamily="ABeeZee"
            fontWeight="400"
            fontSize="13px"
            lineHeight="15.37px"
            letterSpacing="2%"
            color="#043A87"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace={"nowrap"}
          >
            {params?.value}
          </Box>
        );
      },
    },
    {
      field: "externalCustomerId",
      headerName: "Customer#",
  
      minWidth: 150,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Box
              fontFamily="ABeeZee"
              fontWeight="400"
              fontSize="13px"
              lineHeight="15.37px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              NA
            </Box>
          );
        }
  
        return (
          <BootstrapTooltip title={params.value}>
            <Box
              fontFamily="ABeeZee"
              fontWeight="400"
              fontSize="13px"
              lineHeight="15.37px"
              letterSpacing="2%"
              color="#043A87"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              {params?.value}
            </Box>
          </BootstrapTooltip>
        );
      },
    },
    {
      field: "product_code",
      headerName: "Product Code",
      minWidth: 120,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Box
              fontFamily="ABeeZee"
              fontWeight="400"
              fontSize="13px"
              lineHeight="15.37px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              NA
            </Box>
          );
        }
  
        return (
          <Box
            fontFamily="ABeeZee"
            fontWeight="400"
            fontSize="13px"
            lineHeight="15.37px"
            letterSpacing="2%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace={"nowrap"}
          >
            {params?.value}
          </Box>
        );
      },
    },
    {
      field: "merchant_code",
      headerName: "Merchant Code",
      minWidth: 150,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Box
              fontFamily="ABeeZee"
              fontWeight="400"
              fontSize="13px"
              lineHeight="15.37px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              NA
            </Box>
          );
        }
  
        return (
          <Box
            fontFamily="ABeeZee"
            fontWeight="400"
            fontSize="13px"
            lineHeight="15.37px"
            letterSpacing="2%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace={"nowrap"}
          >
            {params?.value}
          </Box>
        );
      },
    },
  
    {
      field: "offer_name",
      headerName: "Offer Name",
      minWidth: 190,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Box
              fontFamily="ABeeZee"
              fontWeight="400"
              fontSize="13px"
              lineHeight="15.37px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              NA
            </Box>
          );
        }
        return (
          <BootstrapTooltip title={params.value}>
            <Box
              fontFamily="ABeeZee"
              fontWeight="400"
              fontSize="13px"
              lineHeight="15.37px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              {params?.value}
            </Box>
          </BootstrapTooltip>
        );
      },
    },
    {
      field: "points_issued",
      headerName: "Points Issued",
      minWidth: 100,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Box
              fontFamily="ABeeZee"
              fontWeight="400"
              fontSize="13px"
              lineHeight="15.37px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              NA
            </Box>
          );
        }
  
        return (
          <Box
            fontFamily="ABeeZee"
            fontWeight="400"
            fontSize="13px"
            lineHeight="15.37px"
            letterSpacing="2%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace={"nowrap"}
          >
            {params?.value}
          </Box>
        );
      },
    },
  
    {
      field: "reward_currency",
      headerName: "Reward Currency",
      minWidth: 150,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Box
              fontFamily="ABeeZee"
              fontWeight="400"
              fontSize="13px"
              lineHeight="15.37px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              NA
            </Box>
          );
        }
        let phoneNumber = params.value;
        let number = phoneNumber.slice(0, 3) + " " + phoneNumber.slice(3);
        return (
          <Typography
            fontFamily="ABeeZee"
            fontWeight="400"
            fontSize="13px"
            lineHeight="15.37px"
            letterSpacing="2%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace={"nowrap"}
          >
            {params.value}
          </Typography>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 150,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Box
              fontFamily="ABeeZee"
              fontWeight="400"
              fontSize="13px"
              lineHeight="15.37px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              NA
            </Box>
          );
        }
  
        return (
          <Box
            fontFamily="ABeeZee"
            fontWeight="400"
            fontSize="13px"
            lineHeight="15.37px"
            letterSpacing="2%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace={"nowrap"}
          >
            {params?.value}
          </Box>
        );
      },
    },
    {
      field: "offer_description",
      headerName: "Offer Description",
      minWidth: 150,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Box
              fontFamily="ABeeZee"
              fontWeight="400"
              fontSize="13px"
              lineHeight="15.37px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              NA
            </Box>
          );
        }
  
        return (
          <Box
            fontFamily="ABeeZee"
            fontWeight="400"
            fontSize="13px"
            lineHeight="15.37px"
            letterSpacing="2%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace={"nowrap"}
          >
            {params?.value}
          </Box>
        );
      },
    },
    {
      field: "offer_type",
      headerName: "Offer Type",
      minWidth: 150,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Box
              fontFamily="ABeeZee"
              fontWeight="400"
              fontSize="13px"
              lineHeight="15.37px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              NA
            </Box>
          );
        }
  
        return (
          <Box
            fontFamily="ABeeZee"
            fontWeight="400"
            fontSize="13px"
            lineHeight="15.37px"
            letterSpacing="2%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace={"nowrap"}
          >
            {params?.value}
          </Box>
        );
      },
    },
    {
      field: "offer_id",
      headerName: "Offer Id",
      minWidth: 150,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Box
              fontFamily="ABeeZee"
              fontWeight="400"
              fontSize="13px"
              lineHeight="15.37px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              NA
            </Box>
          );
        }
  
        return (
          <Box
            fontFamily="ABeeZee"
            fontWeight="400"
            fontSize="13px"
            lineHeight="15.37px"
            letterSpacing="2%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace={"nowrap"}
          >
            {params?.value}
          </Box>
        );
      },
    },
    {
      field: "initiative_code",
      headerName: "Initiative Code",
      minWidth: 150,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Box
              fontFamily="ABeeZee"
              fontWeight="400"
              fontSize="13px"
              lineHeight="15.37px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              NA
            </Box>
          );
        }
  
        return (
          <Box
            fontFamily="ABeeZee"
            fontWeight="400"
            fontSize="13px"
            lineHeight="15.37px"
            letterSpacing="2%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace={"nowrap"}
          >
            {params?.value}
          </Box>
        );
      },
    },
    {
      field: "category_code",
      headerName: "Category Code",
      minWidth: 150,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Box
              fontFamily="ABeeZee"
              fontWeight="400"
              fontSize="13px"
              lineHeight="15.37px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              NA
            </Box>
          );
        }
  
        return (
          <Box
            fontFamily="ABeeZee"
            fontWeight="400"
            fontSize="13px"
            lineHeight="15.37px"
            letterSpacing="2%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace={"nowrap"}
          >
            {params?.value}
          </Box>
        );
      },
    },
    {
      field: "subcategory_code",
      headerName: "Subcategory Code",
      minWidth: 150,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Box
              fontFamily="ABeeZee"
              fontWeight="400"
              fontSize="13px"
              lineHeight="15.37px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              NA
            </Box>
          );
        }
  
        return (
          <Box
            fontFamily="ABeeZee"
            fontWeight="400"
            fontSize="13px"
            lineHeight="15.37px"
            letterSpacing="2%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace={"nowrap"}
          >
            {params?.value}
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    // setFieldName([TData?.columns]);

    fetchFilters();
  }, []);

  useEffect(() => {
    // getAllTransactions();
    if (search) {
      if (dateFilter.select !== "CUSTOM") {
        postFilterData("");
      }
    }
  }, [
    // transactions?.paginationModel,
    // searchDebounce,
    // searchFlag,
    // filterOptions,
    dateFilter?.select,
    // doneButton,
  ]);

  useEffect(() => {
    // getAllTransactions();
    if (search) {
      if (dateFilter.select !== "CUSTOM") {
        postFilterData("",null,true);
      }
    }
  }, [
    transactions?.paginationModel,
    // searchDebounce,
    // searchFlag,
    // filterOptions,
    // dateFilter?.select,
    // doneButton,
  ]);

  useEffect(() => {
    if (
      dateFilter?.select == "CUSTOM" &&
      dateFilter?.from_time &&
      dateFilter?.to_time &&
      typeof dateFilter?.from_time === "string" &&
      typeof dateFilter?.to_time === "string"
    ) {
      // postFilterData("");
      postFilterData("",null,true);
    }
  }, [
    // dateFilter?.from_time,
    // dateFilter?.to_time,
    transactions?.paginationModel,
    // doneButton,
  ]);
  useEffect(() => {
    let debounceTimer;
    if (
      dateFilter?.select == "CUSTOM" &&
      dateFilter?.from_time &&
      dateFilter?.to_time &&
      typeof dateFilter?.from_time === "string" &&
      typeof dateFilter?.to_time === "string"&&
         dateFilter?.from_time!== "Invalid Date" &&
      dateFilter?.to_time!== "Invalid Date"
    ) {
      debounceTimer = setTimeout(() => {
        postFilterData("");
      }, 800);
    }
        // Cleanup function
        return () => {
          clearTimeout(debounceTimer);
        };
  }, [
    dateFilter?.from_time,
    dateFilter?.to_time,
  ]);

  useEffect(() => {
    postFilterData("done");
  }, [doneButton]);

  useEffect(() => {
    // Step 1: Filter TData columns where flag is true
    let  filteredTData = fieldsName?.filter(
      (column) => column.flag === true
    );

    filteredTData = [...filteredTData,  { "key": "copyBtn", "displayName": "Action", "flag": true }];

    // Step 2: Filter columns where field matches key from filteredTData
    const filteredColumns = columns.filter((column) =>
      filteredTData.some(
        (filteredColumn) => filteredColumn.key === column.field
      )
    );

    const finalColumns = [...filteredColumns];

    setColumnFields(finalColumns);
  }, [fieldsName]);

  useEffect(() => {
    console.log("date");
    if (startDate && endDate) {
      setDateFilter((prev) => ({
        ...prev,
        from_time: dayjs(new Date(startDate)),
        to_time: dayjs(new Date(endDate)),
      }));
    }
  }, [startDate, endDate]);

  // if (loading) {
  //   return <Loader />;
  // }

  return (
    <Box
      sx={{
        margin: { sm: "1.5rem 1.5rem" },
      }}
    >
      {/* search and filter */}
      <Box
        display="flex"
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Box display="flex" flexWrap="wrap" gap="20px">
          <SearchActionComponent
            value={search}
            setState={setSearch}
            placeholder={"Search Transaction by User#"}
            require={{ search: false, download: false }}
          >
            <QueryInput
              placeholder={"Search user by User#"}
              value={search}
              setState={setSearch}
              handleSearch={handleSearch}
              hitEnter={true}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "18px",
              }}
            >
              <CommonDateFilterPanel
              disabled={!noOptions}
                dateFilter={dateFilter}
                filterSelectOptions={filterSelectOptions}
                handleDateFilter={handleDateFilter}
              />
              <Button
                sx={moreFilterButtonStyle}
                startIcon={
                  <Avatar
                    src={spinnerIcon}
                    sx={{
                      width: "16px",
                      height: "16px",
                    }}
                  />
                }
                onClick={() => {
                  toggleDrawer(anchor, true)();
                }}
                variant={"text"}
              >
                <Typography
                  fontFamily="Proxima Nova A"
                  lineHeight="17.05px"
                  fontSize="14px"
                  sx={moreFilterTextStyle}
                >
                  {"More Filters"}
                </Typography>
              </Button>
            </Box>
          </SearchActionComponent>
        </Box>
        <Box>
          <MuiButton
            style={{
              color: "#FFF",
              fontFamily: "ABeeZee",
              fontSize: "15px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
              padding: "10px 30px ",
              height: "45px",
            }}
            isDisable={
              transactions.data?.length === 0 ||
              !noOptions ||
              !permission?.DOWNLOAD
            }
            onClick={() => {
              // handleExport();
              postFilterData("download", showFilter);
            }}
            TooltipMessage={
              !noOptions
                ? "Please Enter User#"
                : transactions.data?.length === 0
                ? "No Data Found"
                : "You don't have permission"
            }
          >
            Export
          </MuiButton>
        </Box>
      </Box>

      <Box sx={{ marginTop: "20px" }}>
        {showFilter && (
          <FilteredResults options={showFilter} handleRemove={removeFilters} />
        )}
      </Box>
      <Box>
        {!noOptions ? (
          <Stack
            spacing={3}
            justifyContent={"center"}
            alignItems={"center"}
            marginTop={"30vh"}
          >
            {/* {!search && ( */}
            <Avatar
              sx={{
                borderRadius: "0",
              }}
              src={documentsvg}
            />
            {/* )} */}
            <Stack spacing={2} justifyContent={"center"} alignItems={"center"}>
              {/* {!search ? ( */}
              <>
                <Typography sx={titleStyle}>Search User#</Typography>
                <Typography sx={subTitle}>
                  Simply type the user# into the search box and instantly
                  retrieve the data.
                </Typography>
              </>
            </Stack>
          </Stack>
        ) : noOptions && transactions?.data.length > 0 ? (
          <Box>
            <Box
              display="flex"
              justifyContent={"space-between"}
              marginTop={"50px"}
            >
              <TableTotalCount
                width="160px"
                totalCount={transactions?.totalCount ?? 0}
                text={"Transactions"}
              />
          <ToggleColumn tableFields={fieldsName} handleToggleColumn={handleToggleColumn} disabledIndex={2}/>
            </Box>

            <UiTable
              row={transactions?.data || []}
              alternaviteIdName={"transaction_id"}
              columns={columnFields || []}
              totalCount={transactions?.totalCount}
              paginationModel={transactions?.paginationModel}
              setPaginationModel={handlePaginationModel}
              loading={loading}
            />

{/* <UiTable
          row={cohorts?.data || []}
          columns={columnFields || []}
          totalCount={cohorts?.totalCount}
          paginationModel={cohorts?.paginationModel}
          setPaginationModel={handlePaginationModel}
          handleRowClick={handleRowClick}
        /> */}
          </Box>
        ) : (
          <Stack
            spacing={2}
            justifyContent={"center"}
            alignItems={"center"}
            marginTop={"30vh"}
          >
            <Typography sx={titleStyle}>No Data Found</Typography>
          </Stack>
        )}
      </Box>

      {/* more Filter */}
      <SwipeableDrawerComponent
        onOpen={toggleDrawer(anchor, true)}
        isOpen={filter.right}
        onClose={toggleDrawer(anchor, false)}
        anchor={anchor}
      >
        <CustomFilter
          filterOptions={filterConfig}
          handleClear={handleClearFilter(initialFilterOptions)}
          handleDoneButton={handleDoneButton}
          handleFilterState={handleFilterState}
          onCLose={toggleDrawer(anchor, false)}
        />
      </SwipeableDrawerComponent>
    </Box>
  );
};

export default Transactions;
