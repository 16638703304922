import { TextField, Box } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

export const MUDatePicker = ({
  value,
  onChange,
  disableFuture = false,
  disablePast = false,
  maxDate = null,
  minDate = null,
  errorMessage = "",
  disabled = false,
  placeholder = "Select Date", 
}) => {
  return (
    <Box sx={{ mt: '10px', width: "100%" }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          disabled={disabled}
          value={value ? dayjs(value) : null}
          disablePast={disablePast}
          disableFuture={disableFuture}
          maxDate={maxDate}
          minDate={minDate}
          format="DD/MM/YYYY"
          className="form-control"
          slotProps={{
            textField: {
              size: "small",
              error: !!errorMessage,
              helperText: errorMessage,
              label: placeholder, 
            },
          }}
          onChange={(date) => {
            if (date?.isValid()) {
              let dateWithTime = date
                .set("hour", dayjs().hour())
                .set("minute", dayjs().minute());
              onChange(dateWithTime?.toDate());
            } else {
              onChange(date);
            }
          }}
          renderInput={(params) => <TextField {...params} variant="outlined" />}
        />
      </LocalizationProvider>
    </Box>
  );
};
