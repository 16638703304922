import React, { useState } from "react";
import TableTotalCount from "../components/TableTotalCount";
import UiTable from "../components/UiTable";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { BootstrapTooltip } from "../utils/tooltip";
import { MdModeEditOutline } from "react-icons/md";

export default function ViewLimits({ handleEditLimit, limits, setLimits,updateStatus,loading }) {
  const navigate = useNavigate();

  const handlePaginationModel = (value) => {
    if (!value) return;
    setLimits((prev) => ({ ...prev, paginationModel: value }));
  };

  const handleRowClick = (params) => {
    // navigate(`/edit-limits/${params.id}`);
    console.log(params,"params")
  };

  const columns = [
    {
      field: "organization",
      headerName: "Organization",
      // minWidth: 190,
      flex: 1.7,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Box
              sx={{
                fontFamily: "ABeeZee",
                fontWeight: 400,
                fontSize: "13px",
                lineHeight: "15.37px",
                letterSpacing: "2%",
                textTransform: "capitalize",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              NA
            </Box>
          );
        }
        return (
          <BootstrapTooltip title={params.value}>
            <Box
              paddingRight="auto"
              marginLeft="5px"
              sx={{
                fontFamily: "ABeeZee",
                fontWeight: 400,
                fontSize: "13px",
                lineHeight: "15.37px",
                letterSpacing: "2%",
                textTransform: "capitalize",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {params?.value}
            </Box>
          </BootstrapTooltip>
        );
      },
    },
    {
      field: "merchant",
      headerName: "Merchant",
      // minWidth: 190,
      flex: 1.7,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Box
              sx={{
                fontFamily: "ABeeZee",
                fontWeight: 400,
                fontSize: "13px",
                lineHeight: "15.37px",
                letterSpacing: "2%",
                textTransform: "capitalize",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              NA
            </Box>
          );
        }
        return (
          <BootstrapTooltip title={params.value}>
            <Box
              sx={{
                fontFamily: "ABeeZee",
                fontWeight: 400,
                fontSize: "13px",
                lineHeight: "15.37px",
                letterSpacing: "2%",
                textTransform: "capitalize",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {params?.value}
            </Box>
          </BootstrapTooltip>
        );
      },
    },
    {
      field: "category",
      headerName: "Category",
      // minWidth: 190,
      flex: 1.7,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Box
              sx={{
                fontFamily: "ABeeZee",
                fontWeight: 400,
                fontSize: "13px",
                lineHeight: "15.37px",
                letterSpacing: "2%",
                textTransform: "capitalize",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              NA
            </Box>
          );
        }
        return (
          <BootstrapTooltip title={params.value}>
            <Box
              sx={{
                fontFamily: "ABeeZee",
                fontWeight: 400,
                fontSize: "13px",
                lineHeight: "15.37px",
                letterSpacing: "2%",
                textTransform: "capitalize",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {params?.value}
            </Box>
          </BootstrapTooltip>
        );
      },
    },
    {
      field: "subCategory",
      headerName: "Subcategory",
      flex: 1.7,
      // minWidth: 190,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Box
              sx={{
                fontFamily: "ABeeZee",
                fontWeight: 400,
                fontSize: "13px",
                lineHeight: "15.37px",
                letterSpacing: "2%",
                textTransform: "capitalize",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              NA
            </Box>
          );
        }

        return (
          <BootstrapTooltip title={params.value}>
            <Box
              sx={{
                fontFamily: "ABeeZee",
                fontWeight: 400,
                fontSize: "13px",
                lineHeight: "15.37px",
                letterSpacing: "2%",
                textTransform: "capitalize",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {params?.value}
            </Box>
          </BootstrapTooltip>
        );
      },
    },
    {
      field: "product",
      headerName: "Product ",
      // minWidth: 190,
      flex: 1.7,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Box
              sx={{
                fontFamily: "ABeeZee",
                fontWeight: 400,
                fontSize: "13px",
                lineHeight: "15.37px",
                letterSpacing: "2%",
                textTransform: "capitalize",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              NA
            </Box>
          );
        }

        return (
          <BootstrapTooltip title={params?.value}>
            <Box
              sx={{
                fontFamily: "ABeeZee",
                fontWeight: 400,
                fontSize: "13px",
                lineHeight: "15.37px",
                letterSpacing: "2%",
                textTransform: "capitalize",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {params?.value}
            </Box>
          </BootstrapTooltip>
        );
      },
    },
    {
      field: "daily",
      headerName: "Daily",
      // minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Box
              sx={{
                fontFamily: "ABeeZee",
                fontWeight: 400,
                fontSize: "13px",
                lineHeight: "15.37px",
                letterSpacing: "2%",
                textTransform: "capitalize",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              NA
            </Box>
          );
        }

        return (
          // <BootstrapTooltip title={params?.value}>
          <Box
            sx={{
              fontFamily: "ABeeZee",
              fontWeight: 400,
              fontSize: "13px",
              lineHeight: "15.37px",
              letterSpacing: "2%",
              textTransform: "capitalize",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {params?.value}
          </Box>
          // </BootstrapTooltip>
        );
      },
    },
    {
      field: "weekly",
      headerName: "Weekly",
      // minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Box
              sx={{
                fontFamily: "ABeeZee",
                fontWeight: 400,
                fontSize: "13px",
                lineHeight: "15.37px",
                letterSpacing: "2%",
                textTransform: "capitalize",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              NA
            </Box>
          );
        }

        return (
          // <BootstrapTooltip title={params?.value}>
          <Box
            sx={{
              fontFamily: "ABeeZee",
              fontWeight: 400,
              fontSize: "13px",
              lineHeight: "15.37px",
              letterSpacing: "2%",
              textTransform: "capitalize",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {params?.value}
          </Box>
          // </BootstrapTooltip>
        );
      },
    },
    {
      field: "monthly",
      headerName: "Monthly",
      // minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Box
              sx={{
                fontFamily: "ABeeZee",
                fontWeight: 400,
                fontSize: "13px",
                lineHeight: "15.37px",
                letterSpacing: "2%",
                textTransform: "capitalize",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              NA
            </Box>
          );
        }

        return (
          <Box
            sx={{
              fontFamily: "ABeeZee",
              fontWeight: 400,
              fontSize: "13px",
              lineHeight: "15.37px",
              letterSpacing: "2%",
              textTransform: "capitalize",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {params?.value}
          </Box>
        );
      },
    },
    {
      field: "total",
      headerName: "Total",
      // minWidth: 150,
      flex: 1.5,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Box
              sx={{
                fontFamily: "ABeeZee",
                fontWeight: 400,
                fontSize: "13px",
                lineHeight: "15.37px",
                letterSpacing: "2%",
                textTransform: "capitalize",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              NA
            </Box>
          );
        }

        return (
          <Box
            sx={{
              fontFamily: "ABeeZee",
              fontWeight: 400,
              fontSize: "13px",
              lineHeight: "15.37px",
              letterSpacing: "2%",
              textTransform: "capitalize",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {params?.value}
          </Box>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      // minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        return (
          <BootstrapTooltip title={ updateStatus? "Edit" :"You Don't Have Permission to Edit"}>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                marginLeft:"10px",
                color:updateStatus?"black":"gray"
              }}
              onClick={() => handleEditLimit(params.row)}
            >
              {params?.value}
            </Box>
          </BootstrapTooltip>
        );
      },
    },
  ];
  return (
    <Box>
      <Box>
        <UiTable
          row={limits?.data || []}
          columns={columns || []}
          totalCount={limits?.totalCount}
          paginationModel={limits?.paginationModel}
          setPaginationModel={handlePaginationModel}
          handleRowClick={handleRowClick}
          loading={loading}
        />
      </Box>
    </Box>
  );
}
