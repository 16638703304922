// import { Switch } from "@mui/material";
// import { styled } from "@mui/system";
// import React from "react";

// const BlackSwitch = styled(Switch)(({ theme }) => ({
//   '& .MuiSwitch-switchBase.Mui-checked': {
//     color: 'black',
//     // '&:hover': {
//     //   backgroundColor: 'black',
//     // },
//   },
//   '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
//     backgroundColor: 'black',
//   },
// }));

// const CustomSwitch = ({checked,onChange}) => {
//   return <BlackSwitch checked={checked} onChange={onChange}/>;
// };

// export default CustomSwitch;
import { Switch } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";

const SmallSwitch = styled(Switch)(({ theme }) => ({
  width: 32,  // Adjusted width
  height: 16, // Adjusted height
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 2, // Reduced padding for the thumb
    '&.Mui-checked': {
      transform: 'translateX(16px)', // Adjust for the smaller size
      color: '#003D86',
    },
  },
  '& .MuiSwitch-thumb': {
    width: 12, // Adjust thumb size
    height: 12, // Adjust thumb size
  },
  '& .MuiSwitch-track': {
    borderRadius: 20 / 2, // Adjust border radius for the track
    backgroundColor: 'black',
    opacity: 1,
  },
}));

const CustomSwitch = ({ checked, onChange }) => {
  return <SmallSwitch checked={checked} onChange={onChange} />;
};

export default CustomSwitch;
