import { Box, Button, Switch, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import MuiInput from "../../MuiInput";
import Label from "../../Label";
import MuiSelectAndSearch from "../../MuiSelectAndSearch";
import plusIcon from "../../../assets/plusBtn.png";
import SmallImageUpload from "../../SmallImageUpload";
import tickImage from "../../../assets/whiteTick.png";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import MuiRadioGroup from "../../MuiRadioGroup";
import deleteLogo from "../../../assets/delete_black.png";
import editLogo from "../../../assets/edit_black.png";
import BackButtonImg from "../../../assets/backArrow.png";
import CreateReward from "../CreateReward";
import DeleteConformation from "../DeleteConformation";
import Trivia_CreateReward from "./Trivia_CreateReward";
// import Trivia_WhilePlay from "./Trivia_WhilePlay";
// import Trivia_BeforePlay from "./Trivia_BeforePlay";
// import Trivia_AfterPlay from "./Trivia_AfterPlay";
import BeforePlay from "../BeforePlay";
import AfterPlay from "../AfterPlay";
import WhenPlay from "../WhenPlay";
import { toast, ToastContainer } from "react-toastify";
import {
  clearRewardPreviousValue,
  saveRewardPreviousValue,
} from "../../../Redux/OfferReducer/actionType";
import { getRewardTypesFunction } from "../../../Redux/OfferReducer/action";
import EditReward from "../EditReward";

// import DeleteConformation from "./DeleteConformation";
// const array = [
//   {
//     title: "",
//     title_required: true,
//     probability: "",
//     probability_required: true,
//     limit: false,
//     limit_required: true,
//     rewardLevel: "",
//     rewardLevel_required: true,
//     rewardType: "",
//     rewardType_required: true,
//     rewardLimit: [
//       {
//         time: "",
//         time_required: true,
//         amount: "",
//         amount_required: true,
//       },
//       {
//         time: "",
//         time_required: true,
//         amount: "",
//         amount_required: true,
//       },
//     ],
//   },
//   {
//     title: "",
//     title_required: true,
//     probability: "",
//     probability_required: true,
//     limit: false,
//     limit_required: true,
//     rewardLevel: "",
//     rewardLevel_required: true,
//     rewardType: "",
//     rewardType_required: true,
//     rewardLimit: [
//       {
//         time: "",
//         time_required: true,
//         amount: "",
//         amount_required: false,
//       },
//       {
//         time: "",
//         time_required: true,
//         amount: "",
//         amount_required: true,
//       },
//     ],
//   },
// ];

// const images = [
//   "https://images.unsplash.com/photo-1653489218319-0e59c55ddc68?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
//   "https://plus.unsplash.com/premium_photo-1694004709804-e51d0c54f57f?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
//   "https://plus.unsplash.com/premium_photo-1681533650874-9a0c01a7ee53?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fHNjcmF0Y2glMjBjYXJkfGVufDB8fDB8fHww",
// ];
const options = [
  { name: "Option 1", id: "option1" },
  { name: "Option 2", id: "option2" },
  { name: "Option 3", id: "option3" },
  { name: "Option 4", id: "option4" },
];
// const option = [
//   { value: "option1", label: "Option 1" },
//   { value: "option2", label: "Option 2" },
//   { value: "option3", label: "Option 3" },
//   { value: "option4", label: "Option 4" },
//   { value: "option5", label: "Option 5" },
// ];

// const beforePlayData = {
//   title_required: true,
//   titleColor_required: true,
//   merchantLogo_required: true,
//   scratchCard_required: true,
//   backgroundColor_required: true,
// };

// const afterPlayData = {
//   title_required: true,
//   titleColor_required: true,
//   noRewardTitle_required: true,
//   noRewardTitleColor_required: true,
//   scratchCard_required: true,
//   backgroundColor_required: true,
// };

// const whenPlayData = {
//   title_required: true,
//   titleColor_required: true,
//   noRewardTitle_required: true,
//   noRewardTitleColor_required: true,
//   scratchCard_required: true,
//   backgroundColor_required: true,
// };
const schema = {
  // title: "",
  title_required: true,
  // probability: "",
  probability_required: true,
  limit: false,
  limit_required: true,
  // rewardLevel: "",
  rewardLevel_required: true,
  // rewardType: "",
  rewardType_required: true,
  rewardLimit: [
    {
      // time: "",
      time_required: true,
      // amount: "",
      amount_required: true,
    },
  ],
};

const schemaWithReward = {
  // title: "",
  title_required: true,
  // probability: "",
  probability_required: true,
  limit: false,
  limit_required: true,
  // rewardLevel: "",
  rewardLevel_required: true,
  // rewardType: "",
  reward_type: {
    id: "cashback",
    name: "Cashback",
  },
  rewardType_required: true,
  rewardLimit: [
    {
      // time: "",
      time_required: true,
      // amount: "",
      amount_required: true,
    },
  ],
};

export default function Rewards_Trivia({
  data,
  onChange,
  errorMessage,
  // rewardArray = array,
  handleBack,
  handleNext,
  setGameType,
  fields,
}) {
  const [step, setStep] = useState(1);
  const [loader, setLoader] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [createReward, setCreateReward] = useState(false);
  const [activeBox, setActiveBox] = useState(null);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const token = useSelector((store) => store.AuthReducer.token);
  const BaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [rewardIndex, setRewardIndex] = useState(0);
  const [rewardTypeName, setRewardTypeName] = useState(() => {
    const triviaData = data?.game?.triviaData;
    if (Array.isArray(triviaData) && triviaData[rewardIndex]) {
      return triviaData[rewardIndex].reward_type?.name || "";
    }
    return "";
  });
  const previousRewardValue = useSelector(
    (store) => store?.OfferReducer?.rewardPreviousValue
  );
  const rewards = useSelector(
    (store) => store?.OfferReducer?.allRewards
  )?.rewardTypes?.find((item) => item.name === rewardTypeName)?.reward_details;

  const limitOptions = useSelector(
    (store) => store?.OfferReducer?.allRewards
  )?.rewardTypes?.find((item) => item.name === rewardTypeName)?.limitOptions;

  const dispatch = useDispatch();
  console.log(rewards, "previousRewardValue");

  const minimumRewardNumber = fields?.minimum_rewards?.value;
  const rewardArray = new Array(minimumRewardNumber).fill(schemaWithReward);

  console.log(data, "scratchCardV2");
  const handleData = (name, value, index) => {
    console.log(name, value, index);

    const updatedScratchCardData = [...(data?.game?.triviaData || [])];
    if (updatedScratchCardData[index]) {
      updatedScratchCardData[index] = {
        ...updatedScratchCardData[index],
        [name]: value,
      };
    }

    onChange({
      ...data,
      game: {
        ...data?.game,
        triviaData: updatedScratchCardData,
      },
    });
  };

  const handleRewardLimitChange = (
    scratchCardIndex,
    limitIndex,
    field,
    value
  ) => {
    const updatedScratchCardData = JSON.parse(JSON.stringify(data.game.triviaData));
    updatedScratchCardData[scratchCardIndex].rewardLimit[limitIndex][field] = value;
    onChange({
      ...data,
      game: {
        ...data.game,
        triviaData: updatedScratchCardData,
      },
    });
  };

  const handleNextStep = () => {
    if (step < 4) {
      setStep(step + 1);
    } else {
      handleNext();
    }
  };

  const handleBackStep = () => {
    if (step > 1) {
      setStep(step - 1);
    } else {
      handleBack();
    }
  };

  // const handleBeforePlayChange = (field, value, obj) => {
  //   if (field == "merchantLogoUpload") {
  //     setLoader(true);
  //     const formData = new FormData();
  //     formData.append("file", value);
  //     axios
  //       .post(`${BaseUrl}/api/upload`, formData, {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           "Content-Type": "multipart/form-data",
  //         },
  //       })
  //       .then((response) => {
  //         console.log("Upload successful", response.data);
  //         onChange({
  //           ...data,
  //           beforePlay: {
  //             ...data.beforePlay,
  //             triviaData: {
  //               ...data.beforePlay.triviaData,
  //               [field]: value,
  //               [`${field}Url`]: response.data.public_url,
  //               [`${field}Id`]: response.data.id,
  //               merchantLogo: response.data.public_url,
  //             },
  //           },
  //         });
  //       })
  //       .catch((error) => {
  //         console.error("Error uploading file:", error);
  //         // to show error in case any error occurs in uploading image
  //         // toast.error("error uploading file")
  //       })
  //       .finally(() => {
  //         setLoader(false);
  //       });
  //   } else if (field == "scratchCardUpload") {
  //     setLoader(true);
  //     const formData = new FormData();
  //     formData.append("file", value);
  //     axios
  //       .post(`${BaseUrl}/api/upload`, formData, {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           "Content-Type": "multipart/form-data",
  //         },
  //       })
  //       .then((response) => {
  //         console.log("Upload successful", response.data);
  //         onChange({
  //           ...data,
  //           beforePlay: {
  //             ...data.beforePlay,
  //             triviaData: {
  //               ...data.beforePlay.triviaData,
  //               [field]: value,
  //               [`${field}Url`]: response.data.public_url,
  //               [`${field}Id`]: response.data.id,
  //               scratchCard: response.data.public_url,
  //             },
  //           },
  //         });
  //       })
  //       .catch((error) => {
  //         console.error("Error uploading file:", error);
  //         // to show error in case any error occurs in uploading image
  //         // toast.error("error uploading file")
  //       })
  //       .finally(() => {
  //         setLoader(false);
  //       });
  //   } else if (field == "backgroundColorUpload") {
  //     setLoader(true);
  //     const formData = new FormData();
  //     formData.append("file", value);
  //     axios
  //       .post(`${BaseUrl}/api/upload`, formData, {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           "Content-Type": "multipart/form-data",
  //         },
  //       })
  //       .then((response) => {
  //         console.log("Upload successful", response.data);
  //         onChange({
  //           ...data,
  //           beforePlay: {
  //             ...data.beforePlay,
  //             triviaData: {
  //               ...data.beforePlay.triviaData,
  //               [field]: value,
  //               [`${field}Url`]: response.data.public_url,
  //               [`${field}Id`]: response.data.id,
  //               backgroundColor: response.data.public_url,
  //             },
  //           },
  //         });
  //       })
  //       .catch((error) => {
  //         console.error("Error uploading file:", error);
  //         // to show error in case any error occurs in uploading image
  //         // toast.error("error uploading file")
  //       })
  //       .finally(() => {
  //         setLoader(false);
  //       });
  //   } else {
  //     if (field === "prefilledData") {
  //       onChange({
  //         ...data,
  //         beforePlay: {
  //           ...data.beforePlay,
  //           triviaData: {
  //             ...data.beforePlay.triviaData,
  //             ...obj,
  //           },
  //         },
  //       });
  //     } else {
  //       onChange({
  //         ...data,
  //         beforePlay: {
  //           ...data.beforePlay,
  //           triviaData: {
  //             ...data.beforePlay.triviaData,
  //             [field]: value,
  //           },
  //         },
  //       });
  //     }
  //   }
  // };
  const handleBeforePlayChange = (field, value, obj) => {
    if (field.endsWith("_upload")) {
      setLoader(true);
      const formData = new FormData();
      formData.append("file", value);
      axios
        .post(`${BaseUrl}/api/upload`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log("Upload successful", response.data);
          const baseField = field.replace("_upload", "");
          onChange({
            ...data,
            beforePlay: {
              ...data.beforePlay,
              triviaData: {
                ...data.beforePlay.triviaData,
                [field]: value,
                [`${baseField}_upload_url`]: response.data.public_url,
                [`${baseField}_upload_id`]: response.data.id,
                [baseField]: response.data.public_url,
              },
            },
          });
        })
        .catch((error) => {
          console.error("Error uploading file:", error);
          // To show error in case any error occurs in uploading image
          // toast.error("Error uploading file")
        })
        .finally(() => {
          setLoader(false);
        });
    } else {
      if (field === "prefilledData") {
        onChange({
          ...data,
          beforePlay: {
            ...data.beforePlay,
            triviaData: {
              ...data.beforePlay.triviaData,
              ...obj,
            },
          },
        });
      } else {
        onChange({
          ...data,
          beforePlay: {
            ...data.beforePlay,
            triviaData: {
              ...data.beforePlay.triviaData,
              [field]: value,
            },
          },
        });
      }
    }
  };
  // const handleWhenPlayChange = (field, value, obj) => {
  //   if (field == "merchantLogoUpload") {
  //     setLoader(true);
  //     const formData = new FormData();
  //     formData.append("file", value);
  //     axios
  //       .post(`${BaseUrl}/api/upload`, formData, {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           "Content-Type": "multipart/form-data",
  //         },
  //       })
  //       .then((response) => {
  //         console.log("Upload successful", response.data);
  //         onChange({
  //           ...data,
  //           whenPlay: {
  //             ...data.whenPlay,
  //             triviaData: {
  //               ...data.whenPlay.triviaData,
  //               [field]: value,
  //               [`${field}Url`]: response.data.public_url,
  //               [`${field}Id`]: response.data.id,
  //               merchantLogo: response.data.public_url,
  //             },
  //           },
  //         });
  //       })
  //       .catch((error) => {
  //         console.error("Error uploading file:", error);
  //         // to show error in case any error occurs in uploading image
  //         // toast.error("error uploading file")
  //       })
  //       .finally(() => {
  //         setLoader(false);
  //       });
  //   } else if (field == "scratchCardUpload") {
  //     setLoader(true);
  //     const formData = new FormData();
  //     formData.append("file", value);
  //     axios
  //       .post(`${BaseUrl}/api/upload`, formData, {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           "Content-Type": "multipart/form-data",
  //         },
  //       })
  //       .then((response) => {
  //         console.log("Upload successful", response.data);
  //         onChange({
  //           ...data,
  //           whenPlay: {
  //             ...data.whenPlay,
  //             triviaData: {
  //               ...data.whenPlay.triviaData,
  //               [field]: value,
  //               [`${field}Url`]: response.data.public_url,
  //               [`${field}Id`]: response.data.id,
  //               scratchCard: response.data.public_url,
  //             },
  //           },
  //         });
  //       })
  //       .catch((error) => {
  //         console.error("Error uploading file:", error);
  //         // to show error in case any error occurs in uploading image
  //         // toast.error("error uploading file")
  //       })
  //       .finally(() => {
  //         setLoader(false);
  //       });
  //   } else if (field == "backgroundColorUpload") {
  //     setLoader(true);
  //     const formData = new FormData();
  //     formData.append("file", value);
  //     axios
  //       .post(`${BaseUrl}/api/upload`, formData, {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           "Content-Type": "multipart/form-data",
  //         },
  //       })
  //       .then((response) => {
  //         console.log("Upload successful", response.data);
  //         onChange({
  //           ...data,
  //           whenPlay: {
  //             ...data.whenPlay,
  //             triviaData: {
  //               ...data.whenPlay.triviaData,
  //               [field]: value,
  //               [`${field}Url`]: response.data.public_url,
  //               [`${field}Id`]: response.data.id,
  //               backgroundColor: response.data.public_url,
  //             },
  //           },
  //         });
  //       })
  //       .catch((error) => {
  //         console.error("Error uploading file:", error);
  //         // to show error in case any error occurs in uploading image
  //         // toast.error("error uploading file")
  //       })
  //       .finally(() => {
  //         setLoader(false);
  //       });
  //   } else {
  //     if (field === "prefilledData") {
  //       onChange({
  //         ...data,
  //         whenPlay: {
  //           ...data.whenPlay,
  //           triviaData: {
  //             ...data.whenPlay.triviaData,
  //             ...obj,
  //           },
  //         },
  //       });
  //     } else {
  //       onChange({
  //         ...data,
  //         whenPlay: {
  //           ...data.whenPlay,
  //           triviaData: {
  //             ...data.whenPlay.triviaData,
  //             [field]: value,
  //           },
  //         },
  //       });
  //     }
  //   }
  // };

  // const handleAfterPlayChange = (field, value, obj) => {
  //   if (field == "merchantLogoUpload") {
  //     setLoader(true);
  //     const formData = new FormData();
  //     formData.append("file", value);
  //     axios
  //       .post(`${BaseUrl}/api/upload`, formData, {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           "Content-Type": "multipart/form-data",
  //         },
  //       })
  //       .then((response) => {
  //         console.log("Upload successful", response.data);
  //         onChange({
  //           ...data,
  //           afterPlay: {
  //             ...data.afterPlay,
  //             triviaData: {
  //               ...data.afterPlay.triviaData,
  //               [field]: value,
  //               [`${field}Url`]: response.data.public_url,
  //               [`${field}Id`]: response.data.id,
  //               merchantLogo: response.data.public_url,
  //             },
  //           },
  //         });
  //       })
  //       .catch((error) => {
  //         console.error("Error uploading file:", error);
  //         // to show error in case any error occurs in uploading image
  //         // toast.error("error uploading file")
  //       })
  //       .finally(() => {
  //         setLoader(false);
  //       });
  //   } else if (field == "scratchCardUpload") {
  //     setLoader(true);
  //     const formData = new FormData();
  //     formData.append("file", value);
  //     axios
  //       .post(`${BaseUrl}/api/upload`, formData, {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           "Content-Type": "multipart/form-data",
  //         },
  //       })
  //       .then((response) => {
  //         console.log("Upload successful", response.data);
  //         onChange({
  //           ...data,
  //           afterPlay: {
  //             ...data.afterPlay,
  //             triviaData: {
  //               ...data.afterPlay.triviaData,
  //               [field]: value,
  //               [`${field}Url`]: response.data.public_url,
  //               [`${field}Id`]: response.data.id,
  //               scratchCard: response.data.public_url,
  //             },
  //           },
  //         });
  //       })
  //       .catch((error) => {
  //         console.error("Error uploading file:", error);
  //         // to show error in case any error occurs in uploading image
  //         // toast.error("error uploading file")
  //       })
  //       .finally(() => {
  //         setLoader(false);
  //       });
  //   } else if (field == "backgroundColorUpload") {
  //     setLoader(true);
  //     const formData = new FormData();
  //     formData.append("file", value);
  //     axios
  //       .post(`${BaseUrl}/api/upload`, formData, {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           "Content-Type": "multipart/form-data",
  //         },
  //       })
  //       .then((response) => {
  //         console.log("Upload successful", response.data);
  //         onChange({
  //           ...data,
  //           afterPlay: {
  //             ...data.afterPlay,
  //             triviaData: {
  //               ...data.afterPlay.triviaData,
  //               [field]: value,
  //               [`${field}Url`]: response.data.public_url,
  //               [`${field}Id`]: response.data.id,
  //               backgroundColor: response.data.public_url,
  //             },
  //           },
  //         });
  //       })
  //       .catch((error) => {
  //         console.error("Error uploading file:", error);
  //         // to show error in case any error occurs in uploading image
  //         // toast.error("error uploading file")
  //       })
  //       .finally(() => {
  //         setLoader(false);
  //       });
  //   } else {
  //     if (field === "prefilledData") {
  //       onChange({
  //         ...data,
  //         afterPlay: {
  //           ...data.afterPlay,
  //           triviaData: {
  //             ...data.afterPlay.triviaData,
  //             ...obj,
  //           },
  //         },
  //       });
  //     } else {
  //       onChange({
  //         ...data,
  //         afterPlay: {
  //           ...data.afterPlay,
  //           triviaData: {
  //             ...data.afterPlay.triviaData,
  //             [field]: value,
  //           },
  //         },
  //       });
  //     }
  //   }
  // };
  const handleWhenPlayChange = (field, value, obj) => {
    if (field.endsWith("_upload")) {
      setLoader(true);
      const formData = new FormData();
      formData.append("file", value);
      axios
        .post(`${BaseUrl}/api/upload`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log("Upload successful", response.data);
          const baseField = field.replace("_upload", "");
          onChange({
            ...data,
            whenPlay: {
              ...data.whenPlay,
              triviaData: {
                ...data.whenPlay.triviaData,
                [field]: value,
                [`${baseField}_upload_url`]: response.data.public_url,
                [`${baseField}_upload_id`]: response.data.id,
                [baseField]: response.data.public_url,
              },
            },
          });
        })
        .catch((error) => {
          console.error("Error uploading file:", error);
          // To show error in case any error occurs in uploading image
          // toast.error("Error uploading file")
        })
        .finally(() => {
          setLoader(false);
        });
    } else {
      if (field === "prefilledData") {
        onChange({
          ...data,
          whenPlay: {
            ...data.whenPlay,
            triviaData: {
              ...data.whenPlay.triviaData,
              ...obj,
            },
          },
        });
      } else {
        onChange({
          ...data,
          whenPlay: {
            ...data.whenPlay,
            triviaData: {
              ...data.whenPlay.triviaData,
              [field]: value,
            },
          },
        });
      }
    }
  };

  const handleAfterPlayChange = (field, value, obj) => {
    if (field.endsWith("_upload")) {
      setLoader(true);
      const formData = new FormData();
      formData.append("file", value);
      axios
        .post(`${BaseUrl}/api/upload`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log("Upload successful", response.data);
          const baseField = field.replace("_upload", "");
          onChange({
            ...data,
            afterPlay: {
              ...data.afterPlay,
              triviaData: {
                ...data.afterPlay.triviaData,
                [field]: value,
                [`${baseField}_upload_url`]: response.data.public_url,
                [`${baseField}_upload_id`]: response.data.id,
                [baseField]: response.data.public_url,
              },
            },
          });
        })
        .catch((error) => {
          console.error("Error uploading file:", error);
          // To show error in case any error occurs in uploading image
          // toast.error("Error uploading file")
        })
        .finally(() => {
          setLoader(false);
        });
    } else {
      if (field === "prefilledData") {
        onChange({
          ...data,
          afterPlay: {
            ...data.afterPlay,
            triviaData: {
              ...data.afterPlay.triviaData,
              ...obj,
            },
          },
        });
      } else {
        onChange({
          ...data,
          afterPlay: {
            ...data.afterPlay,
            triviaData: {
              ...data.afterPlay.triviaData,
              [field]: value,
            },
          },
        });
      }
    }
  };
   const handleAddNewRewards = (field, value, obj = {}) => {
    onChange({
      ...data,
      addNewRewards: {
        ...data.addNewRewards,
        triviaData: {
          ...data.addNewRewards.triviaData,
          startFromScratch: {
            ...data.addNewRewards.triviaData.startFromScratch,
            ...obj
          },
          [field]: value,
        },
      },
    });
  };

  const handleStartFromScratch = (field, value) => {
    if (field === "csvFile" && value) {
      // Check if the selected file is a CSV file
      console.log(field, value.type, "csvFile");
      if (value.type !== "text/csv") {
        onChange({
          ...data,
          addNewRewards: {
            ...data.addNewRewards,
            triviaData: {
              ...data.addNewRewards.triviaData,
              startFromScratch: {
                ...data.addNewRewards.triviaData.startFromScratch,
                csvError: "Please select a CSV file",
              },
            },
          },
        });
        return;
      } else {
        onChange({
          ...data,
          addNewRewards: {
            ...data.addNewRewards,
            triviaData: {
              ...data.addNewRewards.triviaData,
              startFromScratch: {
                ...data.addNewRewards.triviaData.startFromScratch,
                csvError: "",
              },
            },
          },
        });
      }

      // Upload CSV file
      setLoader(true);
      const formData = new FormData();
      formData.append("file", value);
      axios
        .post(`${BaseUrl}/api/upload?voucherCSV=true`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log("CSV upload successful", response.data);
          onChange({
            ...data,
            addNewRewards: {
              ...data.addNewRewards,
              triviaData: {
                ...data.addNewRewards.triviaData,
                startFromScratch: {
                  ...data.addNewRewards.triviaData.startFromScratch,
                  [field]: value,
                  csvUrl: response.data?.public_url,
                  voucherCollectionId: response.data?.voucherCollectionId,
                },
              },
            },
          });
        })
        .catch((error) => {
          console.error("Error uploading CSV file:", error);
          onChange({
            ...data,
            addNewRewards: {
              ...data.addNewRewards,
              triviaData: {
                ...data.addNewRewards.triviaData,
                startFromScratch: {
                  ...data.addNewRewards.triviaData.startFromScratch,
                  csvError: "Error uploading CSV file",
                },
              },
            },
          });
        })
        .finally(() => {
          setLoader(false);
        });
    } else if (field === "rewardIconUpload") {
      const formData = new FormData();
      formData.append("file", value);
      axios
        .post(`${BaseUrl}/api/upload`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log("Upload successful", response.data);
          onChange({
            ...data,
            addNewRewards: {
              ...data.addNewRewards,
              triviaData: {
                ...data.addNewRewards.triviaData,
                startFromScratch: {
                  ...data.addNewRewards.triviaData.startFromScratch,
                  [field]: value,
                  [`${field}Url`]: response.data.public_url,
                  [`${field}Id`]: response.data.id,
                  rewardIcon: response.data.public_url,
                },
              },
            },
          });
        })
        .catch((error) => {
          console.error("Error uploading file:", error);
          // You might want to handle this error, e.g., set an error state
        });
    } else {
      onChange({
        ...data,
        addNewRewards: {
          ...data?.addNewRewards,
          triviaData: {
            ...data?.addNewRewards?.triviaData,
            startFromScratch: {
              ...data?.addNewRewards?.triviaData?.startFromScratch,
              [field]: value,
            },
          },
        },
      });
    }
  };
  const handleDeleteLimit = (rewardIndex, limitIndex) => {
    const updatedScratchCardData = [...data.game.triviaData];
    const updatedRewardLimits = [
      ...updatedScratchCardData[rewardIndex].rewardLimit,
    ];

    // Remove the limit at the specified index
    updatedRewardLimits.splice(limitIndex, 1);

    // Update the state
    onChange({
      ...data,
      game: {
        ...data.game,
        triviaData: updatedScratchCardData.map((reward, index) =>
          index === rewardIndex
            ? { ...reward, rewardLimit: updatedRewardLimits }
            : reward
        ),
      },
    });
  };

  const handleAddLimit = (rewardIndex) => {
    const updatedScratchCardData = [...data.game.triviaData];
    const updatedRewardLimits = [
      ...updatedScratchCardData[rewardIndex].rewardLimit,
    ];

    // Add a new limit object
    updatedRewardLimits.push({
      time: "",
      time_required: true,
      amount: "",
      amount_required: true,
    });

    // Update the state
    onChange({
      ...data,
      game: {
        ...data.game,
        triviaData: updatedScratchCardData.map((reward, index) =>
          index === rewardIndex
            ? { ...reward, rewardLimit: updatedRewardLimits }
            : reward
        ),
      },
    });
  };
  const handleCreateReward = () => {
    const rewardData = {
      ...schema,
      ...data?.addNewRewards?.triviaData?.startFromScratch,
      ...limitOptions
    };

    console.log(rewardData, "data");
    const updatedData = [...data?.game?.triviaData, rewardData];
    onChange({
      ...data,
      game: {
        ...data.game,
        triviaData: updatedData,
      },
      addNewRewards: {
        ...data.addNewRewards,
        triviaData: {
          ...data.addNewRewards.triviaData,
          rewardType: "",
        },
      },
    });

    setCreateReward(false);
    toast.success("Reward Created Successfully");
  };

  const handleEditReward = (field, value, rewardIndex) => {
    const updatedScratchCardData = [...data?.game?.triviaData];
    const updatedReward = { ...updatedScratchCardData[rewardIndex], ...limitOptions };
  
    if (field === "csvFile" && value) {
      // Check if the selected file is a CSV file
      console.log(field, value.type, "csvFile");
      if (value.type !== "text/csv") {
        updatedReward.csvError = "Please select a CSV file";
        updatedScratchCardData[rewardIndex] = updatedReward;
        onChange({
          ...data,
          game: {
            ...data.game,
            triviaData: updatedScratchCardData,
          },
        });
        return;
      } else {
        updatedReward.csvError = "";
        updatedScratchCardData[rewardIndex] = updatedReward;
        onChange({
          ...data,
          game: {
            ...data.game,
            triviaData: updatedScratchCardData,
          },
        });
      }
  
      // Upload CSV file
      setLoader(true);
      const formData = new FormData();
      formData.append("file", value);
      axios
        .post(`${BaseUrl}/api/upload?voucherCSV=true`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log("CSV upload successful", response.data);
  
          updatedReward[field] = value;
          updatedReward.csvUrl = response.data?.public_url;
          updatedReward.voucherCollectionId = response.data?.voucherCollectionId;
          updatedScratchCardData[rewardIndex] = updatedReward;
  
          onChange({
            ...data,
            game: {
              ...data.game,
              triviaData: updatedScratchCardData,
            },
          });
        })
        .catch((error) => {
          console.error("Error uploading CSV file:", error);
          updatedReward.csvError = "Error uploading CSV file";
          updatedScratchCardData[rewardIndex] = updatedReward;
          onChange({
            ...data,
            game: {
              ...data.game,
              triviaData: updatedScratchCardData,
            },
          });
        })
        .finally(() => {
          setLoader(false);
        });
    } else if (field === "rewardIconUpload") {
      const formData = new FormData();
      formData.append("file", value);
  
      setLoader(true);
  
      axios
        .post(`${BaseUrl}/api/upload`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log("Upload successful", response.data);
  
          updatedReward[field] = value;
          updatedReward[`${field}Url`] = response.data.public_url;
          updatedReward[`${field}Id`] = response.data.id;
          updatedReward.rewardIcon = response.data.public_url;
  
          updatedScratchCardData[rewardIndex] = updatedReward;
  
          onChange({
            ...data,
            game: {
              ...data.game,
              triviaData: updatedScratchCardData,
            },
          });
        })
        .catch((error) => {
          console.error("Error uploading file:", error);
          // Handle error if needed
        })
        .finally(() => {
          setLoader(false);
        });
    } else {
      updatedReward[field] = value;
      updatedScratchCardData[rewardIndex] = updatedReward;
  
      onChange({
        ...data,
        game: {
          ...data.game,
          triviaData: updatedScratchCardData,
        },
      });
    }
  };

  const handleUpdateReward = () => {
    setIsEdit(false);
    dispatch({ type: clearRewardPreviousValue });
    toast.success("Reward Updated Successfully");
  };

  const handleOpenEditReward = (rewardIndex) => {
    setIsEdit(true);
    setRewardIndex(rewardIndex);
    dispatch({
      type: saveRewardPreviousValue,
      payload: data?.game?.triviaData,
    });
  };

  const handleCancelEditReward = () => {
    setIsEdit(false);
    onChange({
      ...data,
      game: {
        ...data.game,
        triviaData: previousRewardValue,
      },
    });
    dispatch({ type: clearRewardPreviousValue });
  };

  const handleOpenDeleteConfirmationBox = (index) => {
    setRewardIndex(index);
    handleOpen();
  };

  const handleDeleteReward = (index) => {
    const updatedScratchCardData = [...data.game.triviaData];
    updatedScratchCardData.splice(index, 1);
    onChange({
      ...data,
      game: {
        ...data.game,
        triviaData: updatedScratchCardData,
      },
    });
    handleClose();
    toast.success("Reward Deleted Successfully");
  };

  useEffect(() => {
    onChange({
      ...data,
      game: {
        ...data?.game,
        triviaData:
          data?.game?.triviaData?.length > 0
            ? data?.game?.triviaData
            : rewardArray,
      },
    });
    setStep(1);
    dispatch(getRewardTypesFunction(1, 5));
  }, []);

  useEffect(() => {
    const triviaData = data?.game?.triviaData;
    if (Array.isArray(triviaData) && triviaData[rewardIndex]) {
      setRewardTypeName(triviaData[rewardIndex].reward_type?.name || "");
    }
  }, [data?.game?.triviaData, rewardIndex]);
  return (
    <Box>
      <DeleteConformation
        open={open}
        setOpen={setOpen}
        handleClose={handleClose}
        handleOpen={handleOpen}
        rewardNumber={rewardIndex + 1}
        handleDeleteReward={handleDeleteReward}
      />
      <ToastContainer />
      <Box
        sx={{
          display: isEdit || createReward ? "none" : "flex",
          justifyContent: "start",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Button sx={{ minWidth: "0px" }} onClick={() => setGameType("")}>
            <img src={BackButtonImg} alt="" />
          </Button>
          <Typography
            sx={{
              fontFamily: "Mulish",
              fontWeight: 700,
              fontSize: "20px",
              lineHeight: "26px",
              color: "#0F1828",
              // ml: "12px",
            }}
          >
            Trivia
          </Typography>
        </Box>
      </Box>
      {step === 1 ? (
        createReward ? (
          <Box>
            <Trivia_CreateReward
              createReward={createReward}
              setCreateReward={setCreateReward}
              handleAddNewRewards={handleAddNewRewards}
              data={data}
              handleStartFromScratch={handleStartFromScratch}
              onChange={onChange}
              handleCreateReward={handleCreateReward}
              rewardTypeName={rewardTypeName}
              setRewardTypeName={setRewardTypeName}
            />
          </Box>
        ) : isEdit ? (
          <Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Button sx={{ minWidth: "0px" }} onClick={handleCancelEditReward}>
                <img src={BackButtonImg} alt="" />
              </Button>
              <Typography
                sx={{
                  fontFamily: "Mulish",
                  fontWeight: 700,
                  fontSize: "20px",
                  lineHeight: "26px",
                  color: "#0F1828",
                  // ml: "12px",
                }}
              >
                Edit Reward
              </Typography>
            </Box>
            <EditReward
              rewards={rewards}
              data={data}
              // handleStartFromScratch={handleStartFromScratch}
              handleEditReward={handleEditReward}
              onChange={onChange}
              field={"triviaData"}
              setRewardTypeName={setRewardTypeName}
              rewardIndex={rewardIndex}
              handleCancelEditReward={handleCancelEditReward}
              handleUpdateReward={handleUpdateReward}
            />
          </Box>
        ) : (
          <Box>
            <Typography
              sx={{
                fontFamily: "Mulish",
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "20px",
                color: "#0F1828",
                marginBottom: "10px",
                textAlign: "start",
                marginLeft: "10px",
                marginTop: "20px",
              }}
            >
              Add Reward (
              {fields?.minimum_rewards?.label +
                " " +
                fields?.minimum_rewards?.value}
              )
            </Typography>
            {data?.game?.triviaData?.map((reward, index) => (
              <Box
                key={index}
                sx={{
                  width: "400px",
                  border:
                    activeBox === index
                      ? "1px solid black"
                      : "1px solid #E3E7EF",
                  padding: "10px",
                  margin: "10px",
                  borderRadius: "8px",
                  padding: "20px",
                }}
                onClick={() => setActiveBox(index)}
                onMouseEnter={() => setActiveBox(index)}
                onMouseLeave={() => {
                  // Only set activeBox to null if no child element is focused
                  if (
                    !document.activeElement ||
                    !document.activeElement.closest(`[data-index="${index}"]`)
                  ) {
                    setActiveBox(null);
                  }
                }}
                data-index={index}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Mulish",
                      fontWeight: "600",
                      fontSize: "14px",
                      lineHeight: "17.57px",
                      color: "#000000",
                      textAlign: "left",
                      mb: "16px",
                    }}
                  >{`Reward #${index + 1}`}</Typography>

                  <Box sx={{ display: "flex", gap: "1px", mt: "-10px" }}>
                    <Button
                      sx={{ minWidth: "0px" }}
                      onClick={() => handleOpenEditReward(index)}
                    >
                      <img
                        style={{ height: "24px", width: "24px" }}
                        src={editLogo}
                        alt=""
                      />
                    </Button>
                    <Button
                      sx={{
                        minWidth: "0px",
                        display:
                          data?.game?.triviaData?.length > minimumRewardNumber
                            ? "block"
                            : "none",
                      }}
                      onClick={() => handleOpenDeleteConfirmationBox(index)}
                    >
                      <img
                        style={{ height: "24px", width: "24px" }}
                        src={deleteLogo}
                        alt=""
                      />
                    </Button>
                  </Box>
                </Box>
                <Box
                  sx={{ display: reward?.title_required ? "block" : "none" }}
                >
                  <Label label="Reward Title" />
                  <MuiInput
                    value={data?.game?.triviaData[index]?.title}
                    onChange={(event) => handleData("title", event, index)}
                  />
                </Box>
                <Box
                  sx={{
                    display: reward?.probability_required ? "block" : "none",
                    mt: "10px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "2px",
                    }}
                  >
                    <Label label="Score" />
                    <Typography
                      sx={{
                        fontFamily: "Mulish",
                        fontWeight: 400,
                        fontSize: "12px",
                        lineHeight: "15.06px",
                        color: "#6F747E",
                        mt: "2px",
                      }}
                    >
                      (Users with score between 0/1 to 1/1 % Will receive this
                      reward)
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      gap: "10px",
                    }}
                  >
                    <Box sx={{ width: "50%", mt: "5px" }}>
                      <MuiSelectAndSearch
                        options={options}
                        value={data?.game?.triviaData[index]?.scoreType}
                        onChange={(event) =>
                          handleData("scoreType", event, index)
                        }
                        placeholder="--Select--"
                      />
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        gap: "5px",
                        width: "50%",
                        alignItems: "center",
                      }}
                    >
                      <MuiInput
                        type="number"
                        value={data?.game?.triviaData[index]?.startPercentage}
                        onChange={(event) =>
                          handleData("startPercentage", event, index)
                        }
                      />
                      <Typography
                        sx={{
                          fontFamily: "Mulish",
                          fontWeight: 400,
                          fontSize: "16px",
                          lineHeight: "15.06px",
                          color: "#000000",
                          mt: "10px",
                        }}
                      >
                        to
                      </Typography>
                      <MuiInput
                        type="number"
                        value={data?.game?.triviaData[index]?.endPercentage}
                        onChange={(event) =>
                          handleData("endPercentage", event, index)
                        }
                      />
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: reward?.limit_required && data?.game?.triviaData[index]?.limitLevelOptions?.length? "block" : "none",
                    background: "#F9F9F9",
                    mt: "20px",
                    px: "12px",
                    borderRadius: "8px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: "50px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Mulish",
                        fontWeight: 600,
                        fontSize: "16px",
                        lineHeight: "20.08px",
                        color: "#000000",
                      }}
                    >
                      Reward Limit
                    </Typography>
                    <Switch
                      sx={{
                        "& .MuiSwitch-switchBase.Mui-checked": {
                          color: "#000000",
                        },
                        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                          {
                            backgroundColor: "#000000",
                          },
                      }}
                      checked={data?.game?.triviaData[index]?.limit}
                      onChange={(event) =>
                        handleData("limit", event.target.checked, index)
                      }
                    />
                  </Box>

                  {data?.game?.triviaData[index]?.limit && (
                    <Box>
                      <Box
                        sx={{
                          display: reward?.rewardLevel_required
                            ? "block"
                            : "none",
                        }}
                      >
                        <MuiSelectAndSearch
                           options={data?.game?.triviaData[index]?.limitLevelOptions}
                          value={data?.game?.triviaData[index]?.rewardLevel}
                          onChange={(event) =>
                            handleData("rewardLevel", event, index)
                          }
                          placeholder="--Select Level of Reward --"
                        />
                      </Box>
                      <Box
                        sx={{
                          display: reward?.rewardType_required
                            ? "block"
                            : "none",
                        }}
                      >
                        <MuiSelectAndSearch
                          options={ data?.game?.triviaData[index]?.limitTypeOptions}
                          value={data?.game?.triviaData[index]?.rewardType}
                          onChange={(event) =>
                            handleData("rewardType", event, index)
                          }
                          placeholder="--Select Type of Reward --"
                        />
                      </Box>
                      <Box sx={{ pb: "10px" ,mt:"10px"}}>
                        {data?.game?.triviaData[index]?.rewardLimit?.map(
                          (limit, ind) => (
                            <Box
                              sx={{
                                display: "flex",
                                gap: "10px",
                                alignItems: "center",
                                 mt:"-15px"
                              }}
                            >
                              <Box
                                sx={{
                                  width: "182px",
                                  // display: limit?.time_required
                                  //   ? "block"
                                  //   : "none",
                                }}
                              >
                                <MuiSelectAndSearch
                                   options={ data?.game?.triviaData[index]?.limitOptions}
                                  value={limit?.type}
                                  onChange={(event) =>
                                    handleRewardLimitChange(
                                      index,
                                      ind,
                                      "type",
                                      event
                                    )
                                  }
                                  placeholder="Select Time"
                                />
                              </Box>
                              <Box
                                sx={{
                                  width: "182px",
                                  // display: limit?.amount_required
                                  //   ? "block"
                                  //   : "none",
                                }}
                              >
                                <MuiInput
                                  type="number"
                                  mt="6px"
                                  value={limit?.amount}
                                  onChange={(event) =>
                                    handleRewardLimitChange(
                                      index,
                                      ind,
                                      "amount",
                                      event
                                    )
                                  }
                                  placeholder={"Amount"}
                                />
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  padding: "0",
                                  height: "38px",
                                  width: "45px",
                                  border: "1px solid #76757577",
                                  borderRadius: "4px",
                                  mb: "10px",
                                  mt: "16px",
                                }}
                                onClick={() => handleAddLimit(index)}
                              >
                                <img src={plusIcon} alt="" />
                              </Box>
                              <Box
                                sx={{
                                  display:
                                    data?.game?.triviaData[index]
                                      ?.rewardLimit?.length == 1
                                      ? "none"
                                      : "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  padding: "0",
                                  height: "38px",
                                  width: "45px",
                                  border: "1px solid #76757577",
                                  borderRadius: "4px",
                                  mb: "10px",
                                  mt: "16px",
                                }}
                                onClick={() => handleDeleteLimit(index, ind)}
                              >
                                <img src={deleteLogo} alt="" />
                              </Box>
                            </Box>
                          )
                        )}
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            ))}
            <Box sx={{ width: "400px", marginTop: "20px" }}>
              <Button
                sx={{
                  fontFamily: "Mulish",
                  fontWeight: 600,
                  fontSize: "16px",
                  lineHeight: "20.08px",
                  color: "#272727",
                  height: "44px",
                  backgroundColor: "#F9F9F9",
                  width: "440px",
                  marginLeft: "10px",
                  borderRadius: "8px",
                  display: "flex",
                  justifyContent: "center",
                  gap: "10px",
                }}
                onClick={() => setCreateReward(true)}
              >
                <img src={plusIcon} alt="" />
                Add New Reward
              </Button>
            </Box>
          </Box>
        )
      ) : (
        <Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "20px",
              width: "700px",
              height: "45px",
              backgroundColor: "#F7F9FC",
              borderRadius: "8px",
              cursor: "pointer",
              my: "20px",
            }}
          >
            <Box
              onClick={(e) => {
                e.stopPropagation();
                setStep(2);
              }}
              sx={
                step === 2
                  ? {
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "50%",
                      backgroundColor: "black",
                      color: "white",
                      height: "35px",
                      ml: "10px",
                      borderRadius: "8px",
                    }
                  : {
                      width: "50%",
                      height: "100%",
                      display: "grid",
                      placeItems: "center",
                    }
              }
            >
              Before Play
            </Box>
            <Box
              onClick={(e) => {
                e.stopPropagation();
                setStep(3);
              }}
              sx={
                step === 3
                  ? {
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "50%",
                      backgroundColor: "black",
                      color: "white",
                      height: "35px",
                      ml: "10px",
                      borderRadius: "8px",
                    }
                  : {
                      width: "50%",
                      height: "100%",
                      display: "grid",
                      placeItems: "center",
                    }
              }
            >
              When Playing
            </Box>
            <Box
              onClick={(e) => {
                e.stopPropagation();
                setStep(4);
              }}
              sx={
                step === 4
                  ? {
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "50%",
                      backgroundColor: "black",
                      color: "white",
                      height: "35px",
                      ml: "10px",
                      borderRadius: "8px",
                    }
                  : {
                      width: "50%",
                      height: "100%",
                      display: "grid",
                      placeItems: "center",
                    }
              }
            >
              After Play
            </Box>
          </Box>
          {step === 2 ? (
            <BeforePlay
              data={data}
              onChange={onChange}
              handleBeforePlayChange={handleBeforePlayChange}
              game={"triviaData"}
              fields={fields.before_play}
            />
          ) : step === 3 ? (
            <Box>
              {" "}
              <WhenPlay
                data={data}
                onChange={onChange}
                handleWhenPlayChange={handleWhenPlayChange}
                game={"triviaData"}
                fields={fields.while_playing}
              />{" "}
            </Box>
          ) : (
            <AfterPlay
              data={data}
              onChange={onChange}
              handleAfterPlayChange={handleAfterPlayChange}
              fields={fields.after_play}
              game={"triviaData"}
            />
          )}
        </Box>
      )}
      <Box
        sx={{
          display: createReward || isEdit ? "none" : "flex",
          flexDirection: "row",
          pt: 2,
          // px: "15px",
          paddingBottom: "37px",
        }}
      >
        <Button
          color="inherit"
          onClick={handleBackStep}
          sx={{ mr: 1 }}
          style={{
            backgroundColor: "white",
            color: "black",
            padding: "8px 30px",
            marginRight: "20px",
            fontFamily: "Mulish",
            fontWeight: "700",
            fontSize: "16px",
            lineHeight: "20.08px",
            borderRadius: "7px",
            height: "40px",
          }}
          variant="outlined"
          size="small"
        >
          Previous
        </Button>
        <Button
          style={{
            backgroundColor: "black",
            color: "white",
            padding: "8px 40px",
            fontFamily: "Mulish",
            fontWeight: "700",
            fontSize: "16px",
            lineHeight: "20.08px",
            borderRadius: "7px",
            height: "40px",
          }}
          variant="contained"
          size="small"
          onClick={handleNextStep}
          sx={{ mr: 1 }}
        >
          Next {step + 1}/5
        </Button>
      </Box>
    </Box>
  );
}
