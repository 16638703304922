import React, { useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import HorizontalLinearStepper from "./create-game-components/Stepper";
import AllGames from "./create-game-components/AllGames";
import GameType from "./create-game-components/GameType";
import BeforePlayPage from "./create-game-components/BeforePlayPage";
import AfterPlayPage from "./create-game-components/AfterPlayPage";
import {
  getAllGameTypes,
  getAllRewardFunction,
  getRewardTypesFunction,
} from "../../../Redux/OfferReducer/action";
import { DeviceFrameset } from "react-device-frameset";
import editLogo from "../../../assets/Pen.png";
const steps = ["Game Type", "Add Reward", "Before Play", "After Play"];

export default function CreateGamePage({ showEdit }) {
  const [activeStep, setActiveStep] = React.useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }else{
      navigate("/offers/create-offers/preview");
    }
  };

  const handleBack = () => {
    if (activeStep === 0) {
      navigate("/offers/create-offers/set-qualifying-criteria");
    } else if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  useEffect(() => {
    dispatch(getAllRewardFunction());
    dispatch(getRewardTypesFunction(1, 5));
    dispatch(getAllGameTypes(1, 5));
  }, [dispatch]);

  useEffect(() => {
    if (pathname === "/offers/create-offers/preview") {
      setActiveStep(1);
    }
  }, []);

  return (
    <Box>
      <Box sx={{ display: showEdit ? "block" : "none" }}>
        <Box
          sx={{
            display: showEdit ? "flex" : "none",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid #E4E4F0",
            background: "#FFFFFF",
            // my: "16px",
            mx: "20px",
            py: "8px",
            px: "20px",
            borderRadius: "8px 8px 0px 0px",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Lato",
              fontSize: "16px",
              fontWeight: "700",
              lineHeight: "20px",
              color: "#0B1B32",
            }}
          >
            Create Game
            <span style={{ color: "#858D98", fontSize: "14px" }}>
              ({activeStep + 1}/{steps?.length})
            </span>
          </Typography>
          <Button
            sx={{
              display: "flex",
              gap: "6px",
              // mr: "10px",
              px: "10px",
              zIndex: "10",
            }}
            onClick={() => {
              navigate("/offers/create-offers/create-game");
            }}
          >
            <img
              style={{ height: "16px", height: "16px" }}
              src={editLogo}
              alt=""
            />
            <Typography
              sx={{
                fontFamily: "Lato",
                fontSize: "14px",
                fontWeight: "600",
                lineHeight: "16.8px",
                color: "#003D86",
              }}
            >
              Edit
            </Typography>
          </Button>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "calc(100vh - 120px)",
          margin: showEdit ? "0px 20px 20px 20px" : "20px",
          padding: "0px 0px 20px 20px",
          background: "#FFFFFF",
          borderRadius: showEdit ? "0px 0px 8px 8px" : "8px",
          position: "relative",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Lato",
            fontSize: "16px",
            fontWeight: "700",
            lineHeight: "20px",
            textAlign: "left",
            my: "20px",
            color: "#0B1B32",
            display: showEdit ? "none" : "block",
          }}
        >
          Create Game{" "}
          <span style={{ color: "#858D98", fontSize: "14px" }}>
            ({activeStep + 1}/{steps?.length})
          </span>
        </Typography>
        <Box
          sx={{
            display:showEdit? "flex":"none",
            justifyContent: "flex-start",
            borderBottom: "1px solid #E4E4F0",
          }}
        >
          <Button
            sx={{
              borderBottom:
                activeStep === 1
                  ? "2px solid #0C51A1"
                  : "2px solid transparent",
              padding: "10px",
              borderRadius: "0px",
              fontFamily: "Lato",
              fontSize: "14px",
              fontWeight: "700",
              lineHeight: "16.8px",
              color: activeStep === 1 ? "#003D86" : "#858D98",
              textTransform: "capitalize",
            }}
            onClick={() => setActiveStep(1)}
          >
            Add Reward
          </Button>
          <Button
            sx={{
              borderBottom:
                activeStep === 2
                  ? "2px solid #0C51A1"
                  : "2px solid transparent",
              padding: "10px",
              borderRadius: "0px",
              fontFamily: "Lato",
              fontSize: "14px",
              fontWeight: "700",
              lineHeight: "16.8px",
              color: activeStep === 1 ? "#003D86" : "#858D98",
              textTransform: "capitalize",
            }}
            onClick={() => setActiveStep(2)}
          >
            Before Play
          </Button>
          <Button
            sx={{
              borderBottom:
                activeStep === 3
                  ? "2px solid #0C51A1"
                  : "2px solid transparent",
              padding: "10px",
              borderRadius: "0px",
              fontFamily: "Lato",
              fontSize: "14px",
              fontWeight: "700",
              lineHeight: "16.8px",
              color: activeStep === 1 ? "#003D86" : "#858D98",
              textTransform: "capitalize",
            }}
            onClick={() => setActiveStep(3)}
          >
            After Play
          </Button>
        </Box>
        <Box sx={{ display: "flex", flex: 1 }}>
          <Box
            sx={{
              width: activeStep === 0 ? "100%" : "65%",
              // overflow: "auto",
              mt: showEdit ? "20px" : "0px",
            }}
          >
            <Box sx={{ display: showEdit ? "none" : "block" }}>
              <HorizontalLinearStepper
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                handleBack={handleBack}
                handleNext={handleNext}
                steps={steps}
                showEdit={showEdit}
              />
            </Box>
            {activeStep === 0 && <AllGames />}
            {activeStep === 1 && <GameType showEdit={showEdit}/>}
            {activeStep === 2 && <BeforePlayPage showEdit={showEdit} />}
            {activeStep === 3 && <AfterPlayPage showEdit={showEdit} />}
          </Box>
          {activeStep !== 0 && (
            <Box
              sx={{
                width: "33%",
                background: "#E8F0F9",
                height: "100%",
                borderRadius: "0px 6px 0px 0px",
                position: "absolute",
                top: "0px",
                right: "0px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box sx={{ width: "100%",overflow:"hidden" }}>
                <DeviceFrameset
                  device="iPhone X"
                  color="black"
                  zoom="55%"
                  style={{
                    maxHeight: "100%",
                    overflowY: "scroll",
                    border: "1px solid #003D86",
                  }}
                ></DeviceFrameset>
              </Box>
            </Box>
          )}
        </Box>

        <Box
          sx={{
            display:showEdit?"none": "flex",
            justifyContent: "flex-start",
            gap: "16px",
            padding: "20px 0",
            marginTop: "auto",
          }}
        >
          <Button
            sx={{
              width: "136px",
              height: "30px",
              backgroundColor: "#E8F0F9",
              padding: "7px 12px",
              borderRadius: "6px",
              "&:hover": {
                backgroundColor: "#E8F0F9",
              },
            }}
            onClick={handleBack}
          >
            <Typography
              sx={{
                fontFamily: "Lato",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "16.8px",
                color: "#003D86",
              }}
            >
              Previous
            </Typography>
          </Button>
          <Button
            sx={{
              width: "136px",
              height: "30px",
              backgroundColor: "#003D86",
              padding: "7px 12px",
              borderRadius: "6px",
              "&:hover": {
                backgroundColor: "#003D86",
              },
            }}
            onClick={handleNext}
          >
            <Typography
              sx={{
                fontFamily: "Lato",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "16.8px",
                color: "#FFFFFF",
              }}
            >
              Save & Next
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
