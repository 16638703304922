import React, { useEffect, useState } from "react";
import start from "../../assets/start.png";
import cashback from "../../assets/cashback.png";
import digiGold from "../../assets/digigold.png";
import neucoin from "../../assets/neucoin.png";
import voucher from "../../assets/voucher.png";
import betterLuck from "../../assets/betterLuck.png";
import { Box, Button, Typography } from "@mui/material";
import BackButtonImg from "../../assets/backArrow.png";
import product from "../../assets/product.png";
import MuiSelectAndSearch from "../MuiSelectAndSearch";
import Label from "../Label";
import MuiInput from "../MuiInput";
import StartFromScratch from "./StartFromScratch";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllRewardFunction,
  getRewardTypesFunction,
} from "../../Redux/OfferReducer/action";
import { fetchCsvFileFromUrl, transformRewardDetails } from "../../utils/CreateGame_PreData";

export default function CreateReward({
  data,
  createReward,
  setCreateReward,
  handleAddNewRewards,
  handleStartFromScratch,
  onChange,
  handleCreateReward,
  rewardTypeName,
  setRewardTypeName,
}) {
  const rewardType = useSelector(
    (store) => store?.OfferReducer?.allRewards
  )?.rewardTypes;
  const previouslyCreatedReward = useSelector(
    (store) => store?.OfferReducer?.allRewards
  )?.previouslyCreated;
  const templates = useSelector(
    (store) => store?.OfferReducer?.allRewards
  )?.templates;

  const rewardTypes = [
    { name: "Start from Scratch", icon: start, id: "start_from_scratch" },
    // { title: "Better Luck Next Time", image: betterLuck, id: "better_luck_next_time" },
    // { title: "Voucher", image: voucher, id: "voucher" },
    // { title: "Neu Coins", image: neucoin, id: "neu_coins" },
    // { title: "Digital Gold", image: digiGold, id: "digital_gold" },
    // { title: "Cashback", image: cashback, id: "cashback" },
  ];

  const options = [
    // { name: "Start from Scratch", id: "start_from_scratch" },
    { name: "Better Luck Next Time", id: "better_luck_next_time" },
    { name: "Voucher", id: "voucher" },
    { name: "Neu Coins", id: "neu_coins" },
    { name: "Digital Gold", id: "digital_gold" },
    { name: "Cashback", id: "cashback" },
  ];
  // const previouslyCreatedReward = [
  //   { title: "Free Product", image: product, rewardType: "CUSTOM REWARD" },
  //   {
  //     title: "You have won an exclusive Voucher",
  //     image: voucher,
  //     rewardType: "Voucher",
  //   },
  //   {
  //     title: "You have won 500 Digital Gold",
  //     image: digiGold,
  //     rewardType: "DIGITAL GOLD",
  //   },
  //   {
  //     title: "You have earned 200 coins",
  //     image: neucoin,
  //     rewardType: "NEU COINS",
  //   },
  //   {
  //     title: "You have won ₹20 Cashback",
  //     image: cashback,
  //     rewardType: "CASHBACK",
  //   },
  //   {
  //     title: "Better Luck Next Time",
  //     image: betterLuck,
  //     rewardType: "NO REWARD",
  //   },
  // ];

  // const templates = [
  //   {
  //     title: "You have won an exclusive Voucher",
  //     image: voucher,
  //     rewardType: "Voucher",
  //   },
  //   {
  //     title: "You have earned 200 coins",
  //     image: neucoin,
  //     rewardType: "NEU COINS",
  //   },
  //   {
  //     title: "You have won ₹20 Cashback",
  //     image: cashback,
  //     rewardType: "CASHBACK",
  //   },
  //   {
  //     title: "You have won 500 Digital Gold",
  //     image: digiGold,
  //     rewardType: "DIGITAL GOLD",
  //   },
  //   {
  //     title: "Better Luck Next Time",
  //     image: betterLuck,
  //     rewardType: "NO REWARD",
  //   },
  // ];
  console.log(previouslyCreatedReward, "previouslyCreatedReward");
  const dispatch = useDispatch();
  useEffect(() => {
    // if(data?.addNewRewards?.scratchCardData?.rewardType !== "start_from_scratch"){
    //   const value = options.find((item) => item.id === data?.addNewRewards?.scratchCardData?.rewardType)
    //   handleStartFromScratch("rewardType", value)
    // }
    // To reset the startFromScratch data
    onChange({
      ...data,
      addNewRewards: {
        ...data?.addNewRewards,
        scratchCardData: {
          ...data?.addNewRewards?.scratchCardData,
          startFromScratch: {},
        },
      },
    });
  }, [data?.addNewRewards?.scratchCardData?.rewardType]);

  useEffect(() => {
    dispatch(getRewardTypesFunction(1, 5));
    // dispatch(getAllRewardFunction());
  }, []);
  return (
    <Box>
      {data?.addNewRewards?.scratchCardData?.rewardType ? (
        <Box>
          <StartFromScratch
            onChange={onChange}
            data={data}
            handleAddNewRewards={handleAddNewRewards}
            handleStartFromScratch={handleStartFromScratch}
            handleCreateReward={handleCreateReward}
            rewardTypeName={rewardTypeName}
            setRewardTypeName={setRewardTypeName}
          />
        </Box>
      ) : (
        <Box>
          {/* <Box sx={{ display: "flex", justifyContent: "start" }}>
            <Button onClick={() => setCreateReward(false)}>
              <img src={BackButtonImg} alt="" />
              <Typography
                sx={{
                  fontFamily: "Mulish",
                  fontWeight: 700,
                  fontSize: "20px",
                  lineHeight: "26px",
                  color: "#0F1828",
                  ml: "12px",
                }}
              >
                Add New Reward
              </Typography>
            </Button>
          </Box> */}

          <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Button
              sx={{ minWidth: "0px" }}
              onClick={() => setCreateReward(false)}
            >
              <img src={BackButtonImg} alt="" />
            </Button>
            <Typography
              sx={{
                fontFamily: "Mulish",
                fontWeight: 700,
                fontSize: "20px",
                lineHeight: "26px",
                color: "#0F1828",
                // ml: "12px",
              }}
            >
              Add New Reward
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontWeight: "Mulish",
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "20px",
                color: "#0F1828",
                textAlign: "start",
                mt: "25px",
                ml: "12px",
                // mb:"12px",
              }}
            >
              Choose a Type of Reward
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: "20px",
                py: "15px",
                borderTop: "2px solid #E3E7EF",
                m: "12px",
              }}
            >
              {/* {rewardTypes.map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    width: "120px",
                    height: "120px",
                    backgroundColor: "#F4F4F4",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                    borderRadius: "12px",
                    cursor: "pointer",
                    border:
                      data?.addNewRewards?.scratchCardData?.rewardType ===
                      item?.name
                        ? "2px solid #000000"
                        : "2px solid transparent",
                  }}
                  onClick={() => handleAddNewRewards("rewardType", item?.name)}
                >
                  {console.log(item, "item")}
                  <img src={item.icon} alt="" />
                  <Typography
                    sx={{
                      fontFamily: "Mulish",
                      fontWeight: 600,
                      fontSize: "12px",
                      lineHeight: "15px",
                      color: "#212121",
                      textAlign: "center",
                      mx: "10px",
                    }}
                  >
                    {item?.name}
                  </Typography>
                </Box>
              ))} */}
              {rewardType.map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    width: "120px",
                    height: "120px",
                    backgroundColor: "#F4F4F4",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                    borderRadius: "12px",
                    cursor: "pointer",
                    border:
                      data?.addNewRewards?.scratchCardData?.rewardType ===
                      item.name
                        ? "2px solid #000000"
                        : "2px solid transparent",
                  }}
                  onClick={() => handleAddNewRewards("rewardType", item?.name)}
                >
                  <img
                    style={{ height: "40px", width: "40px" }}
                    src={item?.icon}
                    alt={`${item.name} icon`}
                  />
                  <Typography
                    sx={{
                      fontFamily: "Mulish",
                      fontWeight: 600,
                      fontSize: "12px",
                      lineHeight: "15px",
                      color: "#212121",
                      textAlign: "center",
                      mx: "10px",
                    }}
                  >
                    {item?.name}
                  </Typography>
                </Box>
              ))}
              {/* <Box
          sx={{
            width: "120px",
            height: "120px",
            backgroundColor: "#F4F4F4",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            borderRadius: "12px",
            // px: "15px",
          }}
        >
            <img src={start} alt="" />
            <Typography sx={{fontFamily:"Mulish",fontWeight:600,fontSize:"12px",lineHeight:"15px",color:"#212121",textAlign:"center",mx:"10px"}}>
                Start from Scratch
            </Typography>
        </Box> */}
            </Box>
          </Box>

          <Box
            sx={{
              display: previouslyCreatedReward?.length > 0 ? "block" : "none",
            }}
          >
            <Typography
              sx={{
                fontWeight: "Mulish",
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "20px",
                color: "#0F1828",
                textAlign: "start",
                mt: "25px",
                ml: "12px",
                // mb:"12px",
              }}
            >
              Previously Created by You
            </Typography>

            <Box
              sx={{
                display: "flex",
                gap: "20px",
                py: "15px",
                borderTop: "2px solid #E3E7EF",
                m: "12px",
                flexWrap: "wrap",
                overflowX:"hidden",
              }}
            >
              {previouslyCreatedReward?.map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    width: "120px",
                    height: "150px",
                    backgroundColor: "#F4F4F4",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                    borderRadius: "12px",
                    cursor: "pointer",
                    border:
                      data?.addNewRewards?.scratchCardData
                        ?.previouslyCreatedReward === item.title
                        ? "2px solid #000000"
                        : "2px solid transparent",
                  }}
                  onClick={async () => {
                    const rewardDetails = {
                      ...transformRewardDetails(item?.reward_details),
                      probability: item?.win_probability,
                      reward_type_id: item?.reward_type_id,
                      title: item?.title,
                      rewardLevel: item?.limit_level,
                      rewardType: item?.limit_type,
                      rewardLimit: item?.limits,
                      rewardIcon: item?.reward_details?.reward_icon,
                      reward_icon: item?.reward_details?.reward_icon,
                      voucherCollectionId: item?.reward_details?.voucherCollectionId ?? "",
                      csvUrl: item?.reward_details?.csvUrl ?? "",
                    };
                  
                    if (item?.reward_details?.csvUrl) {
                      rewardDetails.csvFile = await fetchCsvFileFromUrl(item?.reward_details?.csvUrl);
                    }
                  
                    rewardDetails.cashback_type = rewardType
                      ?.find((reward) => reward.name === item?.name)
                      ?.reward_details?.find(
                        (element) => element?.backend_label === "cashback_type"
                      )
                      ?.options?.map((option) => ({
                        id: option?.value,
                        name: option?.label,
                        fields: option?.fields,
                      }))
                      ?.find((elem) => elem?.id === item?.reward_details?.cashback_type);
                  
                    handleAddNewRewards("rewardType", item?.name, rewardDetails);
                  }}
                >
                  <img
                    style={{ height: "40px", width: "40px" }}
                    src={item?.icon}
                    alt=""
                  />
                  <Box height="45px">
                    <Typography
                      sx={{
                        fontFamily: "Mulish",
                        fontWeight: 600,
                        fontSize: "12px",
                        lineHeight: "15px",
                        color: "#212121",
                        textAlign: "center",
                        mx: "13px",
                      }}
                    >
                      {item.title}
                    </Typography>
                  </Box>

                  <Button
                    sx={{
                      height: "20px",
                      backgroundColor: "#FFFFFF",
                      fontFamily: "Mulish",
                      fontWeight: 600,
                      fontSize: "8px",
                      color: "#000000",
                      "&:hover": {
                        backgroundColor: "#FFFFFF", // Or whatever the default background is
                      },
                    }}
                  >
                    {item?.name}
                  </Button>
                </Box>
              ))}
            </Box>
          </Box>

          <Box sx={{ display: templates?.length > 0 ? "block" : "none" }}>
            <Typography
              sx={{
                fontWeight: "Mulish",
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "20px",
                color: "#0F1828",
                textAlign: "start",
                mt: "25px",
                ml: "12px",
                // mb:"12px",
              }}
            >
              Templates
            </Typography>

            <Box
              sx={{
                display: "flex",
                gap: "20px",
                py: "15px",
                borderTop: "2px solid #E3E7EF",
                m: "12px",
                flexWrap:"wrap",
              }}
            >
              {templates?.map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    width: "120px",
                    height: "150px",
                    backgroundColor: "#F4F4F4",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                    borderRadius: "12px",
                    cursor: "pointer",
                    border:
                      data?.addNewRewards?.scratchCardData?.templates ===
                      item.title
                        ? "2px solid #000000"
                        : "2px solid transparent",
                  }}
                  onClick={async () => {
                    const rewardDetails = {
                      ...transformRewardDetails(item?.reward_details),
                      probability: item?.win_probability,
                      reward_type_id: item?.reward_type_id,
                      title: item?.title,
                      rewardLevel: item?.limit_level,
                      rewardType: item?.limit_type,
                      rewardLimit: item?.limits,
                      rewardIcon: item?.reward_details?.reward_icon,
                      reward_icon: item?.reward_details?.reward_icon,
                      voucherCollectionId: item?.reward_details?.voucherCollectionId ?? "",
                      csvUrl: item?.reward_details?.csvUrl ?? "",
                    };
                  
                    if (item?.reward_details?.csvUrl) {
                      rewardDetails.csvFile = await fetchCsvFileFromUrl(item?.reward_details?.csvUrl);
                    }
                  
                    rewardDetails.cashback_type = rewardType
                      ?.find((reward) => reward.name === item?.name)
                      ?.reward_details?.find(
                        (element) => element?.backend_label === "cashback_type"
                      )
                      ?.options?.map((option) => ({
                        id: option?.value,
                        name: option?.label,
                        fields: option?.fields,
                      }))
                      ?.find((elem) => elem?.id === item?.reward_details?.cashback_type);
                  
                    handleAddNewRewards("rewardType", item?.name, rewardDetails);
                  }}
                  
                >
                  <img  style={{ height: "40px", width: "40px" }} src={item?.icon} alt="" />
                  <Box height="45px">
                    <Typography
                      sx={{
                        fontFamily: "Mulish",
                        fontWeight: 600,
                        fontSize: "12px",
                        lineHeight: "15px",
                        color: "#212121",
                        textAlign: "center",
                        mx: "13px",
                      }}
                    >
                      {item.title}
                    </Typography>
                  </Box>

                  <Button
                    sx={{
                      height: "20px",
                      backgroundColor: "#FFFFFF",
                      fontFamily: "Mulish",
                      fontWeight: 600,
                      fontSize: "8px",
                      color: "#000000",
                      "&:hover": {
                        backgroundColor: "#FFFFFF", // Or whatever the default background is
                      },
                    }}
                  >
                    {item?.rewardType}
                  </Button>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}
