import { Box, Button, Modal, Typography } from "@mui/material";
import React from "react";
import deleteIcon from "../../assets/deleteIcon.svg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "430px",
  height: "240px",
  bgcolor: "background.paper",
//   border: "2px solid #000",
borderRadius:"20px",
  boxShadow: 24,
  p: 4,
};

export default function DeleteConformation({
  handleOpen,
  handleClose,
  setOpen,
  open,
  rewardNumber = 1,
  handleDeleteReward
}) {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <img src={deleteIcon} alt="" />
          </Box>
          <Typography
            sx={{
              fontFamily: "Mulish",
              fontWeight: "700",
              fontSize: "20px",
              lineHeight: "25.1px",
              color: "#000000",
              textAlign: "center",
              mt:"22px",
              mb:"6px",
            }}
          >
            Delete Reward
          </Typography>

          <Typography
            sx={{
              fontFamily: "Mulish",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "22px",
              textAlign: "center",
            }}
          >
            Are you sure want to delete the{" "}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Mulish",
              fontWeight: 600,
              fontSize: "16px",
              lineHeight: "22px",
              textAlign: "center",
            }}
          >
            Reward {rewardNumber} ?
          </Typography>

          <Box sx={{display:"flex",justifyContent:"space-between",mt:"21px"}}>
            <Button
              sx={{
                width: "205px",
                height: "42px",
                //   backgroundColor:"#26292D",
                color: "#26292D",
                border: "1px solid #26292D",
                borderRadius:"7px",
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              sx={{
                width: "205px",
                height: "42px",
                backgroundColor: "#26292D",
                color: "white",
                border: "1px solid #26292D",
                borderRadius:"7px",
                '&:hover': {
                    backgroundColor: "#26292D",
                  },
              }}
              onClick={()=>handleDeleteReward(rewardNumber-1)}
            >
              Delete Reward
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
