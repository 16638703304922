import {
  Box,
  Stack,
  Typography,
  Button,
  IconButton,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import AnalyticsSection from "./AnalyticsSection";
import CustomPieChart from "./charts/CustomPieChart";
import CustomAreaChart from "./charts/CustomAreaChart";
import { initialStateOverviewFilter } from "../utils/initialStates";
import CloseIcon from "@mui/icons-material/Close";
import QuickFilter from "./QuickFilter";
import UiTable from "./UiTable";
import { BootstrapTooltip } from "../utils/tooltip";
import axios from "axios";
import ProductDetailsAnalytics from "./ProductDetailsAnalytics";
import QueryInput from "./QueryInput";
import { formatDateNew } from "../utils/formateDate";
import { toast } from "react-toastify";
import DateFilterPanel from "./DateFilterPanel";
import FilteredResults from "./FilteredResults";
import ExportButton from "./ExportButton";
import { handleDownload } from "../utils/download";
import usePermission from "../utils/permissions";
import useDebounce from "../hooks/useDebounce";
import ToggleColumn from "../UIComponents/ToggleColumn";

const data = [
  {
    name: "JAN",
    uv: 4520,
  },
  {
    name: "FEB",
    uv: 4200,
  },
  {
    name: "MAR",
    uv: 3000,
  },
  {
    name: "APR",
    uv: 3250,
  },
  {
    name: "MAY",
    uv: 2920,
  },
  {
    name: "JUN",
    uv: 2000,
  },
  {
    name: "JULY",
    uv: 1000,
  },
];

const data1 = [
  {
    name: "JAN",
    uv: 4000,
  },
  {
    name: "FEB",
    uv: 3500,
  },
  {
    name: "MAR",
    uv: 3200,
  },
  {
    name: "APR",
    uv: 3266,
  },
  {
    name: "MAY",
    uv: 2000,
  },
  {
    name: "JUN",
    uv: 1500,
  },
  {
    name: "JULY",
    uv: 1000,
  },
];

const data2 = [
  {
    name: "11 AM",
    uv: 0,
  },
  {
    name: "1 PM",
    uv: 0,
  },
  {
    name: "3 pm",
    uv: 0,
  },
];

const data3 = [
  {
    name: "11 AM",
    uv: 0,
  },
  {
    name: "1 PM",
    uv: 0,
  },
  {
    name: "3 pm",
    uv: 0,
  },
];

const data4 = [
  { name: "Monday", uv: 100 },
  { name: "Tuesday", uv: 150 },
  { name: "Wednesday", uv: 200 },
  { name: "Thursday", uv: 180 },
  { name: "Friday", uv: 220 },
  { name: "Saturday", uv: 250 },
  { name: "Sunday", uv: 230 },
];

const data5 = [
  { name: "Monday", uv: 100 },
  { name: "Tuesday", uv: 150 },
  { name: "Wednesday", uv: 200 },
  { name: "Thursday", uv: 180 },
  { name: "Friday", uv: 220 },
  { name: "Saturday", uv: 250 },
  { name: "Sunday", uv: 230 },
];

const overviewOptions = [
  {
    title: "Product Analytics",
    options: [
      {
        value: "300",
        title: "Total Redemption",
      },
      {
        value: "70",
        title: "Distinct Users",
      },
      {
        value: "3000",
        title: "Total Reward Value (Neu Coin)",
      },
      {
        value: "8",
        title: "Average Reward (Neu Coin)",
      },
      {
        value: "₹1500",
        title: "Total Reward Value (Digital Gold)",
      },
      {
        value: "5",
        title: "Average Reward (Digital Gold)",
      },
    ],
  },
  {
    title: "Scratch Card Analytics",
    options: [
      {
        value: "30",
        title: "Total Scratch Cards Issued",
      },
      {
        value: "8",
        title: "Total Scratch Cards Locked",
      },
      {
        value: "30",
        title: "Total Scratch Cards Unlocked",
      },
      {
        value: "22",
        title: "Total Scratch Cards Scratched",
      },
      {
        value: "3",
        title: "Total Scratch Cards Expired",
      },
      {
        value: "12",
        title: "Total Reward Processed",
      },
      {
        value: "5",
        title: "Total Scratch Cards Failed",
      },
      {
        value: "3",
        title: "Scratch Cards Issued Per User",
      },
    ],
  },
];

const overviewOptions1 = [
  {
    title: "Product Analytics",
    options: [
      {
        value: "5",
        title: "Total Users",
      },
      {
        value: "10",
        title: "Distinct Users",
      },
      {
        value: "200",
        title: "Total Reward Value (Neo Coin)",
      },
      {
        value: "1",
        title: "Average Reward (Neo Coin)",
      },
      {
        value: "100",
        title: "Total Reward Value (Digital Gold)",
      },
      {
        value: "0",
        title: "Average Reward (Digital Gold)",
      },
    ],
  },
  {
    title: "Scratch Card Analytics",
    options: [
      {
        value: "5",
        title: "Total Scratch Cards Issued",
      },
      {
        value: "3",
        title: "Total Scratch Cards Locked",
      },
      {
        value: "2",
        title: "Total Scratch Cards Unlocked",
      },
      {
        value: "2",
        title: "Total Scratch Cards Scratched",
      },
      {
        value: "0",
        title: "Total Scratch Cards Expired",
      },
      {
        value: "2",
        title: "Total Reward Processed",
      },
      {
        value: "0",
        title: "Total Scratch Cards Failed",
      },
    ],
  },
];

const overviewOptions2 = [
  {
    title: "Product Analytics",
    options: [
      {
        value: "30",
        title: "Total Users",
      },
      {
        value: "10",
        title: "Distinct Users",
      },
      {
        value: "300",
        title: "Total Reward Value (Neo Coin)",
      },
      {
        value: "10",
        title: "Average Reward (Neo Coin)",
      },
      {
        value: "1000",
        title: "Total Reward Value (Digital Gold)",
      },
      {
        value: "1",
        title: "Average Reward (Digital Gold)",
      },
    ],
  },
  {
    title: "Scratch Card Analytics",
    options: [
      {
        value: "10",
        title: "Total Scratch Cards Issued",
      },
      {
        value: "8",
        title: "Total Scratch Cards Locked",
      },
      {
        value: "3",
        title: "Total Scratch Cards Unlocked",
      },
      {
        value: "2",
        title: "Total Scratch Cards Scratched",
      },
      {
        value: "0",
        title: "Total Scratch Cards Expired",
      },
      {
        value: "2",
        title: "Total Reward Processed",
      },
      {
        value: "1",
        title: "Total Scratch Cards Failed",
      },
    ],
  },
];

const piechartOptions = [
  {
    legend: "BUDGET VS SPENDS",
    series: ["Total Spends", "Remaining Budgets"],
    data: [
      { name: "Active Campagins", value: 75000 },
      { name: "Active Campagins", value: 25000 },
    ],
    options: [{ name: "Active Campagins", value: "₹75000" }],
    color: ["#685EDD", "#D6D6D6"],
    pieCenterData: {
      label: "Budget",
      value: "₹1,00,000",
    },
  },
  {
    legend: "Active Offers VS inactive OFFERS",
    series: [
      "Total Active Offers",
      "Total Expired Offers",
      "Total Inactive Offers",
    ],
    data: [
      { name: "Total Active Offers", value: 33 },
      { name: "Total Expired Offers", value: 17 },
      { name: "Total Inactive Offers", value: 50 },
    ],
    options: [
      { name: "Total Active Offers", value: 33 },
      { name: "Total Expired Offers", value: 17 },
    ],
    color: ["#00A52E", "#3C3C3C", "#D9D9D9"],
    pieCenterData: {
      label: "Offers",
      value: "100",
    },
  },
];

const piechart1 = [{ ...piechartOptions[0] }, { ...piechartOptions[1] }];

const piechart2 = [{ ...piechartOptions[0] }, { ...piechartOptions[1] }];

const areaChart = [
  {
    legend: {
      label: "Amount VS Time",
      sublabel: "Digital Gold",
    },
    data,
    strokeColor: "#043A87",
    backgroundColor: "#043A87",
  },
  {
    legend: {
      label: "Amount VS Time",
      sublabel: "Neu Coins",
    },
    data: data1,
    strokeColor: "#DA8BFF",
    backgroundColor: "#DA8BFF",
  },
];

const quickFilterConfig = [
  {
    label: "BNPL (3/5)",
    value: "BNPL (3/5)",
  },
];

const areaChart1 = [
  { ...areaChart[0], data: data2 },
  { ...areaChart[1], data: data3 },
];

const areaChart2 = [
  { ...areaChart[0], data: data4 },
  { ...areaChart[1], data: data5 },
];

const dummyDataSet = {
  "This Week": {
    overviewOptions: overviewOptions2,
    piechartOptions: piechart2,
    areaChart: areaChart2,
  },
  Today: {
    overviewOptions: overviewOptions1,
    piechartOptions: piechart1,
    areaChart: areaChart1,
  },
  "All Time": {
    overviewOptions,
    piechartOptions,
    areaChart,
  },
};

const GenricText = ({ text }) => {
  const textStyle = {
    fontFamily: "ABeeZee",
    fontSize: "13px",
    fontWeight: 400,
    lineHeight: "15.37px",
    letterSpacing: "0.02em",
    textAlign: "left",
    textOverflow: "ellipsis",
    overflow: "hidden",
  };

  return (
    <BootstrapTooltip title={text}>
      <Typography style={textStyle}>{text}</Typography>
    </BootstrapTooltip>
  );
};

function processData(response) {
  const data = response?.data?.data;

  const areaChartCss = [
    {
      strokeColor: "#043A87",
      backgroundColor: "#043A87",
    },
    {
      strokeColor: "#DA8BFF",
      backgroundColor: "#DA8BFF",
    },
  ];

  const piechartColors = [
    ["#685EDD", "#D6D6D6"],
    ["#00A52E", "#3C3C3C", "#D9D9D9"],
  ];

  const overviewOptions = [
    {
      title: "All Rewards Analytics",
      options: [...data?.reward_analytics, ...data?.currency_rewards] ?? [],
    },
    {
      title: "Scratch Card Analytics",
      options: data?.scratch_card_analytics ?? [],
    },
  ];

  const piechartOptions = data?.graph_data?.map((res, index) => ({
    ...res,
    color: piechartColors[index],
  }));

  const areaChart = data?.area_chart?.map((res, index) => ({
    ...res,
    ...areaChartCss[index],
  }));

  console.log(areaChart, "areachart");

  return { overviewOptions, piechartOptions, areaChart };
}

const filterSelectOptions = [
  { label: "Custom", value: "CUSTOM" },
  { label: "Today", value: "TODAY" },
  { label: "Yesterday", value: "YESTERDAY" },
  { label: "This Week", value: "THIS_WEEK" },
  { label: "This Month", value: "THIS_MONTH" },
  { label: "All Time", value: "ALL_TIME" },
];

const TData = () => {
  return [
    { key: "product_name", displayName: "Product", flag: true },
    { key: "total_budget", displayName: "Budget", flag: true },
    { key: "total_spends", displayName: "Spends", flag: true },
    {
      key: "total_users_reward_value",
      displayName: "Total Users Reward value",
      flag: true,
    },
    { key: "average_reward", displayName: "Avg Reward", flag: true },
    { key: "total_cards_issued", displayName: "Total Card Issued", flag: true },
    {
      key: "total_cards_redeemed",
      displayName: "Total Card Redeemed",
      flag: true,
    },
  ];
};

const ProductLevel = ({
  activeButton,
  filter,
  handleReset,
  doneButton,
  dateFilter,
  handleShowDateFilter,
  showDateFilter,
  handleRemove,
  handleDateFilter,
}) => {
  const [fieldsName, setFieldName] = useState(TData);
  const [columnFields, setColumnFields] = useState([]);

  const [quickfilter, setQuickFilter] = useState(() => {
    return [];
  });

  const flagRef = useRef("");
  const permission = usePermission();
  const [showFilter, setShowFilter] = useState([]);

  const dateFilterLayout = {
    select: "ALL_TIME",
    from_time: "",
    to_time: "",
    loader: false,
  };

  const [tabFilter, setTabFilter] = useState({});

  const [search, setSearch] = useState("");
  const searchDebounce = useDebounce(search, 1000);
  const token = useSelector((store) => store.AuthReducer.token);
  const [loading, setLoding] = useState(false);

  const [products, setProducts] = useState({
    data: [],
    totalcount: 0,
    paginationModel: {
      page: 0,
      pageSize: 10,
    },
  });

  const [date, setDate] = useState({
    from_time: "",
    to_time: "",
  });

  const [tabLoader, setTabLoader] = useState(false);

  const [multipleTab, setMultipleTab] = useState({});

  const [activeSection, setActiveSection] = useState("All");

  const activeIndex = useRef();

  const handleToggleColumn = (i) => {
    console.log(i, "index");
    const data = fieldsName.map((e, index) => {
      if (index === i) {
        e.flag = !e.flag;
      }
      return e;
    });

    setFieldName(data);
  };

  const handleSelectNameClick = (value) => {
    setActiveSection(value);
  };

  const [tableButton, setTableButton] = useState([
    {
      label: "All",
      value: "All",
      handleSelectNameClick,
    },
  ]);
  const sectionTitleStyle = {
    fontFamily: "Proxima Nova A",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "24px",
    letterSpacing: "0em",
    textAlign: "left",
  };

  const handleTicketStatus = (e) => {
    const ticketStatus = [...quickfilter];
    if (ticketStatus.includes(e.target.value)) {
      ticketStatus.splice(ticketStatus.indexOf(e.target.value), 1);
    } else {
      ticketStatus.push(e.target.value);
    }
    setQuickFilter(ticketStatus);
  };

  const cssOptions = {
    "Product Analytics": {
      titleColor: "#585858",
      columnsInRow: 3,
    },
    "Scratch Card Analytics": {
      titleColor: "#585858",
      sidebackgroundColor: "#D4D71E",
      columnsInRow: 3,
    },
  };

  // const queryBuilder = () => {};

  const columns = [
    {
      field: "product_name",
      headerName: "Product",
      flex: 1,
      renderCell: (param) => {
        if (!param.value) {
          return "NA";
        }

        const buttomStyle = {
          width: "100%",
          height: "100%",
          justifyContent: "flex-start",
        };

        return (
          <Button sx={buttomStyle}>
            <BootstrapTooltip title={param.value}>
              {param?.value}
            </BootstrapTooltip>
          </Button>
        );
      },
    },
    {
      field: "total_budget",
      headerName: "Budget",
      flex: 0.7,
      renderCell: (param) => {
        if (!param?.value && typeof param?.value !== "number") return "NA";

        return <GenricText text={param?.value ?? ""} />;
      },
    },
    {
      field: "total_spends",
      headerName: "Spends",
      flex: 0.7,
      renderCell: (param) => {
        if (!param?.value && typeof param?.value !== "number") return "NA";

        return <GenricText text={param?.value ?? ""} />;
      },
    },
    {
      field: "total_users_reward_value",
      headerName: "Total Users Reward value",
      flex: 0.7,
      renderCell: (param) => {
        if (!param?.value && typeof param?.value !== "number") return "NA";

        return <GenricText text={param?.value ?? ""} />;
      },
    },
    {
      field: "average_reward",
      headerName: "Avg Reward",
      flex: 1,
      renderCell: (param) => {
        if (!param?.value && typeof param?.value !== "number") return "NA";

        return <GenricText text={param?.value ?? ""} />;
      },
    },
    {
      field: "total_cards_issued",
      headerName: "Total Card Issued",
      flex: 1,
      renderCell: (param) => {
        if (!param?.value && typeof param?.value !== "number") return "NA";

        return <GenricText text={param?.value ?? ""} />;
      },
    },
    {
      field: "total_cards_redeemed",
      headerName: "Total Card Redeemed",
      flex: 1,
      renderCell: (param) => {
        if (!param?.value && typeof param?.value !== "number") return "NA";

        return <GenricText text={param?.value ?? ""} />;
      },
    },
  ];

  const handleRowClick = async (row) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const BaseUrl = process.env.REACT_APP_API_BASE_URL;

    const url = `${BaseUrl}/api/analytics/products/${row?.row?.product_id}`;

    await axios
      .post(url, {}, config)
      .then((res) => {
        if (!res?.data?.data?.message === "Offer not found") return;
        const data = processData(res);
        const { overviewOptions, piechartOptions, areaChart } = data;

        if (
          !multipleTab?.hasOwnProperty(row?.row?.product_id) &&
          Object.values(multipleTab).length <= 4
        ) {
          setMultipleTab((prev) => ({
            ...prev,
            [row?.id]: {
              id: row.row.product_id,
              loader: false,
              piechart: piechartOptions[0],
              areaChart: areaChart,
              scratchCardAnalytics: overviewOptions[1],
              rewardCardAnalytics: overviewOptions[0],
              product: row.row.product_name,
            },
          }));

          setTabFilter((prev) => ({
            ...prev,
            [row?.id]: { ...dateFilterLayout },
          }));
        } else {
          return;
        }

        if (showDateFilter) {
          handleShowDateFilter(activeButton, false);
        }

        activeIndex.current = row?.id;
        setActiveSection(row?.id);
      })
      .catch((error) => {
        // toasr
        if (error?.response?.data?.message) {
          toast.warning(error?.response?.data?.message);
        }
      });
  };

  const getProductById = async (id) => {
    let timer;
    if (id && multipleTab?.hasOwnProperty(id)) {
      setTabLoader(true);
    }
    const obj = {};

    if (
      tabFilter[id]?.select &&
      tabFilter[id]?.select === "CUSTOM" &&
      tabFilter[id]?.to_time &&
      tabFilter[id]?.from_time &&
      typeof tabFilter[id]?.from_time === "string" &&
      typeof tabFilter[id]?.to_time === "string"
    ) {
      let startDate = formatDateNew(tabFilter[id]?.from_time ?? "");
      let endDate = formatDateNew(tabFilter[id]?.to_time ?? "");
      obj["dateStart"] = startDate;
      obj["dateEnd"] = endDate;
    }

    if (tabFilter[id]?.select) {
      obj["day"] = tabFilter[id]?.select;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const BaseUrl = process.env.REACT_APP_API_BASE_URL;

    const url = `${BaseUrl}/api/analytics/products/${id}`;

    try {
      const res = await axios.post(url, obj, config);
      const data = processData(res);
      const { overviewOptions, piechartOptions, areaChart } = data;

      if (
        // !multipleTab?.hasOwnProperty(id) &&
        Object.values(multipleTab).length <= 4
      ) {
        setMultipleTab((prev) => ({
          ...prev,
          [id]: {
            ...prev[id],
            id: id,
            piechart: piechartOptions[0],
            areaChart: areaChart,
            scratchCardAnalytics: overviewOptions[1],
            rewardCardAnalytics: overviewOptions[0],
          },
        }));
      } else {
        return;
      }
    } catch (error) {
      console.log(error);
    } finally {
      if (timer) {
        clearTimeout(timer);
      }
      // handleDateFilter(id, "loader", true);
      if (id && multipleTab?.hasOwnProperty(id)) {
        timer = setTimeout(() => {
          setTabLoader(false);
        }, 500);
      }
    }
  };

  const handlePaginationModel = (data) => {
    if (!data) return;
    setProducts((prev) => ({ ...prev, paginationModel: data }));
  };

  const getProducts = async (status, data,isPagination=false) => {
    if (status !== "download") {
      setLoding(true);
    }

    if(!isPagination){
      const paginationValue = {
        page: 0,
        pageSize: products?.paginationModel.pageSize,
      };
        handlePaginationModel(paginationValue);
      }

    const obj = {
      ...(search && { search: search }),
      page:isPagination ? products?.paginationModel.page + 1 : 1,
      itemsPerPage: products?.paginationModel.pageSize,
    };

    if (
      dateFilter?.select &&
      dateFilter?.select === "CUSTOM" &&
      dateFilter?.to_time &&
      dateFilter?.from_time
    ) {
      let startDate = formatDateNew(dateFilter?.from_time ?? "");
      let endDate = formatDateNew(dateFilter?.to_time ?? "");
      obj["dateStart"] = startDate;
      obj["dateEnd"] = endDate;
    }

    if (dateFilter?.select) {
      obj["day"] = dateFilter?.select;
    }

    if (status === "done") {
      setShowFilter(filter);
    }

    if ((!status || status === "download") && data) {
      data?.forEach((res) => {
        if (res?.state?.length > 0) {
          obj[res?.name] = res?.state;
        }
      });
    } else if (status === "clear") {
      data?.forEach((res) => {
        if (res?.state?.length > 0) {
          obj[res?.name] = res?.state;
        }
      });
    } else if (status === "done") {
      filter?.forEach((res) => {
        if (res?.state?.length > 0) {
          obj[res?.name] = res?.state;
        }
      });
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const BaseUrl = process.env.REACT_APP_API_BASE_URL;

    if (status === "download") {
      obj["total_count"] = products?.totalcount;
      handleDownload(BaseUrl, obj, token, "productAnalytics");
      return;
    }

    try {
      const response = await axios.post(
        `${BaseUrl}/api/analytics/products`,
        obj,
        config
      );

      if (dateFilter?.select !== "CUSTOM") {
        handleDateFilter(activeButton, "", "", {
          from_time: response?.data?.day_start_date,
          to_time: response?.data?.day_end_date,
        });
      }

      setProducts((prev) => ({
        ...prev,
        data: response?.data?.data,
        totalcount: response?.data?.productCount,
      }));
    } catch (error) {
      console.log(error);
    } finally {
      setTimeout(() => {
        setLoding(false);
      }, 300);
    }
  };

  const removeFilters = (name, value) => {
    handleRemove(name, value);
    const latestData = showFilter.map((filter) =>
      filter?.name === name
        ? {
            ...filter,
            state: filter.state.filter((res) => res !== value),
          }
        : filter
    );
    setShowFilter((prevOptions) => {
      const updatedData = prevOptions?.map((filter) =>
        filter?.name === name
          ? {
              ...filter,
              state: filter.state.filter((res) => res !== value),
            }
          : filter
      );

      return updatedData;
    });

    getProducts("clear", latestData);
  };

  const handleSearch = () => {
    getProducts("", showFilter);
  };

  const handleDateFilters = (parentname, name, value) => {
    setTabFilter((prev) => ({
      ...prev,
      [parentname]: { ...prev[parentname], [name]: value },
    }));
  };

  useEffect(() => {
    // Step 1: Filter TData columns where flag is true
    let filteredTData = fieldsName?.filter((column) => column.flag === true);

    // filteredTData = [...filteredTData, { "key": "copyBtn", "displayName": "Action", "flag": true }];

    // Step 2: Filter columns where field matches key from filteredTData
    const filteredColumns = columns.filter((column) =>
      filteredTData.some(
        (filteredColumn) => filteredColumn.key === column.field
      )
    );

    const finalColumns = [...filteredColumns];

    setColumnFields(finalColumns);
  }, [fieldsName]);

  useEffect(() => {
    getProducts("done");
  }, [doneButton]);

  useEffect(() => {
    getProducts("done",null,true);
  }, [ products?.paginationModel]);

  useEffect(() => {
    if (!flagRef.current) {
      flagRef.current = "initialized";
      return;
    }

    if (dateFilter?.select !== "CUSTOM") {
      getProducts("", showFilter);
    }
  }, [dateFilter?.select]);

  useEffect(() => {
    if (
      dateFilter?.select === "CUSTOM" &&
      dateFilter?.from_time &&
      dateFilter?.to_time
    ) {
      setDate({
        from_time: dateFilter?.from_time ?? "",
        to_time: dateFilter?.to_time ?? "",
      });
    }
  }, [dateFilter?.from_time, dateFilter?.to_time]);

  useEffect(() => {
    if (date?.from_time || date?.to_time) {
      getProducts("", showFilter);
    } else {
      getProducts("", showFilter);
    }
  }, [date, searchDebounce]);

  useEffect(() => {
    if (
      activeSection === "All" ||
      !activeSection ||
      !multipleTab?.hasOwnProperty(activeSection)
    )
      return;

    if (
      tabFilter[activeSection]?.select === "CUSTOM" &&
      tabFilter[activeSection]?.to_time &&
      tabFilter[activeSection]?.from_time &&
      typeof tabFilter[activeSection]?.to_time === "string" &&
      typeof tabFilter[activeSection]?.from_time === "string"
    ) {
      getProductById(activeSection);
    } else if (
      tabFilter[activeSection]?.select &&
      tabFilter[activeSection]?.select !== "CUSTOM"
    ) {
      getProductById(activeSection);
    }
  }, [tabFilter, activeSection]);

  useEffect(() => {
    return () => {
      handleReset(activeButton, initialStateOverviewFilter);
      handleShowDateFilter(activeButton, true);
    };
  }, []);

  useEffect(() => {
    if (tabFilter[activeSection]?.select !== "CUSTOM") {
      handleDateFilters(activeSection, "to_time", { unset: true });
      handleDateFilters(activeSection, "from_time", { unset: true });
    }
    console.log(tabFilter[activeSection]?.to_time, "select");
  }, [tabFilter[activeSection]?.select]);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        {/* <QuickFilter
          handleTicketStatus={handleTicketStatus}
          quickfilter={quickfilter}
          quickFilterConfig={quickFilterConfig}
        /> */}
        <Stack
          direction={"row"}
          width={"100%"}
          justifyContent={"space-between"}
        >
          {showDateFilter ? (
            <QueryInput
              placeholder={"Search by Product Name"}
              value={search}
              setState={setSearch}
              handleSearch={handleSearch}
            />
          ) : (
            <Box> </Box>
          )}
          {!showDateFilter ? (
            <DateFilterPanel
              key={activeSection}
              dateFilter={tabFilter}
              filterSelectOptions={filterSelectOptions}
              activeButton={activeSection}
              handleDateFilter={handleDateFilters}
            />
          ) : (
            <ExportButton
              isDisbaled={products.data.length === 0 || !permission?.DOWNLOAD}
              handleExport={() => getProducts("download", showFilter)}
              TooltipMessage={
                products.data.length === 0
                  ? "No Data Found"
                  : "You don't have permission"
              }
            />
          )}
        </Stack>
      </Box>

      {showFilter && showFilter.length > 0 && showDateFilter && (
        <Box
          sx={{
            paddingTop: showFilter.length > 0 ? "28px" : "0",
          }}
        >
          <FilteredResults options={showFilter} handleRemove={removeFilters} />
        </Box>
      )}

      <Box>
        <Box
          sx={{
            display: "flex",
            gap: "3px",
            justifyContent:"space-between",
            alignItems: "flex-end",
            height: "fit-content",
          }}
        >
<Box  sx={{
            display: "flex",
            gap: "3px",
            // justifyContent:"space-between",
            alignItems: "flex-end",
            height: "fit-content",
          }}>


          {tableButton?.map((res) => (
            <Button
              key={res.label}
              sx={{
                display: "flex",
                width: "142px",
                padding: "10px",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                marginTop: "25px",
                borderRadius: "10px 10px 0 0",
                border: "1px solid #929EAE",
                height: "38px",
              }}
              style={{
                background: activeSection === res?.value ? "#FFF" : "#DEE0E3",
              }}
              onClick={() => {
                handleShowDateFilter(activeButton, true);
                res.handleSelectNameClick(res?.value);
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  fontSize: "14px",
                  fontFamily: "Proxima Nova",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  textAlign: "right",
                  fontWeight: "600",
                  lineHeight: "normal",
                  letterSpacing: "0.28px",
                  textTransform: "capitalize",
                  color: "#000",
                }}
              >
                {res?.label}
              </Typography>

              {products.totalcount > 0 && (
                <Box
                  sx={{
                    display: "inline-flex",
                    padding: "2px 5px",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "10px",
                    background: "#000",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#fff",
                      // textAlign: "right",
                      fontFamily: "Proxima Nova",
                      fontSize: "14px",
                      lineHeight: "normal",
                      fontWeight: "600px",
                      letterSpacing: "0.28px",
                      textTransform: "capitalize",
                    }}
                  >
                    {products?.totalcount}
                  </Typography>
                </Box>
              )}
            </Button>
          ))}

          {Object.values(multipleTab).map((res, index) => (
            <Button
              key={res.label}
              sx={{
                display: "flex",
                width: "142px",
                position: "relative",
                padding: "10px",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                marginTop: "25px",
                height: "38px",
                borderRadius: "10px 10px 0 0",
                border: "1px solid #929EAE",
                zIndex: index + 1,
              }}
              style={{
                background: activeSection === res?.id ? "#FFF" : "#DEE0E3",
              }}
              onClick={() => {
                handleSelectNameClick(res?.id ?? "");

                if (showDateFilter) {
                  handleShowDateFilter(activeButton, false);
                }
                activeIndex.current = res?.id;
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  fontSize: "14px",
                  fontFamily: "Proxima Nova",
                  height: "16px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  // textAlign: "right",
                  fontWeight: "600",
                  lineHeight: "normal",
                  letterSpacing: "0.28px",
                  textTransform: "capitalize",
                  paddingLeft: "10px",
                  color: "#000",
                }}
              >
                <BootstrapTooltip title={res?.product}>
                  {res?.product}
                </BootstrapTooltip>
              </Typography>

              <IconButton
                sx={{
                  // position: "absolute",
                  // top: "-7px",
                  // right: "-8px",
                  backgroundColor: "#F1554C",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "9px",
                  width: "9px",
                  padding: "10px",
                  "&:hover": {
                    backgroundColor: "#F1554C",
                  },
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleShowDateFilter(activeButton, true);
                  setActiveSection("All");
                  setShowFilter(true);
                  setMultipleTab((prev) => {
                    const updatedState = { ...prev };
                    delete updatedState[res?.id];
                    return updatedState;
                  });
                }}
              >
                <CloseIcon
                  sx={{
                    fill: "#fff",
                    width: "14px",
                    height: "14px",
                  }}
                />
              </IconButton>
            </Button>
          ))}

</Box>



          <Box sx={{ display: activeSection === "All"?"flex":"none", justifyContent: "end" }}>
            <ToggleColumn
              tableFields={fieldsName}
              handleToggleColumn={handleToggleColumn}
              disabledIndex={1}
            />
          </Box>
        </Box>
        {activeSection === "All" && (
          <Box>
            <UiTable
              row={products?.data}
              columns={columnFields || []}
              totalCount={products?.totalcount ?? 0}
              paginationModel={products?.paginationModel}
              handleRowClick={handleRowClick}
              setPaginationModel={handlePaginationModel}
              alternaviteIdName={"product_id"}
              loading={loading}
            />
          </Box>
        )}

        {activeSection !== "All" &&
          (tabLoader ? (
            <Box
              sx={{
                display: "flex",
                height: "30vh",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress style={{ color: "#26292D" }} />
            </Box>
          ) : (
            <ProductDetailsAnalytics data={multipleTab[activeSection]} />
          ))}
      </Box>
      {/* {
        <>
          <Stack
            spacing={5}
            boxSizing={"border-box"}
            width={"100%"}
            mt={"15px"}
          >
            {overviewData?.overviewOptions.map((res) => (
              <AnalyticsSection
                title={res.title}
                options={res?.options ?? []}
                titleColor={cssOptions[res.title]?.titleColor}
                sidebackgroundColor={cssOptions[res.title]?.sidebackgroundColor}
                columnsInRow={cssOptions[res.title]?.columnsInRow}
              />
            ))}
          </Stack>

          <Stack spacing={3} mt={"32px"}>
            <Typography sx={sectionTitleStyle}>Fulfilment Chart</Typography>
            <Stack spacing={5}>
              <Stack direction={"row"} spacing={"42px"}>
                {overviewData?.piechartOptions.map((res) => (
                  <CustomPieChart
                    key={res.legend}
                    legend={res.legend}
                    color={res.color}
                    series={res.series}
                    options={res.options}
                    data={res.data}
                    pieCenterData={res.pieCenterData}
                  />
                ))}
              </Stack>
              <Stack direction={"row"} spacing={"42px"}>
                {overviewData?.areaChart.map((res) => (
                  <CustomAreaChart
                    key={res?.legend?.label}
                    legend={res?.legend ?? {}}
                    data={res?.data}
                    strokeColor={res?.strokeColor}
                    backgroundColor={res?.backgroundColor}
                  />
                ))}
              </Stack>
            </Stack>
          </Stack>
        </>
      } */}
    </Box>
  );
};

export default ProductLevel;
