import {
  Avatar,
  Box,
  CircularProgress,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import QueryInput from "./QueryInput";
import documentsvg from "../assets/Frame.svg";
import AnalyticsSection from "./AnalyticsSection";
import { initialStateUserLevelFilter } from "../utils/initialStates";
import CustomAreaChart from "./charts/CustomAreaChart";
import { useSelector } from "react-redux";
import axios from "axios";
import { formatDateNew } from "../utils/formateDate";
import ClearIcon from "@mui/icons-material/Clear";
import ExportButton from "./ExportButton";
import { handleDownload } from "../utils/download";

const data = [
  {
    name: "JAN",
    uv: [0, 50, 850],
  },
  {
    name: "FEB",
    uv: 900,
  },
  {
    name: "MAR",
    uv: 2000,
  },
  {
    name: "APR",
    uv: 3250,
  },
  {
    name: "MAY",
    uv: 2920,
  },
  {
    name: "JUN",
    uv: 1000,
  },
  {
    name: "JULY",
    uv: 1000,
  },
];

function processData(response) {
  const data = response?.data?.data;

  const areaChartCss = [
    {
      strokeColor: "#043A87",
      backgroundColor: "#043A87",
    },
    {
      strokeColor: "#DA8BFF",
      backgroundColor: "#DA8BFF",
    },
  ];

  const piechartColors = [
    ["#685EDD", "#D6D6D6"],
    ["#00A52E", "#3C3C3C", "#D9D9D9"],
  ];

  const overviewOptions = [
    {
      title: "All Rewards Analytics",
      options: [...data?.reward_analytics, ...data?.currency_rewards] ?? [],
    },
    {
      title: "Scratch Card Analytics",
      options: data?.scratch_card_analytics ?? [],
    },
  ];

  const piechartOptions = data?.graph_data?.map((res, index) => ({
    ...res,
    color: piechartColors[index],
  }));

  const areaChart = data?.area_chart?.map((res, index) => ({
    ...res,
    ...areaChartCss[index],
  }));

  return { overviewOptions, piechartOptions, areaChart };
}

const areaChart = {
  legend: {
    label: "Amount VS Time",
    sublabel: "NEU COINS",
  },
  data,
  strokeColor: "#DA8BFF",
  backgroundColor: "#DA8BFF",
};

const rewardCardAnalytics = {
  title: "Reward Analytics",
  options: [
    {
      value: "40",
      title: "Total Offers Avail",
    },
    {
      value: "5430",
      title: "Total Neu Coins Credited",
    },
    {
      value: "₹745",
      title: "Total Gold Credited",
    },
    {
      value: "100",
      title: "Average Rewards Earned",
    },
  ],
};

const scratchCardAnalytics = {
  title: "Scratch Card Analytics",
  options: [
    {
      value: "50",
      title: "Total Scratch Cards Issued",
    },
    {
      value: "10",
      title: "Total Scratch Cards Locked",
    },
    {
      value: "40",
      title: "Total Scratch Cards Unlocked",
    },
    {
      value: "27",
      title: "Total Scratch Cards Redeemed",
    },
    {
      value: "8",
      title: "Total Scratch Cards Expired",
    },
    {
      value: "13",
      title: "Total Scratch Cards Failed (BLNT)",
    },
  ],
};

const rewardCardAnalytics1 = {
  title: "Reward Analytics",
  options: [
    {
      value: "47",
      title: "Total Offers Redeemed",
    },
    {
      value: "6200",
      title: "Total Neu Coins Credited",
    },
    {
      value: "₹852",
      title: "Total Gold Credited",
    },
    {
      value: "200",
      title: "Average Reward Value",
    },
  ],
};

function removeSlugFromString(inputString) {
  const result = inputString.replace(/_/g, " ");

  return result;
}

const scratchCardAnalytics1 = {
  title: "Scratch Card Analytics",
  options: [
    {
      value: "60",
      title: "Total Scratch Cards Issued",
    },
    {
      value: "5",
      title: "Total Scratch Cards Locked",
    },
    {
      value: "50",
      title: "Total Scratch Cards Unlocked",
    },
    {
      value: "32",
      title: "Total Scratch Cards Redeemed",
    },
    {
      value: "6",
      title: "Total Scratch Cards Expired",
    },
    {
      value: "3",
      title: "Total Scratch Cards Failed (BLNT)",
    },
    // {
    //   value : "6",
    //   title : "Scratch Cards Issued Per User"
    // },
  ],
};

const UserLevel = ({
  activeButton,
  filter,
  handleReset,
  dateFilter,
  handleDateFilter,
}) => {
  const [search, setSearch] = useState("");
  const [noOptions, setNoOptions] = useState(false);
  const text = useRef("");
  const [loader, setloader] = useState(false);
  const [date, setDate] = useState({
    startDate: "",
    endDate: "",
  });
  const flagRef = useRef("");

  const cardoptions = {
    reward_analytics: {
      title: "Reward Analytics",
      columnsInRow: 4,
    },
    scratch_card_analytics: {
      title: "Scratch Card Analytics",
      sidebackgroundColor: "#D4D71E",
      columnsInRow: 3,
    },
  };

  const [userLevel, setUserLevel] = useState({});
  const token = useSelector((store) => store.AuthReducer.token);

  const getUserLevel = async (status) => {
    if (status !== "download") {
      setloader(true);
    }
    const obj = {};
    if (
      dateFilter?.select &&
      dateFilter?.select === "CUSTOM" &&
      dateFilter?.to_time &&
      dateFilter?.from_time
    ) {
      let startDate = formatDateNew(dateFilter?.from_time ?? "");
      let endDate = formatDateNew(dateFilter?.to_time ?? "");
      obj["dateStart"] = startDate;
      obj["dateEnd"] = endDate;
    }

    if (dateFilter?.select) {
      obj["day"] = dateFilter?.select;
    }
    // if(!search) return
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const BaseUrl = process.env.REACT_APP_API_BASE_URL;

    if (status === "download") {
      handleDownload(BaseUrl, obj, token);
      return;
    }

    try {
      const response = await axios.post(
        `${BaseUrl}/api/analytics/users/${search}`,
        obj,
        config
      );

      const data = processData(response);
      const { overviewOptions, areaChart } = data;

      if (dateFilter?.select !== "CUSTOM") {
        handleDateFilter(activeButton, "", "", {
          from_time: response?.data?.day_start_date,
          to_time: response?.data?.day_end_date,
        });
      }

      //   text.current = search;

      //   Object.entries(response?.data)?.map(([key , value]) => {
      //     const data = {}
      //     data['title'] = removeSlugFromString(key);
      //     const options = []
      //    //  if(key === "reward_analytics"){}
      //     Object.entries(value).map(([childkey , value]) => {
      //       if(childkey !== "currencyPoints"){
      //        options.push({
      //          title : removeSlugFromString(childkey),
      //          value : value
      //        })
      //       }
      //     })
      //    obj[key] = {...cardoptions[key] , data : {...data , options}}
      //  })

      //  if( response?.data?.reward_analytics?.currencyPoints){
      //    const currencyPoints = Object.entries( response?.data?.reward_analytics?.currencyPoints).map(([key , value])=> {
      //      if(key === "total_DIGITAL_GOLD_credited"){
      //       return {title : "Total Gold Credited" , value : `₹${value}`}
      //      }else {
      //        return {title : "Total Neu Coins Credited" , value :`${value}`}
      //      }
      //   })

      //   if(obj?.reward_analytics?.data?.options){
      //     obj.reward_analytics.data.options = [...obj.reward_analytics.data.options , ...currencyPoints]
      //   }

      //  }

      setUserLevel({
        reward_analytics: overviewOptions[0],
        scratch_card_analytics: overviewOptions[1],
        areaChart: areaChart,
        current: search,
      });
    } catch (error) {
      if (
        error?.response?.data?.message === "User not found" ||
        search.length === 0
      ) {
        setUserLevel({});
        setNoOptions(true);
      }
      // if(error?.response?.message)
    } finally {
      setTimeout(() => {
        setloader(false);
      }, 300);
    }
  };

  const handleSearch = () => {
    if (search.length === 0) {
      if (noOptions) {
        setNoOptions(false);
      }

      setUserLevel({});
    } else {
      setloader(true);
      getUserLevel();
    }
  };

  //css
  const titleStyle = {
    fontFamily: "Proxima Nova A",
    fontSize: 20,
    fontWeight: 600,
    lineHeight: "24px",
    letterSpacing: "0em",
    color: "#0F1828",
  };

  const subTitle = {
    fontFamily: "Proxima Nova A",
    width: "325px",
    fontSize: 16,
    fontWeight: 400,
    lineHeight: "21px",
    textAlign: "center",
    letterSpacing: "0em",
    color: "#0F182899",
  };

  const checkUserData = () => {
    return Object.values(userLevel).length === 0;
  };

  const queryBuilder = () => {};

  useEffect(() => {
    if (!search || checkUserData()) return;
    // if (!flagRef.current && !checkUserData()) {
    //   flagRef.current = "initialized";
    //   return;
    // }

    if (dateFilter?.select !== "CUSTOM") {
      getUserLevel();
    }
  }, [dateFilter?.select]);

  useEffect(() => {
    if (
      dateFilter?.select === "CUSTOM" &&
      dateFilter?.from_time &&
      dateFilter?.to_time
    ) {
      setDate({
        from_time: dateFilter?.from_time ?? "",
        to_time: dateFilter?.to_time ?? "",
      });
    }
  }, [dateFilter?.from_time, dateFilter?.to_time]);

  useEffect(() => {
    if ((!checkUserData() && date?.from_time) || date?.to_time) {
      getUserLevel("done");
    }
  }, [date]);

  useEffect(() => {
    return () => {
      handleReset(activeButton, initialStateUserLevelFilter);
    };
  }, []);
  //Object.values(userLevel).length === 0

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <QueryInput
          placeholder={"Search user by User#"}
          value={search}
          setState={setSearch}
          handleSearch={handleSearch}
          hitEnter={true}
        />
      </Box>

      {loader ? (
        <Box
          sx={{
            display: "flex",
            height: "30vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress style={{ color: "#26292D" }} />
        </Box>
      ) : (
        <>
          {Object.values(userLevel).length === 0 ? (
            <Box
              sx={{
                height: "calc(100vh - 252px)",
                width: "100%",
                boxSizing: "border-box",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Stack
                spacing={3}
                justifyContent={"center"}
                alignItems={"center"}
              >
                {!noOptions && (
                  <Avatar
                    sx={{
                      borderRadius: "0",
                    }}
                    src={documentsvg}
                  />
                )}
                <Stack
                  spacing={2}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  {!noOptions ? (
                    <>
                      <Typography sx={titleStyle}>Search User#</Typography>
                      <Typography sx={subTitle}>
                        Simply type the user# into the search box and instantly
                        retrieve the data.
                      </Typography>
                    </>
                  ) : (
                    <Typography sx={titleStyle}>No User Found</Typography>
                  )}
                </Stack>
              </Stack>
            </Box>
          ) : (
            <Stack
              spacing={3}
              sx={{
                boxSizing: "border-box",
                width: "100%",
                mt: "19px",
              }}
            >
              <Stack spacing={1.8}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    style={{
                      textAlign: "left",
                    }}
                  >
                    {userLevel.current}
                  </Typography>
                  <IconButton
                    sx={{
                      color: "#000",
                    }}
                    onClick={() => {
                      setUserLevel([]);
                      setSearch("");
                      setNoOptions(false);
                      handleDateFilter(activeButton, "", "", {
                        from_time: "",
                        to_time: "",
                        select: "THIS_MONTH",
                      });
                    }}
                  >
                    <ClearIcon />{" "}
                  </IconButton>
                </Box>
                <Divider />
              </Stack>

              {/* {
              userLevel?.reward_analytics && 
             } */}

              {userLevel?.reward_analytics && (
                <AnalyticsSection
                  title={userLevel?.reward_analytics?.title}
                  options={userLevel?.reward_analytics?.options}
                  titleColor="#585858"
                  columnsInRow={4}
                />
              )}

              {userLevel?.scratch_card_analytics && (
                <AnalyticsSection
                  title={userLevel?.scratch_card_analytics?.title}
                  options={userLevel?.scratch_card_analytics?.options}
                  titleColor="#585858"
                  sidebackgroundColor="#D4D71E"
                  columnsInRow={3}
                />
              )}

              {/* {Object.entries(userLevel)?.map(
               ([key, { title, data, columnsInRow, sidebackgroundColor }]) => (
                 <AnalyticsSection
                   key={key}
                   title={title}
                   options={data?.options}
                   titleColor="#585858"
                   columnsInRow={columnsInRow}
                   sidebackgroundColor={sidebackgroundColor} 
                 />
               )
             )} */}

              <Box>
                {/* {userLevel?.areaChart && (
                  <CustomAreaChart
                    key={userLevel?.areaChart?.legend?.label}
                    legend={userLevel?.areaChart?.legend ?? {}}
                    data={userLevel?.areaChart?.data}
                    strokeColor={userLevel?.areaChart?.strokeColor}
                    backgroundColor={userLevel?.areaChart?.backgroundColor}
                  />
                )} */}

                {userLevel?.areaChart &&
                  userLevel?.areaChart?.map((chartData, index) => (
                    <CustomAreaChart
                      key={index}
                      legend={chartData?.legend ?? {}}
                      data={chartData?.data}
                      strokeColor={chartData?.strokeColor}
                      backgroundColor={chartData?.backgroundColor}
                    />
                  ))}
              </Box>
            </Stack>
          )}
        </>
      )}
    </Box>
  );
};

export default UserLevel;
