import { Box } from "@mui/material";
import React from "react";
import MuiButton from "../UIComponents/MuiButton";

const ExportButton = ({ handleExport, isDisbaled,TooltipMessage }) => {
  return (
    <Box >
      <MuiButton
        isDisable={isDisbaled}
        style={{
          height: "43px",
          width: "120px",
          padding: "14px 20px 14px 20px",
          borderRadius: "5px",
          color: "#FFF",
          fontFamily: "ABeeZee",
          fontSize: "15px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "normal",
        }}
        onClick={() => {
          handleExport();
        }}
        TooltipMessage={TooltipMessage}
      >
        Export
      </MuiButton>
    </Box>
  );
};

export default ExportButton;
