import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import BoldLabel from "../../../../UIComponents/BoldLevel";
import { selectGameType } from "../../../../Redux/OfferReducer/actionType";

export default function AllGames() {
  const [gameType, setGameType] = useState("");
  const gameData = useSelector((store) => store?.OfferReducer?.allGames);
  const data = useSelector(
    (store) => store?.OfferReducer?.offerData?.createGame
  );
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const handleSelectGame = (game, id) => {
    dispatch({
      type: selectGameType,
      payload: { gameName: game, game_type_id: id, gameTypeName: game },
    });
    setGameType(game);
  };
  return (
    <Box>
      <Box>
        <BoldLabel label="Choose a Game type" />
        <Box sx={{ display: "flex", gap: "20px", height: "40vh", mt: "8px" }}>
          {gameData?.map((game, index) => {
            const isSelected = data?.gameName === game?.name;
            const isEditable =
              pathname.startsWith("/edit-offers") ||
              pathname.startsWith("/preview-offer");
            const cursorStyle =
              (isEditable && isSelected) || !isEditable ? "pointer" : "default";

            return (
              <Box
                key={index}
                sx={{
                  height: "160px",
                  width: "23%",
                  border: isSelected
                    ? "1.5px solid #9CBFEB"
                    : "1.5px solid transparent",
                  borderRadius: "12px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: cursorStyle,
                  gap: "8px",
                  backgroundColor: isSelected ? "#E8F0F9" : "#F9F9FC",
                }}
                onClick={() => {
                  if (
                    pathname.startsWith("/edit-offers") ||
                    pathname.startsWith("/preview-offer")
                  ) {
                    if (data?.gameName === game?.name) {
                      handleSelectGame(game?.name, game?.id);
                    }
                  } else {
                    handleSelectGame(game?.name, game?.id);
                  }
                }}
              >
                <img
                  src={game?.icon}
                  style={{ height: "58px", width: "58px" }}
                  alt={`${game?.name} Image`}
                />
                <Typography
                  sx={{
                    fontFamily: "Mulish",
                    fontWeight: 600,
                    fontSize: "16px",
                    lineHeight: "20.08px",
                    color: "#272728",
                  }}
                >
                  {game?.name}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>

    </Box>
  );
}
