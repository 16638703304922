import React, { useEffect } from "react";
import { Box, Button, Radio, Typography } from "@mui/material";
import MuiButton from "../../UIComponents/MuiButton";
import Label from "../../UIComponents/Label";
import MuiSelect from "../../UIComponents/MuiSelect";
import MultiSelect from "../../UIComponents/MultiSelect";
import { useDispatch, useSelector } from "react-redux";
import {
  getCohortsSetFunction,
  getRuleSetFunction,
} from "../../Redux/OfferReducer/action";
import MuiMultiSelectWithSearch from "../../UIComponents/MuiMultiSelectWithSearch";
import MuiSelectAndSearch from "../../UIComponents/MuiSelectAndSearch";
import MuiRadioGroup from "../../UIComponents/MuiRadioGroup";
import { RiDeleteBinLine } from "react-icons/ri";
import cohortDeleteBtn from "../../assets/ChorortDeleteBtn.png";
import highGraph from "../../assets/highGraph.svg";
import { useLocation } from "react-router-dom";
const options = [
  { label: "Include", value: "INCLUDE" },
  { label: "Exclude", value: "EXCLUDE" },
];

const cohortOptions = [
  { label: "Option 1", value: "option1" },
  { label: "Option 2", value: "option2" },
  { label: "Option 3", value: "option3" },
  { label: "Option 4", value: "option4" },
  { label: "Option 5", value: "option5" },
];

const SetOfferRules = ({
  data,
  onChange,
  errorMessage,
  handleSetOfferRulesError,
  handleTabs,
  isEdit = false,
  handleAddCohortsTab,
  handleAddProjectionsTab,
  isPreviewShow
}) => {
  const handleData = (key, value) => {
    if (errorMessage?.[key]) {
      handleSetOfferRulesError((prev) => {
        return {
          ...prev,
          [key]: "",
        };
      });
    }
    onChange({ ...data, [key]: value });
  };
  const {pathname} = useLocation();
  const dispatch = useDispatch();
  const ruleData = useSelector((store) => store.OfferReducer.ruleOption);
  const cohortsData = useSelector((store) => store.OfferReducer.cohortsOption);
  // console.log(cohortsData);

  const ruleOption = ruleData?.map((element) => {
    return { name: element?.name, id: element?.id };
  });
  const cohortsOption = cohortsData?.map((element) => {
    return { name: element?.name, id: element?.id, value: "INCLUDE" };
  });
  console.log(data, "offerRule");

  const handleIncludeExclude = (index, val) => {
    const updatedCohorts = [...data.linkToCohort];
    updatedCohorts[index] = { ...updatedCohorts[index], value: val };
    console.log(updatedCohorts);
    handleData("linkToCohort", updatedCohorts);
  };

  const handleDeleteCohortSelectedOption = (index) => {
    const updatedCohorts = [...data.linkToCohort];
    updatedCohorts.splice(index, 1);
    handleData("linkToCohort", updatedCohorts);
  };

  useEffect(() => {
    dispatch(getRuleSetFunction());
    dispatch(getCohortsSetFunction());
  }, []);

  return (
    <Box
      sx={{
        display: isEdit ? "flex" : "block",
        justifyContent: "space-between",
        flexWrap: "wrap",
      }}
    >
      <Box>
        <Typography
          sx={{
            textAlign: "left",
            color: "var(--Font-Dark, #0F1828)",
            fontFamily: "Mulish",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "130%",
            mb: "18px",
          }}
        >
          1. Select Rule
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: 5,
            alignItems: "end",
            flexWrap: "wrap",
            mb: isEdit ? "20px" : "40px",
          }}
        >
          <Box sx={{ width: "500px" }}>
            <Label label="Link To Rule" />
            {/* <MuiSelect
              value={data?.linkToRule}
              onChange={(value) => handleData("linkToRule", value)}
              options={ruleOption}
              placeholder="Link To Rule"
              errorMessage={errorMessage?.linkToRule}
            /> */}
            <MuiSelectAndSearch
              value={data?.linkToRule}
              onChange={(value) => handleData("linkToRule", value)}
              options={ruleOption}
              placeholder="Link To Rule"
              errorMessage={errorMessage?.linkToRule}
              editBoolean={data?.previous_linkToRule?.id??false}
            />
          </Box>
          {!isEdit && (
            <Typography
              sx={{
                pb: "20px",
                color: "#000",
                fontFamily: "ABeeZee",
                fontSize: "15px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
              }}
            >
              OR
            </Typography>
          )}
          {!isEdit && (
            <Box sx={{ pb: "5px" }}>
              <MuiButton
                bg="#4D4D4D"
                style={{
                  fontFamily: "ABeeZee",
                  fontWeight: 400,
                  fontSize: "15px",
                  lineHeight: "17.73px",
                  padding: "10px",
                  width: "140px",
                  borderRadius: "4px",
                }}
                onClick={handleTabs}
              >
                Create Rules
              </MuiButton>
            </Box>
          )}
        </Box>
      </Box>
      <Box>
        <Typography
          sx={{
            textAlign: "left",
            color: "var(--Font-Dark, #0F1828)",
            fontFamily: "Mulish",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "130%",
            mb: "18px",
          }}
        >
          2. Select Cohort
        </Typography>
        <Box
          sx={{ display: "flex", gap: 5, alignItems: "end", flexWrap: "wrap" }}
        >
          <Box sx={{ width: "500px" }}>
            <Label label="Link To Cohort" />
            {/* <MultiSelect
              value={data?.linkToCohort}
              onChange={(value) => handleData("linkToCohort", value)}
              options={cohortsOption}
              placeholder="Link To Cohort"
              errorMessage={errorMessage?.linkToCohort}
            /> */}
            <MuiMultiSelectWithSearch
              value={data?.linkToCohort}
              onChange={(value) => handleData("linkToCohort", value)}
              options={cohortsOption}
              placeholder="Link To Cohort"
              errorMessage={errorMessage?.linkToCohort}
              editBoolean={data?.previous_linkToCohort?.length>0??false}
            />
          </Box>
          {!isEdit && (
            <Typography
              sx={{
                pb: "20px",
                color: "#000",
                fontFamily: "ABeeZee",
                fontSize: "15px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
              }}
            >
              OR
            </Typography>
          )}
          {!isEdit && (
            <Box sx={{ pb: "5px" }}>
              <MuiButton
                bg="#4D4D4D"
                style={{
                  fontFamily: "ABeeZee",
                  fontWeight: 400,
                  fontSize: "15px",
                  lineHeight: "17.73px",
                  padding: "10px",
                  width: "140px",
                  borderRadius: "4px",
                }}
                onClick={handleAddCohortsTab}
              >
                Create Cohort
              </MuiButton>
            </Box>
          )}
        </Box>
        <Box>
          {/* <Label label="Added Cohort" /> */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
            }}
          >
            {data?.linkToCohort &&
              data?.linkToCohort?.map((e, i) => (
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      backgroundColor: "#E3E7EF",
                      padding: " 7px 16px",
                      height: "30px",
                      borderRadius: "8px",
                      margin: "8px 0",
                      width: "470px",
                    }}
                    key={i}
                  >
                    <Typography>{e.name}</Typography>
                    <Box paddingBottom="5px">
                      {console.log(e, "value")}
                      <MuiRadioGroup
                        options={options}
                        value={e.value}
                        onChange={(value) => handleIncludeExclude(i, value)}
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      height: "40px",
                      width: "40px",
                      borderRadius: "50%",
                      backgroundColor: "#E3E7EF",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() => handleDeleteCohortSelectedOption(i)}
                  >
                    {/* <RiDeleteBinLine style={{ color: "black" }} /> */}
                    <img src={cohortDeleteBtn} alt="" />
                  </Box>
                </Box>
              ))}
          </Box>
        </Box>
      </Box>
      <Box style={{ position: "absolute", bottom: 30, right: 40,display: pathname !=="/create-offers" || isPreviewShow?"none":"block" }}>
        <Button
          color="inherit"
          sx={{ mr: 1 }}
          style={{
            backgroundColor: "white",
            color: "black",
            padding: "9px 10px",
            height: "40px",
            fontFamily: "Mulish",
            fontWeight: 700,
            fontSize: "16px",
          }}
          variant="outlined"
          size="small"
          onClick={handleAddProjectionsTab}
        >
          View Projection{" "}
          <span style={{ marginLeft: "12px", marginTop: "5px" }}>
            <img src={highGraph} alt="" />
          </span>
        </Button>
      </Box>
    </Box>
  );
};

export default SetOfferRules;
