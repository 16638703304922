// ReusableTimePicker.js
import React from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { TimePicker } from "@mui/x-date-pickers";

const ReusableTimePicker = ({
  label,
  ampm = false,
  name,
  disabled = false,
  value,
  onChange = () => {},
  sx = {},
  setState,
}) => {
  return (
    <TimePicker
      sx={{ marginTop: "18px", ...sx }}
      ampm={ampm}
      disabled={disabled}
      label={label}
      value={dayjs(value)}
      onChange={(newValue) => onChange(name, newValue.toISOString(), setState)}
    />
  );
};

ReusableTimePicker.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  sx: PropTypes.object, // Optional styling prop
};

export default ReusableTimePicker;
