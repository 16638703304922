
import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";

import ProjectionCard from "./ProjectionCard";


const ProjectionSection = ({
  options = [],
  title = "",
  titleColor,
  sidebackgroundColor,
  columnsInRow,
}) => {
  return (
    <Stack spacing={3}>
      <Typography
        variant="h2"
        sx={{
          fontFamily: "Proxima Nova A",
          fontSize: "20px",
          fontWeight: 600,
          lineHeight: "24px",
          letterSpacing: "0em",
          textAlign: "left",
        }}
      >
        {title}
      </Typography>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: `repeat(${columnsInRow}, 1fr)`,
          gap: "40px", // Adjust the gap between items as needed
          gridColumnGap: "16px",
          gridRowGap: "25px",
        }}
      >
        {options.map((analyticsItem, index) => (
          <ProjectionCard
            value={analyticsItem[1]}
            key={index}
            title={analyticsItem[0]}
            titleColor={titleColor}
            sidebackgroundColor={sidebackgroundColor}
          />
        ))}
      </Box>
    </Stack>
  );
};

export default ProjectionSection;
