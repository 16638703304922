import { Box, Button, Switch, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import MuiSelectAndSearch from "../../../UIComponents/MuiSelectAndSearch";
import MuiMultiSelectWithSearch from "../../../UIComponents/MuiMultiSelectWithSearch";
import BoldLabel from "../../../UIComponents/BoldLevel";
import { useDispatch, useSelector } from "react-redux";
import {
  getCohortsSetFunction,
  getRuleSetFunction,
} from "../../../Redux/OfferReducer/action";
import { FaPlus } from "react-icons/fa6";
import { setQualifyingCriteriaDataChanges } from "../../../Redux/OfferReducer/actionType";
import cohortDeleteBtn from "../../../assets/redDeleteBtn.png";
import { useNavigate } from "react-router-dom";
import editLogo from "../../../assets/Pen.png";

export default function SetQualifyingCriteria({ showEdit }) {
  // const [step, setStep] = useState(1);
  const data = useSelector(
    (store) => store.OfferReducer?.offerData?.setQualifyingCriteria
  );
  const step = useSelector(
    (store) => store.OfferReducer?.offerData?.setQualifyingCriteria
  )?.step;
  const ruleData = useSelector((store) => store.OfferReducer.ruleOption);
  const ruleOption = ruleData?.map((element) => {
    return { name: element?.name, id: element?.id };
  });

  const cohortsData = useSelector((store) => store.OfferReducer.cohortsOption);
  const cohortsOption = cohortsData?.map((element) => {
    return { name: element?.name, id: element?.id, value: "INCLUDE" };
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleData = (key, value) => {
    dispatch({
      type: setQualifyingCriteriaDataChanges,
      payload: { [key]: value },
    });
  };
  const handleCohortValueChange = (cohortId, newValue) => {
    const updatedLinkToCohort = data?.linkToCohort.map((cohort) =>
      cohort.id === cohortId
        ? { ...cohort, value: newValue ? "INCLUDE" : "EXCLUDE" }
        : cohort
    );
    handleData("linkToCohort", updatedLinkToCohort);
  };
  const handleCohortDelete = (cohortId) => {
    const updatedLinkToCohort = data?.linkToCohort.filter(
      (cohort) => cohort.id !== cohortId
    );
    handleData("linkToCohort", updatedLinkToCohort);
  };
  console.log(data, "setQualifyingCriteriaDataChanges");
  useEffect(() => {
    dispatch(getRuleSetFunction());
    dispatch(getCohortsSetFunction());
  }, []);
  return (
    <Box>
      <Box sx={{ display: showEdit ? "block" : "none" }}>
        <Box
          sx={{
            display: showEdit ? "flex" : "none",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid #E4E4F0",
            background: "#FFFFFF",
            // my: "16px",
            mx: "20px",
            py: "8px",
            px: "20px",
            borderRadius: "8px 8px 0px 0px",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Lato",
              fontSize: "16px",
              fontWeight: "700",
              lineHeight: "20px",
              color: "#0B1B32",
            }}
          >
            Set Qualifying Criteria{" "}
            <span style={{ color: "#858D98" }}>({step}/2)</span>
          </Typography>
          <Button
            sx={{
              display: "flex",
              gap: "6px",
              // mr: "10px",
              px: "10px",
            }}
            onClick={() => {
              navigate("/offers/create-offers/set-qualifying-criteria");
            }}
          >
            <img
              style={{ height: "16px", height: "16px" }}
              src={editLogo}
              alt=""
            />
            <Typography
              sx={{
                fontFamily: "Lato",
                fontSize: "14px",
                fontWeight: "600",
                lineHeight: "16.8px",
                color: "#003D86",
              }}
            >
              Edit
            </Typography>
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            borderBottom: "1px solid #E4E4F0",
            background: "#FFFFFF",
            // my: "16px",
            mx: "20px",
            // py: "8px",
            px: "20px",
          }}
        >
          <Button
            sx={{
              borderBottom:
                step === 1 ? "2px solid #0C51A1" : "2px solid transparent",
              padding: "10px",
              borderRadius: "0px",
              fontFamily: "Lato",
              fontSize: "14px",
              fontWeight: "700",
              lineHeight: "16.8px",
              color: step === 1 ? "#003D86" : "#858D98",
              textTransform: "capitalize",
            }}
            onClick={() => handleData("step", 1)}
          >
            Rule
          </Button>
          <Button
            sx={{
              borderBottom:
                step === 2 ? "2px solid #0C51A1" : "2px solid transparent",
              padding: "10px",
              borderRadius: "0px",
              fontFamily: "Lato",
              fontSize: "14px",
              fontWeight: "700",
              lineHeight: "16.8px",
              color: step === 2 ? "#003D86" : "#858D98",
              textTransform: "capitalize",
            }}
            onClick={() => handleData("step", 2)}
          >
            Cohort
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          margin: showEdit ? "0px 20px 20px 20px" : "20px",
          padding: "20px",
          background: "#FFFFFF",
          borderRadius: showEdit ? "0px 0px 8px 8px" : "8px",
          height: "78vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box>
          {step === 1 ? (
            <Box>
              <Typography
                sx={{
                  textAlign: "left",
                  color: "#0B1B32",
                  fontFamily: "Lato",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "20px",
                  mb:showEdit?"0px": "26px",
                  display: showEdit ? "none" : "block",
                }}
              >
                Set Qualifying Criteria{" "}
                <span style={{ color: "#858D98" }}>({step}/2)</span>
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: 5,
                  alignItems: "end",
                  flexWrap: "wrap",
                  mt: "-20px",
                }}
              >
                <Box sx={{ width: "510px" ,display: showEdit ? "none" : "block"}}>
                  <BoldLabel label="Link To Rule" />
                  <MuiSelectAndSearch
                    value={data?.linkToRule}
                    onChange={(value) => handleData("linkToRule", value)}
                    options={ruleOption}
                    placeholder="Link To Rule"
                    isDisable={showEdit}
                    //   errorMessage={errorMessage?.linkToRule}
                    editBoolean={data?.previous_linkToRule?.id ?? false}
                  />
                </Box>

                <Box sx={{display: showEdit ? "block" : "none"}}>
                  <BoldLabel label="Linked Rule" />
                  <Typography
                    sx={{
                      height: "26px",
                      width: "767px",
                      backgroundColor: "#F9F9FC",
                      fontFamily: "Lato",
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "16.8px",
                      textAlign: "left",
                      display: showEdit ? "flex" : "none",
                      alignItems: "center",
                      borderRadius: "8px",
                      mt:"8px",
                      padding:"3px 16px",
                      boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                    }}
                  >
                    {data?.linkToRule?.name}
                  </Typography>
                </Box>

                <Typography
                  sx={{
                    pb: "10px",
                    color: "#000",
                    fontFamily: "Lato",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "16px",
                    mx: "100px",
                    display: showEdit ? "none" : "block",
                  }}
                >
                  OR
                </Typography>

                <Box sx={{ pb: "5px", display: showEdit ? "none" : "block" }}>
                  <Button
                    sx={{
                      width: "177px",
                      height: "32px",
                      backgroundColor: "#003D86",
                      borderRadius: "6px",
                      color: "#FFFFFF",
                      "&:hover": {
                        backgroundColor: "#003D86",
                      },
                    }}
                    onClick={() => {
                      navigate(
                        "/offers/create-offers/set-qualifying-criteria/create-new-rule",
                        { state: { id: "offer" } }
                      );
                      
                    }}
                  >
                    <FaPlus />
                    <Typography
                      sx={{
                        fontFamily: "Lato",
                        fontSize: "14px",
                        lineHeight: "16.8px",
                        fontWeight: 500,
                        marginLeft: "6px",
                        textTransform: "capitalize",
                      }}
                    >
                      Create New Rule
                    </Typography>
                  </Button>
                </Box>
              </Box>
              <Typography
                sx={{
                  fontFamily: "Lato",
                  fontSize: "10px",
                  lineHeight: "16px",
                  fontWeight: 400,
                  color: "#858D98",
                  textAlign: "left",
                  display: showEdit ? "none" : "block",
                }}
              >
                Link to Rule will allow users to qualify for the game
              </Typography>
            </Box>
          ) : (
            <Box>
              <Typography
                sx={{
                  textAlign: "left",
                  color: "#0B1B32",
                  fontFamily: "Lato",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "20px",
                  mb: "26px",
                  display: showEdit ? "none" : "block",
                }}
              >
                Set Qualifying Criteria{" "}
                <span style={{ color: "#858D98" }}>({step}/2)</span>
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: 5,
                  alignItems: "end",
                  flexWrap: "wrap",
                }}
              >
                <Box
                  sx={{ width: "510px", display: showEdit ? "none" : "block" }}
                >
                  <BoldLabel label="Link To Cohort" />
                  <MuiMultiSelectWithSearch
                    value={data?.linkToCohort ?? []}
                    onChange={(value) => handleData("linkToCohort", value)}
                    options={cohortsOption}
                    placeholder="Link To Cohort"
                    isDisable={showEdit}
                    //   errorMessage={errorMessage?.linkToCohort}
                    editBoolean={
                      data?.previous_linkToCohort?.length > 0 ?? false
                    }
                  />
                </Box>

                <Typography
                  sx={{
                    pb: "20px",
                    color: "#000",
                    fontFamily: "Lato",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "16px",
                    mx: "100px",
                    display: showEdit ? "none" : "block",
                  }}
                >
                  OR
                </Typography>

                <Box sx={{ pb: "5px", display: showEdit ? "none" : "block" }}>
                  <Button
                    sx={{
                      width: "177px",
                      height: "32px",
                      backgroundColor: "#003D86",
                      borderRadius: "6px",
                      color: "#FFFFFF",
                      // display: showEdit ? "none" : "block",
                      "&:hover": {
                        backgroundColor: "#003D86",
                      },
                    }}
                    onClick={() => {
                      navigate(
                        "/offers/create-offers/set-qualifying-criteria/create-new-cohort"
                      );
                    }}
                  >
                    <FaPlus />
                    <Typography
                      sx={{
                        fontFamily: "Lato",
                        fontSize: "14px",
                        lineHeight: "16.8px",
                        fontWeight: 500,
                        marginLeft: "6px",
                        textTransform: "capitalize",
                        // display: showEdit ? "none" : "block",
                      }}
                    >
                      Create New Cohort
                    </Typography>
                  </Button>
                </Box>
              </Box>
              <Typography
                sx={{
                  fontFamily: "Lato",
                  fontSize: "10px",
                  lineHeight: "16px",
                  fontWeight: 400,
                  color: "#858D98",
                  textAlign: "left",
                  display: showEdit ? "none" : "block",
                }}
              >
                Link to Cohort will allow users to qualify for the game
              </Typography>
              <Box
                mt={showEdit ? "-20px" : "26px"}
                sx={{
                  display: data?.linkToCohort?.length > 0 ? "block" : "none",
                }}
              >
                {/* <Label label="Added Cohort" /> */}
                <BoldLabel label="Linked Cohorts" />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    // mt:"8px",
                  }}
                >
                  {data?.linkToCohort &&
                    data?.linkToCohort?.map((e, i) => (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            backgroundColor: "#F9F9FC",
                            padding:showEdit? "0px 16px" :" 3px 16px",
                            height:showEdit?"29px" : "30px",
                            borderRadius: "8px",
                            margin: "8px 0",
                            width: showEdit ? "767px" : "955px",
                            boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                          }}
                          key={i}
                        >
                          <span
                            style={{
                              display: "flex",
                              gap: "10px",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                fontFamily: "Lato",
                                fontWeight: 400,
                                fontSize: "13px",
                                lineHeight: "15.6px",
                                color: "#000000",
                              }}
                            >
                              {i + 1} .
                            </Typography>
                            <Typography
                              sx={{
                                fontFamily: "Lato",
                                fontWeight: 400,
                                fontSize: "14px",
                                lineHeight: "16.8px",
                                color: "#000000",
                              }}
                            >
                              {e.name}
                            </Typography>
                          </span>
                          <Box
                            paddingBottom="5px"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontFamily: "Lato",
                                fontWeight: 400,
                                fontSize: "12px",
                                lineHeight: "14.4px",
                                color: "#000000",
                                display: showEdit ? "none" : "block",
                              }}
                            >
                              Exclude
                            </Typography>
                            <Switch
                              sx={{
                                "& .MuiSwitch-switchBase.Mui-checked": {
                                  color: "#ffffff",
                                },
                                "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                                  {
                                    backgroundColor: "#29CC6A",
                                  },
                                display: showEdit ? "none" : "",
                              }}
                              checked={e?.value === "INCLUDE"}
                              onChange={(event) =>
                                handleCohortValueChange(
                                  e.id,
                                  event.target.checked
                                )
                              }
                            />
                            <Typography
                              sx={{
                                fontFamily: "Lato",
                                fontWeight: 400,
                                fontSize: "12px",
                                lineHeight: "14.4px",
                                color: "#000000",
                                display: showEdit ? "none" : "block",
                              }}
                            >
                              Include
                            </Typography>

                            <Button
                              sx={{
                                display: showEdit ? "none" : "",
                              }}
                              onClick={() => handleCohortDelete(e.id)}
                            >
                              <img src={cohortDeleteBtn} alt="" />
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    ))}
                </Box>
              </Box>
            </Box>
          )}
        </Box>

        <Box
          sx={{
            display: showEdit ? "none" : "flex",
            justifyContent: "flex-start",
            gap: "16px",
            marginTop: "41px",
            float: "bottom",
          }}
        >
          <Button
            sx={{
              width: "136px",
              height: "30px",
              backgroundColor: "#E8F0F9",
              padding: "7px 12px",
              borderRadius: "6px",
              "&:hover": {
                backgroundColor: "#E8F0F9",
              },
            }}
          >
            <Typography
              sx={{
                fontFamily: "Lato",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "16.8px",
                color: "#003D86",
              }}
              onClick={() => {
                if (step === 1) {
                  navigate("/offers/create-offers/offer-details");
                } else {
                  // setStep(1);
                  handleData("step", 1);
                }
              }}
            >
              Previous
            </Typography>
          </Button>
          <Button
            sx={{
              width: "136px",
              height: "30px",
              backgroundColor: "#003D86",
              padding: "7px 12px",
              borderRadius: "6px",
              "&:hover": {
                backgroundColor: "#003D86",
              },
            }}
            onClick={() => {
              if (step === 1) {
                // setStep(2);
                handleData("step", 2);
              } else {
                navigate("/offers/create-offers/create-game");
              }
            }}
          >
            <Typography
              sx={{
                fontFamily: "Lato",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "16.8px",
                color: "#FFFFFF",
              }}
            >
              Save & Next
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
