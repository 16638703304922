import React from "react";
import { Button } from "@mui/material";
import { BootstrapTooltip } from "../utils/tooltip";

const MuiButton = ({
  variant = "contained",
  style = { padding: "8px 30px" },
  bg = "black",
  color = "white",
  icon = "",
  isDisable = false,
  onClick,
  children,
  TooltipMessage
}) => {
  return (
    <BootstrapTooltip title={TooltipMessage&&isDisable?TooltipMessage:""} placement="left-end">
  <span>
    <Button
      disabled={isDisable}
      style={{
        backgroundColor: !isDisable ? bg : "gray",
        color: color,
        textTransform: "none",
        ...style,
      }}
      variant={variant}
      startIcon={icon && <img src={icon} alt="icon" />}
      size="small"
      onClick={onClick}
    >
      {children}
    </Button>
  </span>
</BootstrapTooltip>
  );
};

export default MuiButton;
