import { Box, Button, Modal, Typography } from "@mui/material";
import React, { useEffect } from "react";
import cancelImg from "../../assets/projectionCancel.svg";
import MuiSelectAndSearch from "../../UIComponents/MuiSelectAndSearch";
import crossImg from "../../assets/closeBtnlogo.png";
import { MUIDateTimePicker } from "../../UIComponents/MUIDateTimePicker";
import CustomDatePicker from "../CustomDatePicker";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { ToastContainer, toast } from "react-toastify";
import ProjectionLoader from "./ProjectionLoader";
import { useSelector } from "react-redux";

const option = [
  {
    name: "Last 7 days",
    id: "Last 7 days",
  },
  {
    name: "Last 30 days",
    id: "Last 30 days",
  },
  {
    name: "Last Month",
    id: "Last Month",
  },
  {
    name: "Custom",
    value: "custom",
  },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "475px",
  bgcolor: "background.paper",
  boxShadow: 24,
  // p: 4,
  borderRadius: "20px",
};

export default function ProjectionList({
  handleShowViewProjection,
  activeTab,
  tabData,
  setTabData,
  handleCreateProjection,

}) {

const loading = useSelector(store=>store.OfferReducer.projectionLoader)
  const handleData = (name, value) => {
    setTabData((prevTabData) => {
      return prevTabData.map((tab, index) => {
        if (index === activeTab) {
          return {
            ...tab,
            [name]: value,
          };
        }
        return tab;
      });
    });
  };
  

  const handleRunClick = () => {
    const activeTabData = tabData[activeTab];
    if (!activeTabData.day) {
      toast.error("Please select the period");
    } else if (
      activeTabData.day?.value === "custom" &&
      (!activeTabData.startDate || !activeTabData.endDate)
    ) {
      toast.error("Please select both start and end dates");
    } else {
      handleCreateProjection();
      // handleData("modalStatus", false);
    }
  };

  useEffect(()=>{
    if(!loading){
      handleData("modalStatus", false);
    }

  },[loading])

  console.log(loading,"loading")
  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <ToastContainer/>
        <Typography
          sx={{
            fontFamily: "Mulish",
            fontWeight: 700,
            fontSize: "18px",
            lineHeight: "23.4px",
            color: "#0F1828",
          }}
        >
          Projection List
        </Typography>

        <Button
          sx={{
            fontFamily: "ABeeZee",
            fontWeight: 400,
            fontSize: "15px",
            lineHeight: "17.73px",
            padding: "6px 15px",
            backgroundColor: "#26292D",
            borderRadius: "4px",
            height: "40px",
            color: "#FFF",
            "&:hover": {
              backgroundColor: "#26292D",
            },
          }}
          onClick={() => {
            handleData("cancelModel", false);
            handleData("createModal", true);
            handleData("modalStatus", true);
          }}
        >
          Create New Projection
        </Button>
      </Box>

      <Box>
        <Button
          onClick={() => {
            handleData("createModal", false);
            handleData("cancelModel", true);
            handleData("modalStatus", true);
          }}
        >
          Cancel Projection
        </Button>
      </Box>

      <Modal
        open={tabData[activeTab]?.modalStatus}
        // onClick={() => handleData("modalStatus", false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {/* cancel Projection */}
          <Box
            sx={{
              display: tabData[activeTab].cancelModel ? "block" : "none",
              paddingTop: "24px",
            }}
          >
            <img
              style={{
                height: "75.17px",
                width: "75.17px",
                display: "block",
                margin: "auto",
              }}
              src={cancelImg}
              alt=""
            />
            <Typography
              sx={{
                fontFamily: "Mulish",
                fontWeight: 700,
                fontSize: "24px",
                lineHeight: "30.12px",
                marginTop: "22px",
                marginBottom: "6px",
                textAlign: "center",
                color: "#000000",
              }}
            >
              Cancel Projection
            </Typography>

            <Typography
              sx={{
                fontFamily: "Mulish",
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "22px",
                textAlign: "center",
                color: "#384151",
                marginBottom: "14px",
              }}
            >
              Are you sure want to cancel this projection?
            </Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                margin: "24px",
              }}
            >
              <Button
                sx={{
                  height: "42px",
                  padding: "9px 30px",
                  color: "#26292D",
                  fontFamily: "Mulish",
                  fontWeight: 700,
                  fontSize: "16px",
                  lineHeight: "21px",
                  borderRadius: "7px",
                  border: "1px solid #26292D",
                  width: "205px",
                }}
                onClick={() => {
                  // handleData("createModal", false);
                  // handleData("cancelModel", true);
                  handleData("modalStatus", false);
                }}
              >
                Close
              </Button>

              <Button
                sx={{
                  height: "42px",
                  // padding:"9px 30px",
                  color: "#FFFFFFFC",
                  fontFamily: "Mulish",
                  fontWeight: 700,
                  fontSize: "16px",
                  lineHeight: "21px",
                  borderRadius: "7px",
                  border: "1px solid #26292D",
                  backgroundColor: "#26292D",
                  width: "205px",
                  "&:hover": {
                    backgroundColor: "#26292D",
                  },
                }}
              >
                Cancel Projection
              </Button>
            </Box>
          </Box>

          {/* create Projection Modal*/}
      {  loading? <Box > <ProjectionLoader/> </Box> :<Box
            padding="24px"
            sx={{
              display: tabData[activeTab].createModal ? "block" : "none",
              position: "relative",
            }}
          >
            <img
              style={{
                position: "absolute",
                right: "20px",
                top: "16px",
                cursor: "pointer",
              }}
              onClick={() => handleData("modalStatus", false)}
              src={crossImg}
              alt=""
            />
            <Typography
              sx={{
                fontFamily: "Mulish",
                fontWeight: 700,
                fontSize: "24px",
                lineHeight: "30.12px",
                color: "#000000",
              }}
            >
              Create Projection
            </Typography>

            <Typography
              sx={{
                marginTop: "6px",
                fontFamily: "Mulish",
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "22px",
                color: "#384151",
              }}
            >
              To start a new projection select the date
            </Typography>

            <Typography
              sx={{
                fontFamily: "Mulish",
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "17.57px",
                color: "#6F747E",
                marginTop: "20px",
                marginBottom: "10px",
              }}
            >
              Period
            </Typography>
            <Box width="427px">
              <MuiSelectAndSearch
                options={option}
                value={tabData[activeTab]?.day}
                onChange={(e) => handleData("day", e)}
              />
            </Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box
                sx={{
                  display: tabData[activeTab]?.day?.value==="custom"?"flex":"none",
                  justifyContent: "space-between",
                  gap: "20px",
                  marginTop: "12px",
                }}
              >
                <CustomDatePicker
                  name={"startDate"}
                  value={
                    tabData[activeTab]?.startDate
                      ? dayjs(new Date(tabData[activeTab]?.startDate))
                      : null
                  }
                  onChange={(name, value) => handleData(name, value)}
                  placeholder="From Date"
                  label={"From Date"}
                  // disablePast={true}
                  maxDate={tabData[activeTab].endDate ? dayjs(new Date(tabData[activeTab].endDate)) : null}
                />
                <CustomDatePicker
                  value={
                    tabData[activeTab]?.endDate
                      ? dayjs(new Date(tabData[activeTab]?.endDate))
                      : null
                  }
                  name={"endDate"}
                  label={"From Date"}
                  // value={tabData[activeTab]?.endDate}
                  onChange={(name, value) => handleData(name, value)}
                  placeholder="From Date"
                  // disablePast={true}
                  minDate={tabData[activeTab].startDate ? dayjs(new Date(tabData[activeTab].startDate)) : null}
                />
              </Box>
            </LocalizationProvider>

            <Box sx={{ marginTop: "40px" }}>
              <Button
                sx={{
                  width: "100%",
                  backgroundColor: "#26292D",
                  color: "#fff",
                  fontFamily: "Mulish",
                  fontWeight: 700,
                  fontSize: "16px",
                  lineHeight: "20.08px",
                  padding: "9px 30px",
                  "&:hover": {
                    backgroundColor: "#26292D",
                  },
                }}
                onClick={handleRunClick}
              >
                Run
              </Button>
            </Box>
          </Box>}



          
        </Box>
      </Modal>
    </Box>
  );
}
