import React, { useState } from "react";
import scratchCardImg from "../../assets/scratche.png";
import wheelImg from "../../assets/wheel.png";
import slotMachineImg from "../../assets/machine.png";
import triviaImg from "../../assets/trivia.png";
import { Box, Button, Typography } from "@mui/material";
import Games from "../../UIComponents/CreateGameV2/Games";
import BackButtonImg from "../../assets/backArrow.png";
import ScratchCardV2 from "../../UIComponents/CreateGameV2/ScratchCardV2";
import SlotMachine from "../../UIComponents/CreateGameV2/SlotMachine";
import SpinTheWheel from "../../UIComponents/CreateGameV2/SpinTheWheel";
import Trivia from "../../UIComponents/CreateGameV2/Trivia";

const gameData = [
  { name: "Scratch Card", img: scratchCardImg, path: "scratch_card" },
  { name: "Spin The Wheel", img: wheelImg, path: "spin_wheel" },
  { name: "Slot Machine", img: slotMachineImg, path: "slot_machine" },
  { name: "Trivia", img: triviaImg, path: "trivia" },
];

export default function CreateGameV2({
    data,
    onChange,
    errorMessage,
    handleNext,
    handleBack,
    activeStep
}) {
  const [gameType, setGameType] = useState("");
  console.log(gameType);

//   const handleData = (name,value,index)=>{
//     console.log(name,value,index);
//     onChange({...data, game:[...game,game[index].name=value]});
//   }
  return (
    <>
      {/* {gameType && (
        <Box sx={{ display: "flex", justifyContent: "start" }}>
          <Button onClick={() => setGameType("")}>
            <img src={BackButtonImg} alt="" />
            <Typography
              sx={{
                fontFamily: "Mulish",
                fontWeight: 700,
                fontSize: "20px",
                lineHeight: "26px",
                color: "#0F1828",
                ml: "12px",
              }}
            >
              {gameType}
            </Typography>
          </Button>
        </Box>
      )} */}
      {gameType === "Scratch Card" ? (
        <Box>
          <ScratchCardV2 data={data} onChange={onChange} errorMessage={errorMessage} handleNext={handleNext} handleBack={handleBack} setGameType={setGameType} gameType={gameType}/>
        </Box>
      ) : gameType === "Spin The Wheel" ? (
        <Box>
          <SpinTheWheel data={data} onChange={onChange} errorMessage={errorMessage} handleNext={handleNext} handleBack={handleBack} setGameType={setGameType} gameType={gameType}/>
        </Box>
      ) : gameType === "Slot Machine" ? (
        <Box>
          <SlotMachine data={data} onChange={onChange} errorMessage={errorMessage} handleNext={handleNext} handleBack={handleBack} setGameType={setGameType} gameType={gameType} />
        </Box>
      ) : gameType === "Trivia" ? (
        <Box>
          <Trivia data={data} onChange={onChange} errorMessage={errorMessage} handleNext={handleNext} handleBack={handleBack} setGameType={setGameType} gameType={gameType} />
        </Box>
      ) : (
        <Games activeStep={activeStep} gameData={gameData} setGameType={setGameType} data={data} onChange={onChange} handleNext={handleNext} handleBack={handleBack}/>
      )}
    </>
  );
}
