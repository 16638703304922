import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import { Box, Button, Drawer, Typography } from "@mui/material";
import crossIcon from ".././assets/cross.png";
import MuiRadioGroup from "./MuiRadioGroup";
import MuiSelect from "./MuiSelect";
import MuiInput from "./MuiInput";
import MuiTextarea from "./MuiTextarea";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { getWhereSectionSelectOne } from "../Redux/CreateRuleReducer/action";
import { useLocation } from "react-router-dom";
import CustomSelectProps from "./ReactSelectWithSearch";

const RadioOption = [
  { label: "ACTIVE", value: "ACTIVE" },
  { label: "INACTIVE", value: "INACTIVE" },
];
const option = [
  { label: "NUMBER", value: "NUMBER" },
  { label: "STRING", value: "STRING" },
];
const optionWithArray = [
  { label: "NUMBER", value: "NUMBER" },
  { label: "STRING", value: "STRING" },
  { label: "ARRAY", value: "ARRAY"},
];

const initialDrawerValue = {
  status: "",
  type: "",
  name: "",
  // code: "",
  description: "",
};

export default function MuiSelectWithSearch({
  value,
  onChange,
  label,
  options,
  errorMessage = "",
  attribute_entity,
  activeTab
}) {
  const [searchTerm, setSearchTerm] = React.useState("");
  const [filteredOptions, setFilteredOptions] = React.useState(options);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  //   to store data of drawer
  const [newOption, setNewOption] = React.useState(initialDrawerValue);
  const [isOpen, setIsOpen] = React.useState(false);
  const [drawerValidationErrors, setDrawerValidationErrors] = React.useState({
    status: "",
    type: "",
    name: "",
    // code: "",
    description: "",
  });
  const searchInputRef = React.useRef(null);
  const BaseUrl = process.env.REACT_APP_API_BASE_URL;
  const token = useSelector((store) => store.AuthReducer.token);
  const productIds = useSelector((store) => store.CreateRuleReducer.productId);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const handleChange = (event) => {
    onChange(event);
  };

  //   to search in option inside select
  const handleSearchChange = (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);
    const filtered = options.filter((option) =>
      option.label.toLowerCase().startsWith(term)
    );
    setFilteredOptions(filtered);
  };

  //   to stop select value of search bar in select tag
  const handleSearchClick = (event) => {
    event.stopPropagation();
    searchInputRef.current.focus();
  };

  //   to open drawer
  const handleDrawerOpen = (event) => {
    event.stopPropagation();
    setIsOpen(false);
    setDrawerOpen(true);
  };

  //   to close drawer
  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setNewOption(initialDrawerValue);
  };

  // to handle onChange inside drawer
  const handleInputChange = (key, value) => {
    setNewOption((prev) => ({ ...prev, [key]: value }));
  };

  //   to validate drawer
  const validateInputs = () => {
    const errors = {};
    if (!newOption.status) {
      errors.status = "Status is required";
    }
    if (!newOption.type) {
      errors.type = "Type is required";
    }
    if (!newOption.name) {
      errors.name = "Name is required";
    }
    // if (!newOption.code) {
    //   errors.code = "Code is required";
    // }
    if (!newOption.description) {
      errors.description = "Description is required";
    }
    setDrawerValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  //   to save data of drawer
  const handleSave = () => {
    const isValid = validateInputs();
    if (isValid) {
      // These code are commented bcz if we need to add drawer data value in select tag option we can do with the help of this
      //   let option = [...options, newOption];
      //   setFilteredOptions(option);
      //   console.log(option);

      let submitData = {
        attributeName: newOption?.name,
        description: newOption?.description,
        status: newOption?.status,
        attributeValueType: newOption?.type,
        entity:attribute_entity
      };
      if (pathname === "/create-offers") {
        submitData.productId = productIds;
      }
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      axios
        .post(`${BaseUrl}/api/rule-attributes`, submitData, config)
        .then((res) => {
          console.log(res.data, "attributes");
          toast.success("Rule Attribute Added Successfully");
          setNewOption(initialDrawerValue);
          dispatch(getWhereSectionSelectOne(productIds, pathname,attribute_entity));
          handleDrawerClose();
        })
        .catch((error) => {
          console.log(error);
          toast.error(error?.response?.data?.message);
        });

      console.log(submitData);
    }
  };

  React.useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  React.useEffect(()=>{
    dispatch(getWhereSectionSelectOne(productIds, pathname,attribute_entity));
  },[activeTab])
  return (
    <FormControl
      sx={{ mt: "10px", width: "100%" }}
      size="small"
      error={!!errorMessage}
    >
      {/* <ToastContainer /> */}
      <InputLabel>{label}</InputLabel>
      {/* {console.log(value)} */}

      {/* ---- react select -----*/}
      <CustomSelectProps
        value={value ?? ""}
        options={options}
        handleClick={(event) => handleDrawerOpen(event)}
        onChange={handleChange}
      />

      {/* ---- normal select -----*/}
      {/* <Select
        value={value ?? ""}
        label={label ?? ""}
        onChange={handleChange}
        sx={{ textAlign: "left" }}
        displayEmpty
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onOpen={() => setIsOpen(true)}
        renderValue={
          value !== ""
            ? undefined
            : () => (
                <em
                  style={{
                    color: "gray",
                    fontFamily: "Mulish",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "130%",
                  }}
                >
                  --Select--
                </em>
              )
        }
      >
        <MenuItem onClick={handleSearchClick}>
          <TextField
            onChange={handleSearchChange}
            placeholder="Search"
            size="small"
            fullWidth
            sx={{ mt: "10px" }}
            inputRef={searchInputRef}
            onClick={(event) => event.stopPropagation()}
          />
          <Button
            sx={{ mr: 1 }}
            style={{
              backgroundColor: "black",
              color: "white",
              padding: "8px 30px",
              marginLeft: "15px",
              marginTop: "9px",
            }}
            variant="contained"
            size="small"
            onClick={(event) => handleDrawerOpen(event)}
          >
            Add
          </Button>
        </MenuItem>
        {filteredOptions?.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select> */}
      <FormHelperText>{errorMessage}</FormHelperText>
      <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose}>
        <Box width="413px" padding="25px">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              fontSize="20px"
              fontWeight={500}
              fontFamily="Inter"
              lineHeight="24.2px"
            >
              Add New Attribute
            </Typography>
            <Button onClick={handleDrawerClose}>
              <img src={crossIcon} alt="close button" />
            </Button>
          </Box>
          <hr />
          <Box marginTop="18px">
            <Typography
              fontFamily="Mulish"
              fontSize="14px"
              lineHeight="17.57px"
              fontWeight={600}
            >
              Status
            </Typography>
            <MuiRadioGroup
              options={RadioOption}
              value={newOption.status}
              onChange={(value) => handleInputChange("status", value)}
            />
            {drawerValidationErrors.status && (
              <FormHelperText error>
                {drawerValidationErrors.status}
              </FormHelperText>
            )}
          </Box>
          <Box marginTop="18px">
            <Typography
              fontFamily="Mulish"
              fontSize="14px"
              lineHeight="17.57px"
              fontWeight={600}
            >
              Type
            </Typography>
            <MuiSelect
              options={attribute_entity==="customer"? option :optionWithArray}
              value={newOption.type}
              onChange={(value) => handleInputChange("type", value)}
            />
            {drawerValidationErrors.type && (
              <FormHelperText error>
                {drawerValidationErrors.type}
              </FormHelperText>
            )}
          </Box>
          <Box marginTop="18px">
            <Typography
              fontFamily="Mulish"
              fontSize="14px"
              lineHeight="17.57px"
              fontWeight={600}
            >
              Name
            </Typography>
            <MuiInput
              placeholder="Add Name"
              value={newOption.name}
              onChange={(event) => handleInputChange("name", event)}
            />
            {drawerValidationErrors.name && (
              <FormHelperText error>
                {drawerValidationErrors.name}
              </FormHelperText>
            )}
          </Box>
          {/* <Box marginTop="18px">
            <Typography
              fontFamily="Mulish"
              fontSize="14px"
              lineHeight="17.57px"
              fontWeight={600}
            >
              Code
            </Typography>
            <MuiInput
              placeholder="Add Code"
              value={newOption.code}
              onChange={(event) => handleInputChange("code", event)}
            />
            {drawerValidationErrors.code && (
              <FormHelperText error>
                {drawerValidationErrors.code}
              </FormHelperText>
            )}
          </Box> */}
          <Box marginTop="18px">
            <Typography
              fontFamily="Mulish"
              fontSize="14px"
              lineHeight="17.57px"
              fontWeight={600}
            >
              Description
            </Typography>
            <MuiTextarea
              minRows={6}
              placeholder="Add Description"
              value={newOption.description}
              onChange={(event) => handleInputChange("description", event)}
            />
            {drawerValidationErrors.description && (
              <FormHelperText error>
                {drawerValidationErrors.description}
              </FormHelperText>
            )}
          </Box>
          <Box
            marginTop="52px"
            sx={{
              display: "flex",
              flexDirection: "row",
              pt: 2,
              paddingBottom: "37px",
            }}
          >
            <Button
              style={{
                backgroundColor: "black",
                color: "white",
                padding: "8px 30px",
              }}
              variant="contained"
              size="small"
              sx={{ mr: 1 }}
              onClick={handleSave}
            >
              Save
            </Button>
            <Button
              color="inherit"
              sx={{ mr: 1 }}
              style={{
                backgroundColor: "white",
                padding: "8px 30px",
                marginRight: "20px",
              }}
              variant="outlined"
              size="small"
              onClick={handleDrawerClose}
            >
              cancel
            </Button>
          </Box>
        </Box>
      </Drawer>
    </FormControl>
  );
}
