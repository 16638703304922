import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import CustomPieChart from "./charts/CustomPieChart";
import AnalyticsCard from "./AnalyticsCard";
import AnalyticsSection from "./AnalyticsSection";
import CustomAreaChart from "./charts/CustomAreaChart";
import ProductListPieChart from "./charts/ProductListPieChart";

const CustomCard = ({
  value,
  title,
  height,
  titleColor = "#363636",
  sidebackgroundColor = "#9383D1",
}) => {
  // css
  const containerStyle = {
    width: "100%",
    height: height ? height : "100px",
    gap: "10%",
    borderRadius: "12px 12px 12px 12px",
    background: "#fff",
    position: "relative",
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center", // Vertically center the content
  };

  const analyticsCashTextStyle = {
    fontFamily: "Proxima Nova A",
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "32px",
    letterSpacing: "0em",
    textAlign: "left",
  };

  const analyticsTitleTextStyle = {
    fontFamily: "Proxima Nova A",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "21.9px",
    color: titleColor,
    textAlign: "left",
  };

  return (
    <Box sx={containerStyle}>
      <Box
        sx={{
          width: "6px",
          height: "96%",
          background: sidebackgroundColor,
          borderRadius: "12px 0 0  12px",
        }}
      ></Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignContent: "center",
        }}
      >
        <Stack spacing={2}>
          <Typography sx={analyticsCashTextStyle}>{value}</Typography>
          <Typography sx={analyticsTitleTextStyle}>{title}</Typography>
        </Stack>
      </Box>
    </Box>
  );
};

const ProductDetailsAnalytics = ({ data }) => {
  const { piechart, areaChart, scratchCardAnalytics, rewardCardAnalytics } =
    data;

  const cssOptions = {
    "Reward Analytics": {
      titleColor: "#585858",
      columnsInRow: 2,
    },
    "Scratch Card Analytics": {
      titleColor: "#585858",
      sidebackgroundColor: "#D4D71E",
      columnsInRow: 3,
    },
  };

  const commonFlexStyle = {
    display: "flex",
    gap: "40px",
    flexDirection: "row",
  };

  const rightGridContainerStyle = {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "40px",
  };

  return (
    <Box
      sx={{
        paddingBottom: "12px",
      }}
    >
      <Box
        sx={{
          borderBottom: "2px solid #D9D9D9",
          mb: "18px",
        }}
      >
        <Typography
          sx={{
            padding: "10px 0",
            textAlign: "left",
          }}
        >
          {data?.product}
        </Typography>
      </Box>
      <Stack sx={commonFlexStyle}>
        {piechart && (
          <ProductListPieChart
            margin={true}
            key={piechart.legend}
            legend={piechart.legend}
            color={piechart.color}
            series={piechart.series}
            options={piechart.options}
            data={piechart.data}
            pieCenterData={piechart.pieCenterData}
          />
        )}

        <Stack width="50%" spacing={3} height="350px">
          <Typography
            variant="h2"
            sx={{
              fontFamily: "Proxima Nova A",
              fontSize: "20px",
              fontWeight: 600,
              lineHeight: "24px",
              letterSpacing: "0em",
              textAlign: "left",
            }}
          >
            Reward Analytics
          </Typography>
          <Box sx={{ ...rightGridContainerStyle }} height={"clac(100% - 24px)"}>
            {rewardCardAnalytics &&
              rewardCardAnalytics?.options?.map((analyticsItem) => (
                <CustomCard
                  // height={"124px"}
                  value={analyticsItem.value}
                  key={analyticsItem.label}
                  title={analyticsItem.label}
                  // titleColor={titleColor}
                  // sidebackgroundColor={sidebackgroundColor}
                />
              ))}
          </Box>
        </Stack>
      </Stack>

      <Box>
        {areaChart &&
          areaChart?.map((chartData, index) => (
            <CustomAreaChart
              key={index}
              legend={chartData?.legend ?? {}}
              data={chartData?.data}
              strokeColor={chartData?.strokeColor}
              backgroundColor={chartData?.backgroundColor}
            />
          ))}
      </Box>

      <Box mt={"28px"}>
        {data?.scratchCardAnalytics && (
          <AnalyticsSection
            title={scratchCardAnalytics.title}
            options={scratchCardAnalytics?.options ?? []}
            titleColor={cssOptions[scratchCardAnalytics.title]?.titleColor}
            sidebackgroundColor={
              cssOptions[scratchCardAnalytics.title]?.sidebackgroundColor
            }
            columnsInRow={cssOptions[scratchCardAnalytics.title]?.columnsInRow}
          />
        )}
      </Box>
    </Box>
  );
};

export default ProductDetailsAnalytics;
