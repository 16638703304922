import { Box, Typography } from "@mui/material";
import React from "react";
import loaderImg from "../../assets/projection_loader.gif";

export default function ProjectionLoader() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        margin:"40px"
      }}
    >
      <Box
        sx={{
          width: "100%",
          // height: "389px",
          // padding: "40px",
          textAlign: "center",
        }}
      >
        <img src={loaderImg} alt="" />
        <Typography
          sx={{
            fontFamily: "Mulish",
            fontWeight: 700,
            fontSize: "24px",
            lineHeight: "30.12px",
            marginTop: "32px",
            marginBottom: "10px",
          }}
        >
          Analyzing Data
        </Typography>
        <Typography
          sx={{
            fontFamily: "Mulish",
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "24px",
            color:"#384151"
          }}
        >
          Please wait while we gather and analyze your previous campaigns. This
          may take a while.
        </Typography>
      </Box>
    </Box>
  );
}
