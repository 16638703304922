import React, { useRef, memo, useMemo } from "react";
import JoditEditor from "jodit-react";
import { Box } from "@mui/material";

const RichEditor = ({ placeholder = "", onChange, value, errorMessage , editBoolean }) => {
  const editor = useRef(null);

  const config = useMemo(() => {
    return {
      readonly: false, // all options from https://xdsoft.net/jodit/docs/,
      placeholder: placeholder || "",
      height: 300,
      disablePlugins: ["font", "speech-recognize", "add-new-line"],
      editorCssClass: editBoolean ? 'orange-outline' : '',
    };
  }, [placeholder]);

  return useMemo(
    () => (
      <Box sx={{ mt: "10px", width: "100%", textAlign: "start",
                 // Add custom styles for the orange outline
                 '& .jodit-container': {
                  border:errorMessage?"1px solid #d40e10" :editBoolean ? '1px solid orange' : '',
                  // minHeight:"60px",
                  // height:"100px!important",
                  // maxHeight:"900px"
                },
                // '$.jodit-wysiwyg':{
                //   minHeight:"10px",
                //   height:"100px ",
                // }
                // '& .jodit-workplace': {
                //   border: editBoolean ? '1px solid orange' : 'inherit',
                // },
                // Add a CSS class for the orange outline
                // '& .orange-outline': {
                //   outline: editBoolean ? '2px solid orange' : '',
                // },
       }}>
        <JoditEditor
          ref={editor}
          value={value}
          config={config}
          onChange={onChange}
        />
        {errorMessage && (
          <Box
            sx={{
              mt: 1,
              fontSize: "12px",
              color: "#d74848",
              textAlign: "left",
              ml: "15px",
            }}
          >
            {errorMessage}
          </Box>
        )}
      </Box>
    ),
    [config, value, onChange, errorMessage]
  );
};

export default memo(RichEditor);
