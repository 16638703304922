import React, { useState } from "react";
import OfferDetailsPage from "./OfferDetailsPage";
import SetQualifyingCriteria from "./SetQualifyingCriteria";
import CreateGamePage from "./CreateGamePage";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Modal, Typography } from "@mui/material";
import { getToken } from "../../../utils/token";
import axios from "axios";
import { clearCreateOfferData } from "../../../Redux/OfferReducer/actionType";
import { useNavigate } from "react-router-dom";
import linkImg from "../../../assets/linkImg.png";
import tick from "../../../assets/tick-circle.svg";
import closeBtnLogo from "../../../assets/closeBtnlogo.png";
import { toast } from "react-toastify";
import link2 from "../../../assets/link-2.svg";

export default function PreviewPage() {
  const data = useSelector((store) => store.OfferReducer?.offerData);
  const [isEdit, setIsEdit] = useState({
    offerDetails: true,
    qualifyingCriteria: true,
    createGame: true,
  });
  const [offerId, setOfferId] = useState(0);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const BaseUrl = process.env.REACT_APP_API_BASE_URL;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const rewards = useSelector((store) => store?.OfferReducer?.allRewardTypes);
  const gameDataName =
    data?.createGame?.gameName === "Spin The Wheel"
      ? "spinTheWheelData"
      : data?.createGame?.gameName === "Slot Machine"
      ? "slotMachineData"
      : data?.createGame?.gameName === "Scratch Card"
      ? "scratchCardData"
      : data?.createGame?.gameName === "Trivia"
      ? "triviaData"
      : "";

  const envUrl = process.env.REACT_APP_OFFER_SUCCESS_URL;
  const OfferSuccessUrl = `${envUrl}/${offerId}`;

  const copyToClipboard = () => {
    const link = OfferSuccessUrl; // Replace with your actual offer link
    navigator.clipboard
      .writeText(link)
      .then(() => {
        // alert("Link copied to clipboard!");
      })
      .catch((error) => {
        console.error("Failed to copy: ", error);
      });
  };

  console.log(gameDataName, data, "offerData");

  function findCashbackTypeKeyValuePairs(obj) {
    const cashbackTypeId = obj.cashback_type?.id;

    if (!cashbackTypeId) {
      return {};
    }

    return Object.entries(obj).reduce((result, [key, value]) => {
      if (key.startsWith(cashbackTypeId)) {
        const newKey = key.replace(`${cashbackTypeId}_`, "");
        result[newKey] = value;
      }
      return result;
    }, {});
  }
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so we add 1
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };
  const handleCreateOffer = () => {
    const offerData = {
      productId: data?.offerDetails?.product,
      merchantId: data?.offerDetails?.merchant,
      name: data?.offerDetails?.title,
      description: data?.offerDetails?.description,
      startsAt: formatDate(data?.offerDetails?.startDate),
      endsAt: formatDate(data?.offerDetails?.endDate),
      lockingPeriod: data?.offerDetails?.lockingPeriod,
      expiryPeriod: data?.offerDetails?.expiryPeriod,
      termsAndConditions: data?.offerDetails?.termsAndConditions,
      initiativeId: data?.offerDetails?.offerInitiative?.id,
      coverImageId: data?.offerDetails?.coverPhotoId,
      earnRuleSetId: data?.setQualifyingCriteria?.linkToRule,
      collectionsIds: data?.setQualifyingCriteria?.linkToCohort,
      game: {
        game_type_id: data?.createGame?.game_type_id,
        rewards: data?.createGame?.game?.[gameDataName]?.map((reward) => {
          return {
            reward_type_id: rewards?.find(
              (item) => item?.name == reward?.reward_type?.name
            )?.id,
            title: reward?.title,
            win_probability: +reward?.win_probability,
            limit: reward?.limit,
            reward_details: {
              reward_type: reward?.reward_type,
              cashback_type: reward?.cashback_type,
              ...findCashbackTypeKeyValuePairs(reward),
            },
            design_details: {
              after_play: data?.createGame?.afterPlay?.[gameDataName]?.find(
                (item) => item?.reward_type?.id === reward?.reward_type?.id
              ),
            },
          };
        }),
        design: {
          before_play: data?.createGame?.beforePlay?.[gameDataName],
          after_play: data?.createGame?.afterPlay?.[gameDataName],
        },
        details: {},
      },
    };
    console.log(offerData, "createOffer");

    const token = getToken();

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .post(`${BaseUrl}/api/create-offer-v2`, offerData, config)
      .then((res) => {
        dispatch({ type: clearCreateOfferData });
        console.log(res.data, "offerCreated");
        // setLoading(false);
        setOfferId(res.data.offerId);
        handleOpen();
      })
      .catch((error) => {
        // dispatch({ type: clearCreateOfferData});
        // dispatch({
        //   type: errorSubmitOffer,
        //   payload: error?.response?.data?.message,
        // });
        console.log(error);
        toast.error(error?.message);
        // setLoading(false);
      });
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropProps={{
          onClick: (event) => event.stopPropagation(),
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "425px",
            bgcolor: "background.paper",
            border: "2px solid transparent",
            boxShadow: 24,
            p: 4,
            borderRadius: "12px",
          }}
        >
          <Button
            style={{
              position: "absolute",
              top: "16px",
              right: "16px",
              cursor: "pointer",
              minWidth: "0px",
            }}
            onClick={() => {
              handleClose();
              navigate("/view-offers");
            }}
          >
            <img src={closeBtnLogo} alt="" />
          </Button>

          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              sx={{
                height: "66px",
                width: "66px",
                backgroundColor: "#42EE281A",
                borderRadius: "18px",
                display: "grid",
                placeItems: "center",
              }}
            >
              <Box
                sx={{
                  height: "33px",
                  width: "33px",
                  backgroundColor: "#7AD16C",
                  borderRadius: "50%",
                  display: "grid",
                  placeItems: "center",
                }}
              >
                <img src={tick} alt="" />
              </Box>
            </Box>
          </Box>
          <Box mt={"24px"}>
            <Typography
              sx={{
                fontFamily: "Lato",
                fontSize: "24px",
                fontWeight: 600,
                lineHeight: "36px",
                color: "#0B1B32",
                textAlign: "center",
              }}
            >
              Offer Created Successfully
            </Typography>
          </Box>
          <Box mt={"29px"} mb={"2px"} sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              height={"32px"}
              border="1px solid gray"
              borderRadius="8px"
              // padding="0px 12px"
              paddingLeft="8px"
              width="373px"
            >
              <Typography
                variant="body1"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                sx={{
                  fontFamily: "Lato",
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "16px",
                  color: "#1D1F2C",
                }}
              >
                {OfferSuccessUrl}
              </Typography>
              <Button
                onClick={copyToClipboard}
                sx={{
                  display: "block",
                  marginLeft: "auto",
                  backgroundColor: "#F9F9FC",
                  height: "32px",
                  color: "#003D86",
                  width: "93px",
                  padding: "4px",
                  minWidth: "110px",
                  borderRadius: "0px 8px 8px 0px",
                  "&:hover": {
                    backgroundColor: "#F9F9FC",
                    opacity: 1,
                  },
                }}
              >
                <Box
                  display="flex"
                  gap="5px"
                  height="15px"
                  // width="150px"
                  alignItems="center"
                  paddingLeft="5px"
                  textTransform="capitalize"
                >
                  <Typography
                    sx={{
                      fontFamily: "Lato",
                      fontSize: "12px",
                      fontWeight: 600,
                      lineHeight: "16px",
                      color: "#003D86",
                      ml: "5px",
                    }}
                  >
                    Copy
                  </Typography>{" "}
                  <Typography
                    sx={{
                      fontFamily: "Lato",
                      fontSize: "12px",
                      fontWeight: 600,
                      lineHeight: "16px",
                      color: "#003D86",
                    }}
                  >
                    {" "}
                    Link
                  </Typography>{" "}
                  <img src={link2} alt="" />
                </Box>
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <OfferDetailsPage showEdit={true} />
      <SetQualifyingCriteria showEdit={true} />
      <CreateGamePage showEdit={true} />
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            gap: "16px",
            my: "20px",
            mx: "20px",
            pb: "30px",
          }}
        >
          <Button
            sx={{
              width: "136px",
              height: "30px",
              backgroundColor: "#E8F0F9",
              padding: "7px 12px",
              borderRadius: "6px",
              "&:hover": {
                backgroundColor: "#E8F0F9",
              },
            }}
          >
            <Typography
              sx={{
                fontFamily: "Lato",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "16.8px",
                color: "#003D86",
              }}
            >
              Cancel
            </Typography>
          </Button>
          <Button
            sx={{
              width: "136px",
              height: "30px",
              backgroundColor: "#003D86",
              padding: "7px 12px",
              borderRadius: "6px",
              "&:hover": {
                backgroundColor: "#003D86",
              },
            }}
          >
            <Typography
              sx={{
                fontFamily: "Lato",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "16.8px",
                color: "#FFFFFF",
              }}
              onClick={handleCreateOffer}
            >
              Create Offer
            </Typography>
          </Button>
        </Box>
      </Box>
    </>
  );
}
