import styles from "./deal.module.scss";
import React, { useEffect, useState } from "react";
import infoIcon from "../../../assets/Info-icon-black.png";
import { DeviceFrameset } from "react-device-frameset";
import "react-device-frameset/styles/marvel-devices.min.css";
const Deals = ({ dealData, poster }) => {
  const [data, setData] = useState({});

  function formatDateRange(startDate, endDate) {
    // Convert start and end dates to JavaScript Date objects
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);
    // Get month and day from start and end dates
    const startMonth = startDateObj.toLocaleString("default", {
      month: "short",
    });
    const endMonth = endDateObj.toLocaleString("default", { month: "short" });
    const startDay = startDateObj.getDate();
    const endDay = endDateObj.getDate();
    // Format the string
    const formattedString = `Valid From ${startDay} ${startMonth} to ${endDay} ${endMonth}`;
    return formattedString;
  }
  useEffect(() => {
    setData(dealData);
  }, [dealData]);
  return (
    <DeviceFrameset
      device="iPhone X"
      color="black"
      zoom="55%"
      style={{
        overflowY: "scroll",
      }}
    >
      <div className={styles.mainContainer}>
        {/* <Header headerText="Unbeatable Deals" /> */}
        <div className={styles.container}>
          <div className={styles.dealImageContainer}>
            {data.coverImageUrl && (
              <img
                src={data.coverImageUrl}
                alt="logo"
                priority={true}
                layout="responsive"
                width={360}
                height={270}
              />
            )}
          </div>
          <div className={styles.mainContent}>
            <div className={styles.DealsDetailsContainer}>
              {/* <div className={styles.DealTitle}>{data?.title ?? ''}</div>
            <div className={styles.dealSourceText}>{data?.description ?? ''}</div> */}
              <div className={styles.dealValidityContainer}>
                {data?.startDate && data?.endDate && (
                  <div className={styles.dealInfoIcon}>
                    <img src={infoIcon} height={20} width={20} alt="info" />
                  </div>
                )}
                <div className={styles.dealValidityText}>
                  {data?.startDate && data?.endDate
                    ? formatDateRange(data?.startDate, data.endDate)
                    : ""}
                </div>
              </div>
              <div className={styles.horizontalLineContainer}>
                <div className={styles.horizontalLine} />
              </div>
              <div className={styles.dealTermsConditionContainer}>
                <div className={styles.dealsTermsConditionLabel}>
                  Terms And Conditions
                </div>
                {data?.TAndC && (
                  <div
                    className={styles.termsAndConditions}
                    dangerouslySetInnerHTML={{
                      __html: data?.TAndC || "",
                    }}
                  />
                )}
              </div>
            </div>
            <div style={{ height: "32px", background: "#FFFFFF" }} />
          </div>
        </div>
        {/* {otherDeals && otherDeals.length > 0 && (
        <div className={styles.dealsContainer}>
          <CarouselContainerHeader
            description=""
            link="/rewards/deals"
            linkText="View All"
            title="You may also like"
          />
          <div className={styles.scratchCardsCarousel}>
            <DealsCarousel
              deals={otherDeals}
              dealsCardsCarouselResponsiveData={
                dealsCardsCarouselResponsiveData
              }
            />
          </div>
        </div>
      )} */}
      </div>
    </DeviceFrameset>
  );
};
export default Deals;
