import dayjs from "dayjs";

export const isValidBasicOffersData = (data, handleError, setError) => {
  let flag = true;
  // if (data.category.length === 0 || !data.category) {
  //   handleError("category", "category is required!", setError);
  //   flag = false;
  // }
  // if (data.subCategory.length === 0 || !data.subCategory) {
  //   handleError("subCategory", "sub category is required!", setError);

  //   flag = false;
  // }
  // if (data.merchant.length === 0 || !data.merchant) {
  //   handleError("merchant", "merchant is required!", setError);
  //   flag = false;
  // }
  // if (data?.product.length === 0 || !data?.product) {
  //   handleError("product", "product is required!", setError);
  //   flag = false;
  // }
  return flag;
};

// validate date is valid or not
function isValidDate(dateString) {
  // Try parsing the input string with dayjs
  const parsedDate = dayjs(dateString);
  // Check if the parsed date is valid
  return parsedDate.isValid();
}

// validate size and aspect ratio of an image
function validateImageSizeAndAspectRatio(
  imageInput,
  maxSizeInBytes,
  targetAspectRatio,
  sizeErrorMessage,
  aspectRatioErrorMessage,
  callback
) {
  console.log("img")
  if (imageInput) {
    const imageFile = imageInput;
    // will compare size in bytes eg. 5 * 1024 * 1024; // 5MB
    if (imageFile.size > maxSizeInBytes) {
      console.log("imgIF")
      callback(sizeErrorMessage);
    } else {
      console.log("imgELSE")
      const img = new Image();

      img.onload = function () {
        const aspectRatio = img.width / img.height;
        console.log(aspectRatio,"aspectRatio")
        // will compare aspect ratio eg 4:3
        if (Math.abs(aspectRatio - targetAspectRatio) > 0.01) {
          callback(aspectRatioErrorMessage);
        } else {
          callback(false);
        }
      };
      // Load the image data
      const reader = new FileReader();
      reader.onload = function (e) {
        img.src = e.target.result;
      };
      reader.readAsDataURL(imageFile);
    }
  }
}

// to check end date should not less than start date
export function isEndDateValid(startDate, endDate) {
  // Convert string dates to Date objects
  const startDateObj = new Date(startDate);
  const endDateObj = new Date(endDate);

  // Compare the dates
  return endDateObj < startDateObj;
}

export const isValidOfferDetailsData = (data, handleError, setError) => {
  let flag = true;
  if (!data?.title) {
    handleError("title", "Title is required!", setError);
    flag = false;
  }
  if (!data?.description) {
    handleError("description", "Description is required!", setError);
    flag = false;
  }
  if (!isValidDate(data?.startDate)) {
    const errorMessage = data?.startDate
      ? "please enter valid date!"
      : "from date is required!";
    handleError("startDate", errorMessage, setError);
    flag = false;
  }
  if (!isValidDate(data?.endDate)) {
    const errorMessage = data?.endDate
      ? "please enter valid date!"
      : "to date is required!";
    handleError("endDate", errorMessage, setError);
    flag = false;
  }
  if (data?.startDate && isEndDateValid(data.startDate, data.endDate)) {
    handleError(
      "endDate",
      "to date should be greater or equal to from date!",
      setError
    );
    flag = false;
  }
  // if (!data?.TAndC) {
  //   handleError("TAndC", "detailed T&C is required!", setError);
  //   flag = false;
  // }
  // if (!data?.offerInformationForPWAAfter) {
  //   handleError(
  //     "offerInformationForPWAAfter",
  //     "offer information for PWA is required!",
  //     setError
  //   );
  //   flag = false;
  // }
  // if (!data?.offerInformationForPWABefore) {
  //   handleError(
  //     "offerInformationForPWABefore",
  //     "offer information for PWA is required!",
  //     setError
  //   );
  //   flag = false;
  // }
  // if (!data?.coverPhoto) {
  //   handleError("coverPhoto", "cover photo is required!", setError);
  //   flag = false;
  // }
  //  else {
  //   const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
  //   const targetAspectRatio = 4 / 3; // Target aspect ratio (4:3)
  //   const sizeErrorMessage =
  //     "image size exceeds 5MB. please choose a smaller image.";
  //   const aspectRatioErrorMessage = "image aspect ratio must be 4:3.";

  //   validateImageSizeAndAspectRatio(
  //     data?.coverPhoto,
  //     maxSizeInBytes,
  //     targetAspectRatio,
  //     sizeErrorMessage,
  //     aspectRatioErrorMessage,
  //     function (errorMessage) {
  //       if (errorMessage) {
  //         handleError("coverPhoto", errorMessage, setError);
  //       }
  //     }
  //   );
  // }
  // if (!data?.offerInitiative) {
  //   handleError("offerInitiative", "offer initiative is required!", setError);
  //   flag = false;
  // }
  // if (!data?.rewardCurrency) {
  //   handleError("rewardCurrency", "reward currency is required!", setError);
  //   flag = false;
  // }
  // if (!data?.gameType) {
  //   handleError("gameType", "game type is required!", setError);
  //   flag = false;
  // }
  if (data?.rewardCurrency.includes("VOUCHER") && !data?.csvFile) {
    handleError("csvFile", "CSV File is required!", setError);
    flag = false;
  }
  return flag;
};

// validation for hex color code
function isValidHexColor(hexColor) {
  // Define a regular expression pattern for a valid hex color code
  const hexColorPattern = /^#(?:[0-9a-fA-F]{3}){1,2}$/;

  // Test the input string against the pattern
  return hexColorPattern.test(hexColor);
}

export const isValidCreateGameData = (data, handleError, setError) => {
  let flag = true;
  // if (!data.offerTitleColor || !isValidHexColor(data.offerTitleColor)) {
  //   const errorMessage = data.offerTitleColor
  //     ? "color code is not valid"
  //     : "offer title color  is required!";
  //   handleError("offerTitleColor", errorMessage, setError);
  //   flag = false;
  // }
  // if (!data.scratchCardColor || !isValidHexColor(data.scratchCardColor)) {
  //   const errorMessage = data.scratchCardColor
  //     ? "color code is not valid"
  //     : "scratch card color is required!";
  //   handleError("scratchCardColor", errorMessage, setError);
  //   flag = false;
  // }
  // if (!data.merchantLogo) {
  //   handleError("merchantLogo", "merchant logo type is required!", setError);
  //   flag = false;
  // } else {
  //   const maxSizeInBytes = 500 * 1024; // 5KB
  //   const targetAspectRatio = 1; // Target aspect ratio (1:1)
  //   const sizeErrorMessage =
  //     "image size exceeds 500kb. please choose a smaller image.";
  //   const aspectRatioErrorMessage = "image aspect ratio must be 1:1.";

  //   validateImageSizeAndAspectRatio(
  //     data?.merchantLogo,
  //     maxSizeInBytes,
  //     targetAspectRatio,
  //     sizeErrorMessage,
  //     aspectRatioErrorMessage,
  //     function (errorMessage) {
  //       if (errorMessage) {
  //         handleError("merchantLogo", errorMessage, setError);
  //       }
  //     }
  //   );
  // }
  return flag;
};

export const isValidSetOfferRulesData = (data, handleError, setError) => {
  let flag = true;
  if (!data.linkToRule) {
    handleError("linkToRule", "rule is required!", setError);
    flag = false;
  }
  if (!data.linkToCohort) {
    handleError("linkToCohort", "cohort is required!", setError);

    flag = false;
  }
  return flag;
};

function isValidURL(url) {
  // Regular expression for a URL pattern
  const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;

  // Test the provided URL against the pattern
  return urlPattern.test(url);
}

export const isValidUserCohortData = (data, handleError, setError) => {
  let flag = true;
  if (!data?.url && !data?.csvFile) {
    handleError("csvFile", "CSV file is required!", setError);
    flag = false;
  }
  if (!data?.url && !data?.csvFile) {
    handleError("url", "URL is required!", setError);

    flag = false;
  }
  if (!data?.csvFile && data?.url && !isValidURL(data?.url)) {
    handleError("url", "please enter valid url!", setError);

    flag = false;
  }
  if (!data.name) {
    handleError("name", "name is required!", setError);
    flag = false;
  }
  if (!data?.description) {
    handleError("description", "description is required!", setError);
    flag = false;
  }
  return flag;
};

export const isValidOfferLimitData = (data, setError, ) => {
  let flag = true;

  const offerLevelLimitsFairUsageOfRewardIssuance = {
    total: "",
    daily: "",
    weekly: "",
    monthly: "",
  };

  const offerLevelLimitsFairUsageOfRewardRedemption = {
    total: "",
    daily: "",
    weekly: "",
    monthly: "",
  };

  const userLevelLimitsFairUsageOfRewardIssuance = {
    total: "",
    daily: "",
    weekly: "",
    monthly: "",
    totalAmount: "",
  };

  const userLevelLimitsFairUsageOfRewardRedemption = {
    total: "",
    daily: "",
    weekly: "",
    monthly: "",
    totalAmount: "",
  };

  // ["total", "daily", "weekly", "monthly"]?.forEach((period) => {
  //   if (!data?.offerLevelLimits?.fairUsageOfRewardIssuance?.[period]) {
  //     offerLevelLimitsFairUsageOfRewardIssuance[
  //       period
  //     ] = `${period} is required!`;
 
  //     flag = false;
  //   }
  // });

  // ["total", "daily", "weekly", "monthly"]?.forEach((period) => {
  //   if (!data?.offerLevelLimits?.fairUsageOfRewardRedemption?.[period]) {
  //     offerLevelLimitsFairUsageOfRewardRedemption[
  //       period
  //     ] = `${period} is required!`;
    
  //     flag = false;
  //   }
  // });

  // ["total", "daily", "weekly", "monthly", "totalAmount"]?.forEach((key) => {
  //   if (!data?.userLevelLimits?.fairUsageOfRewardIssuance?.[key]) {
  //     userLevelLimitsFairUsageOfRewardIssuance[key] = `${key} is required!`;
     
  //     flag = false;
  //   }
  // });

  // ["total", "daily", "weekly", "monthly", "totalAmount"]?.forEach((key) => {
  //   if (!data?.userLevelLimits?.fairUsageOfRewardRedemption?.[key]) {
  //     userLevelLimitsFairUsageOfRewardRedemption[key] = `${key} is required!`;
  //     flag = false;
  //   }
  // });

  // setError({
  //   userLevelLimits: {
  //     fairUsageOfRewardRedemption: userLevelLimitsFairUsageOfRewardRedemption,
  //     fairUsageOfRewardIssuance: userLevelLimitsFairUsageOfRewardIssuance,
  //   },
  //   offerLevelLimits: {
  //     fairUsageOfRewardRedemption: offerLevelLimitsFairUsageOfRewardRedemption,
  //     fairUsageOfRewardIssuance: offerLevelLimitsFairUsageOfRewardIssuance,
  //   },
  // });

  return flag;
};
