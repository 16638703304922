import React, { useEffect, useState } from "react";
import MultiSelect from "./MultiSelectCheckboxes";
import "./FormContainer.css";
const defaultValues = [
  // { name: "Management Team", value: "management_team" },
  // { name: "Sales", value: "sales" },
];

const FormContainer = ({ options, state, name, handleChange }) => {
  const [selectedOptions, setSelectedOptions] = useState(state);

  const handleMultiChange = (options) => {
    // console.log(options, "set");
    setSelectedOptions(options);
  };

  const handleClearForm = (e) => {
    e.preventDefault();
    setSelectedOptions(defaultValues);
    window.location.reload();
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const formPayload = {
      selectedOptions: selectedOptions,
    };

    console.log("Send this in a POST request:", formPayload);
    alert(JSON.stringify(formPayload, null, 2));
    handleClearForm(e);
  };

  return (
    <form
      id="create-course-form"
      className="container"
      // onSubmit={handleFormSubmit}
    >
      <MultiSelect
        options={options}
        state={state}
        defaultValues={state}
        onChange={handleMultiChange}
        name={name}
        handleChange={handleChange}
      />
    </form>
  );
};

export default FormContainer;
