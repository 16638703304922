import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import {
  PieChart,
  Pie,
  Legend,
  Cell,
  ResponsiveContainer,
  Label,
  Tooltip,
} from "recharts";

const Bullet = ({ backgroundColor, size }) => {
  return (
    <div
      className="CirecleBullet"
      style={{
        backgroundColor,
        height: "15px",
        marginRight: "18px",
        minWidth: "15px",
        borderRadius: "40px",
        padding: "0px",
      }}
    ></div>
  );
};

const CustomizedLegend = (props) => {
  const { payload, series, color, options } = props;
  console.log(payload, "payload");
  return (
    <>
      {/* <List
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {series?.map((entry, index) => (
          <ListItem key={`item-${index}`}>
            <ListItemIcon>
              <Bullet backgroundColor={color[index]} />
            </ListItemIcon>
            <ListItemText primary={entry} style={{ marginLeft: "0px" }} />
          </ListItem>
        ))}
      </List> */}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        {payload?.map((entry, index) => (
          <Stack
            flexDirection={"row"}
            key={`item-${index}`}
            alignItems={"center"}
            justifyContent={"center"}
            gap={"10px"}
            m={"5px"}
          >
            <Box
              sx={{
                height: "100%",
              }}
            >
              <Box
                sx={{
                  width: "12px",
                  height: "12px",
                  paddingTop: "1px",
                }}
              >
                <Bullet backgroundColor={entry?.color} />
              </Box>
            </Box>
            <Typography
              sx={{
                display: "flex",
                fontFamily: "Proxima Nova",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "17.05px",
                marginY: "10px",
                color: "#39465F",
              }}
            >
              {entry?.value + " " + `(${entry?.payload?.value})`}
            </Typography>
          </Stack>
        ))}
      </Box>

      <Box
        sx={{
          marginTop: "5px",
          width: "100%",
        }}
      >
        {options?.map((entry, index) => (
          <Box
            display={"flex"}
            alignItems={"center"}
            gap={"6px"}
            padding={"8px 0px  8px 17px"}
          >
            <Typography
              variant="h1" 
              sx={{
                fontFamily: "Proxima Nova A",
                fontSize: "26px",
                fontWeight: 700,
                lineHeight: "32px",
                letterSpacing: "0em",
                textAlign: "left",
                color: color[index],
              }}
            >
              {entry?.value}
            </Typography>
            <Typography
              sx={{
                fontFamily: "Proxima Nova A",
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "17.05px",
                textAlign: "left",
                color: "#000",
              }}
            >
              {entry?.name}
            </Typography>
          </Box>
        ))}
      </Box>
    </>
  );
};

const CustomLabel = ({ viewBox, labelText, value }) => {
  const { cx, cy } = viewBox;
  const labelY = cy + 20; // Adjust this value to position the label below the value

  return (
    <g>
      <text
        x={cx}
        y={labelY}
        className="recharts-text recharts-label"
        textAnchor="middle"
        dominantBaseline="central"
        alignmentBaseline="middle"
        fill="#000"
        fontSize="24"
        fontWeight="700"
        fontFamily="Proxima Nova A"
      >
        {value}
      </text>
      <text
        x={cx}
        y={cy - 10} // Adjust this value to vertically center the label
        className="recharts-text recharts-label"
        textAnchor="middle"
        dominantBaseline="central"
        alignmentBaseline="middle"
        fontFamily="Proxima Nova A"
        fontSize="14"
        fontWeight="500"
      >
        {labelText}
      </text>
    </g>
  );
};

function CustomPieChart({
  margin = false,
  legend,
  color,
  series,
  options,
  data,
  pieCenterData,
}) {
  return (
    <Box
      sx={{
        width: "59%",
        height: "350px",
        borderRadius: "8px",
        padding: "25px 40px 10px 40px",
        boxShadow: "0px 4px 20px 0px #0000001A",
        backgroundColor: "#fff",
        boxSizing: "border-box",
        mt: !margin ? "18px" : "0",
      }}
    >
      <Typography
        style={{
          fontFamily: "Proxima Nova A",
          textAlign: "left",
          fontSize: 14,
          fontWeight: 500,
          lineHeight: "17px",
          letterSpacing: "1px",
          padding: "10px 0 10px 25px",
        }}
      >
        {legend}
      </Typography>
      <ResponsiveContainer
        style={{
          height: "calc(100% - 52px)",
          width: "100%",
        }}
      >
        <PieChart>
          <Pie
            startAngle={90}
            endAngle={-270}
            data={data}
            dataKey="value"
            cx={100}
            cy={120}
            innerRadius={80}
            outerRadius={100}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={color[index % color.length]} />
            ))}
            <Label
              content={
                <CustomLabel
                  value={pieCenterData?.value}
                  labelText={pieCenterData?.label}
                />
              }
              position="center"
            />
          </Pie>
          <Tooltip
            cursor={{ stroke: "red", strokeWidth: 2 }}
            contentStyle={{
              backgroundColor: "#f5f5f5",
              border: "1px solid #d5d5d5",
            }}
            wrapperStyle={{ zIndex: 1000 }}
            isAnimationActive={true}
          />

          <Legend
            content={
              <CustomizedLegend
                series={series}
                color={color}
                options={options}
              />
            }
            layout="vertical"
            verticalAlign="top"
            align="right"
          />
        </PieChart>
      </ResponsiveContainer>
    </Box>
  );
}

export default CustomPieChart;
