import React, { useEffect } from "react";
import Label from "../Label";
import MuiSelectAndSearch from "../MuiSelectAndSearch";
import { Box, Typography } from "@mui/material";
import UserCohortsFileUpload from "../../components/userCohorts/UserCohortsFileUpload";
import MuiInput from "../MuiInput";
import SmallImageUpload from "../SmallImageUpload";
import sampleCsv from "../../assets/Voucher.csv";

const options = [
  { name: "Better Luck Next Time", id: "better_luck_next_time" },
  { name: "Voucher", id: "voucher" },
  { name: "Neu Coins", id: "neu_coins" },
  { name: "Digital Gold", id: "digital_gold" },
  { name: "Cashback", id: "cashback" },
];

export default function CreateNewReward({
  data,
  handleStartFromScratch,
  onChange,
  rewards,
  field,
  setRewardTypeName = ()=>{}
}) {
  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = sampleCsv;
    link.download = "Voucher.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  useEffect(() => {
    if (
      data?.addNewRewards?.[field]?.rewardType !== "start_from_scratch" &&
      rewards.length > 0
    ) {
      const reward = rewards?.find((item, index) => index === 0);
      const value = reward?.options?.find(
        (item) => item.name === data?.addNewRewards?.[field]?.rewardType
      );
      handleStartFromScratch(reward?.backend_label, value);
    }
  }, [data?.addNewRewards?.[field]?.rewardType]);

  return (
    <Box>
      {rewards?.length > 0 &&
        rewards?.map((item, index) => (
          <Box
            mt={"15px"}
            sx={{ display: item?.mandatory ? "block" : "none" }}
            key={`reward-${item.backend_label}-${index}`}
          >
            {item?.input_type === "dropdown" ? (
              <Box sx={{ width: "400px" }}>
                <Label label={`${index + 1}. ${item.label}`} />
                <MuiSelectAndSearch
                  options={item?.options?.map((item) => ({
                    name: item?.label || item?.name,
                    id: item?.value || item?.id,
                    fields: item?.fields,
                  }))}
                  value={
                    data?.addNewRewards?.[field]?.startFromScratch?.[
                      item?.backend_label
                    ]
                  }
                  onChange={(event) =>{
                    handleStartFromScratch(item?.backend_label, event);
                    if(index===0){
                      setRewardTypeName(event?.name)
                    }
                  }
                  }
                  placeholder="--Select--"
                />
              </Box>
            ) : item?.input_type === "file_upload" ? (
              <Box sx={{ width: "400px" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    // px: "10px",
                  }}
                >
                  <Label label={`${index + 1}. ${item.label}`} />

                  <Typography
                    onClick={handleDownload}
                    style={{
                      fontFamily: "Mulish",
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "17.57px",
                      color: "#0F1828",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    Download Sample
                  </Typography>
                </Box>
                <UserCohortsFileUpload
                  data={data?.addNewRewards?.[field]?.startFromScratch}
                  textVisibility={false}
                  onChange={(field, event) =>
                    handleStartFromScratch(field, event)
                  }
                  errorMessage={
                    data?.addNewRewards?.[field]?.startFromScratch?.csvError
                  }
                />
              </Box>
            ) : item?.input_type === "input_box" ? (
              <Box sx={{ width: "400px" }}>
                <Label label={`${index + 1}. ${item.label}`} />
                <MuiInput
                  value={
                    data?.addNewRewards?.[field]?.startFromScratch?.[
                      item?.backend_label
                    ] || ""
                  }
                  onChange={(event) =>
                    handleStartFromScratch(item?.backend_label, event)
                  }
                  placeholder="40 Characters"
                />
              </Box>
            ) : item?.input_type === "image_upload" ? (
              <Box>
                <Label label={`${index + 1}. ${item.label}`} />
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "20px",
                    py: "15px",
                  }}
                >
                  {item?.options?.map((option, index) => (
                    <Box
                      key={index}
                      sx={{
                        width: "120px",
                        height: "100px",
                        backgroundColor: "#F4F4F4",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                        borderRadius: "12px",
                        cursor: "pointer",
                        border:
                          data?.addNewRewards?.[field]?.startFromScratch
                            ?.rewardIcon === option
                            ? "2px solid #000000"
                            : "2px solid transparent",
                      }}
                      onClick={() =>
                        handleStartFromScratch("rewardIcon", option)
                      }
                    >
                      <img
                        style={{ height: "64px", width: "64px" }}
                        src={option}
                        alt=""
                      />
                    </Box>
                  ))}
                </Box>

                <Box
                  sx={{
                    height: "100px",
                    display: "flex",
                    alignItems: "center",
                    gap: "25px",
                  }}
                >
                  <Typography>OR</Typography>
                  <Box>
                    <SmallImageUpload
                      // value={
                      //   data?.addNewRewards?.[field]?.startFromScratch
                      //     ?.rewardIconUploadUrl
                      // }
                      value={
                        item?.options?.includes(data?.addNewRewards?.[field]?.startFromScratch?.rewardIcon)?"":data?.addNewRewards?.[field]?.startFromScratch?.rewardIcon  
                      }
                      onClick={() => {
                        if (
                          data?.addNewRewards?.[field]?.startFromScratch
                            ?.rewardIconUploadUrl
                        ) {
                          handleStartFromScratch(
                            "rewardIcon",
                            data?.addNewRewards?.[field]?.startFromScratch
                              ?.rewardIconUploadUrl
                          );
                        }
                      }}
                      borderValue={
                        data?.addNewRewards?.[field]?.startFromScratch
                          ?.rewardIcon
                      }
                      onChange={(event) =>
                        handleStartFromScratch("rewardIconUpload", event)
                      }
                      handleImage={(value) =>
                        onChange({
                          ...data,
                          addNewRewards: {
                            ...data.addNewRewards,
                            [field]: {
                              ...data.addNewRewards[field],
                              startFromScratch: {
                                ...data.addNewRewards[field].startFromScratch,
                                rewardIconUpload: value,
                                rewardIconUploadUrl: value,
                                rewardIconUploadId: value,
                                rewardIcon: value,
                              },
                            },
                          },
                        })
                      }
                      image={
                        data?.addNewRewards?.[field]?.startFromScratch
                          ?.rewardIconUploadUrl
                      }
                    />
                  </Box>
                </Box>
              </Box>
                 </Box>
            ) : item?.input_type === "array" ? (
              <Box>
                <Label label={`${index + 1}. ${item.label}`} />

                <Box sx={{ display: "flex", gap: "10px" }}>
                  {(() => {
                    const fields =
                      data?.addNewRewards?.[field]?.startFromScratch?.[
                        rewards?.[index - 1]?.backend_label
                      ]?.fields;
                    const cashbackTypeName =
                      data?.addNewRewards?.[field]?.startFromScratch?.[
                        rewards?.[index - 1]?.backend_label
                      ]?.id || "anything";

                    if (!fields || fields.length === 0) {
                      return (
                        <Box sx={{ width: "400px" }}>
                          <MuiInput
                            key={`input-${cashbackTypeName}`}
                            value={
                              data?.addNewRewards?.[field]?.startFromScratch?.[
                                cashbackTypeName
                              ] || ""
                            }
                            onChange={(event) =>
                              handleStartFromScratch(cashbackTypeName, event)
                            }
                            placeholder="Enter value"
                          />
                        </Box>
                      );
                    }

                    return fields.map((item, fieldIndex) => {
                      const key = `${cashbackTypeName}_${item?.backend_label}`;
                      return (
                        <Box
                          key={`field-${key}-${fieldIndex}`}
                          sx={{
                            width:
                              fields.length > 2
                                ? "150px"
                                : fields.length === 2
                                ? "195px"
                                : "400px",
                          }}
                        >
                          <MuiInput
                            key={`input-${key}`}
                            value={
                              data?.addNewRewards?.[field]?.startFromScratch?.[
                                key
                              ] || ""
                            }
                            onChange={(event) =>
                              handleStartFromScratch(key, event)
                            }
                            placeholder={item?.label}
                          />
                        </Box>
                      );
                    });
                  })()}
                </Box>
              </Box>
            ) : (
              <Label label={`${index + 1}. ${item.label}`} />
            )}
          </Box>
        ))}
    </Box>
  );
}
