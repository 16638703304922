import { DatePicker } from "@mui/x-date-pickers";
import React from "react"; // Adjust the path as needed

const CustomDatePicker = ({
  label,
  value,
  onChange,
  name,
  setState,
  isoString,
  isDispatch,
  state,
}) => {
  return (
    <DatePicker
      label={label}
      value={value}
      onChange={(newValue) =>
        onChange(name, newValue, setState, isoString, isDispatch, state)
      }
    />
  );
};

export default CustomDatePicker;
