import React, { useRef, useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { IconButton } from "@mui/material";
import { fileToBase64Image } from "../utils/fileToBase64";
import uploadIcon from "../assets/UploadIcon.svg";
import clicked from "../assets/clicked.svg";

const NewImageUpload = ({
  onChange,
  errorMessage,
  value,
  handleImage,
  image,
  maxSize = "2MB",
  ratio = "4:3",
  loader = false,
  disable = false,
  editBoolean = true,
  compareLink,
  handleSelectImage = () => {},
  index,
  backend_label=""
}) => {
  const [aspectRatioError, setAspectRatioError] = useState("");
  const [sizeError, setSizeError] = useState("");
  const ImageInputRef = useRef(null);

  const handleSelectImageClick = () => {
    ImageInputRef?.current?.click();
  };

  const handleDeleteImage = () => {
    handleImage(null);
    if (ImageInputRef.current) {
      ImageInputRef.current.value = "";
    }
  };

  const checkAspectRatio = (file) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        const aspectRatioValue = img.width / img.height;
        const [targetWidth, targetHeight] = ratio.split(":").map(Number);
        const targetRatio = targetWidth / targetHeight;
        const margin = 0.01;
        if (Math.abs(aspectRatioValue - targetRatio) < margin) {
          resolve(true);
        } else {
          resolve(false);
        }
      };
      img.onerror = () => {
        reject(new Error("Failed to load image"));
      };
      img.src = URL.createObjectURL(file);
    });
  };

  const getMaxSizeInBytes = (size) => {
    const sizeUnit = size.slice(-2).toUpperCase();
    const sizeValue = parseFloat(size);
    switch (sizeUnit) {
      case "KB":
        return sizeValue * 1024;
      case "MB":
        return sizeValue * 1024 * 1024;
      case "GB":
        return sizeValue * 1024 * 1024 * 1024;
      default:
        return sizeValue; // Assuming size is in bytes if no unit is specified
    }
  };

  async function readImage(file) {
    if (!file) return;

    setAspectRatioError("");
    setSizeError("");

    const maxSizeInBytes = getMaxSizeInBytes(maxSize);

    if (file.size > maxSizeInBytes) {
      setSizeError(`Image size must be less than ${maxSize}`);
      return;
    }

    const isAspectRatioValid = await checkAspectRatio(file);
    if (!isAspectRatioValid) {
      setAspectRatioError(`Image aspect ratio must be ${ratio}`);
      return;
    }

    setSizeError("");
    setAspectRatioError("");
    onChange(file);
    fileToBase64Image(file, handleImage);
  }

  return (
    <>
      <Box sx={{ width: "100%", mt: "10px" }}>
        <input
          type="file"
          accept="image/png, image/jpeg"
          ref={ImageInputRef}
          onChange={(event) => {
            if (event?.target.files) {
              readImage(event.target.files[0]);
            }
          }}
          disabled={disable}
          style={{ display: "none" }}
        />

        {image || (value && typeof value === "string") ? (
          <Box
            sx={{
              display: "flex",
              height: "62px",
              backgroundColor: "#F9F9FC",
              borderRadius: "6px",
              padding: "15px",
              gap: "20px",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                borderRadius: "3px",
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => handleSelectImage(backend_label,value,index)}
            >
              {loader ? (
                <Box width="200px">
                  <CircularProgress color="inherit" />
                </Box>
              ) : (
                <Box sx={{ position: "relative" }}>
                  <img
                    src={
                      value && typeof value === "string"
                        ? `${value}?date=${new Date().getTime()}`
                        : `data:image/jpeg;base64,${image}`
                    }
                    alt="course img"
                    style={{borderRadius: "6px",height:"65px",width:"65px" }}
                  />
                  <img
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      display:value===compareLink?"block":"none"
                    }}
                    src={clicked}
                    alt=""
                  />
                </Box>
              )}
            </Box>
            <Box sx={{ display: "flex", gap: "15px" }}>
              <Box
                sx={{
                  // mb: 3,
                  display: "flex",
                  cursor: "pointer",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 1,
                }}
                onClick={handleSelectImageClick}
              >
                <Box
                  sx={{
                    borderRadius: "50%",
                    backgroundColor: "#b5efbe",
                    // p: "4px",
                    color: "#4ACB5F",
                  }}
                >
                  <IconButton>
                    <FileUploadOutlinedIcon
                      sx={{ color: "#4ACB5F", fontSize: "10px" }}
                    />
                  </IconButton>
                </Box>
                <Box
                  sx={{
                    fontSize: "12px",
                    fontWeight: 600,
                    fontFamily: "Lato",
                    color: "#4ACB5F",
                  }}
                >
                  Change Image
                </Box>
              </Box>
              <Box
                sx={{
                  // mb: 3,
                  display: "flex",
                  cursor: "pointer",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 1,
                }}
                onClick={handleDeleteImage}
              >
                <Box
                  sx={{
                    cursor: "pointer",
                    borderRadius: "50%",
                    backgroundColor: "#f4f4f4",
                    // p: "4px",
                    color: "#F24F4F",
                  }}
                >
                  <IconButton>
                    <DeleteOutlineIcon
                      sx={{ color: "#F24F4F", fontSize: "15px" }}
                    />
                  </IconButton>
                </Box>
                <Box
                  sx={{
                    fontSize: "12px",
                    fontWeight: 600,
                    fontFamily: "Lato",
                    color: "#F24F4F",
                  }}
                >
                  Delete Image
                </Box>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              // border: `${editBoolean ? "2px" : "1px"} ${editBoolean ? "dashed" : "dashed"} ${
              //   aspectRatioError || sizeError || errorMessage
              //     ? "red"
              //     : editBoolean
              //     ? "orange"
              //     : "var(--text-30, rgba(0, 0, 0, 0.30))"
              // }`,
              borderRadius: "8px",
              cursor: "pointer",
              // px: "27px",
              padding: "14px 15px",
              // py: "42px",
              height: "62px",
              backgroundColor: "#F9F9FC",
              display: "flex",
              alignItems: "center",
              gap: "15px",
            }}
            onClick={handleSelectImageClick}
          >
            <img
              style={{ width: "12px", height: "12px" }}
              src={uploadIcon}
              alt=""
            />

            <div>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "center",
                  flexDirection: "column",
                  // mb: "5px",
                  // mt: "13px",
                  gap: "8px",
                }}
              >
                {/* <Box sx={{ borderRadius: "50%", backgroundColor: "#D5D5D5" }}>
                  <IconButton>
                    <FileUploadOutlinedIcon
                      sx={{ color: "#272728", fontSize: "20px" }}
                    />
                  </IconButton>
                </Box> */}
                <Typography
                  sx={{
                    textAlign: "start",
                    fontFamily: "Lato",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "14.4px",
                    color: "#003D86",
                  }}
                >
                  Upload
                </Typography>
                <Typography
                  sx={{
                    textAlign: "start",
                    color: "#7E8794",
                    fontFamily: "Lato",
                    fontSize: "10px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "12px",
                  }}
                >
                  Maximum {maxSize}. Upload .jpeg or .png, files. Aspect Ratio{" "}
                  {ratio}
                </Typography>
              </Box>
            </div>
          </Box>
        )}
      </Box>
      {(aspectRatioError || sizeError || errorMessage) && (
        <Box
          sx={{
            mt: 1,
            fontSize: "14px",
            color: "#d74848",
            textAlign: "left",
            ml: "20px",
          }}
        >
          {aspectRatioError || sizeError || errorMessage}
        </Box>
      )}
    </>
  );
};

export default NewImageUpload;
