import React from "react";
import { TextField, InputAdornment, Typography } from "@mui/material";

const DaysInput = ({
  value,
  onChange,
  type = "number",
  placeholder,
  label = "",
  errorMessage = "",
  disable = false,
  mt = "10px",
  editBoolean,
}) => {
  const handleChange = (inputValue) => {
    if (type === "number") {
      const numericValue = parseFloat(inputValue);
      if (!isNaN(numericValue) && numericValue >= 0) {
        onChange(numericValue);
      } else {
        onChange("");
      }
    } else {
      onChange(inputValue);
    }
  };

  return (
    <TextField
      sx={{
        width: "100%",
        mt: mt,
        height: "32px",
        "& .MuiOutlinedInput-root": {
          height: "32px",
          "& input": {
            fontFamily: "Lato",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "16px",
            padding: "8px",
            paddingRight: "0px",
          },
          "& fieldset": {
            borderColor: editBoolean ? "orange" : "",
            borderRadius: "6px",
          },
          "&:hover fieldset": {
            borderColor: editBoolean ? "orange" : "",
          },
          "&.Mui-focused fieldset": {
            borderColor: editBoolean ? "orange" : "",
          },
          "&.Mui-disabled": {
            opacity: 1,
            "& fieldset": {
              borderColor: editBoolean ? "orange" : "",
            },
          },
          // Remove right padding
          paddingRight: "0px",
        },
        "& .MuiInputLabel-root": {
          fontFamily: "Lato",
          fontWeight: 400,
          fontSize: "12px",
          lineHeight: "16px",
          color: editBoolean ? "orange" : "",
          "&.Mui-focused": {
            color: editBoolean ? "orange" : "",
          },
          "&.Mui-disabled": {
            color: editBoolean ? "orange" : "#000000ba !important",
          },
        },
        "& .MuiInputBase-input::placeholder": {
          fontFamily: "Lato",
          fontWeight: 400,
          fontSize: "12px",
          lineHeight: "16px",
        },
        "& .Mui-disabled": {
          "-webkit-text-fill-color": "#000000ba !important",
          backgroundColor: "transparent",
        },
      }}
      type={type}
      value={value}
      disabled={disable}
      onChange={(e) => handleChange(e.target.value)}
      label={label}
      placeholder={placeholder}
      size="small"
      helperText={errorMessage}
      error={!!errorMessage}
      onWheel={() => document.activeElement.blur()}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <div
              style={{
                // backgroundColor: "blue",
                height: "31px",
                width: "83px",
                display: "flex",
                alignItems: "center",
                borderTopRightRadius: "6px",
                borderBottomRightRadius: "6px",
                backgroundColor: "#E0E2E7",
                textAlign: "center",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Lato",
                  fontWeight: 600,
                  fontSize: "12px",
                  lineHeight: "16px",
                  color:"#003D86",
                  marginLeft: "25px",
                }}
              >
                Days
              </Typography>
            </div>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default DaysInput;
