import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Button,
  // FormGroup,
  FormControlLabel,
  Checkbox,
  Drawer,
  Typography,
} from "@mui/material";
import Label from "../../UIComponents/Label";
import MuiInput from "../../UIComponents/MuiInput";
import MuiTextarea from "../../UIComponents/MuiTextarea";
import MuiRadioGroup from "../../UIComponents/MuiRadioGroup";
// import MuiSelect from "../../UIComponents/MuiSelect";
import ImageUpload from "../../UIComponents/ImageUpload";
import RichEditor from "../../UIComponents/RichEditor";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllGameTypes,
  getCurrencyFunction,
  getGamesFunction,
  getInitiativeFunction,
  getMerchantFunction,
} from "../../Redux/OfferReducer/action";
import { MUIDateTimePicker } from "../../UIComponents/MUIDateTimePicker";
import axios from "axios";
import Deals from "./deals/Deals";
import MuiSelectAndSearch from "../../UIComponents/MuiSelectAndSearch";
// import MuiMultiSelectWithSearch from "../../UIComponents/MuiMultiSelectWithSearch";
import MultiSelect from "../../UIComponents/MultiSelect";
import ReactSlickCarousel from "../ReactSlickCarousel";
import ViewAllDeals from "../scratchcard/offer/ViewAllDeals";
import playLogo from "../../assets/playLogo.png";
import closeBtnLogo from "../../assets/crossBtnBlack.png"
import UserCohortsFileUpload from "../userCohorts/UserCohortsFileUpload";
import { useLocation } from "react-router-dom";
import sampleCsv from '../../assets/Voucher.csv';

const OfferDetails = ({
  data,
  onChange,
  errorMessage,
  handleOfferDetailsError,
  coverPhoto,
  handleCoverPhoto,
  activeStep,
}) => {
  const [loader, setLoader] = useState(false);
  // const [previewOpen, setPreviewOpen] = useState(false);
  const [poster, setPoster] = useState("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const token = useSelector((store) => store.AuthReducer.token);
  const BaseUrl = process.env.REACT_APP_API_BASE_URL;

  const carouselSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
  };

  const [index, setIndex] = useState(0);

  const dispatch = useDispatch();

  const location = useLocation(); 
  const pathname = location.pathname; 

  // Function to handle opening the drawer
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  // Function to handle closing the drawer
  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };
  const initiativeData = useSelector(
    (store) => store.OfferReducer.initiativeOption
  );
  const currencyData = useSelector(
    (store) => store.OfferReducer.rewardCurrencyOption
  );
  const gamesData = useSelector((store) => store.OfferReducer.gamesOption);

  const initiativeOption = initiativeData.map((element) => {
    return { label: element.name, value: element.id };
  });

  const currencyOption = currencyData.map((element) => {
    return { label: element?.name, value: element?.code, id: element?.id };
  });
  const gamesOption = gamesData.map((element) => {
    return { name: element.name, id: element.id };
  });
  // const gamesOption = gamesData.map((element) => {
  //   return { label: element.name, value: element.id };
  // });
  const merchantData = useSelector(
    (store) => store.OfferReducer.merchantOption
  );
  const merchantOption = merchantData.map((element) => {
    return { name: element.name, id: element.id };
  });
  // const merchantOption = merchantData.map((element)=>{
  //       return  {label:element.name,value:element.id}
  //       })

  const handleData = (key, value) => {
    console.log(key,value)
     if (key === "csvFile" && value) {
    // Check if the selected file is a CSV file
    if (value.type !== "text/csv") {
      onChange({ ...data, "csvError": "Please select a CSV file" });
      return;
    } else {
      onChange({...data, "csvError": "" });
    }

    // Upload CSV file
    setLoader(true);
    const formData = new FormData();
    formData.append("file", value);
    axios
      .post(`${BaseUrl}/api/upload`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log("CSV upload successful", response.data);
        onChange({
          ...data,
          [key]:value,
          csvUrl: response.data?.public_url,
          // csvFileName: value.name,
        });
      })
      .catch((error) => {
        console.error("Error uploading CSV file:", error);
        onChange({ ...data, "csvError": "Error uploading CSV file" });
      })
      .finally(() => {
        setLoader(false);
      });
  }

    if (errorMessage?.[key]) {
      handleOfferDetailsError((prev) => {
        return {
          ...prev,
          [key]: "",
        };
      });
    }

    if (key === "coverPhoto") {
      // console.log(value, "coverPhoto");
      setLoader(true);
      const formData = new FormData();
      formData.append("file", value);
      axios
        .post(`${BaseUrl}/api/upload`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log("Upload successful", response.data.id);
          setPoster(response.data?.public_url);
          onChange({
            ...data,
            coverPhotoId: response.data.id,
            [key]: value,
            coverImageUrl: response.data?.public_url,
          });
        })
        .catch((error) => {
          console.error("Error uploading file:", error);
          // to show error in case any error occurs in uploading image
          // toast.error("error uploading file")
        })
        .finally(() => {
          setLoader(false);
        });
    } else if (
      key === "startDate" ||
      key === "endDate" ||
      key === "lockInStartDate" ||
      key === "lockInEndDate"
    ) {
      // Convert date to desired format
      const date = new Date(value);
      const formattedDate = `${date.getFullYear()}-${String(
        date.getMonth() + 1
      ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")} ${String(
        date.getHours()
      ).padStart(2, "0")}:${String(date.getMinutes()).padStart(
        2,
        "0"
      )}:${String(date.getSeconds()).padStart(2, "0")}`;
      onChange({ ...data, [key]: formattedDate });
    } else if (key === "rewardCurrency") {
      const selectedCurrencyIds = value?.map(
        (currencyValue) =>
          currencyOption.find((option) => option.value === currencyValue)?.id
      );
      onChange({
        ...data,
        [key]: value,
        rewardCurrencyId: selectedCurrencyIds,
      });
    } else {
      onChange({ ...data, [key]: value });
    }
  };

  

  const fullUrl = "https://gratifystorage01.blob.core.windows.net/gratify-dev/offerAnalytics_Mon Jun 03 2024 17_31_51 GMT+0530 (India Standard Time) (2).csv_e7ff9b";

  // const url = "/gratify-dev/offerAnalytics_Mon Jun 03 2024 17_31_51 GMT+0530 (India Standard Time) (2).csv_e7ff9b";

  const downloadFile = async () => {

    try {
      const response = await axios.get(data?.csvUrl, {
        responseType: 'blob', // important
      });
      

      // Create a new Blob object using the response data
      const blob = new Blob([response.data], { type: 'text/csv' });

      // Create a link element
      const link = document.createElement('a');

      // Create a URL for the Blob and set it as the link's href
      const url = window.URL.createObjectURL(blob);
      link.href = url;
console.log(url ,"urlwwala")
      // Set the download attribute with a filename
      link.setAttribute('download', 'Voucher.csv');

      // Append the link to the body
      document.body.appendChild(link);

      // Programmatically click the link to trigger the download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Error downloading the file', error);
    }
  };

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = sampleCsv;
    link.download = 'Voucher.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const fetchFileFromUrl = async (url) => {
    try {
      const response = await fetch(new URL(url).pathname);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const blob = await response.blob();
      const fileName = url.split('/').pop();
      const file = new File([blob], fileName, { type: 'text/csv' });
      
      onChange({
        ...data,
        csvFile: file,
        csvUrl: url,
        csvFileName: fileName,
      });
  
      console.log("File fetched successfully:", file);
    } catch (error) {
      console.error('Error fetching file:', error.message);
      onChange({ ...data, csvError: `Error fetching CSV file: ${error.message}` });
    }
  };

  console.log(data, "offerDetails");
  useEffect(() => {
    dispatch(getMerchantFunction(token));
    dispatch(getInitiativeFunction(token));
    dispatch(getGamesFunction(token));
    dispatch(getCurrencyFunction(token));
    // dispatch(getAllGameTypes(1,5))
  }, []);

  useEffect(() => {
    if (data?.csvUrl) {
      fetchFileFromUrl(data?.csvUrl);
    }
  }, [data?.csvUrl]);
  return (
    <Grid
      paddingRight="20px"
      container
      rowSpacing={1}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
    >
     {/* {pathname.startsWith("/preview-offer") ? null : <ToastContainer />} */}
     
      <Grid item xs={12} sm={12} md={6}>
        <Box
          sx={{
            marginRight: { sx: "0px", sm: "0px", md: "70px" },
            marginBottom: "20px",
          }}
        >
          <Label label="1. Offer Title" />
          <MuiInput
            value={data?.title}
            onChange={(value) => handleData("title", value)}
            placeholder="Offer Title"
            errorMessage={errorMessage?.title}
            editBoolean={data?.previous_title??false}
          />
        </Box>
        <Box
          sx={{
            marginRight: { sx: "0px", sm: "0px", md: "70px" },
            marginBottom: "20px",
          }}
        >
          <Label label="2. Program ID (Til Code)" />
          <MuiInput
            value={data?.programId}
            onChange={(value) => handleData("programId", value)}
            placeholder="Enter ID"
            errorMessage={errorMessage?.programId}
            editBoolean={data?.previous_programId??false}
          />
        </Box>
        <Box
          sx={{
            marginRight: { sx: "0px", sm: "0px", md: "70px" },
            marginBottom: "20px",
          }}
        >
          <Label label="3. Offer Description" />
          <MuiTextarea
            placeholder="200 Characters"
            minRows={6}
            value={data?.description}
            onChange={(value) => handleData("description", value)}
            errorMessage={errorMessage?.description}
            editBoolean={data?.previous_description??false}
          />
        </Box>
        <Box
          sx={{
            marginRight: { sx: "0px", sm: "0px", md: "70px" },
            marginBottom: "20px",
          }}
        >
          <Label label="4. Offer Duration" />
          <Box
            sx={{
              marginTop: "10px",
              display: "flex",
              gap: 2,
            }}
          >
            <Box>
              <Label label="From Date And Time" />
              <MUIDateTimePicker
                value={data?.startDate}
                onChange={(value) => handleData("startDate", value)}
                errorMessage={errorMessage?.startDate}
                placeholder=""
                editBoolean={data?.previous_startDate??false}
                // disablePast={true}
                // maxDate={data?.startDate ? new Date(data?.endDate) : null}
              />
            </Box>
            <Box>
              <Label label="To Date And Time" />
              <MUIDateTimePicker
                value={data?.endDate}
                onChange={(value) => handleData("endDate", value)}
                errorMessage={errorMessage?.endDate}
                placeholder=""
                editBoolean={data?.previous_endDate??false}
                // disablePast={true}
                // minDate={data?.startDate ? new Date(data?.startDate) : null}
              />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            marginRight: { sx: "0px", sm: "0px", md: "70px" },
            // marginBottom: "20px",
          }}
        >
          {/* <Label label="5.Add lock in Period" /> */}
          <Box
            sx={{
              marginTop: "10px",
              display: "flex",
              gap: 2,
            }}
          >
            <Box
              sx={{
                marginBottom: "20px",
              }}
            >
              <Label label="5. Lock in Period" />
              <MuiInput
                value={data?.lockingPeriod}
                onChange={(value) => handleData("lockingPeriod", value)}
                placeholder="Enter no. of days"
                errorMessage={errorMessage?.lockingPeriod}
                editBoolean={data?.previous_lockingPeriod??false}
              />
            </Box>
            <Box>
              <Label label="6. Expiry Period" />
              <MuiInput
                value={data?.expiryPeriod}
                onChange={(value) => handleData("expiryPeriod", value)}
                placeholder="Enter no. of days"
                errorMessage={errorMessage?.expiryPeriod}
                editBoolean={data?.previous_expiryPeriod??false}
              />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            marginRight: { sx: "0px", sm: "0px", md: "70px" },
            marginBottom: "20px",
          }}
        >
          <Label label="7. Detailed T&C" />
          {/* <MuiTextarea
            placeholder="1000 Characters"
            minRows={6}
            value={data?.TAndC}
            onChange={(value) => handleData("TAndC", value)}
            errorMessage={errorMessage?.TAndC}
          /> */}
          <RichEditor
            // placeholder="200 Characters"
            value={data?.TAndC}
            onChange={(value) => handleData("TAndC", value)}
            errorMessage={errorMessage?.TAndC}
            editBoolean={data?.previous_TAndC??false}
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Box
          sx={{
            marginLeft: { sx: "0px", sm: "0px", md: "70px" },
            marginBottom: "20px",
          }}
        >
          <Label label="8. Offer Information for PWA (Before Redemption)" />
          <RichEditor
            // placeholder="200 Characters"
            value={data?.offerInformationForPWABefore}
            onChange={(value) =>
              handleData("offerInformationForPWABefore", value)
            }
            errorMessage={errorMessage?.offerInformationForPWABefore}
            editBoolean={data?.previous_offerInformationForPWABefore??false}
          />
        </Box>

        <Box
          sx={{
            marginLeft: { sx: "0px", sm: "0px", md: "70px" },
            marginBottom: "20px",
          }}
        >
          <Label label="9. Offer Cover Photo" />
          <ImageUpload
            loader={loader}
            value={data?.coverPhoto}
            onChange={(value) => handleData("coverPhoto", value)}
            maxSize="2MB"
            ratio="4:3"
            errorMessage={errorMessage?.coverPhoto}
            image={coverPhoto}
            handleImage={(value) => {
              onChange({
                ...data,
                coverPhoto: value,
                coverPhotoId: value,
                coverImageUrl: value,
              });
              // handleData("coverPhoto", value);
              // handleData("coverPhotoId", value);
              // handleData("coverImageUrl", value);
              handleCoverPhoto(value);
            }}
            editBoolean={data?.previous_coverPhoto??false}
          />
        </Box>
        {/* {console.log(data, "coverImageUrl")} */}
          {/* <MuiSelect
            options={gamesOption}
            value={data?.gameType}
            onChange={(value) => handleData("gameType", value)}
            errorMessage={errorMessage?.gameType}
          /> */}
        {/* <Box sx={{ marginLeft: { sx: "0px", sm: "0px", md: "70px" } }}>
          <Label label="10. Select Game type" />
          <MuiSelectAndSearch
            options={gamesOption}
            value={data?.gameType}
            onChange={(value) => handleData("gameType", value)}
            errorMessage={errorMessage?.gameType}
            editBoolean={data?.previous_gameType?.id??false}
            // placeholder="Link To Rule"
          />
        </Box> */}
        <Box
          sx={{
            marginLeft: { sx: "0px", sm: "0px", md: "70px" },
            // marginBottom: isEdit ? "20px" :"40px",
            marginTop: "10px",
          }}
        >
          <Label label="10. Select Merchant" />
          <Box sx={{ maxWidth: "510px" }}>
            <MultiSelect
              value={data?.merchant}
              onChange={(value) => handleData("merchant", value)}
              options={merchantOption}
              placeholder="Link To Rule"
              errorMessage={errorMessage?.merchant}
              editBoolean={data?.previous_merchant?.length>0??false}
            />

            {/* <MuiMultiSelectWithSearch
                 value={data?.merchant}
                 onChange={(value) => handleData("merchant", value)}
                 options={merchantOption}
                 placeholder="Link To Rule"
                 errorMessage={errorMessage?.merchant}
            /> */}
          </Box>
        </Box>
        <Box
          sx={{
            marginLeft: { sx: "0px", sm: "0px", md: "70px", marginTop: "10px" },
          }}
        >
          <Label label="11. Offer Initiative" />
          <MuiRadioGroup
            options={initiativeOption}
            value={data?.offerInitiative}
            onChange={(value) => handleData("offerInitiative", value)}
            errorMessage={errorMessage?.offerInitiative}
            editBoolean={data?.previous_offerInitiative??false}
          />
        </Box>
        <Box sx={{ marginLeft: { sx: "0px", sm: "0px", md: "70px" } }}>
          <Label label="12. Reward Currency" />
          {/* <MuiRadioGroup
            options={currencyOption}
            value={data?.rewardCurrency}
            onChange={(value) => handleData("rewardCurrency", value)}
            errorMessage={errorMessage?.rewardCurrency}
          /> */}

          {/*Multi Select  */}

          {/* <Box style={{ display: "flex", marginTop: "10px" }}>
            {currencyOption.map((option) => (
              <FormControlLabel
                key={option.value}
                control={
                  <Checkbox
                    checked={data.rewardCurrency.includes(option.value)}
                    onChange={(event) => {
                      const checked = event.target.checked;
                      const updatedCurrencies = checked
                        ? [...data.rewardCurrency, option.value]
                        : data.rewardCurrency.filter(
                            (value) => value !== option.value
                          );
                      handleData("rewardCurrency", updatedCurrencies, [
                        option.value,
                      ]);
                    }}
                    style={{ color: "black" }}
                  />
                }
                label={option.label}
              />
            ))}
          </Box> */}

          {/* Single Select */}

          <Box style={{ display: "flex", marginTop: "10px" }}>
            {currencyOption.map((option) => (
              <FormControlLabel
                key={option.value}
                control={
                  <Checkbox
                    checked={data?.rewardCurrency?.includes(option.value)}
                    onChange={() => {
                      // If the currency is already selected, do nothing (to enforce single selection)
                      if (data?.rewardCurrency?.includes(option.value)) return;

                      // If a different currency is selected, update the rewardCurrency value
                      handleData("rewardCurrency", [option.value]);
                    }}
                    // style={{ color: data?.rewardCurrency?.includes(option.value)?"black":"gray" }}
                    sx={{
                      color: 'gray',
                      '&.Mui-checked': {
                        color: data?.previous_rewardCurrency?.length>0?"orange":"black",
                      },
                    }}
          
                  />
                }
                label={option.label}
              />
            ))}
          </Box>
          
        </Box>
        <Box
          sx={{
            marginLeft: { sx: "0px", sm: "0px", md: "70px", marginTop: "10px" },
            display:data?.rewardCurrency?.includes("VOUCHER")?"block":"none"
          }}
        >
             
            <Box sx={{display:"flex",alignItems:"center",justifyContent:"space-between",px:"10px"}}>
              <Typography sx={{ fontSize: "20px", fontFamily:"Mulish",fontWeight:700,color:"#0F1828",lineHeight:"25.1px",marginRight:"auto" }}>
              Upload CSV
              </Typography>

              <Typography onClick={handleDownload} style={{fontFamily:"Mulish",fontSize:"14px",fontWeight:400,lineHeight:"17.57px",color:"#0F1828", textDecoration: "underline",cursor: "pointer" }} >
              Download Sample
              </Typography>
            </Box>  
            <UserCohortsFileUpload
              data={data}
              textVisibility={false}  
              onChange={(key, value) => handleData(key, value)}
              errorMessage={data?.csvError || errorMessage?.csvFile}
            />
        </Box>
        <Box
          sx={{
            display: "flex",
            visibility: drawerOpen ? "hidden" : "visible",
            justifyContent: "end",
            width: "100%",
            marginLeft: "10px",
            marginTop: "20px",
            marginBottom: "-50px",
          }}
        >
          {/* click this button to open drawer */}
          <Button
            color="inherit"
            sx={{ mr: 1 ,
              marginY: "15px",
              backgroundColor: "white",
              color: "black",
              padding: "8px 20px",
              height: "40px",
              fontFamily: "Mulish",
              fontWeight:700,
              fontSize: "16px",
              borderRadius:"7px",}}
            // onClick={() => setPreviewOpen((prev) => !prev)}
            onClick={() => handleDrawerOpen()}
            style={{
              // marginTop: "10px",
              // backgroundColor: "white",
              // color: "black",
              // padding: "8px 20px",
              // height: "40px",
              // fontFamily: "Mulish",
              // fontWeight:700,
              // fontSize: "16px",
              // borderRadius:"7px",
              
              // display: activeStep == 1 ? "block" : "none",
            }}
            variant="outlined"
            size="small"
          >
            Preview <span>
              <img style={{marginLeft:"16px",marginTop:"10px"}} src={playLogo} alt="" />
            </span>
            {/* {previewOpen ? "Close Preview" : "Preview"} */}
          </Button>
        </Box>
      </Grid>
      {/* add this box in Muidrawer */}
      <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose}>
        <Box>
          <Box>
            <Typography
              sx={{
                position: "fixed",
                top: "10%",
                transform: "translateY(-50%)",
                right: "178px",
                color: "#fff",
                zIndex: 8,
                // background: "#fff",
              }}
            >{`(${index + 1}/${2})`}</Typography>
            <Box
              sx={{
                position: "fixed",
                top: "44%",
                transform: "translateY(-50%)",
                right: "82px",
                zIndex: 8,
              }}
            >
              <ReactSlickCarousel
                max={1}
                setCurrentIndex={setIndex}
                settings={carouselSettings}
              >
                <ViewAllDeals
                  data={{
                    label: "offer cover photo",
                    img: data?.coverImageUrl,
                  }}
                />
                <Deals dealData={data} poster={poster} />
              </ReactSlickCarousel>
            <Box
              sx={{
                position: "fixed",
                top: "90%",
                transform: "translateY(-50%)",
                right: "75px",
                zIndex: 8,
              }}
            >
              <Button
                color="inherit"
                sx={{ mr: 1 }}
                // onClick={() => setPreviewOpen((prev) => !prev)}
                onClick={() => handleDrawerClose()}
                style={{
                  marginTop: "10px",
                  backgroundColor: "white",
                  color: "black",
                  padding: "0px 20px",
                  // border:"2px solid black",
                  borderRadius:"7px",
                  // marginRight: "20px",
                  display: "block",
                  marginLeft: "auto",
                  height: "42px",
                  fontFamily: "Mulish",
                  fontWeight:700,
                  fontSize: "16px",
                }}
                variant="outlined"
                size="small"
              >
                Close Preview <span >
                  <img style={{marginBottom:"-2px",marginLeft:"12px"}} src={closeBtnLogo} alt="" />
                </span>
                {/* {previewOpen ? "Close Preview" : "Preview"} */}
              </Button>
            </Box>
            </Box>
          </Box>
        </Box>
      </Drawer>
    </Grid>
  );
};

export default OfferDetails;
