import React from "react";
import { Box } from "@mui/material";

const Label = ({ label,color="#6F747E" }) => {
  return (
    <Box
      sx={{
        color: color,
        fontFamily: "Mulish",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "normal",
        textTransform: "capitalize",
        textAlign:'left',
      }}
    >
      {label}
    </Box>
  );
};

export default Label;
