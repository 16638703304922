import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Overview from "../assets/Overview.svg";
import EsMagicoLogo from "../assets/esmagicoLogo.png";
import FlexBetween from "./FlexBetween";
import Logo from "../assets/GratifyNewLogo.png";
import logoSVG from "../assets/newLogo.svg";
import { useSelector } from "react-redux";
import analytics from "../assets/analytics.svg";
import offers from "../assets/offers.svg";
import rules from "../assets/rules.svg";
import cohorts from "../assets/cohorts.svg";
import transactions from "../assets/transactions.svg";
import limits from "../assets/limits.svg";
import esmagico from "../assets/esmagico.svg";

const allowedData = [
  {
    path: "/analytics",
    title: "Analytics",
    icon: analytics,
    subMenu: null,
    disabled: "false",
  },
  {
    path: "/view-offers",
    title: "Offers/Campaigns",
    icon: offers,
    subMenu: [
      {
        path: "/offers/create-offers/offer-details",
        title: "Create Offers",
        subMenu: [
          {
            path: "/offers/create-offers/offer-details",
            title: "Offer Details",
            disabled: "false",
          },
          {
            path: "/offers/create-offers/set-qualifying-criteria",
            title: "Set Qualifying Criteria",
            disabled: "false",
          },
          {
            path: "/offers/create-offers/create-game",
            title: "Create Game",
            disabled: "false",
          },
        ],
        disabled: "false",
      },
      {
        path: "/view-offers",
        title: "View Offers",
        disabled: "false",
      },
    ],
    disabled: "false",
  },
  {
    path: "/view-rules",
    title: "Rules",
    icon: rules,
    subMenu: [
      {
        path: "/create-rules",
        title: "Create Rules",
        disabled: "false",
      },
      {
        path: "/view-rules",
        title: "View Rules",
        disabled: "false",
      },
    ],
    disabled: "false",
  },
  {
    path: "/limits",
    title: "Limits",
    icon: limits,
    subMenu: null,
    disabled: "false",
  },
  {
    path: "/userCohorts-view",
    title: "User Cohorts",
    icon: cohorts,
    subMenu: [
      {
        path: "/userCohorts-create",
        title: "Create",
        disabled: "false",
      },
      {
        path: "/userCohorts-view",
        title: "View",
        disabled: "false",
      },
    ],
    disabled: "false",
  },
  {
    path: "/transactions",
    title: "Transactions",
    icon: transactions,
    subMenu: null,
    disabled: "false",
  },
];

const Sidebar = ({ drawerWidth, isNonMobile }) => {
  const { pathname } = useLocation();
  const [active, setActive] = useState("");
  const [pathnameArray, setPathnameArray] = useState([]);
  const navigate = useNavigate();

  // Initialize expandedItems with all accordions open
  const [expandedItems, setExpandedItems] = useState(() => {
    const initialState = {};
    allowedData.forEach((item) => {
      if (item.subMenu && item.subMenu.length > 0) {
        initialState[item.title] = true;
      }
    });
    return initialState;
  });

  useEffect(() => {
    const currentPath = pathname.substring(1);
    setActive(currentPath);

    // Update pathnameArray based on the current pathname
    if (pathname.startsWith("/offers")) {
      setPathnameArray(prev => [...prev, pathname]);
    } else {
      setPathnameArray([]);
    }
  }, [pathname]);

  const handleAccordionClick = (item) => {
    if (!expandedItems[item.title]) {
      navigate(item.path);
    }
    setExpandedItems((prev) => ({
      ...prev,
      [item.title]: !prev[item.title],
    }));
  };

  console.log(pathnameArray, "pathnameArray");

  const renderMenuItem = (item, depth = 0) => {
    const hasSubMenu = item.subMenu && item.subMenu.length > 0;
    const lcText = item.title.toLowerCase();
    const isActive = active === item.path.substring(1);
    const isOfferPath = item.path.startsWith("/offers");
    const isDisabled = isOfferPath && !pathnameArray.includes(item.path);

    if (hasSubMenu) {
      return (
        <Accordion
          key={item.title}
          expanded={expandedItems[item.title]}
          onChange={() => handleAccordionClick(item)}
          sx={{
            backgroundColor: "white",
            color: "#0B1B32",
            marginY: "10px",
            boxShadow: "none",
            border: "none",
            "&:before": {
              display: "none",
            },
            "&.Mui-expanded": {
              backgroundColor: depth > 0 ? "#F5F6F7" : "white",
            },
          }}
        >
          <AccordionSummary
            expandIcon={
              <ChevronLeft
                sx={{
                  rotate: "270deg",
                  transition: "color 0.3s",
                }}
              />
            }
            sx={{
              minHeight: "32px",
              height: "32px",
              justifyContent: "space-between",
              alignItems: "center",
              "&.Mui-expanded": {
                minHeight: "32px",
                height: "32px",
                backgroundColor: depth > 0 ? "#F5F6F7" : "white",
              },
              "& .MuiAccordionSummary-content": {
                justifyContent: "flex-start",
                alignItems: "center",
                paddingY: 0,
              },
              "& .MuiAccordionSummary-expandIconWrapper": {
                color: "#B5BBC5",
              },
              "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                color: "#0B1B32",
              },
            }}
          >
            <Box
              component="img"
              sx={{
                height: 25,
                width: 25,
                marginRight: "10px",
                visibility: item?.icon ? "visible" : "hidden",
              }}
              alt="Menu icon"
              src={item?.icon}
            />
            <Typography
              sx={{
                fontFamily: "Lato",
                fontWeight: "600",
                fontSize: "12px",
                lineHeight: "14.4px",
                letterSpacing: "2%",
              }}
            >
              {item.title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              backgroundColor: depth > 0 ? "#F5F6F7" : "white",
              color: "#0B1B32",
              padding: 0,
            }}
          >
            {item.subMenu.map((subItem) => renderMenuItem(subItem, depth + 1))}
          </AccordionDetails>
        </Accordion>
      );
    } else {
      return (
        <ListItem key={item.title} disablePadding>
          <ListItemButton
            onClick={() => {
              if (!isDisabled) {
                navigate(item.path);
                setActive(lcText);
              }
            }}
            sx={{
              height: "35px",
              backgroundColor: isActive ? "#b8d4f7" : "transparent",
              color: isDisabled ? "gray" : "black",
              paddingLeft: depth > 0 ? `${(depth + 1) * 16}px` : "16px",
              "&:hover": {
                backgroundColor: isActive ? "#b8d4f7" : undefined,
                cursor: isDisabled ? "default" : "pointer",
              },
              pointerEvents: isDisabled ? "none" : "auto",
            }}
          >
            {depth === 0 ? (
              <Box
                component="img"
                sx={{
                  height: 25,
                  width: 25,
                  marginRight: "10px",
                }}
                alt="Menu icon"
                src={item?.icon}
              />
            ) : (
              <FiberManualRecordIcon
                sx={{ fontSize: 8, mr: "10px", visibility: "hidden" }}
              />
            )}
            <ListItemText
              primary={item.title}
              primaryTypographyProps={{
                fontFamily: "Lato",
                fontWeight: "600",
                fontSize: "12px",
                lineHeight: "14.4px",
                letterSpacing: "2%",
              }}
            />
          </ListItemButton>
        </ListItem>
      );
    }
  };

  return (
    <Box component="nav">
      <Drawer
        open={true}
        variant="persistent"
        anchor="left"
        sx={{
          width: drawerWidth,
          "& .MuiDrawer-paper": {
            backgroundColor: "white",
            color: "#0B1B32",
            boxSizing: "border-box",
            borderWidth: isNonMobile ? 0 : "2px",
            width: drawerWidth,
          },
        }}
      >
        <Box width="100%" position="relative" bottom="-50px">
          <div
            className="logo-container"
            style={{
              width: "100%",
              height: 62,
              margin: "-50px 0px",
              position: "absolute",
            }}
          >
            <Box m="0.5rem 1rem 0rem 2rem">
              <FlexBetween>
                <Box>
                  <img style={{ margin: "20px 10px" }} src={logoSVG} alt="" />
                </Box>
                {!isNonMobile && (
                  <IconButton
                    onClick={() =>
                      console.log(" setIsSidebarOpen(!isSidebarOpen)")
                    }
                  >
                    <ChevronLeft />
                  </IconButton>
                )}
              </FlexBetween>
            </Box>
          </div>

          <List sx={{ marginTop: "30px" }}>
            {allowedData.map((item) => renderMenuItem(item))}
          </List>
        </Box>

        <Box
          position="absolute"
          bottom="0px"
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            backgroundColor: "white",
            paddingBottom: "20px",
            // zIndex:"-1"
          }}
        >
          {/* <Divider /> */}
          <img src={esmagico} alt="" />
        </Box>
      </Drawer>
    </Box>
  );
};

export default Sidebar;
