import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";

const SwitchableButtonComponent = ({
  activeButton,
  setActiveButton,
  switchableButtonOptions = [],
}) => {
  //css
  const containerStyle = {
    display: "flex",
    alignItems: "center",
    padding: "20px 0",
    flexDirection: "row",
    gap: "18px",
  };

  const labelstyle = {
    color: "#0F1828",
    fontFamily: "Proxima Nova A",
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "22px",
    textTransform: "capitalize",
  };

  const activeButtonCss = {
    ...labelstyle,
    color: "#043A87",
  };

  const dividerStyle = {
    background: "#f7f9fc",
    height: "2px",
  };

  return (
    <Stack sx={containerStyle}>
      {switchableButtonOptions.length > 0 &&
        switchableButtonOptions.map((label) => (
          <Box key={label}>
            <Button
              variant="text"
              onClick={() => {
                setActiveButton(label);
              }}
            >
              <Typography
                sx={activeButton === label ? activeButtonCss : labelstyle}
              >
                {label}
              </Typography>
            </Button>
            <Box
              sx={
                activeButton === label
                  ? { ...dividerStyle, background: "#043A87" }
                  : dividerStyle
              }
            ></Box>
          </Box>
        ))}
    </Stack>
  );
};

export default SwitchableButtonComponent;
