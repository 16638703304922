import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const CustomAccordion = ({ summary, children }) => {
  return (
    <Accordion
      defaultExpanded
      sx={{
        m: "0 0.5rem",
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{
          backgroundColor: "#F6F6F7",
          height: "49px",
          "&.Mui-expanded": {
            minHeight: 15,
            maxHeight: 49,
            m: "0 0.5rem",
          },
        }}
      >
        <Typography
          sx={{
            fontFamily: "Proxima Nova A",
            fontSize: "14px",
            fontWeight: 600,
            lineHeight: "17px",
            letterSpacing: "0em",
            textAlign: "left",
            color: "#535353",
          }}
        >
          {summary}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export default CustomAccordion;
