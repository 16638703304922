import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import Label from "../../UIComponents/Label";
import MuiSelect from "../../UIComponents/MuiSelect";
import MuiInput from "../../UIComponents/MuiInput";
import MuiTextarea from "../../UIComponents/MuiTextarea";
import addBtn from "../../assets/AddBtn.png";
import plusIcon from "../../assets/plusBtn.png";
import { MUDatePicker } from "../../UIComponents/MuiDatePicker";
import MuiSelectWithSearch from "../../UIComponents/MuiSelectWithSearch";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import {
  getTypeOptionsFunction,
  getWhereSectionSelectOne,
  handleSubmitRule,
} from "../../Redux/CreateRuleReducer/action";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { addRule } from "../../Redux/CreateRuleReducer/actionType";

let whereObj = {};

let dummy = {
  name: "",
  description: "",
  type: "CUSTOM",
  conditions: [
    {
      entity: {
        entityId: "",
        attributes: [
          {
            conditionName: "",
            conditionDescription: "",
            attributeName: "",
            attributeId: "",
            valueType: "",
            type: "",
            value: "",
          },
          // {
          //   conditionName: "ec0850d2-3e1b-46e8-8418-f6778111caf8",
          //   conditionDescription: "73eb2faf-adc2-4f2e-9b7d-2559e2e9ab57",
          //   attributeName: "totalAmount1t NUMBER 405",
          //   attributeId: "405",
          //   valueType: "NUMBER",
          //   type: "GREATER_THAN_OR_EQUAL",
          //   value: 12,
          // },
        ],
      },
    },
  ],
  effects: [
    {
      cashback_type: "",
      // min_value: 12,
      // max_value: 14,
      // avg_value: 14,
      // std_deviation: 16,
    },
  ],
};

export default function CreateRule({
  activeTab,
  tabData,
  setTabData,
  handleCancel,
  cancelButtonHide = false,
  resetData,
}) {
  const [activeWhere, setActiveWhere] = useState(0);
  const [dummyData, setDummayData] = useState(dummy);
  const initializeRef = useRef(0);

  // where section data for each tab
  const [whereSectionData, setWhereSectionData] = useState(() =>
    tabData?.map((e) => e.whereSection)
  );

  const dispatch = useDispatch();
  const select1Data = useSelector((store) => store.CreateRuleReducer.select1);
  // console.log(select1Data)
  const options = useSelector((store) => store.CreateRuleReducer.typeOption);
  const token = useSelector((store) => store.AuthReducer.token);
  const productIds = useSelector((store) => store.CreateRuleReducer.productId);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const BaseUrl = process.env.REACT_APP_API_BASE_URL;
  const { state } = useLocation();

  function getRuleByID(id) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(`${BaseUrl}/api/admin/rule-set/${id}`, config)
      .then((res) => {
        console.log(res.data.rules[0], "ruleDetails");
        setDummayData(res.data.rules[0]);
      })
      .catch((error) => {
        console.log(error, "ruleDetails");
      });
  }

  const handleSubmitRule = (rule) => {
    // console.log(token)
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    // console.log(rule, "Submitted");
    // toast.success("Rule created successfully");
    // setTimeout(() => {
    //   if (pathname === "/create-offers") {
    //     handleCancel(tabData[activeTab], activeTab);
    //   } else {
    //     navigate("/view-rules");
    //   }
    // }, 2000);
    axios
      .post(`${BaseUrl}/api/create-rule`, rule, config)
      .then((res) => {
        dispatch({ type: addRule, payload: res.data });
        console.log(res.data, "Submitted");
        toast.success("Rule created successfully");
        setTimeout(() => {
          // if (pathname === "/create-offers"  ) {
          if (pathname === "/create-rules"  ) {
            navigate("/view-rules");
          } else {
            handleCancel(tabData[activeTab], activeTab);
            // to reset Data
            // setTimeout(()=>{
            //   setTabData([
            //     {
            //       rule: "",
            //       description: "",
            //       id: 1,
            //       type: "",
            //       whereSection: [{}],
            //     },
            //   ]);
            // },3000)
          }
        }, 2000);
      })
      .catch((e) => {
        console.log(e);
        // toast.error("Something went wrong");
        toast.error(e?.response?.data?.message);
      });
  };

  const whereOption1 = select1Data?.map((item) => ({
    label: item.attributeName,
    value: `${item.attributeName} ${item.attributeValueType} ${item.id}`,
  }));
  console.log(select1Data,"whereOp")
// console.log(whereOption1,"select")
  // useEffect(()=>{
  //   if(pathname==="/create-rules"){
  //     setTabData([{
  //       rule: "",
  //       description: "",
  //       id: 1,
  //       type: "",
  //       whereSection: [{}]
  //     }])
  //   }
  // },[])
  // console.log(tabData[activeTab])
  //to update tabData when whereSectionData changes
  // console.log(productIds,pathname)
  useEffect(() => {
    console.log("hit")
    dispatch(getWhereSectionSelectOne(productIds, pathname,"loyalty"));
    dispatch(getTypeOptionsFunction());
  }, []);

  useEffect(() => {
    if (initializeRef.current !== 2) {
      initializeRef.current = initializeRef.current + 1;
      return;
    }

    setTabData((prevTabData) => {
      return prevTabData?.map((tab, index) => {
        if (index === activeTab) {
          return {
            ...tab,
            whereSection: whereSectionData[activeTab],
          };
        }
        return tab;
      });
    });
  }, [whereSectionData]);
  const typeInput = options.find((e) => {
    if (e?.value == tabData[activeTab]?.type) {
      return e;
    }
  });

  useEffect(()=>{
    if(state?.id){
      getRuleByID(state.id);
    }
    // on mount phase data will reset to initial state
    if (pathname === "/create-rules") {
               setTabData([
                {
                  rule: "",
                  description: "",
                  id: 1,
                  type: "",
                  whereSection: [{}],
                },
              ]);
      whereSectionData[0].map((e, i) => {
        if (i !== 0) {
          console.log(state?.id);
          handleDeleteWhereSection(i);
        }
      });
      handleDataChange(0, "select1", "");
      handleDataChange(0, "select2", "");
      handleDataChange(0, "Amount", "");
      handleDataChange(0, "stringValue", "");
      handleDataChange(0, "arrayValue", "");
    }
  },[])

  // console.log(tabData)
  // COPY_RULE
  useEffect(() => {
    if (pathname == "/create-rules") {
      if (state?.id) {
       console.log(dummyData)
        dummyData?.conditions[0]?.entity?.attributes?.length>0 &&
          dummyData.conditions[0]?.entity?.attributes?.map((e, i) => {
            handleDataChange(i, "select1", e.attributeName);
            handleDataChange(i, "select2", e.type);
            if (e.valueType === "STRING") {
              handleDataChange(i, "stringValue", e.value);
            } else if (e.valueType === "NUMBER") {
              handleDataChange(i, "Amount", e.value);
            }
            else if(e.valueType==="ARRAY"){
              handleDataChange(i, "arrayValue", e.value);
            }
            handleDataChange(i, "select1", e.attributeName);
            handleDataChange(i, "select2", e.type);
          });

        let obj = dummyData?.effects[0];
        let cashbackType = "";
        for (let key in obj) {
          if (key === "cashback_type") {
            cashbackType = obj[key];
          }
        }

        for (let key in obj) {
          if (key != "cashback_type") {
            handleData(`${cashbackType}_${key}`, obj[key]);
            // console.log(`${cashbackType}_${key}`,obj[key])
          }
        }

        setTabData((prevTabData) => {
          return prevTabData?.map((tab, index) => {
            if (index === activeTab) {
              return {
                ...tab,
                rule: dummyData.name,
                description: dummyData.description,
                type: dummyData.effects[0]?.cashback_type,
              };
            }
            // return tab;
          });
        });
      } else {
        // setTabData((prevTabData) => {
        //   return prevTabData.map((tab, index) => {
        //     if (index === activeTab) {
        //       return {
        //         ...tab,
        //         rule: "",
        //         description: "",
        //         type: "",
        //         // whereSection: [{select1:"",select1:0,Amount:""}], // Reset where section data
        //       };
        //     }
        //     return tab;
        //   });
        // });

        // whereSectionData[0].map((e,i)=>{
        //   if(i!==0){
        //     handleDeleteWhereSection(i);
        //   }
        // })

        // handleDataChange(0, "select1", "");
        // handleDataChange(0, "select2", "");
        // handleDataChange(0, "Amount", "");
        // handleDataChange(0, "stringValue", "");
      }
    }
  }, [select1Data,dummyData]);
  


  // console.log(whereSectionData[0], "object");
  // console.log(tabData)
  useEffect(() => {
    if (pathname === "/create-offers") {
      setWhereSectionData(tabData?.map((e) => e?.whereSection));
    }
  }, [tabData]);
  // console.log(tabData)
  if (!tabData[activeTab]) {
    return null;
  }

  // to handle Data Dynamically
  const handleData = (name, value) => {
    setTabData((prevTabData) => {
      return prevTabData.map((tab, index) => {
        if (index === activeTab) {
          return {
            ...tab,
            [name]: value,
          };
        }
        return tab;
      });
    });
  };

  // to handle where section data
  const handleDataChange = (index, field, value) => {
    const updatedWhereSectionData = [...whereSectionData];
    updatedWhereSectionData[activeTab][index] = {
      ...updatedWhereSectionData[activeTab][index],
      [field]: value,
    };
    const section = updatedWhereSectionData[activeTab][index];

    // Update the field value
    section[field] = value;

    // to add select2Option
    if (field == "select1") {
      const splitValue = value?.split(" ");
      const select2Data = splitValue
        ? select1Data?.filter((e) => {
            if (e.id == splitValue[splitValue.length-1]) {
              return e;
            }
          })
        : [];
      const whereSelect2 =
        select2Data[0]?.operator &&
        select2Data[0]?.operator?.map((e) => {
          return { label: e, value: e };
        });
      section["select2Option"] = whereSelect2;
      if (!whereSelect2) {
        section.select2 = null;
      }
      section["dataType"] = splitValue ? splitValue[splitValue.length-2] : "";
      section["id"] = splitValue ? splitValue[splitValue.length-1] : "";
    }

    // Perform validation for date fields
    if (field == "startDate" || field == "endDate") {
      const checkStartDate = field === "startDate" ? value : section.startDate;
      const checkEndDate = field === "endDate" ? value : section.endDate;
      if (checkStartDate && checkEndDate && checkEndDate < checkStartDate) {
        section[field === "startDate" ? "endDate" : "startDate"] = null;
        toast.error(
          field === "startDate"
            ? "End date cannot be before start date"
            : "Start date cannot be after end date"
        );
      }
    }

    // Update the state with validated data
    updatedWhereSectionData[activeTab][index] = section;
    setWhereSectionData(updatedWhereSectionData);
  };

  // to handle Add Where section
  const handleAddWhereSection = () => {
    const newSection = [
      ...whereSectionData[activeTab],
      {
        ...whereObj,
        id: whereSectionData[activeTab].length + 1,
        // select1: "",
      },
    ];
    const updatedWhereSectionData = [...whereSectionData];
    updatedWhereSectionData[activeTab] = newSection;
    setWhereSectionData(updatedWhereSectionData);

    setActiveWhere((prev) => prev + 1);
  };

  function handleDeleteWhereSection(index) {
    console.log(index, "index ");
    const updatedWhereSectionData = [...whereSectionData];
    updatedWhereSectionData[activeTab].splice(index, 1);
    setWhereSectionData(updatedWhereSectionData);
  }

  // to save all the data in API
  const handleSave = () => {
    // Validate rule name
    if (!tabData[activeTab].rule) {
      toast.error("Rule name is required");
      return;
    }

    // Validate rule description
    if (!tabData[activeTab].description) {
      toast.error("Rule description is required");
      return;
    }

    // Validate rule type
    if (!tabData[activeTab].type) {
      toast.error("Rule type is required");
      return;
    }

    // Validate type specific fields
    if (tabData[activeTab].type) {
      const typeFields = typeInput?.fields;
      if (typeFields) {
        for (const field of typeFields) {
          const fieldName = `${tabData[activeTab].type}_${field.backend_label}`;
          if (!tabData[activeTab][fieldName]) {
            toast.error(`${field.label} is required`);
            return;
          }
        }
      }
    }

    // Validate Where section fields
    for (const section of whereSectionData[activeTab]) {
      if (!section.select1) {
        toast.error("All fields in the Where section are required");
        return;
      }
      if (section.select2Option && !section.select2) {
        toast.error("All fields in the Where section are required");
        return;
      }
      // console.log(section, "section");
      if (
        (section.dataType === "NUMBER" && !section.Amount) ||
        (section.dataType === "STRING" && !section.stringValue) ||
        (section.dataType === "ARRAY" &&
          (!section.arrayValue))
      ) {
        toast.error("All fields in the Where section are required");
        return;
      }
    }

    // If all validations pass, proceed with saving the data

    // to get active fields according to type
    let typeDataFilter = typeInput?.fields?.map((e, i) => {
      return `${tabData[activeTab].type}_${typeInput?.fields[i].backend_label}`;
    });

    // to get values of active fields according to type
    let typeData = typeDataFilter?.map((e) => {
      return tabData[activeTab][e];
    });

    // to covert the data in key value pairs (typeDataFilter's data is key) and (typeData's data is value)
    let filteredTypeData = typeInput?.fields?.map((e, i) => ({
      [typeInput.fields[i].backend_label]: typeData[i],
    }));

    // to convert all the data in one object so that we can pass directly to api
    let typeReduce = filteredTypeData?.reduce(
      (acc, obj) => ({ ...acc, ...obj }),
      {}
    );

    // creating data in required formate
    const data = {
      name: tabData[activeTab].rule,
      description: tabData[activeTab].description,
      type: "CUSTOM",
      // organizationId: "fb14f7c3-f791-4174-a813-570ba90cd213",
      conditions: [
        {
          entity: {
            entityId: 12,
            attributes: tabData[activeTab]?.whereSection?.map((e, i) => {
              return {
                conditionName: uuidv4(),
                conditionDescription: uuidv4(),
                attributeName: e.select1,
                attributeId: e.id,
                valueType: e.dataType,
                type: tabData[activeTab].whereSection[i].select2,
                value:
                  e.dataType == "NUMBER"
                    ? tabData[activeTab].whereSection[i].Amount
                    : e.dataType == "STRING"
                    ? tabData[activeTab].whereSection[i].stringValue
                    : tabData[activeTab].whereSection[i].arrayValue.split(",").map(value => value.trim())
                    // [
                    //     tabData[activeTab].whereSection[i].startDate,
                    //     tabData[activeTab].whereSection[i].endDate,
                    //   ],
              };
            }),
          },
        },
      ],
      effects: [
        {
          cashback_type: tabData[activeTab].type,
          ...typeReduce,
        },
      ],
    };

    // to check validation
    let flag = true;

    // Validation for select2 or 2nd field
    tabData[activeTab].whereSection.map((e, i) => {
      if (
        tabData[activeTab].whereSection[i].select2Option !== null &&
        !tabData[activeTab].whereSection[i].select2
      ) {
        flag = false;
        return;
      }
      // Validation for values or 3rd field
      if (
        (e.dataType == "NUMBER" &&
          !tabData[activeTab].whereSection[i].Amount) ||
        (e.dataType == "STRING" &&
          !tabData[activeTab].whereSection[i].stringValue) ||
        (e.dataType == "ARRAY" &&
          // !tabData[activeTab].whereSection[i].startDate &&
          // !tabData[activeTab].whereSection[i].startDate
          !tabData[activeTab].whereSection[i].arrayValue 
        )
      ) {
        flag = false;
        return;
      }
    });

    if (flag) {
      console.log(data, "data");

      handleSubmitRule(data);
    } else {
      toast.error("All fields are required");
      return;
    }
  };

  return (
    <Box
      sx={{
        justifyContent: "space-between",
        flexWrap: "wrap",
        mx: cancelButtonHide ? "30px" : "0px",
        my: cancelButtonHide ? "45px" : "0px",
        backgroundColor: cancelButtonHide ? "white" : "",
        padding: cancelButtonHide ? "24px 15px" : "0px",
      }}
    >
      {/* to show error tost/alert */}
      <ToastContainer />

      <Box>
        <Typography
          sx={{
            textAlign: "left",
            color: "var(--Font-Dark, #0F1828)",
            fontFamily: "Mulish",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "130%",
          }}
        >
          1. Rule Name
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: 5,
            alignItems: "end",
            flexWrap: "wrap",
          }}
        >
          <Box sx={{ width: "500px" }}>
            <MuiInput
              value={tabData[activeTab]?.rule}
              onChange={(e) => handleData("rule", e)}
              placeholder="40 characters"
              errorMessage={tabData[activeTab]?.error?.rule}
            />
          </Box>
        </Box>
      </Box>
      <Box>
        <Typography
          sx={{
            textAlign: "left",
            color: "var(--Font-Dark, #0F1828)",
            fontFamily: "Mulish",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "130%",
            mt: "18px",
          }}
        >
          2. Rule Description
        </Typography>
        <Box
          sx={{ display: "flex", gap: 5, alignItems: "end", flexWrap: "wrap" }}
        >
          <Box sx={{ width: "500px" }}>
            <MuiTextarea
              value={tabData[activeTab]?.description}
              onChange={(e) => handleData("description", e)}
              placeholder="200 characters"
              errorMessage={tabData[activeTab]?.error?.description}
            />
          </Box>
        </Box>
      </Box>

      <Typography
        sx={{
          textAlign: "left",
          color: "var(--Font-Dark, #0F1828)",
          fontFamily: "Mulish",
          fontSize: "20px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "24.2px%",
          mt: "18px",
          mb: "20px",
        }}
      >
        Type
      </Typography>

      <Box>
        <Box
          sx={{
            display: "flex",
            alignContent: "center",
            gap: 3,
            alignItems: "flex-start",
            flexWrap: "wrap",
          }}
        >
          <Box sx={{ width: "244px" }}>
            <Label label="Cashback" />
            <MuiSelect
              value={tabData[activeTab]?.type ?? ""}
              onChange={(e) => handleData("type", e)}
              options={options}
              errorMessage={tabData[activeTab]?.error?.type}
            />
          </Box>

          {/* dynamic input field  */}
          {/* {console.log(typeInput,"typeInput")} */}
          <Box marginBottom="4px">
            <Box display="flex" gap="14px">
              {typeInput?.fields?.map((field, index) => (
                <Box key={index} width="165px">
                  <Label label={field?.label} />
                  <MuiInput
                    placeholder={field?.label}
                    value={
                      tabData[activeTab]?.[
                        `${tabData[activeTab]?.type}_${field?.backend_label}`
                      ] ?? ""
                    }
                    onChange={(e) =>
                      handleData(
                        `${tabData[activeTab]?.type}_${field?.backend_label}`,
                        e
                      )
                    }
                    errorMessage={
                      tabData[activeTab]?.error?.[
                        `${tabData[activeTab]?.type}_${field?.backend_label}`
                      ]
                    }
                    type="number"
                  />
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>

      <Typography
        sx={{
          textAlign: "left",
          color: "var(--Font-Dark, #0F1828)",
          fontFamily: "Mulish",
          fontSize: "20px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "24.2px%",
          mt: "18px",
          mb: "20px",
        }}
      >
        Where
      </Typography>
      {/* Where Section */}
      <Box display="flex" gap={"10px"}>
        <Box>
          {whereSectionData[activeTab]?.map((e, i) => (
            <Box display="flex" gap="24px" alignItems="center" key={i}>
              <Box width="300px">
                <MuiSelectWithSearch
                  options={whereOption1 ?? []}
                  value={e?.select1 ?? ""}
                  onChange={(selectedOption) =>
                    handleDataChange(i, "select1", selectedOption)
                  }
                  attribute_entity="loyalty"
                  activeTab={activeTab}
                />
              </Box>

              {/* select2 */}
              <Box width="380px">
                <MuiSelect
                  options={e.select2Option ?? []}
                  value={e?.select2 ?? ""}
                  onChange={(selectedOption) =>
                    handleDataChange(i, "select2", selectedOption)
                  }
                />
              </Box>

              {/* value field or 3rd field */}
              {e.dataType === "ARRAY" ? (
                // <Box display="flex" gap="14px">
                //   <Box width="156px">
                //     <MUDatePicker
                //       value={e.startDate}
                //       placeholder="Start Date"
                //       onChange={(date) =>
                //         handleDataChange(i, "startDate", date)
                //       }
                //     />
                //   </Box>
                //   <Box>
                //     <Box
                //       width="38px"
                //       height="38px"
                //       marginTop="10px"
                //       border="1px solid gray"
                //       borderRadius="8px"
                //       display="flex"
                //       justifyContent="center"
                //       alignItems="center"
                //     >
                //       <img src={plusIcon} height="15px" width="15px" alt="" />
                //     </Box>
                //   </Box>
                //   <Box width="156px">
                //     <MUDatePicker
                //       value={e.endDate}
                //       placeholder="End Date"
                //       onChange={(date) => handleDataChange(i, "endDate", date)}
                //     />
                //   </Box>
                // </Box>
                <Box width="250px" marginBottom="4px">
                <MuiInput
                  placeholder="Array Value"
                  value={e.arrayValue ?? ""}
                  onChange={(event) =>
                    handleDataChange(i, "arrayValue", event)
                  }
                />
              </Box>

              ) : e.dataType == "STRING" ? (
                <Box width="250px" marginBottom="4px">
                  <MuiInput
                    placeholder="String Value"
                    value={e.stringValue ?? ""}
                    onChange={(event) =>
                      handleDataChange(i, "stringValue", event)
                    }
                  />
                </Box>
              ) : (
                <Box width="250px" marginBottom="4px">
                  <MuiInput
                    type="number"
                    placeholder="Add Amount"
                    value={e.Amount ?? ""}
                    onChange={(event) => handleDataChange(i, "Amount", event)}
                  />
                </Box>
              )}

              <Box
                height="36px"
                width="36px"
                borderRadius="50%"
                backgroundColor="#DEE0E3"
                onClick={() => handleDeleteWhereSection(i)}
                marginTop="10px"
                display={whereSectionData[activeTab]?.length === 1 ? "none" : "flex"}
                justifyContent="center"
                alignItems="center"
                color="black"
                sx={{ cursor: "pointer" }}
              >
                ✖
              </Box>
            </Box>
          ))}
        </Box>
        <Box marginTop="auto">
          <Box
            height="36px"
            width="36px"
            borderRadius="50%"
            backgroundColor="#DEE0E3"
            onClick={handleAddWhereSection}
            marginBottom="5px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ cursor: "pointer" }}
          >
            <img src={addBtn} width="15px" height="15px" alt="" />
          </Box>
        </Box>
      </Box>

      {/* Save and Cancel button */}
      <Box
        marginTop="auto"
        sx={{
          display: "flex",
          flexDirection: "row",
          pt: 2,
          paddingBottom: "37px",
        }}
      >
        <Button
          color="inherit"
          sx={{ mr: 1 }}
          style={{
            backgroundColor: "white",
            padding: "8px 30px",
            marginRight: "20px",
            display: cancelButtonHide ? "none" : "visible",
          }}
          variant="outlined"
          size="small"
          onClick={() => handleCancel(tabData[activeTab], activeTab)}
        >
          cancel
        </Button>
        <Button
          style={{
            backgroundColor: "black",
            color: "white",
            padding: "8px 30px",
          }}
          variant="contained"
          size="small"
          sx={{ mr: 1 }}
          onClick={handleSave}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
}
