import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
// plase provide status and funtion when we click model will know which funtion to hit
function CustomConfirmationDialog({
  open = false,
  isGroup = false,
  value,
  handleState = () => {},
  setOpen = () => {},
  status = "",
  deleteById = () => {},
  blockById = () => {},
  message = "",
  riderId,
  prop = false, // prop can we id or anyting you can pass if you want
}) {
  const handleCloseDialog = () => {
    if (isGroup) {
      handleState(status, false);
      return;
    }
    setOpen(false);
  };
  const handleConfirm = (e) => {
    if (status) {
      switch (status) {
        case "delete":
          deleteById(prop || e);
          break;
        case "block":
          blockById(prop || riderId || e);
          break;
        default:
          break;
      }
      if (isGroup) {
        handleState(status, false);
      } else {
        handleCloseDialog();
      }
    }
  };
  return (
    <Dialog
      open={open}
      style={{ zIndex: 2000, backgroundColor: "transparent" }}
      onClose={() => {
        if (isGroup) {
          handleState(status, false);
        } else {
          handleCloseDialog();
        }
      }}
    >
      <DialogTitle>Confirmation</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{ margin: "8px 10px" }}>
        <Button
          onClick={() => {
            if (isGroup) {
              handleState(status, false);
            } else {
              handleCloseDialog();
            }
          }}
          sx={{
            backgroundColor: "#fff",
            color: "black",
            border:"2px solid black",
            "&:hover": {
              backgroundColor: "white",
              opacity: 1,
            },
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={(e) => {
            handleConfirm(e);
          }}
          sx={{
            backgroundColor: "#000",
            color: "#fff",
            border:"2px solid black",
            "&:hover": {
              backgroundColor: "black",
              opacity: 1,
            },
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default CustomConfirmationDialog;
