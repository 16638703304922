import React from "react";
import { TextField } from "@mui/material";

const MuiInput = ({
  value,
  onChange,
  type = "text",
  placeholder,
  label = "",
  errorMessage = "",
  disable = false,
  mt = "10px",
  editBoolean,
}) => {
  const handleChange = (inputValue) => {
    if (type === "number") {
      // Ensure the input value is always positive for number type
      const numericValue = parseFloat(inputValue);
      if (!isNaN(numericValue) && numericValue >= 0) {
        onChange(numericValue);
      } else {
        onChange(""); // Set empty string for negative numbers
      }
    } else {
      onChange(inputValue);
    }
  };

  return (
    <TextField
      sx={{
        width: "100%",
        mt: mt,
        height: "32px", // Set height
        "& .MuiOutlinedInput-root": {
          height: "32px", // Set height for the input field
          "& input": {
            fontFamily: "Lato", // Set font family for input text
            fontWeight: 400, // Set font weight for input text
            fontSize: "12px", // Set font size for input text
            lineHeight: "16px", // Set line height for input text
            padding: "8px", // Adjust padding to fit the 32px height
          },
          "& fieldset": {
            borderColor: editBoolean ? "orange" : "", // Custom border color
            borderRadius: "6px", // Set border radius
          },
          "&:hover fieldset": {
            borderColor: editBoolean ? "orange" : "", // Border color on hover
          },
          "&.Mui-focused fieldset": {
            borderColor: editBoolean ? "orange" : "", // Border color when focused
          },
          "&.Mui-disabled": {
            opacity: 1, // Remove default opacity for disabled state
            "& fieldset": {
              borderColor: editBoolean ? "orange" : "", // Keep same border color for disabled
            },
          },
        },
        "& .MuiInputLabel-root": {
          fontFamily: "Lato",
          fontWeight: 400,
          fontSize: "12px",
          lineHeight: "16px",
          color: editBoolean ? "orange" : "",
          "&.Mui-focused": {
            color: editBoolean ? "orange" : "",
          },
          "&.Mui-disabled": {
            color: editBoolean ? "orange" : "#000000ba !important", // Keep label color same in disabled state
          },
        },
        "& .MuiInputBase-input::placeholder": {
          fontFamily: "Lato",
          fontWeight: 400,
          fontSize: "12px",
          lineHeight: "16px",
        },
        "& .Mui-disabled": {
          "-webkit-text-fill-color": "#000000ba !important", // Keep the same text color for disabled state
          backgroundColor: "transparent", // Ensure background remains transparent when disabled
        },
      }}
      type={type}
      value={value}
      disabled={disable}
      onChange={(e) => handleChange(e.target.value)}
      label={label}
      placeholder={placeholder}
      size="small"
      helperText={errorMessage}
      error={!!errorMessage}
      onWheel={() => document.activeElement.blur()} // Prevent number input scrolling
    />
  );
};

export default MuiInput;
