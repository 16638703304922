import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TextField, Button, Typography, Box, Stack } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import Label from "../UIComponents/Label";

const ForgotPassword = () => {
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const [email, setEmail] = useState("");

  const navigate = useNavigate();

  const handleFormSubmit = () => {
    setEmailSubmitted(true);
    console.log(email, "submitted");
  };

  const handleNavigateToLogin = () => {
    navigate("/login");
  };
  console.log(email);
  const titleStyle = {
    fontFamily: "ABeeZee",
    fontSize: "42px",
    fontStyle: "italic",
    fontWeight: 400,
    lineHeight: "50px",
    letterSpacing: "0em",
    textAlign: "left",
  };

  const subtitle = {
    fontSize: "20px",
    fontStyle: "italic",
    fontWeight: 500,
    lineHeight: "25px",
    letterSpacing: "0em",
    color: "#000000B2",
  };

  return (
    <>
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          backgroundColor: "#F7F9FC",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "570px",
            height: emailSubmitted ? "350px" : "480px",
            backgroundColor: "#fff",
            boxShadow: "0px 0px 16px 0px #0000000A",
            boxSizing: "border-box",
            padding: "30px 40px",
            borderRadius: "16px",
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            backgroundColor="white"
            gap="5px"
            mt="1.5%"
          >
            <Typography variant="h3" sx={titleStyle}>
              Gratify
            </Typography>
          </Box>
          <Typography variant="h4" sx={subtitle} textAlign="center" m="15px">
            Reset your password
          </Typography>

          {emailSubmitted ? (
            <Box>
              <Typography
                sx={{
                  marginTop: "30px",
                  fontFamily: "Mulish",
                  fontSize: "18px",
                  fontWeight: 400,
                  lineHeight: "23.4px",
                }}
                textAlign="center"
              >
                Kindly check your email for a link to reset your password. If
                you're unable to locate it in your main inbox, please ensure to
                inspect your spam or junk folder.
              </Typography>

              <div style={{ textAlign: "center" }}>
                <Button
                  variant="contained"
                  sx={{
                    width: "100%",
                    backgroundColor: "black",
                    height: "48px",
                    borderRadius: "10px",
                    mt: "38px",
                    "&:hover": {
                      backgroundColor: "#191919",
                    },
                  }}
                  //   type="submit"
                  onClick={handleNavigateToLogin}
                >
                  Back to Login
                </Button>
              </div>
            </Box>
          ) : (
            <Formik
              initialValues={{ email: "" }}
              validationSchema={yup.object().shape({
                email: yup.string().email().required("Email is required"),
              })}
              onSubmit={(values, { setSubmitting }) => {
                // setTimeout(() => {
                setEmail(values.email);
                setSubmitting(false);
                handleFormSubmit();
                // }, 400);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  {/* {console.log((!errors.email &&values.email.length>0 ),"values")} */}
                  <Typography
                    sx={{
                      marginTop: "30px",
                      fontFamily: "Mulish",
                      fontSize: "18px",
                      fontWeight: 400,
                      lineHeight: "23.4px",
                    }}
                    textAlign="center"
                    // m="5px"
                  >
                    {(!errors.email && values.email.length) > 0
                      ? "Check email for password reset link. If not there, check spam."
                      : "Enter your email id linked with your account where we will send you a password reset link."}
                  </Typography>

                  <Stack spacing={2} sx={{ marginTop: "30px" }}>
                    <div className="email-field">
                      <Label label="Email address" />
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="Email"
                        variant="outlined"
                        sx={{ marginTop: "10px" }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        name="email"
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                      />
                    </div>
                  </Stack>
                  <div className="login-btn" style={{ textAlign: "center" }}>
                    <Button
                      variant="contained"
                      sx={{
                        width: "100%",
                        backgroundColor: "black",
                        height: "48px",
                        borderRadius: "10px",
                        mt: "58px",
                        "&:hover": {
                          backgroundColor: "#191919",
                        },
                      }}
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Send Link
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
          )}
        </Box>
      </Box>
    </>
  );
};

export default ForgotPassword;
