import { Box, MenuItem, Select, Stack } from "@mui/material";
import React from "react";
import DatePicker from "react-multi-date-picker";
import { formatDateNew } from "../utils/formateDate";
import TimePicker from "react-multi-date-picker/plugins/time_picker";


const DateFilterPanel = ({
  filterSelectOptions = [],
  activeButton,
  handleDateFilter,
  dateFilter,
}) => {
  // css
  const containerStyle = {
    width: "320px",
    height: "45px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    // gap: "10px",
    borderRadius: "5px",
    padding: "0px 6px",
    border: "1px solid #D9D9D9",
    boxSizing: "content-box",
    background: "#fff",
    // marginRight:"20px",
  };

  // Inline styles for disabled DatePicker
  const disabledDatePickerStyles = {
    color: "gray",
  };

  return (
    <Box sx={containerStyle}>
      <Select
        displayEmpty
        key={activeButton}
        sx={{
          height: "38px",
          padding: "0",
          margin: "0",
          color: "#6A6A6A",
          fontWeight: 500,
          fontSize: "14px",
          lineHeight: "17.05px",
        outline: "none",
          boxShadow: "none",
          ".MuiOutlinedInput-notchedOutline": { border: 0 },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "0",
          },
        }}
        name="select"
        value={dateFilter[activeButton]?.select ?? ""}
        onChange={(e) => {
          const { name, value } = e.target;
          handleDateFilter(activeButton, name, value);
        }}
      >
        <MenuItem value="" disabled>
          --Select--
        </MenuItem>
        {filterSelectOptions.length > 0 &&
          filterSelectOptions.map((res) => (
            <MenuItem key={res?.label} value={res?.value}>
              {res?.label}
            </MenuItem>
          ))}
      </Select>

      <Box>
        <DatePicker
          range
          rangeHover
          dateSeparator=" ~ "
          disabled={dateFilter[activeButton]?.select !== "CUSTOM"}
          value={[
            dateFilter[activeButton]?.from_time
              ? new Date(dateFilter[activeButton].from_time)
              : "",
            dateFilter[activeButton]?.to_time
              ? new Date(dateFilter[activeButton].to_time)
              : "",
          ]}
          style={{
            fontSize: "16px",
            padding: "10px",
            ...(dateFilter[activeButton]?.select !== "CUSTOM" &&
              disabledDatePickerStyles),
              width: "180px",
          }}
          placeholder="Start Date ~ End Date"
          onChange={(value) => {
            if (value) {
              const startDate = value[0] ? formatDateNew(value[0]) : "";
              const endDate = value[1] ? formatDateNew(value[1]) : "";
              handleDateFilter(activeButton, "from_time", startDate);
              handleDateFilter(activeButton, "to_time", endDate);
            }
          }}
          format="DD:MM:YYYY"
          editable={false}
          // typingTimeout={900}
          position="bottom-end"
          containerStyle={{
            // Add styles for the container element
          }}
          inputClass="rmdp-input-centered"
        />
      </Box>

      {/* Add a <style> tag to define the custom CSS */}
      <style>{`
        .rmdp-input-centered::placeholder {
          text-align: center;
          color: inherit;
        }
      `}</style>
    </Box>
  );
};

export default DateFilterPanel;