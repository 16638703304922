export const initialStateOverviewFilter = {
  totalReward: "",
  rewardLevel: "",
};

export const initialStateRewardLevelFilter = {
  totalReward: "",
  rewardLevel: "",
};

export const initialStateOfferLevel = {
  category: [],
  subCategory: [],
  merchant : [],
  product : []
};

export const initialStateUserLevelFilter = {
  totalReward: "",
  rewardLevel: "",
};
