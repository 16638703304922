import React, { useEffect, useState } from "react";
import { Grid, Box, Button, Drawer } from "@mui/material";
import Label from "../../UIComponents/Label";
import ImageUpload from "../../UIComponents/ImageUpload";
import MuiInput from "../../UIComponents/MuiInput";
import { Divider, Typography } from "@mui/material";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Coupon } from "./ScratchCardPreview";
import ReactSlickCarousel from "../ReactSlickCarousel";
import ScratchCardUnlocked from "../scratchcard/ScratchCardUnlocked";
import WelcomeScreen from "../scratchcard/WelcomeScreen";
import playLogo from "../../assets/playLogo.png";
import closeBtnLogo from "../../assets/crossBtnBlack.png"
import CreateGameV2 from "./CreateGameV2";
import { getAllGameTypes } from "../../Redux/OfferReducer/action";

const CreateGame = ({
  data,
  onChange,
  errorMessage,
  handleCreateGameError,
  merchantLogoImg,
  handleMerchantLogoImg,
  handleScratchCardImage,
  scratchCardImage,
  activeStep,
  isEdit = false,
  handleNext,
  handleBack,
}) => {
  const [loader, setLoader] = useState(false);
  const [scratchCardLoader, setScratchCardLoader] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const token = useSelector((store) => store.AuthReducer.token);
  const BaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [merchantLogo, setMerchangtLogo] = useState("");
  const [scratchCardColor, setScratchCardColor] = useState("");
  const [scratchCard, setScratchCard] = useState("");
  const [previewOpen, setPreviewOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const dispatch = useDispatch();
  const carouselSettings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
  };

  // Function to handle opening the drawer
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  // Function to handle closing the drawer
  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };
  const handleData = (key, value) => {
    if (errorMessage?.[key]) {
      handleCreateGameError((prev) => {
        return {
          ...prev,
          [key]: "",
        };
      });
    }

    if (key === "merchantLogo") {
      setLoader(true);

      const formData = new FormData();
      formData.append("file", value);
      axios
        .post(`${BaseUrl}/api/upload`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log("Upload successful games", response.data.public_url);
          // setMerchangtLogo(response.data?.public_url);
          onChange({
            ...data,
            merchantLogoId: response.data.id,
            [key]: value,
            merchantLogoUrl: response.data?.public_url,
          });
        })
        .catch((error) => {
          console.error("Error uploading file:", error);
        })
        .finally(() => {
          setLoader(false);
        });
    } else if (key === "scratchCardImage") {
      setScratchCardLoader(true);

      const formData = new FormData();
      formData.append("file", value);
      axios
        .post(`${BaseUrl}/api/upload`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log("Upload successful games", response.data.public_url);
          // setScratchCard(response.data?.public_url);
          onChange({
            ...data,
            scratchCardImageId: response.data.id,
            [key]: value,
            scratchCardImageUrl: response.data?.public_url,
          });
        })
        .catch((error) => {
          console.error("Error uploading file:", error);
        })
        .finally(() => {
          setScratchCardLoader(false);
        });
    }else if (key === "scratchCardColor") {
      if (!value) {
        onChange({ ...data, scratchCardColor: "" });
      } else {
        if (!value.startsWith("#")) {
          value = `#${value}`;
        }
        onChange({ ...data, scratchCardColor: value });
      }
    } 
    else if (key === "offerTitleColor") {
      if (!value) {
        onChange({ ...data, offerTitleColor: "" });
      } else {
        if (!value.startsWith("#")) {
          value = `#${value}`;
        }
        onChange({ ...data, offerTitleColor: value });
      }
    } 
     else {
      onChange({ ...data, [key]: value });
    }
  };
// useEffect(()=>{
//   dispatch(getAllGameTypes(1,5))
// },[])
  console.log(data, "createGame");
  return (
    <>
    <CreateGameV2 activeStep={activeStep} data={data} onChange={onChange} errorMessage={errorMessage} handleNext={handleNext} handleBack={handleBack}/>
    </>
    // <Box paddingRight="20px" pt={"20px"}>
      
    //   {/* {!isEdit && (
    //     <Box sx={{ m: 1 }}>
    //       <Typography
    //         sx={{
    //           textAlign: "left",
    //           mb: "4px",
    //           color: "#26292D",
    //           fontFamily: "Mulish",
    //           fontSize: "22px",
    //           fontStyle: "normal",
    //           fontWeight: 600,
    //           lineHeight: "130%",
    //         }}
    //       >
    //         Scratch Card {">"} Create
    //       </Typography>
    //       <Divider sx={{ mb: "20px" }} />
    //     </Box>
    //   )} */}

    //   <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
    //     <Grid item xs={12} sm={12} md={6}>
    //       <Box
    //         sx={{
    //           marginRight: { sx: "0px", sm: "0px", md: "70px" },
    //           marginBottom: "20px",
    //         }}
    //       >
    //         <Label label="1. Merchant Logo" />
    //         <ImageUpload
    //           value={data?.merchantLogo}
    //           onChange={(value) => handleData("merchantLogo", value)}
    //           image={merchantLogoImg}
    //           handleImage={(value) => {
    //             onChange({
    //               ...data,
    //               merchantLogoId: value,
    //               merchantLogoUrl: value,
    //               merchantLogo: value,
    //             });
    //             handleMerchantLogoImg(value);
    //             // console.log(value, "merValue");
    //             // handleData("merchantLogoId", value);
    //             // handleData("merchantLogoUrl", value);
    //             // handleData("merchantLogo", value);
    //           }}
    //           maxSize="2MB"
    //           ratio="1:1"
    //           errorMessage={errorMessage?.merchantLogo}
    //           loader={loader}
    //         />
    //       </Box>
    //       <Box
    //         sx={{
    //           marginRight: { sx: "0px", sm: "0px", md: "70px" },
    //           marginBottom: "20px",
    //         }}
    //       >
    //         <Label label="2. Scratch Card Image" />
    //         <ImageUpload
    //           loader={scratchCardLoader}
    //           value={data?.scratchCardImage}
    //           onChange={(value) => handleData("scratchCardImage", value)}
    //           maxSize="2MB"
    //           ratio="4:3"
    //           errorMessage={errorMessage?.coverPhoto}
    //           image={scratchCardImage}
    //           handleImage={(value) => {
    //             onChange({
    //               ...data,
    //               scratchCardImageId: value,
    //               scratchCardImageUrl: value,
    //               scratchCardImage: value,
    //             });
    //             handleScratchCardImage(value);
    //             // handleData("scratchCardImage", value);
    //             // handleData("scratchCardImageUrl", value);
    //           }}
    //           disable={data?.scratchCardColor}
    //         />
    //       </Box>
    //       <Typography
    //         sx={{
    //           color: "#272728",
    //           fontFamily: "Outfit",
    //           fontSize: "16px",
    //           fontStyle: "normal",
    //           fontWeight: 600,
    //           lineHeight: "23px",
    //           mt: "20px",
    //           mb: "3px",
    //           textAlign: "center",
    //           marginRight: "70px",
    //         }}
    //       >
    //         OR
    //       </Typography>
    //       <Box
    //         sx={{
    //           marginRight: { sx: "0px", sm: "0px", md: "70px" },
    //           marginBottom: "20px",
    //         }}
    //       >
    //         <Box sx={{ mb: 3 }}>
    //           <Label label="Scratch Card Color" />
    //           <MuiInput
    //             disable={data.scratchCardImageUrl}
    //             value={data?.scratchCardColor}
    //             onChange={(value) => handleData("scratchCardColor", value)}
    //             placeholder="Enter HEX Code #"
    //             errorMessage={errorMessage?.scratchCardColor}
    //           />
    //           <Typography
    //             sx={{
    //               fontFamily: "Mulish",
    //               fontWeight: "400",
    //               fontSize: "12px",
    //               lineHeight: "15.06px",
    //               textAlign: "start",
    //               margin: "8px",
    //               color: "#6F747E",
    //             }}
    //           >
    //             Tips: Black #000000, Red #F1554C
    //           </Typography>
    //         </Box>
    //         <Box>
    //           <Label label="3. Offer Title Color" />
    //           <MuiInput
    //             value={data?.offerTitleColor}
    //             onChange={(value) => handleData("offerTitleColor", value)}
    //             placeholder="Enter HEX Code #"
    //             errorMessage={errorMessage?.offerTitleColor}
    //           />
    //         </Box>
    //       </Box>
    //     </Grid>
    //     <Grid item xs={12} sm={12} md={6}>
    //       <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose}>
    //         <Typography
    //           sx={{
    //             position: "fixed",
    //             top: "8%",
    //             transform: "translateY(-50%)",
    //             right: "178px",
    //             color: "#fff",
    //             zIndex: 8,
    //             // background: "#fff",
    //           }}
    //         >{`(${index + 1}/${3})`}</Typography>
    //         <Box
    //           sx={{
    //             marginTop: "20px",
    //             position: "fixed",
    //             top: "40%",
    //             transform: "translateY(-50%)",
    //             right: "82px",
    //             // display:activeStep===2?"visible":"none",
    //           }}
    //         >
    //           <ReactSlickCarousel
    //             max={2}
    //             setCurrentIndex={setIndex}
    //             settings={carouselSettings}
    //           >
    //             <Box>
    //               <WelcomeScreen />
    //             </Box>
    //             <Box>
    //               <Coupon data={data} />
    //             </Box>
    //             <Box>
    //               <ScratchCardUnlocked AllData={data} />
    //             </Box>
    //           </ReactSlickCarousel>
    //           <Box
    //             sx={{
    //               marginTop: "20px",
    //               position: "fixed",
    //               top: "83%",
    //               transform: "translateY(-50%)",
    //               right: "58px",
    //               marginRight: "15px",
    //               // display:activeStep===2?"visible":"none",
    //             }}
    //           >
    //             <Button
    //               color="inherit"
    //               sx={{ mr: 1 }}
    //               onClick={() => handleDrawerClose()}
    //               style={{
    //                 marginTop: "-5px",
    //                 backgroundColor: "white",
    //                 color: "black",
    //                 padding: "8px 20px",
    //                 // marginRight: "20px",
    //                 // display:previewOpen?"block": "none",
    //                 marginLeft: "auto",
    //                 marginBottom: "-50px",
    //                 // marginTop:"auto",
    //                 height: "42px",
    //                 fontFamily: "Mulish",
    //                 fontWeight:700,
    //                 fontSize: "16px",
    //                 borderRadius:"7px",
    //               }}
    //               variant="outlined"
    //               size="small"
    //             >
    //               Close Preview <span >
    //               <img style={{marginBottom:"-2px",marginLeft:"12px"}} src={closeBtnLogo} alt="" />
    //             </span>
    //               {/* {previewOpen?"Close Preview" :"Preview" } */}
    //             </Button>
    //           </Box>
    //         </Box>
    //       </Drawer>
    //     </Grid>
    //     <Button
    //       color="inherit"
    //       sx={{ mr: 1 }}
    //       onClick={() => handleDrawerOpen()}
    //       style={{
    //         marginTop: "20px",
    //         backgroundColor: "white",
    //         color: "black",
    //         padding: "8px 20px",
    //         // marginRight: "20px",
    //         // display:previewOpen||activeStep!==2?"none": "block",
    //         visibility: drawerOpen ? "hidden" : "visible",
    //         marginLeft: "auto",
    //         marginBottom: activeStep === 2 ? "-50px" : "-35px",
    //         height: "40px",
    //         fontFamily: "Mulish",
    //         fontWeight:700,
    //         fontSize: "16px",
    //         borderRadius:"7px",
    //         // marginTop:"auto",
    //       }}
    //       variant="outlined"
    //       size="small"
    //     >
    //       Preview <span>
    //           <img style={{marginLeft:"16px",marginTop:"10px"}} src={playLogo} alt="" />
    //         </span>
    //       {/* {previewOpen?"Close Preview" :"Preview" } */}
    //     </Button>
    //   </Grid>
    // </Box>
  );
};

export default CreateGame;
