/**
 * Formats a number into a shortened format with suffixes (e.g., "k" for thousands, "M" for millions).
 *
 * @param {number} num - The number to be formatted.
 * @returns {string} The formatted number with an appropriate suffix.
 */

function formatNumberShort(num = 0) {
    const suffixes = ["", "k", "M", "B", "T"];
  
    if (num < 1000) {
      return num;
    }
  
    let i = 0;
    while (num >= 1000 && i < suffixes.length - 1) {
      num /= 1000;
      i++;
    }
  
    if (i === 0) {
      return num.toString();
    }
  
    return num.toFixed(1) + suffixes[i];
  }
  
  export default formatNumberShort;
  