import { Search } from "@mui/icons-material";
import { InputAdornment, OutlinedInput, SvgIcon } from "@mui/material";
import React from "react";
import { debounce } from "../utils/debonce";

const QueryInput = ({ value, setState, placeholder, handleSearch ,hitEnter }) => {
  const handleKeyPress = debounce((e) => {
    if(hitEnter) {
      if (e.key === "Enter") {
        handleSearch(e.target.value);
      }
    }
  }, 500);

  return (
    <>
      <OutlinedInput
        defaultValue=""
        value={value}
        onChange={(e) => setState(e.target.value)}
        onKeyDown={handleKeyPress}
        placeholder={placeholder ?? `Search Customer, Phone no, Email`}
        startAdornment={
          <InputAdornment position="start">
            <SvgIcon fontSize="small">
              <Search />
            </SvgIcon>
          </InputAdornment>
        }
        sx={{
          width: "273px",
          borderRadius: "6px",
          height: "30px",
          background: "#FFF",
          "& .MuiOutlinedInput-input": {
            padding: "9px 0px 9px 0px",
          fontFamily:"Lato",
          fontSize: "14px",
          fontWeight: "400",
          lineHeight: "16px",
          },
          "& .MuiInputBase-input::placeholder": {
            fontFamily:"Lato",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "16px",
          },
        }}
      />
    </>
  );
};

export default QueryInput;
