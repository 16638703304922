import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import BackButtonImg from "../../assets/backArrow.png";
import sampleCsv from "../../assets/Voucher.csv";
import pencilImg from "../../assets/edit_black.png";
import uploadImg from "../../assets/uploadImg.png";
import UserCohortsFileUpload from "../../components/userCohorts/UserCohortsFileUpload";
import { useSelector } from "react-redux";
import axios from "axios";
import { options } from "sanitize-html";
import ManualInputQuestion from "./Trivia/ManualInputQuestion";
import Rewards_Trivia from "./Trivia/Rewards_Trivia";

const fields = {
  before_play: [
    {
      label: "Reward Title",
      backend_label: "title",
      mandatory: true,
      input_type: "input_box",
    },
    {
      label: "Title Color",
      backend_label: "title_color",
      mandatory: true,
      input_type: "color_picker",
    },
    {
      label: "CTA Text",
      backend_label: "cta_text",
      mandatory: true,
      input_type: "input_box",
    },
    {
      label: "CTA Color",
      backend_label: "cta_color",
      mandatory: true,
      input_type: "color_picker",
    },
    {
      label: "CTA Text Color",
      backend_label: "cta_text_color",
      mandatory: true,
      input_type: "color_picker",
    },
    {
      label: "Background",
      backend_label: "reward_background",
      mandatory: true,
      input_type: "image_and_color_picker",
      options: [
        {
          label: "Color",
          value: "color",
          backend_label: "background_color",
          mandatory: true,
        },
        {
          label: "Image",
          value: "image",
          backend_label: "background_image",
          mandatory: true,
          options: [
            "https://images.unsplash.com/photo-1653489218319-0e59c55ddc68?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
            "https://plus.unsplash.com/premium_photo-1694004709804-e51d0c54f57f?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
            "https://plus.unsplash.com/premium_photo-1681533650874-9a0c01a7ee53?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fHNjcmF0Y2glMjBjYXJkfGVufDB8fDB8fHww",
          ],
        },
      ],
    },
  ],
  while_playing: [
    {
      label: "Question Title",
      backend_label: "question_title",
      mandatory: true,
      input_type: "input_box",
    },
    {
      label: "Title Color",
      backend_label: "title_color",
      mandatory: true,
      input_type: "color_picker",
    },
    {
      label: "Answer Text",
      backend_label: "answer_text",
      mandatory: true,
      input_type: "input_box",
    },
    {
      label: "Answer Text Color",
      backend_label: "answer_text_color",
      mandatory: true,
      input_type: "color_picker",
    },
    {
      label: "CTA Text",
      backend_label: "cta_text",
      mandatory: true,
      input_type: "input_box",
    },
    {
      label: "CTA Color",
      backend_label: "cta_color",
      mandatory: true,
      input_type: "color_picker",
    },
    {
      label: "CTA Text Color",
      backend_label: "cta_text_color",
      mandatory: true,
      input_type: "color_picker",
    },
    {
      label: "Set Timer",
      backend_label: "timer",
      mandatory: false,
      input_type: "input_box",
    },
    {
      label: "Background",
      backend_label: "reward_background",
      mandatory: true,
      input_type: "image_and_color_picker",
      options: [
        {
          label: "Color",
          value: "color",
          backend_label: "background_color",
          mandatory: true,
        },
        {
          label: "Image",
          value: "image",
          backend_label: "background_image",
          mandatory: true,
          options: [
            "https://images.unsplash.com/photo-1653489218319-0e59c55ddc68?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
            "https://plus.unsplash.com/premium_photo-1694004709804-e51d0c54f57f?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
            "https://plus.unsplash.com/premium_photo-1681533650874-9a0c01a7ee53?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fHNjcmF0Y2glMjBjYXJkfGVufDB8fDB8fHww",
          ],
        },
      ],
    },
  ],
  after_play: [
    {
      label: "Reward Title",
      backend_label: "title",
      mandatory: true,
      input_type: "input_box",
    },
    {
      label: "Title Color",
      backend_label: "title_color",
      mandatory: true,
      input_type: "color_picker",
    },
    {
      label: "Reward Title (Only for No Reward)",
      backend_label: "no_reward_title",
      mandatory: true,
      input_type: "input_box",
    },
    {
      label: "Title Color (Only for No Reward)",
      backend_label: "no_reward_title_color",
      mandatory: true,
      input_type: "color_picker",
    },
    {
      label: "Card",
      backend_label: "card_background",
      mandatory: true,
      input_type: "image_and_color_picker",
      options: [
        {
          label: "Color",
          value: "color",
          backend_label: "background_color",
          mandatory: true,
        },
        {
          label: "Image",
          value: "image",
          backend_label: "background_image",
          mandatory: true,
          options: [
            "https://images.unsplash.com/photo-1653489218319-0e59c55ddc68?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
            "https://plus.unsplash.com/premium_photo-1694004709804-e51d0c54f57f?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
            "https://plus.unsplash.com/premium_photo-1681533650874-9a0c01a7ee53?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fHNjcmF0Y2glMjBjYXJkfGVufDB8fDB8fHww",
          ],
        },
      ],
    },
    {
      label: "Background",
      backend_label: "reward_background",
      mandatory: true,
      input_type: "image_and_color_picker",
      options: [
        {
          label: "Color",
          value: "color",
          backend_label: "background_color",
          mandatory: true,
        },
        {
          label: "Image",
          value: "image",
          backend_label: "background_image",
          mandatory: true,
          options: [
            "https://images.unsplash.com/photo-1653489218319-0e59c55ddc68?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
            "https://plus.unsplash.com/premium_photo-1694004709804-e51d0c54f57f?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
            "https://plus.unsplash.com/premium_photo-1681533650874-9a0c01a7ee53?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fHNjcmF0Y2glMjBjYXJkfGVufDB8fDB8fHww",
          ],
        },
      ],
    },
  ],
};

export default function Trivia({
  data,
  onChange,
  errorMessage,
  // rewardArray = array,
  handleBack,
  handleNext,
  setGameType,
  gameType
}) {
  const [isEdit, setIsEdit] = useState(false);
  const [step, setStep] = useState(1);
  const token = useSelector((store) => store.AuthReducer.token);
  const BaseUrl = process.env.REACT_APP_API_BASE_URL;
  const fields = useSelector(store=>store?.OfferReducer?.allGames)?.find((e) => e?.name === gameType)?.design_fields;
  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = sampleCsv;
    link.download = "Voucher.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleNextStep = () => {
    if (step < 5) {
      setStep(step + 1);
    } else {
      handleNext();
    }
  };

  const handleBackStep = () => {
    if (step > 1) {
      setStep(step - 1);
    } else {
      handleBack();
    }
  };

  const handleData = (field, value) => {
    if (field === "csvFile" && value) {
      // Check if the selected file is a CSV file
      console.log(field, value.type, "csvFile");
      if (value.type !== "text/csv") {
        onChange({
          ...data,
          game: {
            ...data.game,
            triviaData_step1: {
              ...data.game.triviaData_step1,
              csvError: "Please select a CSV file",
            },
          },
        });
        return;
      } else {
        onChange({
          ...data,
          game: {
            ...data.game,
            triviaData_step1: {
              ...data.game.triviaData_step1,
              csvError: "",
            },
          },
        });
      }

      // Upload CSV file
      // setLoader(true);
      const formData = new FormData();
      formData.append("file", value);
      axios
        .post(`${BaseUrl}/api/upload`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log("CSV upload successful", response.data);
          onChange({
            ...data,
            game: {
              ...data.game,
              triviaData_step1: {
                ...data.game.triviaData_step1,
                [field]: value,
                csvUrl: response.data?.public_url,
              },
            },
          });
        })
        .catch((error) => {
          console.error("Error uploading CSV file:", error);
          onChange({
            ...data,
            game: {
              ...data.game,
              triviaData_step1: {
                ...data.game.triviaData_step1,
                csvError: "Error uploading CSV file",
              },
            },
          });
        });
      // .finally(() => {
      //   setLoader(false);
      // });
    } else {
      onChange({
        ...data,
        game: {
          ...data.game,
          triviaData_step1: {
            ...data.game.triviaData_step1,
            [field]: value,
          },
        },
      });
    }
  };

  const handleQuestionData = (field, value, index) => {
    const updatedQuestions = [...data.game.triviaData_step1.questions];
    updatedQuestions[index] = {
      ...updatedQuestions[index],
      [field]: value,
    };

    onChange({
      ...data,
      game: {
        ...data.game,
        triviaData_step1: {
          ...data.game.triviaData_step1,
          questions: updatedQuestions,
        },
      },
    });
  };

  const handleQuestionOptionData = (
    field,
    event,
    questionIndex,
    optionIndex
  ) => {
    const updatedQuestions = [...data.game.triviaData_step1.questions];
    const updatedOptions = [...updatedQuestions[questionIndex].options];

    if (field === "isSelected") {
      // Unselect all options
      updatedOptions.forEach((option) => (option.isSelected = false));
      // Select the clicked option
      updatedOptions[optionIndex].isSelected = true;
    } else {
      updatedOptions[optionIndex] = {
        ...updatedOptions[optionIndex],
        [field]: event,
      };
    }

    updatedQuestions[questionIndex] = {
      ...updatedQuestions[questionIndex],
      options: updatedOptions,
    };

    onChange({
      ...data,
      game: {
        ...data.game,
        triviaData_step1: {
          ...data.game.triviaData_step1,
          questions: updatedQuestions,
        },
      },
    });
  };

  const handleAddOption = (questionIndex) => {
    const updatedQuestions = [...data.game.triviaData_step1.questions];
    const updatedOptions = [...updatedQuestions[questionIndex].options];

    // Add a new option
    updatedOptions.push({
      optionTitle: "",
      isSelected: false,
    });

    updatedQuestions[questionIndex] = {
      ...updatedQuestions[questionIndex],
      options: updatedOptions,
    };

    onChange({
      ...data,
      game: {
        ...data.game,
        triviaData_step1: {
          ...data.game.triviaData_step1,
          questions: updatedQuestions,
        },
      },
    });
  };

  const handleDeleteOption = (questionIndex, optionIndex) => {
    const updatedQuestions = [...data.game.triviaData_step1.questions];
    const updatedOptions = [...updatedQuestions[questionIndex].options];

    // Remove the option at the specified index
    updatedOptions.splice(optionIndex, 1);

    // If the deleted option was selected, select the first option (if it exists)
    if (
      updatedOptions.length > 0 &&
      !updatedOptions.some((opt) => opt.isSelected)
    ) {
      updatedOptions[0].isSelected = true;
    }

    updatedQuestions[questionIndex] = {
      ...updatedQuestions[questionIndex],
      options: updatedOptions,
    };

    onChange({
      ...data,
      game: {
        ...data.game,
        triviaData_step1: {
          ...data.game.triviaData_step1,
          questions: updatedQuestions,
        },
      },
    });
  };

  const handleAddImage = (field, file, questionIndex) => {
    // setLoader(true);
    const formData = new FormData();
    formData.append("file", file);

    axios
      .post(`${BaseUrl}/api/upload`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log("Upload successful", response.data);

        const updatedData = { ...data };
        const updatedQuestions = [
          ...updatedData.game.triviaData_step1.questions,
        ];

        if (!updatedQuestions[questionIndex].images) {
          updatedQuestions[questionIndex].images = [];
        }

        updatedQuestions[questionIndex].images.push(response.data.public_url);

        updatedData.game.triviaData_step1.questions = updatedQuestions;

        onChange(updatedData);
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
        // You can add error handling here, e.g., showing a toast message
        // toast.error("Error uploading file");
      });
    // .finally(() => {
    //   setLoader(false);
    // });
  };

  const handleDeleteImage = (questionIndex, imageIndex) => {
    const updatedData = { ...data };
    const updatedQuestions = [...updatedData.game.triviaData_step1.questions];

    updatedQuestions[questionIndex].images.splice(imageIndex, 1);

    updatedData.game.triviaData_step1.questions = updatedQuestions;

    onChange(updatedData);
  };

  const handleAddNewQuestion = () => {
    const newQuestion = {
      title: "",
      addMedia: false,
      images: [],
      options: [{ optionTitle: "", isSelected: false }],
    };

    const updatedData = {
      ...data,
      game: {
        ...data.game,
        triviaData_step1: {
          ...data.game.triviaData_step1,
          questions: [...data.game.triviaData_step1.questions, newQuestion],
        },
      },
    };

    onChange(updatedData);
  };

  return (
    <Box>
      <Box>
        <Box sx={{ display: step === 1 ? "block" : "none" }}>
          <Box
            sx={{
              display: isEdit ? "none" : "flex",
              justifyContent: "start",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Button sx={{ minWidth: "0px" }} onClick={() => setGameType("")}>
                <img src={BackButtonImg} alt="" />
              </Button>
              <Typography
                sx={{
                  fontFamily: "Mulish",
                  fontWeight: 700,
                  fontSize: "20px",
                  lineHeight: "26px",
                  color: "#0F1828",
                  // ml: "12px",
                }}
              >
                Trivia
              </Typography>
            </Box>
          </Box>
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "400px",
                mt: "20px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Mulish",
                  fontWeight: 600,
                  fontSize: "16px",
                  lineHeight: "20px",
                  color: "#0F1828",
                }}
              >
                Create Quiz
              </Typography>
              <Typography
                onClick={handleDownload}
                style={{
                  fontFamily: "Mulish",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "17.57px",
                  color: "#0F1828",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                Download Sample
              </Typography>
            </Box>

            <Box>
              <Box sx={{ width: "400px", display: "flex", gap: "10px" }}>
                <Button
                  sx={{
                    border:
                      data?.game?.triviaData_step1.triviaType === "manualInput"
                        ? "1px solid #E3E7EF"
                        : "1px solid #272728",
                    width: "100%",
                    borderRadius: "6px",
                    height: "42px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    mt: "20px",
                    backgroundColor:
                      data?.game?.triviaData_step1.triviaType === "manualInput"
                        ? ""
                        : "#e8effb",
                  }}
                  onClick={() => handleData("triviaType", "uploadCsv")}
                >
                  <img src={uploadImg} alt="" />
                  <Typography
                    sx={{
                      fontFamily: "Mulish",
                      fontWeight: 600,
                      fontSize: "14px",
                      lineHeight: "17.57px",
                      color: "#272728",
                      ml: "10px",
                      textTransform: "capitalize",
                    }}
                  >
                    Upload CSV
                  </Typography>
                </Button>

                <Button
                  sx={{
                    border:
                      data?.game?.triviaData_step1.triviaType === "manualInput"
                        ? "1px solid #272728"
                        : "1px solid #E3E7EF",
                    width: "100%",
                    borderRadius: "6px",
                    height: "42px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    mt: "20px",
                    backgroundColor:
                      data?.game?.triviaData_step1.triviaType === "manualInput"
                        ? "#e8effb"
                        : "",
                  }}
                  onClick={() => handleData("triviaType", "manualInput")}
                >
                  <img src={pencilImg} alt="" />
                  <Typography
                    sx={{
                      fontFamily: "Mulish",
                      fontWeight: 600,
                      fontSize: "14px",
                      lineHeight: "17.57px",
                      color: "#272728",
                      ml: "10px",
                      textTransform: "capitalize",
                    }}
                  >
                    Manual Input
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
        {step === 1 ? (
          data?.game?.triviaData_step1.triviaType === "manualInput" ? (
            <Box>
              <ManualInputQuestion
                data={data}
                handleQuestionData={handleQuestionData}
                handleQuestionOptionData={handleQuestionOptionData}
                handleAddOption={handleAddOption}
                handleDeleteOption={handleDeleteOption}
                onChange={onChange}
                handleAddImage={handleAddImage}
                handleDeleteImage={handleDeleteImage}
                handleAddNewQuestion={handleAddNewQuestion}
              />
            </Box>
          ) : (
            <Box sx={{ width: "400px", mt: "20px" }}>
              <UserCohortsFileUpload
                data={data?.game?.triviaData_step1}
                textVisibility={false}
                onChange={(key, value) => handleData(key, value)}
                errorMessage={data?.game?.triviaData_step1?.csvError}
              />
            </Box>
          )
        ) : (
          <Box>
            <Rewards_Trivia
              data={data}
              onChange={onChange}
              handleBack={handleBack}
              handleNext={handleNext}
              setGameType={setGameType}
              setStep={setStep}
              step={step}
              fields={fields}
            />
          </Box>
        )}
      </Box>

      <Box
        sx={{
          display: step < 2 ? "flex" : "none",
          flexDirection: "row",
          pt: 2,
          // px: "15px",
          paddingBottom: "37px",
        }}
      >
        <Button
          color="inherit"
          onClick={handleBackStep}
          sx={{ mr: 1 }}
          style={{
            backgroundColor: "white",
            color: "black",
            padding: "8px 30px",
            marginRight: "20px",
            fontFamily: "Mulish",
            fontWeight: "700",
            fontSize: "16px",
            lineHeight: "20.08px",
            borderRadius: "7px",
            height: "40px",
          }}
          variant="outlined"
          size="small"
        >
          Previous
        </Button>
        <Button
          style={{
            backgroundColor: "black",
            color: "white",
            padding: "8px 40px",
            fontFamily: "Mulish",
            fontWeight: "700",
            fontSize: "16px",
            lineHeight: "20.08px",
            borderRadius: "7px",
            height: "40px",
          }}
          variant="contained"
          size="small"
          onClick={handleNextStep}
          sx={{ mr: 1 }}
        >
          Next {step}/5
        </Button>
      </Box>
    </Box>
  );
}
