import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AddColumnIcon from "../assets/AddColumnIcon.svg";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import MuiButton from "./MuiButton";


const ToggleColumn = ({tableFields=[], handleToggleColumn,disabledIndex=0 }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <MuiButton
        text=" Add Column"
        variant="outlined"
        style={{
          padding: "8px 20px",
          border: "1px solid rgba(0, 0, 0, 0.30)",
          marginBottom: "10px",
        }}
        onClick={handleClick}
        icon={AddColumnIcon}
        bg="white"
        color="black"
      >
        Add Column
      </MuiButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <FormGroup
        // onClick={handleChange}
        >
          {tableFields?.map((options,index) => {
            console.log(options,"options" )
            return (
              <MenuItem>
                <FormControlLabel
                  control={
                    <Checkbox
                      name={options.name}
                      sx={{
                        color: "black",
                        "&.Mui-checked": {
                          color: "black",
                        },
                        "&.Mui-disabled": {
                          color: "gray",
                        },
                      }}
                      onClick={()=>handleToggleColumn(index)}
                      checked={options?.flag}
                      disabled={index <disabledIndex}
                    />
                    
                  }
                  label={options?.displayName}
                />
              </MenuItem>
            );
          })}
        </FormGroup>
      </Menu>
    </div>
  );
};

export default ToggleColumn;
