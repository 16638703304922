import { Box, Button, Radio, Switch, Typography } from "@mui/material";
import React, { useState } from "react";
import MuiInput from "../../MuiInput";
import deleteLogo from "../../../assets/delete_black.png";
import DeleteConformation from "../DeleteConformation";
import Label from "../../Label";
import deleteGray from "../../../assets/delete_gray.png";
import plusIcon from "../../../assets/plusBtn.png";
import SmallImageUpload from "../../SmallImageUpload";
import { MdDelete } from "react-icons/md";
import Trivia_ImageUpload from "./Trivia_ImageUpload";

export default function ManualInputQuestion({
  data,
  handleQuestionData,
  handleQuestionOptionData,
  handleAddOption,
  handleDeleteOption,
  onChange,
  handleAddImage,
  handleDeleteImage,
  handleAddNewQuestion,
}) {
  const [activeBox, setActiveBox] = useState(null);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box>
      <DeleteConformation
        open={open}
        setOpen={setOpen}
        handleClose={handleClose}
        handleOpen={handleOpen}
      />

      {data?.game?.triviaData_step1?.questions?.map((question, index) => (
        <Box
          key={index}
          sx={{
            width: "400px",
            border:
              activeBox === index ? "1px solid black" : "1px solid #E3E7EF",
            padding: "10px",
            my: "10px",
            borderRadius: "8px",
            padding: "20px",
          }}
          onClick={() => setActiveBox(index)}
          onMouseEnter={() => setActiveBox(index)}
          onMouseLeave={() => {
            // Only set activeBox to null if no child element is focused
            if (
              !document.activeElement ||
              !document.activeElement.closest(`[data-index="${index}"]`)
            ) {
              setActiveBox(null);
            }
          }}
          data-index={index}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Mulish",
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "17.57px",
                color: "#000000",
                textAlign: "left",
                mb: "16px",
              }}
            >{`Question #${index + 1}`}</Typography>

            <Box sx={{ display: "flex", gap: "1px", mt: "-10px" }}>
              <Button sx={{ minWidth: "0px" }} onClick={handleOpen}>
                <img
                  style={{ height: "24px", width: "24px" }}
                  src={deleteLogo}
                  alt=""
                />
              </Button>
            </Box>
          </Box>
          <Box>
            <Label label="Question" />
            <MuiInput
              value={data?.game?.triviaData_step1?.questions[index]?.title}
              onChange={(event) => handleQuestionData("title", event, index)}
            />
          </Box>

          <Box
            sx={{
              //   display: reward?.limit_required ? "block" : "none",
              background: "#F9F9F9",
              mt: "20px",
              px: "12px",
              borderRadius: "8px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "50px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Mulish",
                  fontWeight: 600,
                  fontSize: "16px",
                  lineHeight: "20.08px",
                  color: "#000000",
                }}
              >
                Add Media
              </Typography>
              <Switch
                sx={{
                  "& .MuiSwitch-switchBase.Mui-checked": {
                    color: "#000000",
                  },
                  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                    backgroundColor: "#000000",
                  },
                }}
                checked={data?.game?.triviaData_step1?.questions[index]?.addMedia}
                onChange={(event) =>
                  handleQuestionData("addMedia", event.target.checked, index)
                }
              />
            </Box>

            {data?.game?.triviaData_step1?.questions[index]?.addMedia && (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "start",
                  gap: "15px",
                  mt: "10px",
                  pb:"10px",
                }}
              >
                {data?.game?.triviaData_step1?.questions[index]?.images?.map(
                  (image, imageIndex) => (
                    <Box
                      key={imageIndex}
                      sx={{
                        height: "100px",
                        width: "100px",
                        borderRadius: "8px",
                        position: "relative",
                        overflow: "hidden",
                      }}
                    >
                      <img
                        style={{
                          height: "100%",
                          width: "100%",
                          borderRadius: "6px",
                          objectFit: "cover",
                        }}
                        src={image}
                        alt=""
                      />
                      <MdDelete
                        style={{
                          position: "absolute",
                          top: "5px",
                          right: "5px",
                          zIndex: 100,
                          width: "20px",
                          height: "20px",
                          color: "red",
                          cursor: "pointer",
                        }}
                        onClick={() => handleDeleteImage(index, imageIndex)}
                      />
                    </Box>
                  )
                )}

                <Box
                  sx={{
                    height: "100px",
                    display: "flex",
                    alignItems: "center",
                    gap: "25px",
                  }}
                >
                  <Box>
                    <Trivia_ImageUpload
                      onChange={(file) => handleAddImage("images", file, index)}
                      handleImage={() => {}}
                    />
                  </Box>
                </Box>
              </Box>
            )}
          </Box>

          <Box sx={{ mt: "20px" }}>
            <Label label="Answer Options" />
            <Box>
              {question?.options.map((option, optionIndex) => (
                <Box
                  key={optionIndex}
                  sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <Radio
                    checked={option.isSelected}
                    onChange={(event) =>
                      handleQuestionOptionData(
                        "isSelected",
                        event,
                        index,
                        optionIndex
                      )
                    }
                    value={optionIndex}
                    name={`question-${index}-option`}
                    inputProps={{ "aria-label": `Option ${optionIndex + 1}` }}
                    sx={{
                      //   marginRight: "10px",
                      mt: "10px",
                      color: "black",
                      "&.Mui-checked": {
                        color: "black",
                      },
                    }}
                  />
                  <MuiInput
                    value={option?.optionTitle}
                    onChange={(event) =>
                      handleQuestionOptionData(
                        "optionTitle",
                        event,
                        index,
                        optionIndex
                      )
                    }
                  />

                  <Button
                    sx={{
                      minWidth: "40px",
                      height: "40px",
                      borderRadius: "8px",
                      border: "1px solid #E3E7EF",
                      display: "grid",
                      placeItems: "center",
                      mt: "10px",
                      display:question?.options?.length===1?"none":"",
                    }}
                    onClick={() => handleDeleteOption(index, optionIndex)}
                  >
                    <img src={deleteGray} alt="delete button" />
                  </Button>
                  <Button
                    sx={{
                      minWidth: "40px",
                      height: "40px",
                      borderRadius: "8px",
                      border: "1px solid #E3E7EF",
                      visibility:
                        question?.options?.length - 1 === optionIndex
                          ? "visible"
                          : "hidden",
                      display: "grid",
                      placeItems: "center",
                      mt: "10px",
                    }}
                    onClick={() => handleAddOption(index)}
                  >
                    <img src={plusIcon} alt="add button" />
                  </Button>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      ))}

      <Box sx={{ width: "400px", marginTop: "20px" }}>
        <Button
          sx={{
            fontFamily: "Mulish",
            fontWeight: 600,
            fontSize: "16px",
            lineHeight: "20.08px",
            color: "#272727",
            height: "44px",
            backgroundColor: "#F9F9F9",
            width: "440px",
            marginLeft: "10px",
            borderRadius: "8px",
            display: "flex",
            justifyContent: "center",
            gap: "10px",
          }}
          onClick={handleAddNewQuestion}
        >
          <img src={plusIcon} alt="" />
          Add New Question
        </Button>
      </Box>
    </Box>
  );
}
