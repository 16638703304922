import { Box, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import crossIcon from "../assets/crossIconNew.svg"

const FilterListContainer = ({
  name,
  value,
  handleRemove,
  originalValue,
  originalName,
}) => {
  const filterListStyles = {
    fontFamily: "Proxima Nova A",
    background: "#fff",
    display: "flex",
    padding: "5px 8px 5px 8px",
    gap: "0px",
    alignItems: "center",
    borderRadius: "20px",
    border: "1px solid #0000004D",
  };

  const filterListTitle = {
    fontFamily: "Proxima Nova",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "17.05px",
    textAlign: "left",
    color: "#000",
    marginRight: "8px",
  };

  const filterListvalue = {
    ...filterListTitle,
    color: "#6F747E",
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "Proxima Nova A",
    lineHeight: "17.05px",
  };

  const closeButtonStyle = {
    height: "9px",
    width: "9px",
  };

  const closeIconStyle = {
    color: "#000",
    height: "10px",
    width: "10px",
    cursor:"pointer"
  };

  return (
    <Box sx={filterListStyles}>
      <Stack direction={"row"} alignItems={"center"} justifyContent={"center"}>
        {/* <span style={filterListTitle}>{name}:</span> */}
        <span style={filterListvalue}>{value}</span>
      </Stack>
      {/* <IconButton
        onClick={() => {
          handleRemove(originalName, originalValue);
        }}
        sx={closeButtonStyle}
      > */}
        {/* <CloseIcon sx={closeIconStyle} /> */}
        <img src={crossIcon} style={closeIconStyle}  onClick={() => {
          handleRemove(originalName, originalValue);
        }} alt="" />
      {/* </IconButton> */}
    </Box>
  );
};

const FilteredResults = ({ options = [], handleRemove }) => {
  const containerStyle = {
    display: "flex",
    flexWrap: "wrap",
    gap: "18px",
    width: "100%",
  };
  // console.log(options,"FilterOptions")
  return (
    <Box sx={containerStyle}>
      {options.length > 0 &&
        options.map((res) => {
          {
            console.log(res, "FilterOptions");
          }
          if (res.state.length > 0) {
            return (
              <Box
                sx={{
                  display: "flex",
                  gap: "5px",
                  background: "#F0F0F0",
                  alignItems: "center",
                  flexWrap: "wrap",
                  padding: "5px 10px",
                  borderRadius: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: 600,
                    fontFamily: "Proxima Nova A",
                    lineHeight: "17.05px",
                  }}
                >
                  {res?.summaryText}: 
                </Typography>
                {res.state.map((list, index) => {
                  const findLabelName = res?.options?.find(
                    (res) => res.value == list
                  );
                  return (
                    <Box>
                      <FilterListContainer
                        key={list.id}
                        name={res?.summaryText}
                        value={findLabelName?.label}
                        originalValue={list}
                        originalName={res?.name}
                        handleRemove={handleRemove}
                      />
                    </Box>
                  );
                })}
              </Box>
            );
          } else {
            return null;
          }
        })}
    </Box>
  );
};

export default FilteredResults;
