import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
// import Label from "../Label";
// import MuiSelectAndSearch from "../MuiSelectAndSearch";
import MuiInput from "../../MuiInput";
import BackButtonImg from "../../../assets/backArrow.png";
import { useDispatch, useSelector } from "react-redux";
import { getTypeOptionsFunction } from "../../../Redux/CreateRuleReducer/action";
import UserCohortsFileUpload from "../../../components/userCohorts/UserCohortsFileUpload";
import sampleCsv from "../../../assets/Voucher.csv";
import start from "../../../assets/start.png";
import cashback from "../../../assets/cashbackSvg.svg";
import digiGold from "../../../assets/digiGoldSvg.svg";
import neucoin from "../../../assets/neoCoinSvg.svg";
import voucher from "../../../assets/voucherSvg.svg";
import betterLuck from "../../../assets/noRewardSvg.svg";
import SmallImageUpload from "../../SmallImageUpload";
import Label from "../../Label";
import MuiSelectAndSearch from "../../MuiSelectAndSearch";
import CreateNewReward from "../CreateNewReward";

const options = [
  // { name: "Start from Scratch", id: "start_from_scratch" },
  { name: "Better Luck Next Time", id: "better_luck_next_time" },
  { name: "Voucher", id: "voucher" },
  { name: "Neu Coins", id: "neu_coins" },
  { name: "Digital Gold", id: "digital_gold" },
  { name: "Cashback", id: "cashback" },
];

const getRewardTitleNumber = (rewardType) => {
  const specialRewardTypes = [
    "start_from_scratch",
    "neu_coins",
    "digital_gold",
    "cashback",
  ];
  return specialRewardTypes.includes(rewardType);
};

export default function Trivia_StartFromScratch({
  data,
  handleAddNewRewards,
  handleStartFromScratch,
  onChange,
  handleCreateReward,
  rewardTypeName,
  setRewardTypeName
}) {
  const option = useSelector((store) => store.CreateRuleReducer.typeOption);
  // const [rewardTypeName,setRewardTypeName] = useState(data?.addNewRewards?.triviaData?.rewardType);
  const rewards = useSelector(
    (store) => store?.OfferReducer?.allRewards)?.rewardTypes?.find(
    (item) => item?.name === rewardTypeName
  )?.reward_details || {};
  const cashbackTypeOption = option?.map((item) => {
    return {
      name: item?.label,
      id: item?.value,
      fields: item?.fields,
    };
  });
  const dispatch = useDispatch();
  console.log(option, "option");
  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = sampleCsv;
    link.download = "Voucher.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const rewardType = [
    { title: "Better Luck Next Time", image: betterLuck },
    { title: "Voucher", image: voucher },
    { title: "Neu Coins", image: neucoin },
    // { title: "Start from Scratch", image: start },
    { title: "Digital Gold", image: digiGold },
    { title: "Cashback", image: cashback },
  ];
  useEffect(() => {
    dispatch(getTypeOptionsFunction());
    setRewardTypeName(data?.addNewRewards?.triviaData?.rewardType);
  }, []);
  return (
    <Box>
      {/* <Box sx={{ display: "flex", justifyContent: "start" }}>
      <Button onClick={() => handleAddNewRewards("rewardType", "")}>
        <img src={BackButtonImg} alt="" />
        <Typography
          sx={{
            fontFamily: "Mulish",
            fontWeight: 700,
            fontSize: "20px",
            lineHeight: "26px",
            color: "#0F1828",
            ml: "12px",
          }}
        >
          Start From Scratch
        </Typography>
      </Button>
    </Box> */}

      <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <Button
          sx={{ minWidth: "0px" }}
          onClick={() => handleAddNewRewards("rewardType", "")}
        >
          <img src={BackButtonImg} alt="" />
        </Button>
        <Typography
          sx={{
            fontFamily: "Mulish",
            fontWeight: 700,
            fontSize: "20px",
            lineHeight: "26px",
            color: "#0F1828",
            // ml: "12px",
          }}
        >
          Create Reward
        </Typography>
      </Box>

      {/* <Box sx={{ mt: "25px" }}>
        <Box sx={{ width: "400px" }}>
          <Label label={"1. Select Reward Type"} />
          <MuiSelectAndSearch
            options={options}
            value={data?.addNewRewards?.triviaData?.startFromScratch?.rewardType}
            onChange={(event) => handleStartFromScratch("rewardType", event)}
            placeholder="--Select--"
          />
        </Box>
        <Box
          sx={{
            display: getRewardTitleNumber(
              data?.addNewRewards?.triviaData?.startFromScratch?.rewardType?.id
            )
              ? "block"
              : "none",
          }}
        >
          <Box mt={"15px"} sx={{ width: "400px" }}>
            <Label label={"2. Select Cashback Type"} />
            <MuiSelectAndSearch
              options={cashbackTypeOption}
              value={data?.addNewRewards?.triviaData?.startFromScratch?.cashbackType}
              onChange={(event) =>
                handleStartFromScratch("cashbackType", event)
              }
              placeholder="--Select--"
            />
          </Box>
          <Box
            mt={"15px"}
            sx={{
              display: data?.addNewRewards?.triviaData?.startFromScratch?.cashbackType?.id
                ? "block"
                : "none",
            }}
          >
            <Label label={"3. Reward Value"} />
            <Box sx={{ display: "flex", gap: "10px" }}>
              {data?.addNewRewards?.triviaData?.startFromScratch?.cashbackType?.fields?.map(
                (item) => {
                  const key = `${data?.addNewRewards?.triviaData?.startFromScratch?.cashbackType?.name}_${item?.backend_label}`;
                  return (
                    <Box
                      sx={{
                        width:
                          data?.addNewRewards?.triviaData?.startFromScratch?.cashbackType
                            ?.fields.length > 2
                            ? "150px"
                            : data?.addNewRewards?.triviaData?.startFromScratch
                                ?.cashbackType?.fields.length == 2
                            ? "195px"
                            : "400px",
                      }}
                    >
                    
                      <MuiInput
                        key={key}
                        value={data?.addNewRewards?.triviaData?.startFromScratch?.[key]}
                        onChange={(event) => handleStartFromScratch(key, event)}
                        placeholder={item?.label}
                      />
                    </Box>
                  );
                }
              )}
            </Box>
          </Box>
        </Box>

        <Box mt={"15px"} sx={{ width: "400px" }}>
          <Label
            label={`${
              getRewardTitleNumber(
                data?.addNewRewards?.triviaData?.startFromScratch?.rewardType?.id
              )
                ? data?.addNewRewards?.triviaData?.startFromScratch?.cashbackType?.id
                  ? 4
                  : 3
                : 2
            }. Reward Title`}
          />
          <MuiInput
            value={data?.addNewRewards?.triviaData?.startFromScratch?.rewardTitle}
            onChange={(event) => handleStartFromScratch("rewardTitle", event)}
            placeholder="40 Characters"
          />
        </Box>

        <Box
          sx={{
            width: "400px",
            mt: "15px",
            display:
              !data?.addNewRewards?.triviaData?.startFromScratch?.rewardType?.id ||
              getRewardTitleNumber(
                data?.addNewRewards?.triviaData?.startFromScratch?.rewardType?.id
              )
                ? "none"
                : "block",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              px: "10px",
            }}
          >
            <Label label={"3. Upload Voucher CSV"} />

            <Typography
              onClick={handleDownload}
              style={{
                fontFamily: "Mulish",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "17.57px",
                color: "#0F1828",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              Download Sample
            </Typography>
          </Box>
          <UserCohortsFileUpload
            data={data?.addNewRewards?.triviaData?.startFromScratch}
            textVisibility={false}
            onChange={(field, event) => handleStartFromScratch(field, event)}
            errorMessage={data?.addNewRewards?.triviaData?.startFromScratch?.csvError}
          />
        </Box>
        <Box mt={"15px"}>
          <Box sx={{ display: "flex", gap: "8px", mt: "20px" }}>
           
            <Label
              label={`${
                !data?.addNewRewards?.triviaData?.startFromScratch?.rewardType?.id
                  ? 3
                  : getRewardTitleNumber(
                      data?.addNewRewards?.triviaData?.startFromScratch?.rewardType?.id
                    )
                  ? data?.addNewRewards?.triviaData?.startFromScratch?.cashbackType?.id
                    ? 5
                    : 4
                  : 4
              }. Choose a reward icon`}
            />
            <Typography
              sx={{
                fontFamily: "Mulish",
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "15.06px",
                color: "#6F747E",
                mt: "2px",
              }}
            >
              ( Recommended Dimensions Is 755px * 775px )
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
      
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "20px",
                py: "15px",
         
              }}
            >
              {rewardType.map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    width: "120px",
                    height: "100px",
                    backgroundColor: "#F4F4F4",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                    borderRadius: "12px",
                    cursor: "pointer",
                    border:
                      data?.addNewRewards?.triviaData?.startFromScratch?.rewardIcon ===
                      item.title
                        ? "2px solid #000000"
                        : "2px solid transparent",
                  }}
                  onClick={() =>
                    handleStartFromScratch("rewardIcon", item.title)
                  }
                >
                  <img
                    style={{ height: "64px", width: "64px" }}
                    src={item.image}
                    alt=""
                  />
        
                </Box>
              ))}
     
            </Box>

            <Box
              sx={{
                height: "100px",
                display: "flex",
                alignItems: "center",
                gap: "25px",
              }}
            >
              <Typography>OR</Typography>
              <Box>
                <SmallImageUpload
                  value={
                    data?.addNewRewards?.triviaData?.startFromScratch?.rewardIconUploadUrl
                  }
                  onClick={() => {
                    if (
                      data?.addNewRewards?.triviaData?.startFromScratch?.rewardIconUploadUrl
                    ) {
                      handleStartFromScratch(
                        "rewardIcon",
                        data?.addNewRewards?.triviaData?.startFromScratch
                          ?.rewardIconUploadUrl
                      );
                    }
                  }}
                  borderValue={
                    data?.addNewRewards?.triviaData?.startFromScratch?.rewardIcon
                  }
         
                  onChange={(event) =>
                    handleStartFromScratch("rewardIconUpload", event)
                  }
                  handleImage={(value) =>
               

                    onChange({
                      ...data,
                      addNewRewards: {
                        ...data.addNewRewards,
                        triviaData: {
                          ...data.addNewRewards.triviaData,
                          startFromScratch: {
                            ...data.addNewRewards.startFromScratch,
                            rewardIconUpload: value,
                            rewardIconUploadUrl: value,
                            rewardIconUploadId: value,
                            rewardIcon: value,
                          },
                        },
                      },
                    })
                  }
                  image={
                    data?.addNewRewards?.triviaData?.startFromScratch?.rewardIconUploadUrl
                  }
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box> */}
     <CreateNewReward
          rewards={rewards}
          data={data}
          handleStartFromScratch={handleStartFromScratch}
          onChange={onChange}
          field={"triviaData"}
          setRewardTypeName={setRewardTypeName}
        />
      <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            pt: 2,
            // px: "15px",
            paddingBottom: "37px",
          }}
        >
          <Button
            color="inherit"
            onClick={() => handleAddNewRewards("rewardType", "")}
            sx={{ mr: 1 }}
            style={{
              backgroundColor: "white",
              color: "black",
              padding: "8px 30px",
              marginRight: "20px",
              fontFamily: "Mulish",
              fontWeight: "700",
              fontSize: "16px",
              lineHeight: "20.08px",
              borderRadius: "7px",
              height: "40px",
              width: "139px",
            }}
            variant="outlined"
            size="small"
          >
            Close
          </Button>
          <Button
            style={{
              backgroundColor: "black",
              color: "white",
              padding: "8px 40px",
              fontFamily: "Mulish",
              fontWeight: "700",
              fontSize: "16px",
              lineHeight: "20.08px",
              borderRadius: "7px",
              height: "40px",
              width: "139px",
            }}
            variant="contained"
            size="small"
            onClick={handleCreateReward}
            sx={{ mr: 1 }}
          >
            Create
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
