import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import UiTable from "./UiTable";
import QueryInput from "./QueryInput";
import { initialStateOfferLevel } from "../utils/initialStates";
import ProductDetailsAnalytics from "./ProductDetailsAnalytics";
import { useSelector } from "react-redux";
import axios from "axios";
import { BootstrapTooltip } from "../utils/tooltip";
import CloseIcon from "@mui/icons-material/Close";
import { formatDateNew } from "../utils/formateDate";
import DateFilterPanel from "./DateFilterPanel";
import FilteredResults from "./FilteredResults";
import ExportButton from "./ExportButton";
import { handleDownload } from "../utils/download";
import usePermission from "../utils/permissions";
import useDebounce from "../hooks/useDebounce";
import ToggleColumn from "../UIComponents/ToggleColumn";

const filterSelectOptions = [
  { label: "Custom", value: "CUSTOM" },
  { label: "Today", value: "TODAY" },
  { label: "Yesterday", value: "YESTERDAY" },
  { label: "This Week", value: "THIS_WEEK" },
  { label: "This Month", value: "THIS_MONTH" },
  { label: "All Time", value: "ALL_TIME" },
];

function processData(response) {
  const data = response?.data?.data;

  const areaChartCss = [
    {
      strokeColor: "#043A87",
      backgroundColor: "#043A87",
    },
    {
      strokeColor: "#DA8BFF",
      backgroundColor: "#DA8BFF",
    },
  ];

  const piechartColors = [
    ["#685EDD", "#D6D6D6"],
    ["#00A52E", "#3C3C3C", "#D9D9D9"],
  ];

  const overviewOptions = [
    {
      title: "All Rewards Analytics",
      options: [...data?.reward_analytics, ...data?.currency_rewards] ?? [],
    },
    {
      title: "Scratch Card Analytics",
      options: data?.scratch_card_analytics ?? [],
    },
  ];

  const piechartOptions = data?.graph_data?.map((res, index) => ({
    ...res,
    color: piechartColors[index],
  }));

  const areaChart = data?.area_chart?.map((res, index) => ({
    ...res,
    ...areaChartCss[index],
  }));

  return { overviewOptions, piechartOptions, areaChart };
}

// common text component
const GenricText = ({ text }) => {
  const textStyle = {
    fontFamily: "ABeeZee",
    fontSize: "13px",
    fontWeight: 400,
    lineHeight: "15.37px",
    letterSpacing: "0.02em",
    textAlign: "left",
    textOverflow: "ellipsis",
    overflow: "hidden",
  };

  return (
    <BootstrapTooltip title={text}>
      <Typography style={textStyle}>{text}</Typography>
    </BootstrapTooltip>
  );
};

const TData = () => {
  return [
    { key: "id", displayName: "Sl No", flag: true },
    { key: "product", displayName: "Product", flag: true },
    { key: "offers", displayName: "Offers", flag: true },
    { key: "reward_currency", displayName: "Reward Type", flag: true },
    { key: "budget", displayName: "Budget", flag: true },
    { key: "spends", displayName: "Spends", flag: true },
    { key: "total_users_reward_value", displayName: "Total Users Reward value", flag: true },
    { key: "average_reward", displayName: "Avg Reward", flag: true },
    { key: "total_cards_issued", displayName: "Total Card Issued", flag: true },
    { key: "total_cards_redeemed", displayName: "Total Card Redeemed", flag: true },
  ];
};


// main component
const OfferLevel = ({
  activeButton,
  filter,
  handleReset,
  doneButton,
  dateFilter,
  handleShowDateFilter,
  showDateFilter,
  handleRemove,
  handleDateFilter,
}) => {

  const [fieldsName, setFieldName] = useState(TData);
  const [columnFields, setColumnFields] = useState([]);

  const [search, setSearch] = useState("");
  const searchDebounce = useDebounce(search, 1000);
  const [loader, setloader] = useState(false);
  const [date, setDate] = useState({
    startDate: "",
    endDate: "",
  });
  const permission = usePermission();

  const [tabFilter, setTabFilter] = useState({});

  const [showFilter, setShowFilter] = useState([]);

  // state handle tab loader
  const [tabLoader, setTabLoader] = useState(false);
  // using ref handle setTimeout id
  const timerRef = useRef(null);

  const dateFilterLayout = {
    select: "ALL_TIME",
    from_time: "",
    to_time: "",
    loader: false,
  };

  const token = useSelector((store) => store.AuthReducer.token);
  const [offers, setOffers] = useState({
    data: [],
    totalcount: 0,
    paginationModel: {
      page: 0,
      pageSize: 10,
    },
  });

  const activeIndex = useRef();

  const handleToggleColumn = (i) => {
    console.log(i, "index");
    const data = fieldsName.map((e, index) => {
      if (index === i) {
        e.flag = !e.flag;
      }
      return e;
    });

    setFieldName(data);
  };

  const handleSelectNameClick = (value) => {
    if (showDateFilter) {
      handleShowDateFilter(activeButton, false);
    }
    setActiveSection(value);
  };

  const [tableButton, setTableButton] = useState([
    {
      label: "All",
      value: "All",
      handleSelectNameClick,
    },
  ]);
  const [activeSection, setActiveSection] = useState("All");

  const [multipleTab, setMultipleTab] = useState({});
  const flagRef = useRef("");

  const handleTableFilter = (id, name, value) => {
    setTabFilter((prev) => ({ ...prev, [id]: { ...prev[id], [name]: value } }));
  };

  // get offer levels

  const getOffers = async (status, data,isPagination=false) => {
    if (status !== "download") {
      setloader(true);
    }

    if(!isPagination){
      const paginationValue = {
        page: 0,
        pageSize: offers?.paginationModel.pageSize,
      };
        handlePaginationModel(paginationValue);
      }

    const obj = {
      ...(search && { search: search }),
      page: isPagination ? offers?.paginationModel.page + 1 : 1,
      itemsPerPage: offers?.paginationModel.pageSize,
    };

    if (status === "done") {
      setShowFilter(filter);
    }

    if (
      dateFilter?.select &&
      dateFilter?.select === "CUSTOM" &&
      dateFilter?.to_time &&
      dateFilter?.from_time
    ) {
      let startDate = formatDateNew(dateFilter?.from_time ?? "");
      let endDate = formatDateNew(dateFilter?.to_time ?? "");
      obj["dateStart"] = startDate;
      obj["dateEnd"] = endDate;
    }

    if (dateFilter?.select) {
      obj["day"] = dateFilter?.select;
    }

    if ((!status || status==="download") && data) {
      data?.forEach((res) => {
        if (res?.state?.length > 0) {
          obj[res?.name] = res?.state;
        }
      });
    } else if (status === "clear") {
      data?.forEach((res) => {
        if (res?.state?.length > 0) {
          obj[res?.name] = res?.state;
        }
      });
    } else if (status === "done") {
      filter?.forEach((res) => {
        if (res?.state?.length > 0) {
          obj[res?.name] = res?.state;
        }
      });
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const BaseUrl = process.env.REACT_APP_API_BASE_URL;

    if (status === "download") {
      obj["total_count"] = offers?.totalcount;
      handleDownload(BaseUrl, obj, token, "offerAnalytics");
      return;
    }

    try {
      const response = await axios.post(
        `${BaseUrl}/api/analytics/offers`,
        obj,
        config
      );

      if (dateFilter?.select !== "CUSTOM") {
        handleDateFilter(activeButton, "", "", {
          from_time: response?.data?.day_start_date,
          to_time: response?.data?.day_end_date,
        });
      }

      setOffers((prev) => ({
        ...prev,
        data: response?.data?.data,
        totalcount: response?.data?.offerCount,
      }));
    } catch (error) {
      console.log(error);
    } finally {
      setTimeout(() => {
        setloader(false);
      }, 300);
    }
  };
  const getOfferById = async (id) => {
    // handleDateFilter(id, "loader", true);

    if (id && multipleTab?.hasOwnProperty(id)) {
      setTabLoader(true);
    }
    const obj = {};

    if (
      tabFilter[id]?.select &&
      tabFilter[id]?.select === "CUSTOM" &&
      tabFilter[id]?.to_time &&
      tabFilter[id]?.from_time &&
      typeof tabFilter[id]?.from_time === "string" &&
      typeof tabFilter[id]?.to_time === "string"
    ) {
      let startDate = formatDateNew(tabFilter[id]?.from_time ?? "");
      let endDate = formatDateNew(tabFilter[id]?.to_time ?? "");
      obj["dateStart"] = startDate;
      obj["dateEnd"] = endDate;
    }

    if (tabFilter[id]?.select) {
      obj["day"] = tabFilter[id]?.select;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const BaseUrl = process.env.REACT_APP_API_BASE_URL;

    const url = `${BaseUrl}/api/analytics/offer/${id}`;

    try {
      const res = await axios.post(url, obj, config);
      const data = processData(res);
      const { overviewOptions, piechartOptions, areaChart } = data;

      if (
        // !multipleTab?.hasOwnProperty(id) &&
        Object.values(multipleTab).length <= 4
      ) {
        setMultipleTab((prev) => ({
          ...prev,
          [id]: {
            ...prev[id],
            id: id,
            piechart: piechartOptions[0],
            areaChart: areaChart,
            scratchCardAnalytics: overviewOptions[1],
            rewardCardAnalytics: overviewOptions[0],
          },
        }));
      } else {
        return;
      }
    } catch (error) {
      console.log(error);
    } finally {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }

      if (id && multipleTab?.hasOwnProperty(id)) {
        timerRef.current = setTimeout(() => {
          setTabLoader(false);
        }, 500);
      }
    }
  };

  const removeFilters = (name, value) => {
    handleRemove(name, value);
    const latestData = showFilter.map((filter) =>
      filter?.name === name
        ? {
            ...filter,
            state: filter.state.filter((res) => res !== value),
          }
        : filter
    );
    setShowFilter((prevOptions) => {
      const updatedData = prevOptions?.map((filter) =>
        filter?.name === name
          ? {
              ...filter,
              state: filter.state.filter((res) => res !== value),
            }
          : filter
      );

      return updatedData;
    });

    getOffers("clear", latestData);
  };

  const handleSearch = () => {
    getOffers("",showFilter);
  };

  const handlePaginationModel = (data) => {
    if (!data) return;
    setOffers((prev) => ({ ...prev, paginationModel: data }));
  };

  const handleDateFilters = (parentname, name, value) => {
    setTabFilter((prev) => ({
      ...prev,
      [parentname]: { ...prev[parentname], [name]: value },
    }));
  };

  const handleRowClick = async (row) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const BaseUrl = process.env.REACT_APP_API_BASE_URL;

    const url = `${BaseUrl}/api/analytics/offer/${row?.id}`;

    await axios
      .post(url, {}, config)
      .then((res) => {
        const data = processData(res);
        const { overviewOptions, piechartOptions, areaChart } = data;

        if (
          !multipleTab?.hasOwnProperty(row?.id) &&
          Object.values(multipleTab).length <= 4
        ) {
          setMultipleTab((prev) => ({
            ...prev,
            [row?.id]: {
              id: row.id,
              piechart: piechartOptions[0],
              areaChart: areaChart,
              scratchCardAnalytics: overviewOptions[1],
              rewardCardAnalytics: overviewOptions[0],
              product: row.row.offers,
            },
          }));

          setTabFilter((prev) => ({
            ...prev,
            [row?.id]: { ...dateFilterLayout },
          }));
        } else {
          return;
        }
      })
      .catch((error) => {
        console.log(error);
      });
    activeIndex.current = row?.id;
    setActiveSection(row?.id);
    if (showDateFilter) {
      handleShowDateFilter(activeButton, false);
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "Sl No",
      flex: 0.3,
    },
    {
      field: "product",
      headerName: "Product",
      flex: 1,
      renderCell: (param) => {
        if (!param.value) {
          return "NA";
        }

        const buttomStyle = {
          width: "100%",
          height: "100%",
          justifyContent: "flex-start",
        };

        return (
          <Button sx={buttomStyle}>
            <BootstrapTooltip title={param.value}>
              {param?.value}
            </BootstrapTooltip>
          </Button>
        );
      },
    },
    {
      field: "offers",
      headerName: "Offers",
      flex: 1,
      renderCell: (param) => {
        if (!param?.value) return "NA";

        return <GenricText text={param?.value ?? ""} />;
      },
    },
    {
      field: "reward_currency",
      headerName: "Reward Type",
      flex: 1,
      renderCell: (param) => {
        if (!param?.value) return "NA";

        return <GenricText text={param?.value ?? ""} />;
      },
    },
    {
      field: "budget",
      headerName: "Budget",
      flex: 0.7,
      renderCell: (param) => {
        if (!param?.value && typeof param?.value !== "number") return "NA";

        return <GenricText text={param?.value ?? ""} />;
      },
    },
    {
      field: "spends",
      headerName: "Spends",
      flex: 0.7,
      renderCell: (param) => {
        if (!param?.value && typeof param?.value !== "number") return "NA";

        return <GenricText text={param?.value ?? ""} />;
      },
    },
    {
      field: "total_users_reward_value",
      headerName: "Total Users Reward value",
      flex: 0.7,
      renderCell: (param) => {
        if (!param?.value && typeof param?.value !== "number") return "NA";

        return <GenricText text={param?.value ?? ""} />;
      },
    },
    {
      field: "average_reward",
      headerName: "Avg Reward",
      flex: 1,
      renderCell: (param) => {
        if (!param?.value && typeof param?.value !== "number") return "NA";

        return <GenricText text={param?.value ?? ""} />;
      },
    },
    {
      field: "total_cards_issued",
      headerName: "Total Card Issued",
      flex: 1,
      renderCell: (param) => {
        if (!param?.value && typeof param?.value !== "number") return "NA";

        return <GenricText text={param?.value ?? ""} />;
      },
    },
    {
      field: "total_cards_redeemed",
      headerName: "Total Card Redeemed",
      flex: 1,
      renderCell: (param) => {
        if (!param?.value && typeof param?.value !== "number") return "NA";

        return <GenricText text={param?.value ?? ""} />;
      },
    },
  ];

  //css

  const titleCss = {
    fontFamily: "Proxima Nova A",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "24px",
    letterSpacing: "0em",
    color: "#0F1828",
    textAlign: "left",
  };

  useEffect(() => {
    // Step 1: Filter TData columns where flag is true
    let filteredTData = fieldsName?.filter((column) => column.flag === true);

    // filteredTData = [...filteredTData, { "key": "copyBtn", "displayName": "Action", "flag": true }];

    // Step 2: Filter columns where field matches key from filteredTData
    const filteredColumns = columns.filter((column) =>
      filteredTData.some(
        (filteredColumn) => filteredColumn.key === column.field
      )
    );

    const finalColumns = [...filteredColumns];

    setColumnFields(finalColumns);
  }, [fieldsName]);

  useEffect(() => {
    getOffers("done");
  }, [doneButton]);

  useEffect(() => {
    getOffers("done",null,true);
  }, [offers?.paginationModel]);

  useEffect(() => {
    if (!flagRef.current) {
      flagRef.current = "initialized";
      return;
    }

    if (dateFilter?.select !== "CUSTOM") {
      getOffers("",showFilter);
    }
  }, [dateFilter?.select]);

  useEffect(() => {
    if (
      dateFilter?.select === "CUSTOM" &&
      dateFilter?.from_time &&
      dateFilter?.to_time
    ) {
      setDate({
        from_time: dateFilter?.from_time ?? "",
        to_time: dateFilter?.to_time ?? "",
      });
    }
  }, [dateFilter?.from_time, dateFilter?.to_time]);

  useEffect(() => {
    if (activeSection === "All" || !activeSection) return;

    if (
      tabFilter[activeSection]?.select === "CUSTOM" &&
      tabFilter[activeSection]?.to_time &&
      tabFilter[activeSection]?.from_time &&
      typeof  tabFilter[activeSection]?.to_time === "string" &&
      typeof  tabFilter[activeSection]?.from_time === "string"
    ) {
      getOfferById(activeSection);
    } else if (
      tabFilter[activeSection]?.select &&
      tabFilter[activeSection]?.select !== "CUSTOM"
    ) {
      getOfferById(activeSection);
    }
  }, [tabFilter, activeSection]);

  useEffect(() => {
    if (date?.from_time || date?.to_time) {
      getOffers("",showFilter);
    }else {
      getOffers("",showFilter);
    }
  }, [date,searchDebounce]);

  useEffect(() => {
    return () => {
      handleReset(activeButton, initialStateOfferLevel);
      handleShowDateFilter(activeButton, true);
    };
  }, []);

  useEffect(()=>{
    if(tabFilter[activeSection]?.select!=="CUSTOM"){
      handleDateFilters(activeSection,"to_time",{unset:true })
      handleDateFilters(activeSection,"from_time",{unset:true})
    }
  },[tabFilter[activeSection]?.select])

  return (
    <Box>
      <Stack spacing={3}>
        {/* <Typography sx={titleCss}>Product vs Offer vs Rewards</Typography> */}
        <Stack direction={"row"} justifyContent={"space-between"}>
       { showDateFilter ?  <QueryInput
            placeholder={"Search by Offer Name"}
            value={search}
            setState={setSearch}
            handleSearch={handleSearch}
          />:<Box> </Box>}

          {!showDateFilter ? (
            <DateFilterPanel
              key={activeSection}
              dateFilter={tabFilter}
              filterSelectOptions={filterSelectOptions}
              activeButton={activeSection}
              handleDateFilter={handleDateFilters}
            />
          ) : (
            <ExportButton
            isDisbaled={offers.data.length===0 || !permission?.DOWNLOAD }
              handleExport={() => {
                getOffers("download",showFilter);
              }}
               TooltipMessage={ offers.data.length===0?"No Data Found":"You don't have permission"}
            />
          )}
        </Stack>

        {showFilter && showFilter.length > 0 && showDateFilter && (
          <Box
            sx={
              {
                // paddingTop: showFilter.length > 0 ? "28px" : "0",
              }
            }
          >
            <FilteredResults
              options={showFilter}
              handleRemove={removeFilters}
            />
          </Box>
        )}

        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent:"space-between",
              gap: "3px",
              alignItems: "flex-end",
            }}
          >
            <Box       sx={{
              display: "flex",
              // justifyContent:"space-between",
              gap: "3px",
              alignItems: "flex-end",
            }}>

            {tableButton?.map((res) => (
              <Button
                key={res.label}
                sx={{
                  display: "flex",
                  width: "142px",
                  padding: "10px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  height: "38px",
                  // marginTop: "25px",
                  borderRadius: "10px 10px 0 0",
                  border: "1px solid #929EAE",
                }}
                style={{
                  background: activeSection === res?.value ? "#FFF" : "#DEE0E3",
                }}
                onClick={() => {
                  handleShowDateFilter(activeButton, true);
                  handleSelectNameClick(res?.value);
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: "14px",
                    fontFamily: "Proxima Nova",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textAlign: "right",
                    fontWeight: "600",
                    lineHeight: "normal",
                    letterSpacing: "0.28px",
                    textTransform: "capitalize",
                    color: "#000",
                  }}
                >
                  {res?.label}
                </Typography>

                {offers.totalcount > 0 && res?.value === activeSection && (
                  <Box
                    sx={{
                      display: "inline-flex",
                      padding: "2px 5px",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "10px",
                      background: "#000",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#fff",
                        // textAlign: "right",
                        fontFamily: "Proxima Nova",
                        fontSize: "14px",
                        lineHeight: "normal",
                        fontWeight: "600px",
                        letterSpacing: "0.28px",
                        textTransform: "capitalize",
                      }}
                    >
                      {offers?.totalcount}
                    </Typography>
                  </Box>
                )}
              </Button>
            ))}

            {Object.values(multipleTab).map((res, index) => (
              <Button
                key={res.label}
                sx={{
                  display: "flex",
                  width: "142px",
                  position: "relative",
                  height: "38px",
                  padding: "10px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  marginTop: "25px",
                  borderRadius: "10px 10px 0 0",
                  border: "1px solid #929EAE",
                  zIndex: index + 1,
                }}
                style={{
                  background: activeSection === res?.id ? "#FFF" : "#DEE0E3",
                }}
                onClick={() => {
                  handleSelectNameClick(res?.id ?? "");
                  activeIndex.current = res?.id;
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: "14px",
                    fontFamily: "Proxima Nova",
                    height: "16px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    // textAlign: "right",
                    fontWeight: "600",
                    lineHeight: "normal",
                    letterSpacing: "0.28px",
                    textTransform: "capitalize",
                    paddingLeft: "10px",
                    color: "#000",
                  }}
                >
                  <BootstrapTooltip title={res?.product}>
                    {res?.product}
                  </BootstrapTooltip>
                </Typography>

                <IconButton
                  sx={{
                    // position: "absolute",
                    // top: "-7px",
                    // right: "-8px",
                    backgroundColor: "#F1554C",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "9px",
                    width: "9px",
                    padding: "10px",
                    "&:hover": {
                      backgroundColor: "#F1554C",
                    },
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setActiveSection("All");
                    handleShowDateFilter(activeButton, true);
                    // setTableType("Settlements");
                    // setId("");
                    setMultipleTab((prev) => {
                      const updatedState = { ...prev };
                      delete updatedState[res?.id];
                      return updatedState;
                    });

                    setTabFilter((prev) => {
                      const updatedState = { ...prev };
                      delete updatedState[res?.id];
                      return updatedState;
                    });
                  }}
                >
                  <CloseIcon
                    sx={{
                      fill: "#fff",
                      width: "14px",
                      height: "14px",
                    }}
                  />
                </IconButton>
              </Button>
            ))}
            </Box>

                    <Box sx={{ display: activeSection === "All"?"flex":"none", justifyContent: "end" }}>
            <ToggleColumn
              tableFields={fieldsName}
              handleToggleColumn={handleToggleColumn}
              disabledIndex={1}
            />
          </Box>
          </Box>
          {activeSection === "All" && (
            <UiTable
              row={offers?.data}
              columns={columnFields}
              totalCount={offers?.totalcount ?? 0}
              paginationModel={offers?.paginationModel}
              handleRowClick={handleRowClick}
              setPaginationModel={handlePaginationModel}
              loading={loader}
            />
          )}

          {activeSection !== "All" &&
            (tabLoader ? (
              <Box
                sx={{
                  display: "flex",
                  height: "30vh",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress style={{ color: "#26292D" }} />
              </Box>
            ) : (
              <ProductDetailsAnalytics data={multipleTab[activeSection]} />
            ))}
        </Box>
      </Stack>
    </Box>
  );
};

export default OfferLevel;
