import React from "react";
import { TextField } from "@mui/material";

const MuiTextarea = ({
  onChange,
  placeholder,
  minRows = 3,
  value,
  errorMessage = "",
  editBoolean,
  isDisable
}) => {
  return (
    <TextField
      sx={{
        width: "100%",
        mt: "10px",
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: editBoolean ? "orange" : "", // Custom border color for edit mode
            borderRadius: "6px",
          },
          "&:hover fieldset": {
            borderColor: editBoolean ? "orange" : "", // Custom border color on hover in edit mode
          },
          "&.Mui-focused fieldset": {
            borderColor: editBoolean ? "orange" : "", // Custom border color when focused in edit mode
          },
          "&.Mui-disabled": {
            opacity: 1, // Remove opacity to keep the same appearance
            "& fieldset": {
              borderColor: editBoolean ? "orange" : "", // Keep the same border color for disabled
            },
          },
        },
        "& .MuiInputBase-input::placeholder": {
          fontFamily: "Lato",
          fontWeight: 400,
          fontSize: "12px",
          lineHeight: "16px",
          whiteSpace: "pre-line",
        },
        "& .MuiInputBase-input": {
          fontFamily: "Lato",
          fontWeight: 400,
          fontSize: "12px",
          lineHeight: "16px",
        },
        "& .Mui-disabled": {
          WebkitTextFillColor: "#000000ba !important", // Keep the same text color for disabled state
          backgroundColor: "transparent",  // Ensure background remains transparent when disabled
        },
      }}
      disabled={isDisable}
      multiline
      fullWidth
      size="small"
      minRows={minRows}
      placeholder={placeholder}
      value={value ?? ""}
      onChange={(evt) => onChange(evt.target.value)}
      helperText={errorMessage}
      error={!!errorMessage}
    />
  );
};

export default MuiTextarea;
