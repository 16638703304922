import { DatePicker } from "@mui/x-date-pickers";
import React from "react"; // Adjust the path as needed

const CustomDatePicker = ({
  label,
  value,
  onChange,
  name,
  isDisabled = false,
  setState,
  style = {},
  minDate,
  maxDate
}) => {
  return (
    <DatePicker
    minDate={minDate}
    maxDate={maxDate}
      disabled={isDisabled}
      sx={{ ...style, color: "#d9d9da" }}
      label={label}
      slotProps={{ textField: { size: "small" } }}
      value={value}
      onChange={(targetvalue) => onChange(name, targetvalue?.toISOString(), setState)}
    />
  );
};

export default CustomDatePicker;
