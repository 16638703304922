function formatDate(inputDate, changeFormat = false) {
    const dateObject = new Date(inputDate);
  
    // Check if inputDate is a valid date and not null or undefined
    if (!isNaN(dateObject.getTime())) {
      const year = dateObject.getFullYear();
      const month = dateObject.getMonth() + 1; // Months are zero-based, so add 1.
      const day = dateObject.getDate();
  
      // Ensure that year, month, and day are valid before formatting
      if (!isNaN(year) && !isNaN(month) && !isNaN(day)) {
        return changeFormat
          ? `${year}-${month}-${day}`
          : `${day}/${month}/${year}`;
      } else {
        // Handle the case where year, month, or day is not a valid number
        console.error("Invalid year, month, or day.");
        return "--"; // or return an error string or handle the error as appropriate
      }
    } else {
      // Handle the case where inputDate is not a valid date
      console.error("Invalid date:", inputDate);
      return "--"; // or return an error string or handle the error as appropriate
    }
  }
  
  export default formatDate;
  
  
  export function formatDateNew(inputDateString) {
    const inputDate = new Date(inputDateString);
  
    if (isNaN(inputDate.getTime())) {
      // Handle invalid date
      return "Invalid Date";
    }
  
    const year = inputDate.getFullYear();
    const month = (inputDate.getMonth() + 1).toString().padStart(2, "0"); // Adding 1 because months are zero-based
    const day = inputDate.getDate().toString().padStart(2, "0");
  
    return `${year}-${month}-${day}`;
  }