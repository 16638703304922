import axios from "axios";
import { download } from "../pages/Transactions";

export const handleDownload = async (BaseUrl, obj, isAuth, path) => {


  obj.pageName  = path

  // Remove properties if present
if ('page' in obj) {
  delete obj.page;
}

if ('itemsPerPage' in obj) {
  delete obj.itemsPerPage;
}

  const url = `${BaseUrl}/api/export`;
  const token = isAuth;

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };

  try {
    const response = await axios.post(url, obj, {
      headers,
    });

    download(response?.data , path);
  } catch (error) {
    console.log(error);
  }
};
