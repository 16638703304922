import { Box, Typography, colors } from "@mui/material";
import zIndex from "@mui/material/styles/zIndex";
import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

export default function CustomBarChart({ data, barColor,xAxisTitle,yAxisTitle
  ,chartTitle }) {
  return (
    <Box
      p={"10px"}
      border="1px solid #00000026"
      borderRadius="10px"
      height={320}
      pb={6}
    >
      <Typography
        sx={{
          fontFamily: "Proxima Nova A",
          fontWeight: 500,
          fontSize: "16px",
          textAlign: "start",
          marginLeft: "15px",
          lineHeight: "19.49px",
          marginBottom: "30px",
        }}
      >
      {chartTitle}
      </Typography>

      <ResponsiveContainer width="100%">
        <BarChart
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          barCategoryGap="25%" // Increase gap between categories
          barGap={5} // Increase gap between bars within a category
        >
          <CartesianGrid
            strokeDasharray="3 3"
            vertical={false}
            horizontal={false}
          />

          <XAxis
            // label={{
            //   value: "Reward Value (₹)",
            //   position: "insideBottom",
            //   style: {
            //     fontFamily: "Proxima Nova A",
            //     fontWeight: 500,
            //     marginTop: "20px",
            //     fontSize: "14px",
            //     textAlign:"center"
            //   }, // Adjust margin top here
            //   dy: 18, // Move the label up by 20 units
            // }}
            dataKey="x"
          />
          <YAxis
            label={{
              value: `${yAxisTitle}`,
              angle: -90,
              position: "left",
              style: {
                fontFamily: "Proxima Nova A",
                fontWeight: 500,
                // marginTop: "120px",
                lineHeight: "14.62px",
                fontSize: "14px",
                offset: -15,
              },
              dy: -90,
              dx: -6,
              fill: "#000000",
            }}
          />
          <Tooltip shared={false} trigger="click" />
          <Legend
            content={() => (
              <Typography
                sx={{
                  fontFamily: "Proxima Nova A",
                  fontWeight: 500,
                  marginTop: "20px",
                  fontSize: "14px",
                  textAlign: "center",
                  color:"#000000"
                }}
              >
                {xAxisTitle}
              </Typography>
            )}
          />
          <Bar dataKey="y" fill={barColor} />
          {/* <Bar dataKey="uv" fill="#82ca9d" /> */}
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
}
