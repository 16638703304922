import axios from "axios"
import { addRule, getTypeOption, onMount } from "./actionType"
import { getToken } from "../../utils/token";

const BaseUrl = process.env.REACT_APP_API_BASE_URL;


// const data = localStorage.getItem("Auth")
// const AuthDetails = data?JSON.parse(data):{};
// const token = AuthDetails.accessToken; 

// export const getWhereSectionSelectOne = () => (dispatch) => {
//     axios.get(`${BaseUrl}/api/rule-attributes`, {
//         headers: {
//             Authorization: `Bearer ${token}`
//         }
//     }).then((res) => {
//         dispatch({ type: onMount, payload: res.data });
//         // console.log(res.data)
//     }).catch((e) => {
//         console.log(e);
//     });
// };

export const getWhereSectionSelectOne = (id,pathname,ruleType) => (dispatch) => {
    const token = getToken();
    console.log(token)
    let config = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        params: {
            entity:ruleType
        }
    };

    if (pathname === "/create-offers") {
        config.params = {
            ...config.params, 
            productId: id
        };
    }

    console.log(config,"config")
    axios.get(`${BaseUrl}/api/rule-attributes`, config).then((res) => {
        dispatch({ type: onMount, payload: res.data });
        // console.log(res.data)
    }).catch((e) => {
        console.log(e);
    });
};


export const handleSubmitRule = (rule) => (dispatch) => {
    const token = getToken();
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    };
    axios.post(`${BaseUrl}/api/create-rule`, rule,config).then((res) => {
      dispatch({ type: addRule, payload: res.data });
      console.log(res.data, "Submitted");
    }).catch((e) => {
      console.log(e);
    });
  };
  

export const getTypeOptionsFunction = ()=>(dispatch)=>{
    const token = getToken();
    axios.get(`${BaseUrl}/api/cashback-types`,{
        headers: {
            Authorization: `Bearer ${token}`
        }
    }).then((res)=>{
        dispatch({type:getTypeOption,payload:res.data})
    }).catch((e)=>{
        console.log(e)
    })
}