import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";

const FilterClearDoneButton = ({ onClear, onDone }) => {
  return (
    <Box
      sx={{
        margin: "0 0 0 0",
        height: "71px",
        boxSizing: "border-box",
        padding: "20px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        background: "#fff",
        boxShadow: "0px -4px 7px 0px #00000026",
        position: "fixed",
        width: "415px",
        top: "0",
        // right: "0px",
        zIndex: 9,
      }}
    >
      <Button
        sx={{
          padding: "10px, 16px, 10px, 16px",
          borderRadius: "5px",
          boxSizing: "border-box",
          height: "37px",
          border: "1px solid #465466",

          background: "#fff",
        }}
        onClick={onClear}
      >
        <Typography
          sx={{
            fontFamily: "Proxima Nova A",
            color: "#465466",
            fontSize: "14px",
            fontWeight: "600",
          }}
        >
          Clear all filters
        </Typography>
      </Button>
      <Button
        sx={{
          padding: "10px, 16px, 10px, 16px",
          fontWeight: "600",
          color: "#fff",
          background: "#465466",
          "&:hover": {
            background: "#465466",
          },
        }}
        onClick={onDone}
      >
        <Typography
          sx={{
            fontFamily: "Proxima Nova A",
            color: "#FFF",
            fontSize: "14px",
            fontWeight: "600",
          }}
        >
          Done
        </Typography>
      </Button>
    </Box>
  );
};

export default FilterClearDoneButton;
