import React from "react";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  variant: "menu",
};

const MultiSelect = ({
  label = "",
  options,
  placeholder = "Select options",
  onChange,
  value,
  errorMessage,
  disable = false,
  editBoolean,
}) => {
  const isAllSelected =
    options?.length > 0 && value?.length === options?.length;

  const handleChange = (event) => {
    const inputValue = event.target.value;
    if (inputValue[inputValue?.length - 1] === "all") {
      onChange(value?.length === options?.length ? [] : options);
      return;
    }
    const selectedObjects =
      inputValue?.map &&
      inputValue?.map((optionId) =>
        options.find((option) => option.id === optionId)
      );
    onChange(selectedObjects);
  };

  return (
    <FormControl
      sx={{ mt: "10px", width: "100%" }}
      variant="outlined"
      size="small"
      error={!!errorMessage}
    >
      <InputLabel
        sx={{
          fontFamily: "Lato",
          fontSize: "12px",
          fontWeight: 400,
          color: editBoolean ? "orange" : "",
          "&.Mui-focused": {
            color: editBoolean ? "orange" : "",
          },
          "&.Mui-disabled": {
            color: editBoolean ? "orange" : "#000000ba", // Same color in disabled state
          },
        }}
      >
        {label}
      </InputLabel>
      <Select
        multiple
        disabled={disable}
        displayEmpty
        value={
          value?.length === 0 || !value
            ? []
            : value.map
            ? value.map((option) => option?.id)
            : []
        }
        onChange={handleChange}
        renderValue={(selected) => {
          if (selected?.length === 0) {
            return (
              <em
                style={{
                  color: "gray",
                  fontFamily: "Lato",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "16px",
                  opacity: 0.7,
                }}
              >
                {placeholder}
              </em>
            );
          }

          const selectedLabels =
            selected?.map &&
            selected?.map((selectedValue) => {
              const selectedOption = options?.find(
                (option) => option?.id === selectedValue
              );
              return selectedOption ? selectedOption.name : selectedValue;
            });
          return selectedLabels?.join(", ");
        }}
        MenuProps={MenuProps}
        label={label}
        sx={{
          height: "32px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center", // Center text horizontally
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: editBoolean ? "orange" : "",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: editBoolean ? "orange" : "",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: editBoolean ? "orange" : "",
          },
          "& .MuiSelect-select": {
            padding: "0 8px", // Adjust padding to center text properly
            fontFamily: "Lato",
            fontSize: "12px",
            fontWeight: 400,
            lineHeight: "16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start", // Center text in the select input
            height: "32px",
          },
          "& .Mui-disabled": {
            "-webkit-text-fill-color": "#000000ba !important", // Keep the same text color for disabled state
            backgroundColor: "transparent", // Ensure background remains transparent when disabled
          },
        }}
      >
        <MenuItem
          value="all"
          sx={{
            borderBottom: "1px solid #E3E7EF",
            padding: "9px 15px",
            gap: "0px",
          }}
        >
          <ListItemIcon sx={{ marginRight: "-5px" }}>
            <Checkbox
              checked={isAllSelected}
              sx={{
                color: "gray",
                height: "20px",
                width: "20px",
                "&.Mui-checked": {
                  color: "black",
                },
              }}
            />
          </ListItemIcon>
          <ListItemText
            primary="Select All"
            sx={{ textAlign: "start", margin: "0 auto" }} // Center text in the menu item
          />
        </MenuItem>
        {options?.map((option, index) => (
          <MenuItem
            key={option?.id}
            value={option?.id}
            sx={{
              borderBottom:
                index === options.length - 1 ? "none" : "1px solid #E3E7EF",
              padding: "9px 15px",
              gap: "0px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <ListItemIcon sx={{ marginRight: "-5px" }}>
              <Checkbox
                checked={
                  value?.findIndex &&
                  value.findIndex((obj) => obj?.id === option?.id) > -1
                }
                sx={{
                  color: "gray",
                  height: "20px",
                  width: "20px",
                  "&.Mui-checked": {
                    color: "black",
                  },
                }}
              />
            </ListItemIcon>
            <ListItemText
              primary={option.name}
              sx={{ textAlign: "start", margin: "0 auto" }} // Center text in the menu item
            />
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{errorMessage}</FormHelperText>
    </FormControl>
  );
};

export default MultiSelect;
