import React, { useEffect } from "react";
import { Grid, Box } from "@mui/material";
import Label from "../../UIComponents/Label";
import { useDispatch, useSelector } from "react-redux";
// import MuiSelect from "../../UIComponents/MuiSelect";
import {
  // getCategoryFunction,
  getSubCategoryFunction,
  getProductFunction,
  getCategoryFunction,
  // getMerchantFunction,
} from "../../Redux/OfferReducer/action";
// import MuiSelectAndSearch from "../../UIComponents/MuiSelectAndSearch";
import { storeProductId } from "../../Redux/CreateRuleReducer/actionType";
// import MuiMultiSelectWithSearchAndCheckBox from "../../UIComponents/MuiMultiSelectWithSearchAndCheckBox";
import MultiSelect from "../../UIComponents/MultiSelect";
// import MultiSelectAll from "../../UIComponents/MultiSelectAll";

const BasicOffers = ({
  onChange,
  data,
  errorMessage,
  handleBasicOffersError,
  isEdit = false,
}) => {
  const dispatch = useDispatch();

  const handleChange = (key, value) => {
    if (errorMessage?.[key]) {
      handleBasicOffersError((prev) => {
        return {
          ...prev,
          [key]: "",
        };
      });
    }
    onChange({ ...data, [key]: value });
    if (key === "category") {
      onChange({ ...data, [key]: value, subCategory: [], product: [] });
      const categoryIds =
        value &&
        value?.map((e) => {
          return e?.id;
        });
      dispatch(getSubCategoryFunction(categoryIds));
    }
    if (key === "subCategory") {
      onChange({ ...data, [key]: value, product: [] });
      const subCategoryIds =
        value &&
        value?.map((e) => {
          return e?.id;
        });
      dispatch(getProductFunction(subCategoryIds));
    }

    if(key==="product"){
      const productId = value &&
      value?.map((e) => {
        return e?.id;
      });
      dispatch({type:storeProductId,payload:productId})
      console.log(productId,"value")
    }
  };
  const token = useSelector(store=>store.AuthReducer.token);

  const categoryData = useSelector(
    (store) => store.OfferReducer.categoryOption
  );
  const subCategoryData = useSelector(
    (store) => store.OfferReducer.subCategoryOption
  );
  const productData = useSelector((store) => store.OfferReducer.productOption);

  const categoryOption = categoryData.map((element) => {
    return { name: element.name, id: element.id };
  });

  const subCategoryOption = subCategoryData.map((element) => {
    return { name: element.name, id: element.id };
  });

  const productOption = productData.map((element) => {
    return { name: element.name, id: element.id };
  });

  console.log(categoryOption,subCategoryOption,productOption)


console.log(data,"basicOffer")
  useEffect(() => {
    
    if (data.category?.length>0) {
      const categoryIds =data?.category?.map && data?.category?.map((e)=>{
        return e?.id
      })
      console.log(categoryIds,"categoryIds")
      dispatch(getSubCategoryFunction(categoryIds,token));
      // console.log("hello")
    }
    if (data.subCategory?.length>0) {
      const subCategoryIds =data?.subCategory?.map&& data?.subCategory?.map((e)=>{
        return e?.id
      })
      dispatch(getProductFunction(subCategoryIds,token));
    }

    if(data.product){
      dispatch({type:storeProductId,payload:data.product?.id})
      
    }
  }, [data]);

  useEffect(()=>{
    dispatch(getCategoryFunction(token))
  },[])

  return (
    <Grid
      paddingRight="20px"
      container
      rowSpacing={1}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
    >
      <Grid item xs={12} sm={12} md={6}>
        <Box
          sx={{
            marginRight: { sx: "0px", sm: "0px", md: "70px" },
            marginBottom: isEdit ? "20px" : "40px",
          }}
        >
          <Label label="1. Select Category" />
          <Box sx={{ maxWidth: "510px" }}>
            <MultiSelect
              options={categoryOption}
              value={data?.category??""}
              onChange={(value) => handleChange("category", value)}
              errorMessage={errorMessage?.category}
              editBoolean={data?.previous_category?.length>0}
            />
            {/* <MuiSelectAndSearch
              options={categoryOption}
              value={data?.category ?? ""}
              onChange={(value) => handleChange("category", value)}
              errorMessage={errorMessage?.category}
            /> */}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Box
          sx={{
            marginLeft: { sx: "0px", sm: "0px", md: "70px" },
            marginBottom: isEdit ? "20px" : "40px",
          }}
        >
          <Label label="2. Select Sub Category" />
          <Box sx={{ maxWidth: "510px" }}>
            {/* <MuiSelect
              // disable={subCategoryOption.length === 0}
              options={subCategoryOption}
              value={data?.subCategory??""}
              onChange={(value) => handleChange("subCategory", value)}
              errorMessage={errorMessage?.subCategory}
            /> */}
            <MultiSelect
              options={subCategoryOption}
              value={data?.subCategory ?? ""}
              onChange={(value) => handleChange("subCategory", value)}
              errorMessage={errorMessage?.subCategory}
              editBoolean={data?.previous_subCategory?.length>0}
            />
            {/* <MuiSelectAndSearch
              options={subCategoryOption}
              value={data?.subCategory ?? ""}
              onChange={(value) => handleChange("subCategory", value)}
              errorMessage={errorMessage?.subCategory}
            /> */}
          </Box>
        </Box>
      </Grid>
      {/* <Grid item xs={12} sm={12} md={6}>

      </Grid> */}
      <Grid item xs={12} sm={12} md={6}>
        <Box
          sx={{
            marginRight: { sx: "0px", sm: "0px", md: "70px" },
            marginBottom: isEdit ? "20px" : "40px",
          }}
        >
          <Label label="3. Select Product" />
          <Box sx={{ maxWidth: "510px" }}>
            {/* <MuiSelect
              // disable={data?.merchant.length === 0}
              options={productOption}
              value={data?.product}
              onChange={(value) => handleChange("product", value)}
              errorMessage={errorMessage?.product}
            /> */}
            <MultiSelect
              options={productOption}
              value={data?.product}
              onChange={(value) => handleChange("product", value)}
              errorMessage={errorMessage?.product}
              editBoolean={data?.previous_product?.length>0}
            />
            {/* <MuiSelectAndSearch
              options={productOption}
              value={data?.product}
              onChange={(value) => handleChange("product", value)}
              errorMessage={errorMessage?.product}
            /> */}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default BasicOffers;
